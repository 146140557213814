import React, { useEffect, useState } from 'react'
import axios from '../../api/axios'
import { Box, Button, Grid, Stack, Typography, DialogActions, Dialog, DialogTitle, DialogContent, DialogContentText, Snackbar, Skeleton, LinearProgress, TextField, InputLabel, FormControl, Select, MenuItem } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import Notify from '../../features/helpers/notifyConfig';
import { Cancel } from '@mui/icons-material';
import { CertTable } from './CertTable';
import { CertForm } from './CertForm';
import { isSuperAdmin } from '../../components/auth/userAuth';

export const CertIndex = () => {
    const [type, selectType] = useState("My Certificates");
    const [certs, setCerts] = useState("");
    const [Rcerts, setRCerts] = useState("");
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [editCert, seteditCert] = useState("");
    const [data, setData] = useState({
        "Course_Name": "",
        "Issuing_Authority": "",
        "Issue_Date": "",
        "Certificate_ID": "",
        "Expiry_Date": "",
        "Document": "",
        "Tag": ""
    });

    const handleClose = () => {
        setOpen(false);
    };

    const handleClickOpen = () => {
        setOpen(true);
        seteditCert("");
        setData({
            "Course_Name": "",
            "Issuing_Authority": "",
            "Issue_Date": "",
            "Certificate_ID": "",
            "Expiry_Date": "",
            "Document": "",
            "Tag": ""
        });
    };

    useEffect(() => {
        if (editCert != "") {
            setOpen(true);
            setData(editCert);
        }
    }, [editCert])

    const submitAddCert = () => {
        let hasWarning = false;

        if (data.Course_Name === "") {
            Notify.warning("Certificate Name must be mentioned!");
            hasWarning = true;
        }
        else if (data.Issuing_Authority === "") {
            Notify.warning("Issuing Authority must be mentioned!");
            hasWarning = true;
        }
        else if (data.Issue_Date === "") {
            Notify.warning("Issue Date must be mentioned!");
            hasWarning = true;
        }
        else if (data.Document === "") {
            Notify.warning("Upload the Certificate first!");
            hasWarning = true;
        }

        if (!hasWarning) {
            // 1. Adding New Certificate
            const finData = {
                "Certificates": [
                    data
                ]
            }
            const addCert = async () => {
                await axios.post
                    ("/dashboard/certificates/add", JSON.stringify(finData),
                        {
                            headers: {
                                'Access-Control-Allow-Origin': '*',
                                'Content-Type': 'application/json',
                                withCredentials: true,
                                mode: 'no-cors',
                                'Authorization': `Bearer ` + localStorage.getItem('access')
                            }
                        }).then((response) => {
                            console.log(response);
                            setOpen(false);
                            setLoading(prev => !prev);
                            Notify.success("Certificate Added Successfully!")
                        }).catch((error) => {
                            console.log(error.response);
                            Notify.error("Error Occurred. Try Again!");
                        })
            }
            addCert();
        }
    }

    // 1. Get All Certificates
    const getcerts = async () => {
        await axios.get("/dashboard/certificates/all",
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ` + localStorage.getItem('access'),
                    'Access-Control-Allow-Origin': '*',
                    mode: 'no-cors'
                }
            }
        ).then((response) => {
            console.log(response?.data?.Certificates);
            setCerts(response?.data?.Certificates);
        }).catch((error) => {
            console.log(error.response)
        });
    }


    // 2. Get All Reviewing Certificates
    const getReviewcerts = async () => {
        await axios.get("/dashboard/certificates/review",
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ` + localStorage.getItem('access'),
                    'Access-Control-Allow-Origin': '*',
                    mode: 'no-cors'
                }
            }
        ).then((response) => {
            console.log(response?.data?.Review_certificates);
            setRCerts(response?.data?.Review_certificates);
        }).catch((error) => {
            console.log(error.response)
        });
    }

    useEffect(() => {
        getcerts();
        getReviewcerts();
    }, [loading])

    return (
        <Box py={2} px={4}>
            {
                (certs != "")
                    ?
                    <Box>
                        <Stack direction='row' justifyContent='space-between'>
                        <Button sx={{ marginBottom: '1rem' }} onClick={handleClickOpen} variant='contained' size='small'>Add&nbsp;&nbsp;<AddIcon /></Button>
                        {isSuperAdmin(
                        <FormControl sx={{width:'140px'}}>
                            <InputLabel id="demo-simple-select-label">Status</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={type}
                                label="Status"
                                onChange={() => {
                                    type == "Pending" ? selectType("My Certificates") : selectType("Pending");
                                }}
                            >
                                <MenuItem value="Pending"><Typography variant='subtitle2'>Pending</Typography></MenuItem>
                                <MenuItem value="My Certificates"><Typography variant='subtitle2'>My Certificates</Typography></MenuItem>
                            </Select>
                        </FormControl>,<></>)
                        }
                        </Stack>

                        {
                           type == "Pending" 
                           ?<CertTable table_data={Rcerts} setLoading={setLoading} type="pending" />
                           :<CertTable table_data={certs} setLoading={setLoading} />
                        }
                        <Dialog
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="edit-apartment"
                            fullWidth
                            maxWidth='sm'
                        >
                            <DialogTitle sx={{ backgroundColor: "#F6F5F5" }} id="edit-apartment">Add Certificate</DialogTitle>
                            <DialogContent sx={{ backgroundColor: "#F6F5F5" }}>
                                <CertForm data={data} setData={setData} />
                            </DialogContent>
                            <DialogActions sx={{ backgroundColor: "#F6F5F5" }}>
                                <Button sx={{ position: "absolute", top: 10, right: 0 }} onClick={handleClose} color="secondary">
                                    <Cancel sx={{ fontSize: "1.8rem" }} />
                                </Button>
                                <Button variant='contained' onClick={() => submitAddCert()} color="primary">Add</Button>
                            </DialogActions>
                        </Dialog>
                    </Box>
                    :
                    <Stack justifyContent="center" alignItems="center" height="70vh">
                        <Box pb={2}>Wait while we gather required information...</Box>
                        <Stack gap={4} sx={{ width: '50%', color: 'grey.500' }} spacing={2}>
                            <LinearProgress color="inherit" />
                        </Stack>
                    </Stack>
            }
        </Box>
    )
}
