import { Close } from '@mui/icons-material';
import { Box, IconButton, InputAdornment, TextField } from '@mui/material'
import { useEffect } from 'react';

export const ContributionInput = ({ setContribution, contribution, alignment }) => {

    const setValues = (val, index) => {
        const updatedArray = [...contribution[alignment]];
        updatedArray[index] = val;
        setContribution({...contribution, [alignment]: updatedArray });
    } 

    const handleClear = (index) => {
        const updatedArray = [...contribution[alignment]];
        updatedArray.splice(index, 1);
        setContribution({...contribution, [alignment]: updatedArray });
    }
    
    return (
        <Box mt={2}>
            {
                contribution[alignment]
                    ? contribution[alignment].map((value, key) => {
                        return (
                            <TextField
                                label = {`Title ${key+1}`}
                                value={value}
                                onChange={(e) => setValues(e.target.value, key)}
                                key={key}
                                fullWidth
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => handleClear(key)}
                                                edge="end"
                                            >
                                                <Close color="error" />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                                sx={{my: '0.6rem'}}
                            />
                        )
                    })
                    : <></>
            }
        </Box>
    )
}
