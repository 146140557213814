import { Box, Grid, Stack, Typography, TextField, MenuItem, Button, InputAdornment, InputLabel, Select, Tooltip } from "@mui/material"
import { VisuallyHiddenInput } from "../../EmployeeData/EmployeeDocument"
import Notify from "../../../features/helpers/notifyConfig"
import React, { useState } from 'react'
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn'
import DescriptionIcon from '@mui/icons-material/Description';
import { UploadIcon } from "../../../components/Icons/UploadIcon"
import { DateRangePicker } from "rsuite"
import '../Styles/DateStyle.css'
import axios from "../../../api/axios"
import { convertToBase64 } from "../../Certificates/CertForm"
import InfoIcon from '@mui/icons-material/Info';
import { styled } from '@mui/material/styles';
import { tooltipClasses } from '@mui/material/Tooltip';

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip arrow {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 200,
  },
});


const reimbursementType = [
    { value: 'Hotel' },
    { value: 'Transport' },
    { value: 'Fuel' },
    { value: 'Meals' },
    { value: 'Phone' },
    { value: 'Entertainment' },
    { value: 'Miscellaneous' },
]

const InputHeight = {
    '.MuiInputBase-root': {
        background: 'lightgrey !important',
        height: '35px !important',
        background: '#e3e3e3',
        paddingLeft: '0px !important',
    },
    '.MuiOutlinedInput-notchedOutline': {
        borderWidth: '1px !important',
    }
}

const ButtonStyle = {
    '&.MuiButtonBase-root': {
        height: '38px !important',
        padding: '0px 45px',
        background: '#198f51'
    }
}

const Button2Style = {
    '&.MuiButtonBase-root': {
        height: '38px !important',
        padding: '0px 35px'
    }
}

const UploadStyle = {
    '&.MuiButtonBase-root': {
        height: '38px !important',
        width: '75%',
        border: 'solid 1px #4769c3',
        backgroundColor: '#CCDAFF',
        color: '#4769c3'
    }
}

const infoMessage = {
    PRJCT_TYPE_INFO: "Select the project associated with this claim (If you're unsure, select EC Internal).",
    DURATION_INFO: 'Enter the duration time of your claim',
    ADVANCES_INFO: 'If company provided you any advance money, then please enter here',
    PURPOSE_INFO: 'Please enter the purpose of this claim in brief',
}

const InputStyle = ({ label, input, pr = '', info }) => {
    return (
        <>
            <Stack direction='row' spacing={0.5} alignItems='center'>
                <Typography mb={0.5} pl={0.5} variant="subtitle2">{label}{pr != '' ? <span style={{ color: 'red', paddingLeft: '2px' }}>*</span> : <></>}</Typography>
                {info ? <Box sx={{transform:'translateY(-0.5px)'}}><CustomWidthTooltip
                    title={info}
                ><InfoIcon sx={{ fontSize: '12px', color: '#4769c3' }} /></CustomWidthTooltip></Box> : <></>}
            </Stack>
            {input}
        </>
    )
}

const UploadBtn = ({ ied }) => {
    return (
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { width: '75%' },
            }}
            noValidate
            autoComplete="off"
        >
            <TextField type="date" sx={InputHeight} id={ied} variant="outlined" />
        </Box>
    )
}

export const ExpenseForm = ({ infodata, setData, data }) => {
    const [filename, setFilename] = useState("");
    const [file, setFile] = useState();
    const [curritem, setCurrItem] = useState({
        "date": "",
        "items": [
            {
                "category": "",
                "description": "",
                "amount": "",
                "receipt": ""
            }
        ]
    });

    // const checkField = () => {
    //     if (!field) {
    //         setData((prevData) => ({
    //             ...prevData,
    //             claims: [
    //                 ...prevData.claims,
    //                 {
    //                     "date": "",
    //                     "items": []
    //                 }
    //             ]
    //         }));
    //         newField(true);
    //     }
    // }

    const triggerChange = (e, id) => {
        const value = e.target.value;
        // console.log(value);
        if (id == "projectID" || id == "advances" || id == "purpose") {
            setData((prev) => ({
                ...prev, [id]: id == "advances" ? Number(value) : value
            }))
        }
        else if (id == "category" || id == "description" || id == "amount") {
            setCurrItem((prev) => {
                const newItems = [...prev.items];
                newItems[0][id] = id == "amount" ? Number(value) : value;
                return {
                    ...prev,
                    items: newItems
                };
            })
        }
        else if (id == "date") {
            setCurrItem((prev) => {
                return {
                    ...prev,
                    "date": value
                };
            })
        }
    }

    const CurrencySelector = () => {
        return (
            <Box>
                <Select
                    sx={{ borderRadius: '5px 0 0 5px !important', marginRight: '10px' }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={data.currency}
                    onChange={(e) => setData({ ...data, currency: e.target.value })}
                >
                    <MenuItem value="$">$</MenuItem>
                    <MenuItem value="₹">₹</MenuItem>
                </Select>
            </Box>
        )
    }

    //file handle/upload
    const handleChange = async (e) => {
        const file = e.target.files[0];
        console.log(file.size);
        var fileExt = file.name.split('.').pop().toLowerCase();
        if (fileExt != "pdf") {
            setFile();
            setFilename();
            Notify.warning("Only pdf format is allowed!")
        }
        else {
            if (file.size < 900000) {
                // setFile(file);
                setFilename(file.name);
                const base64 = await convertToBase64(file);
                setCurrItem((prev) => {
                    const newItems = [...prev.items];
                    newItems[0].receipt = base64;
                    return {
                        ...prev,
                        items: newItems
                    };
                })
                // console.log(file);
            }
            else {
                setFile();
                setFilename();
                Notify.warning("Please ensure the size of file should be less than 900KB")
            }
        }
    }

    const DropdownInput = ({ ied, array, val, label, vaal }) => {
        return (
            <Box
                sx={{
                    '& .MuiTextField-root': { width: '75%' },
                }}
            >
                <TextField
                    id={ied}
                    value={vaal}
                    select
                    sx={InputHeight}
                    onChange={(e) => triggerChange(e, ied)}
                >
                    {array.map((option, key) => (
                        <MenuItem key={key} value={option[val]}>
                            {option[label]}
                        </MenuItem>
                    ))}
                </TextField>
            </Box>
        )
    }

    const DateFieldInput = ({ ied, vaal }) => {
        return (
            <Box
                sx={{
                    '& .MuiTextField-root': { width: '75%' },
                }}
            >
                <TextField
                    onChange={(e) => triggerChange(e, ied)}
                    value={vaal} type="date" sx={InputHeight} id={ied} variant="outlined" />
            </Box>
        )
    }

    const dateChange = (val) => {
        if (val != null) {
            const startDate = new Date(val[0]);
            const endDate = new Date(val[1]);
            const stDate = `${startDate.getFullYear()}-${startDate.getMonth() + 1}-${startDate.getDate()}`;
            const enDate = `${endDate.getFullYear()}-${endDate.getMonth() + 1}-${endDate.getDate()}`;
            setData((prev) => ({
                ...prev,
                "duration": {
                    "from": stDate,
                    "to": enDate
                }
            }))
        }
    }

    const TextFieldInput = ({ ied, startSign, vaal }) => {
        return (
            <Box
                sx={{
                    '& .MuiTextField-root': { width: '75%' },
                }}
            >
                <TextField
                    value={vaal}
                    onChange={(e) => triggerChange(e, ied)}
                    InputProps={{ startAdornment: <InputAdornment position="start">{startSign}</InputAdornment> }}
                    sx={InputHeight} id={ied} variant="outlined" />
            </Box>
        )
    }

    //Submit Expense Report
    const addExpenseReport = () => {
        if (curritem.items[0].category == "") Notify.warning("Reimbursement type must be provided");
        else if (curritem.items[0].amount == "") Notify.warning("Expense amount must be provided");
        else if (curritem.date == "") Notify.warning("Expense Date must be provided");
        else if (curritem.items[0].receipt == "") Notify.warning("Please upload the invoice for proof");
        else {
            setData((prev) => {
                const newClaims = [...prev.claims];
                newClaims.push(curritem);
                return {
                    ...prev,
                    claims: newClaims
                }
            })

            setCurrItem({
                "date": "",
                "items": [
                    {
                        "category": "",
                        "description": "",
                        "amount": "",
                        "receipt": ""
                    }
                ]
            })
            setFile();
            setFilename("");
        }
    }

    return (
        <>
            {/* Global Inputs */}
            <Grid container rowSpacing={1} bgcolor='#e8e5cca1' alignItems='end' pb={2} px={1} mb={1} borderRadius='5px'>
                <Grid item xl={3} md={2.7}>
                    <InputStyle pr='imp' label='Type of Project' info={infoMessage['PRJCT_TYPE_INFO']} input={
                        <Box
                            sx={{
                                '& .MuiTextField-root': { width: '75%' },
                            }}
                        >
                            <TextField
                                id={'projectID'}
                                value={data.projectID}
                                select
                                sx={InputHeight}
                                onChange={(e) => triggerChange(e, 'projectID')}
                            >
                                {infodata.Projects.map((option, key) => (
                                    <MenuItem key={key} value={option.projectID}>
                                        {option.projectName}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Box>}
                    />
                </Grid>

                <Grid item lg={3} md={2.7}>
                    <InputStyle pr='imp' label='Duration' info={infoMessage['DURATION_INFO']} input={<DateRangePicker className="custom-date-range-picker" id="duration" onChange={(val) => dateChange(val)} />} />
                </Grid>

                <Grid item xl={3} md={2.7}>
                    <InputStyle label='Advances' info={infoMessage['ADVANCES_INFO']} input={
                        <Box
                            sx={{
                                '& .MuiTextField-root': { width: '75%' },
                            }}
                        >
                            <TextField
                                type="number"
                                value={data.advances}
                                onChange={(e) => triggerChange(e, 'advances')}
                                InputProps={{ startAdornment: <CurrencySelector></CurrencySelector> }}
                                sx={InputHeight} id={'advances'} variant="outlined" />
                        </Box>}
                    />
                </Grid>

                <Grid item xl={3} lg={3} md={4}>
                    <InputStyle pr='imp' label='Purpose' info={infoMessage['PURPOSE_INFO']} input={
                        <Box
                            sx={{
                                '& .MuiTextField-root': { width: '75%' },
                            }}
                        >
                            <TextField
                                value={data.purpose}
                                onChange={(e) => triggerChange(e, 'purpose')}
                                // InputProps={{ startAdornment: <InputAdornment position="start"></InputAdornment> }}
                                sx={InputHeight} id={'purpose'} variant="outlined" />
                        </Box>}
                    />
                </Grid>

            </Grid>

            {/* Local Inputs */}
            <Grid container rowSpacing={1.5} px={1} alignItems='end'>
                <Grid item xl={3} lg={3} md={4}>
                    <InputStyle pr='imp' label='Type of Reimbursement' input={
                        <Box
                            sx={{
                                '& .MuiTextField-root': { width: '75%' },
                            }}
                        >
                            <TextField
                                id={'category'}
                                value={curritem.items[0].category}
                                select
                                sx={InputHeight}
                                onChange={(e) => triggerChange(e, 'category')}
                            >
                                {reimbursementType.map((option, key) => (
                                    <MenuItem key={key} value={option.value}>
                                        {option.value}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Box>} />
                </Grid>

                <Grid item xl={3} lg={3} md={4}>
                    <InputStyle pr='imp' label='Expense Date' input={
                        <Box
                            sx={{
                                '& .MuiTextField-root': { width: '75%' },
                            }}
                        >
                            <TextField
                                onChange={(e) => triggerChange(e, 'date')}
                                value={curritem.date} type="date" sx={InputHeight} id={'date'} variant="outlined" />
                        </Box>
                    } />
                </Grid>

                <Grid item xl={3} lg={3} md={4}>
                    <InputStyle pr='imp' label='Amount' input={
                        <Box
                            sx={{
                                '& .MuiTextField-root': { width: '75%' },
                            }}
                        >
                            <TextField
                                type="number"
                                value={curritem.items[0].amount}
                                onChange={(e) => triggerChange(e, 'amount')}
                                InputProps={{ startAdornment: <CurrencySelector></CurrencySelector> }}
                                sx={InputHeight} id={'amount'} variant="outlined" />
                        </Box>}
                    />
                </Grid>

                <Grid item xl={3} lg={3} md={4}>
                    <InputStyle label='Description' input={
                        <Box
                            sx={{
                                '& .MuiTextField-root': { width: '75%' },
                            }}
                        >
                            <TextField
                                value={curritem.items[0].description}
                                onChange={(e) => triggerChange(e, 'description')}
                                InputProps={{ startAdornment: <InputAdornment position="end" sx={{ marginRight: "7px" }}><DescriptionIcon /></InputAdornment> }}
                                sx={InputHeight} id={'description'} variant="outlined" />
                        </Box>}
                    />
                </Grid>

                <Grid position='relative' item xl={3} lg={3} md={4}>
                    {<InputStyle label='Upload your Invoice' pr='imp' input={<Button
                        component="label"
                        // sx={{ borderStyle: "dashed", borderRadius: "5px", borderWidth: "1px" }}
                        sx={UploadStyle}
                        tabIndex={-1}>
                        <UploadIcon />
                        &nbsp;&nbsp;Upload here
                        <VisuallyHiddenInput onChange={handleChange} type="file" />
                    </Button>}
                    />}
                    <Typography
                        sx={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "-webkit-box",
                            WebkitLineClamp: "1",
                            WebkitBoxOrient: "vertical",
                        }}
                        position='absolute' fontFamily='Segoe UI' mt={0.5} color={filename ? 'green' : '#1b2c58'} fontWeight='600'
                        fontSize='10px' width='75%'>{filename ? filename : "File Size should be <900KB"}</Typography>
                </Grid>

                <Grid item xl={3} lg={3} md={4}>
                    <Button onClick={addExpenseReport} sx={Button2Style} size="small" variant="contained">Add</Button>
                </Grid>
            </Grid>
        </>
    )
}
