import React from 'react'

export const CertificatesApi = [
'(ISC)² Certified Authorization Professional(CAP)',
'AWS Certified Cloud Practitioner',
'AWS Certified Developer - Associate',
'AWS Certified DevOps Engineer - Professional',
'AWS Certified Security - Specialty',
'AWS Certified Solutions Architect - Associate',
'AWS Certified Solutions Architect - Professional',
'AWS Certified SysOps Administrator Associate',
'Certificate of Cloud Security Knowledge (CCSK)',
'Certified AppViewX Network Configuration Manager (ACNCM)',
'Certified AVD Professional (CAP)',
'Certified Blockchain Security Professional (CBSP)',
'Certified Cloud Security Knowledge (CCSK)',
'Certified Cloud Security Professional (CCSP)',
'Certified Ethical Hacker (CEH)',
'Certified Forensic Computer Examiner (CFCE)',
'Certified in Risk and Information Systems Control (CRISC)',
'Certified Information Privacy Professional (CIPP)',
'Certified Information Security Manager (CISM)',
'Certified Information Systems Auditor (CISA)',
'Certified Information Systems Security Professional (CISSP)',
'Certified Keyfactor Administrator (CKA)',
'Certified Keyfactor Consultant (CKC)',
'Certified Keyfactor Developer (CKD)',
'Certified Public Key Infrastructure Professional (CPKIP)',
'Certified Secure Software Lifecycle Professional (CSSLP)',
'Certified Wireless Security Engineer (CWSE)',
'Cisco Certified CyberOps Associate',
'Cloud Security Alliance (CSA)',
'CompTIA Advanced Security Practitioner (CASP+)',
'CompTIA Cloud+',
'CompTIA Cybersecurity Analyst (CySA+)',
'CompTIA PenTest+',
'CompTIA Security+',
'EC-Council Certified Security Analyst (ECSA)',
'Entrust Certificate Authority (CA) Administrator',
'Entrust Certificate Management System (CMS) Certified Administrator',
'Entrust IdentityGuard Administrator',
'Entrust IdentityGuard Certified Deployment Professional',
'Entrust IdentityGuard Certified System Administrator',
'Entrust PKI Administrator',
'GIAC Certified Incident Handler (GCIH)',
'GIAC Industrial Cyber Security Certification (GICSP)',
'GIAC Penetration Tester (GPEN)',
'GIAC Security Essentials (GSEC)',
'GIAC Security Expert (GSE)',
'Google Certified Professional (GCP) Cloud Architect',
'Google Certified Professional (GCP) Data Engineer',
'Google Certified Professional Cloud Architect',
'Google Certified Professional Data Engineer',
'Google Cloud – Professional Cloud Architect',
'Google Cloud Associate Cloud Engineer',
'Google Cloud Certified Cloud Digital Leader',
'Google Cloud Professional Cloud Architect',
'Google Cloud Professional Cloud Developer',
'Google Cloud Professional Data Engineer',
'Google Cybersecurity Professional Certificate',
'Healthcare Information Security and Privacy Practitioner (HCISPP)',
'IBM Certified Cloud Solution Architect v3',
'ISACA Certified in Risk and Information Systems Control (CRISC)',
'ISACA Certified Information Security Manager (CISM)',
'ISACA Certified Information Systems Auditor (CISA)',
'Keyfactor Certified Administrator (KCA)',
'Keyfactor Certified Professional (KCP)',
'Keyfactor Code Signing Certificate Management Certification',
'Keyfactor Code Signing Certificate Professional',
'Keyfactor Command Certification',
'Keyfactor PKI Professional Certification',
'Microsoft Azure Developer Associate',
'Microsoft Certified Azure Administrator Associate',
'Microsoft Certified: Azure Administrator Associate',
'Microsoft Certified: Azure Fundamentals',
'Microsoft Certified: Azure Security Engineer Associate',
'Microsoft Certified: Azure Solutions Architect Expert',
'Microsoft Certified: Microsoft Azure Fundamentals',
'nCipher Certified Hardware Engineer (NCHE)',
'nCipher Certified Integration Engineer (NCIE)',
'nCipher Certified Security Professional (NCSP)',
'nCipher Certified Systems Engineer (NCSE)',
'Offensive Security Certified Professional (OSCP)',
'Oracle Cloud Infrastructure (OCI) Foundations 2020 Certified Associate',
'SafeNet KeySecure Administrator',
'SafeNet Luna Certified HSM Administrator',
'SafeNet Luna Certified HSM Developer',
'SafeNet Luna Certified HSM Engineer',
'SafeNet Luna EFT HSM Certification',
'SafeNet Luna Network HSM Certification',
'SafeNet Luna PCIe HSM Certification',
'SafeNet Luna SA HSM Certification',
'SafeNet Trusted Platform Module (TPM) Security Officer',
'SANS GIAC Security Certifications',
'Security+ CE',
'Thales Certified Data Protection Expert (TCDPE)',
'Thales Certified Security Professional (TCSP)',
'Thales Certified System Engineer (TCSE)',
'Thales CipherTrust Manager Certification',
'Thales Cloud Security Expert',
'Thales Cybersecurity Architect (TCSA)',
'Thales Data Protection on Demand Certification',
'Thales Data Protection Professional (TDPP)',
'Thales Key Management System Professional (TKMSP)',
'Thales nShield System Administration Certification',
'Thales nShield Technical Certification',
'Venafi Certified Administrator (VCA)',
'Venafi Certified Expert (VCE)',
'Venafi Certified Professional (VCP)',
'Venafi Machine Identity Protection Fundamentals',
'Venafi Platform Administrator Certification',
'Venafi Platform API Integration Certification',
'Venafi Trust Protection Platform Expert Certification',
'Venafi TrustAssurance Administrator (VTAA)',
'Venafi TrustAssurance Platform Professional (VTAP)'
]
