import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Button, Grid, MenuItem, Typography, DialogActions, Dialog, DialogTitle, DialogContent, DialogContentText, InputAdornment, Chip, FormControlLabel, Switch, Divider, Stack, IconButton, Tooltip } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Cancel } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { SimpleForms } from '../../pages/QuoteGeneration/Forms/SimpleForms';
import Notify from '../../features/helpers/notifyConfig';
import axios from '../../api/axios';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ClearIcon from '@mui/icons-material/Clear';

// {ChancesOfWinning,Channel,Customers,EC_PointOfContact,Reseller,Stage,WonLost}
export function Form({ prop, data = "", type = "", setData, action = "" }) {
    // alert(data['Reseller'])
    const { ChancesOfWinning, Channel, TypeOfProject, Customer, EC_PointOfContact, Reseller, Stage, PaymentTerms, PaymentStatus, WonLost } = prop;
    const [showPassword, setShowPassword] = useState(false);
    const [filename, setFilename] = useState("");
    const optionsWithAddNew = ["Add New", ...TypeOfProject];
    const [open, setOpen] = useState(false);
    const [projType, setProjType] = useState(null);
    const [pr, setpr] = useState(null);
    const [alignment, setAlignment] = useState('product');
    const [pdata, setpData] = useState({
        "product_name": "",
        "product_code": "",
        "alias": "",
        "license_type": ""
    });
    const [sdata, setsData] = useState({
        "service_name": "",
        "service_code": "",
        "alias": "",
    });
    const capitName = alignment == "product" ? "Product" : "Service";

    const submitAddForm = () => {
        let hasWarning = false;
        if (alignment == "product") {
            if (pdata.product_name === "") {
                Notify.warning("Product Name must be mentioned!");
                hasWarning = true;
            }
            else if (pdata.product_code === "") {
                Notify.warning("Product Code must be mentioned!");
                hasWarning = true;
            }
            else if (pdata.alias === "") {
                Notify.warning("Product Alias must be mentioned!");
                hasWarning = true;
            }
            else if (pdata.license_type === "") {
                Notify.warning("License Type must be mentioned!");
                hasWarning = true;
            }
        }
        else {
            if (sdata.service_name === "") {
                Notify.warning("Service Name must be mentioned!");
                hasWarning = true;
            }
            else if (sdata.service_code === "") {
                Notify.warning("Service Code must be mentioned!");
                hasWarning = true;
            }
            else if (sdata.alias === "") {
                Notify.warning("Service Alias must be mentioned!");
                hasWarning = true;
            }
        }

        if (!hasWarning) {
            const findata = alignment == "product" ? pdata : sdata;
            const api = alignment == "product" ? "/quote-generation/product/add" : "/quote-generation/service/add";
            // Adding New Product/Service
            const addProdServ = async () => {
                await axios.post
                    (api, JSON.stringify(findata),
                        {
                            headers: {
                                'Access-Control-Allow-Origin': '*',
                                'Content-Type': 'application/json',
                                withCredentials: true,
                                mode: 'no-cors',
                                'Authorization': `Bearer ` + localStorage.getItem('access')
                            }
                        }).then((response) => {
                            setOpen(false);
                            setpData({
                                "product_name": "",
                                "product_code": "",
                                "alias": "",
                                "license_type": ""
                            });
                            setsData({
                                "service_name": "",
                                "service_code": "",
                                "alias": ""
                            });
                            setData(prevState => ({
                                ...prevState,
                                "TypeOfProject": findata.alias
                            }))
                            setpr(findata.alias);
                            Notify.success(`${capitName} Added Successfully!`)
                        }).catch((error) => {
                            console.log(error.response);
                            Notify.error("Error Occurred. Try Again!");
                        })
            }
            addProdServ();
        }
    }

    const handleChange = (event, newAlignment) => {
        setAlignment(newAlignment);
    };

    const handleClickOpen = () => {
        setOpen(true);
        setProjType("sd");
    };

    const handleClose = () => {
        setOpen(false);
    };

    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });

    const handleAddData = (e, val) => {
        // const val = e.target.value;

        if (e && e.target) {
            const naam = e.target.id.split('-')[0];
            if (naam == "TypeOfProject" && val == "Add New") val = "";
            setData(prevState => ({
                ...prevState,
                [naam]: val
            }))
        }
    }

    const handleSwitchChange = (event) => {
        setData(prevState => ({
            ...prevState,
            is_Followup: event.target.checked
        }))
    };

    const handleAddInputData = (e) => {
        const val = e.target.value;
        const naam = e.target.id;

        setData(prevState => ({
            ...prevState,
            [naam]: val
        }))
        // console.log(data)
    }

    const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
                resolve(fileReader.result);
            };
            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };

    const handleFileUpload = async (e) => {
        const file = e.target.files[0];
        setFilename(file.name);
        const base64 = await convertToBase64(file);
        setData(prevState => ({
            ...prevState,
            PO_Order: base64
        }))
    };

    const addNotes = () => {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const day = String(today.getDate()).padStart(2, '0');

        const updatedNotes = [...data.NotesOnFollow];
        updatedNotes.unshift({
            "note": "",
            "date": `${year}-${month}-${day}`
        });
        setData((prevState) => ({
            ...prevState,
            NotesOnFollow: updatedNotes,
        }));
    }

    const DeleteNotes = (index) => {
        const temp = data.NotesOnFollow;
        setData((prevState) => ({
            ...prevState,
            NotesOnFollow: temp.filter((value, key) => key != index)
        }));
    }

    const handleAddNotes = (e, index, type) => {
        const updatedNotes = [...data.NotesOnFollow];
        updatedNotes[index][type] = e.target.value;

        setData((prevState) => ({
            ...prevState,
            NotesOnFollow: updatedNotes,
        }));
    }

    return (
        <Box sx={{ display: 'flex', flexWrap: 'wrap' }} mt={1}>
            <Grid container>
                <Grid item xs={6} p={1}>
                    <Autocomplete
                        id="Customer"
                        value={data['Customer']}
                        sx={{ width: "100%" }}
                        freeSolo
                        options={Customer.map((option) => option)}
                        renderInput={(params) => <TextField {...params} required label="Customer" />}
                        onInputChange={handleAddData}
                    />
                </Grid>

                {(type != "sale" && action != "transfer") ?
                    (pr ?
                        <Grid item xs={6} p={1}>
                            <TextField
                                required
                                value={pr}
                                disabled
                                // id="TypeOfProject"
                                sx={{ width: "100%" }}
                                // onChange={handleAddInputData}
                                label="Type of Project"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Chip size="small" label={capitName} />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        :
                        <Grid item xs={6} p={1}>
                            <Autocomplete
                                id="TypeOfProject"
                                value={data['TypeOfProject']}
                                sx={{ width: "100%" }}
                                freeSolo
                                options={optionsWithAddNew}
                                renderInput={(params) => <TextField {...params} required label="Type of Project" />}
                                onInputChange={handleAddData}
                                renderOption={(props, option) => (
                                    <li {...props}>
                                        {option === "Add New" ? (
                                            <Typography sx={{ color: 'green', fontWeight: '600', width: '100%', height: '100%' }} onClick={handleClickOpen}>Add New</Typography>
                                        ) : (
                                            option
                                        )}
                                    </li>
                                )}
                                getOptionLabel={(option) => (option === "Add New" ? "" : option)}
                            />
                        </Grid>
                    )
                    :
                    <Grid item xs={6} p={1}>
                        <TextField
                            required
                            value={data['TypeOfProject']}
                            id="TypeOfProject"
                            sx={{ width: "100%" }}
                            onChange={handleAddInputData}
                            label="Type of Project"
                        />
                    </Grid>
                }

                <Grid item xs={6} p={1}>
                    <Autocomplete
                        id="Channel"
                        value={action == "transfer" ? "" : data['Channel']}
                        sx={{ width: '100%' }}
                        freeSolo
                        options={Channel.map((option) => option)}
                        renderInput={(params) => <TextField {...params} required label="Referral Channel" />}
                        onChange={handleAddData}
                    />
                </Grid>

                <Grid item xs={6} p={1}>
                    <Autocomplete
                        id="Reseller"
                        value={data['Reseller']}
                        sx={{ width: '100%' }}
                        freeSolo
                        options={Reseller.map((option) => option)}
                        renderInput={(params) => <TextField {...params} required label="Reseller" />}
                        onInputChange={handleAddData}
                    />
                </Grid>

                <Grid item xs={6} p={1}>
                    <Autocomplete
                        id="EC_PointOfContact"
                        value={data['EC_PointOfContact']}
                        sx={{ width: '100%' }}
                        freeSolo
                        options={EC_PointOfContact.map((option) => option)}
                        onInputChange={handleAddData}
                        renderInput={(params) => <TextField {...params} required label="EC-Point of contact" />}
                    />
                </Grid>

                {(type != "sale" && action != "transfer") ?
                    <>
                        <Grid item xs={6} p={1}>
                            <TextField
                                id="Client_PointOfContact"
                                value={data['Client_PointOfContact']}
                                label="Client-Point of Contact"
                                sx={{ width: '100%' }}
                                onChange={handleAddInputData}
                                type="tel"
                            />
                        </Grid>

                        <Grid item xs={6} p={1}>
                            <TextField
                                id="Client_POC_Email"
                                value={data['Client_POC_Email']}
                                label="Client-POC Email"
                                type="email"
                                onChange={handleAddInputData}
                                sx={{ width: '100%' }}
                            />
                        </Grid>
                    </>
                    : <></>
                }

                {(type == "sale" || action == "transfer") ?
                    <>
                        <Grid item xs={6} p={1} >
                            <TextField
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                id="DateSold"
                                required
                                value={data['DateSold']}
                                label="Date Sold"
                                sx={{ width: '100%' }}
                                onChange={handleAddInputData}
                                type="date"
                            />
                        </Grid>
                        {console.log(data['DateSold'])}
                        <Grid item xs={6} p={1} >
                            <TextField
                                id="Quarter"
                                label="Quarter"
                                value={data['DateSold'] == "" ? Math.ceil((new Date().getMonth() + 1) / 3) : Math.ceil((new Date(data['DateSold']).getMonth() + 1) / 3)}
                                onChange={handleAddInputData}
                                sx={{ width: '100%' }}
                                disabled
                            />
                        </Grid>

                        <Grid item xs={6} p={1} >
                            <TextField
                                id="Year"
                                label="Year"
                                sx={{ width: '100%' }}
                                value={new Date().getFullYear()}
                                onChange={handleAddInputData}
                                disabled
                            />
                        </Grid>
                    </>
                    : <></>}

                <Grid item xs={6} p={1} >
                    <Autocomplete
                        id="Stage"
                        value={data['Stage']}
                        sx={{ width: '100%' }}
                        freeSolo
                        options={Stage.map((option) => option)}
                        onInputChange={handleAddData}
                        renderInput={(params) => <TextField {...params} label="Stage" />}
                    />
                </Grid>

                <Grid item xs={6} p={1} >
                    <TextField
                        id="ProjectSize"
                        value={data['ProjectSize']}
                        label="Project Size"
                        onChange={handleAddInputData}
                        sx={{ width: '100%' }}
                    />
                </Grid>

                {(type != "sale" && action != "transfer") ?
                    <>
                        <Grid item xs={6} p={1} >
                            <Autocomplete
                                id="ChancesOfWinning"
                                value={data['ChancesOfWinning']}
                                sx={{ width: '100%' }}
                                freeSolo
                                options={ChancesOfWinning.map((option) => option)}
                                onInputChange={handleAddData}
                                renderInput={(params) => <TextField {...params} label="Chances of Winning" />}
                            />
                        </Grid>

                        <Grid item xs={6} p={1} >
                            <Autocomplete
                                id="WonLost"
                                value={data['WonLost']}
                                sx={{ width: '100%' }}
                                freeSolo
                                options={WonLost.map((option) => option)}
                                onInputChange={handleAddData}
                                renderInput={(params) => <TextField {...params} label="Won/Lost" />}
                            />
                        </Grid>

                        <Grid item xs={6} p={1} >
                            <TextField
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                id="Followup_Date"
                                value={data['Followup_Date']}
                                label="Followup Date"
                                sx={{ width: '100%' }}
                                onChange={handleAddInputData}
                                type="date"
                            />
                        </Grid>
                    </>
                    : <></>}

                {(type == "sale" || action == "transfer") ?
                    <>
                        <Grid item xs={6} p={1} >
                            <TextField
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                id="InvoiceIssueDate"
                                value={data['InvoiceIssueDate']}
                                label="Invoice IssueDate"
                                sx={{ width: '100%' }}
                                onChange={handleAddInputData}
                                type="date"
                            />
                        </Grid>

                        <Grid item xs={6} p={1} >
                            <Autocomplete
                                id="PaymentTerms"
                                value={data['PaymentTerms']}
                                sx={{ width: '100%' }}
                                freeSolo
                                options={PaymentTerms.map((option) => option)}
                                onInputChange={handleAddData}
                                renderInput={(params) => <TextField {...params} label="Payment Terms" />}
                            />
                        </Grid>

                        <Grid item xs={6} p={1} >
                            <TextField
                                id="DelinquentTerms"
                                value={data['DelinquentTerms']}
                                label="Delinquent Terms"
                                sx={{ width: '100%' }}
                                onChange={handleAddInputData}
                            />
                        </Grid>

                        <Grid item xs={6} p={1} >
                            <Autocomplete
                                id="PaymentStatus"
                                value={data['PaymentStatus']}
                                sx={{ width: '100%' }}
                                freeSolo
                                options={PaymentStatus.map((option) => option)}
                                onInputChange={handleAddData}
                                renderInput={(params) => <TextField {...params} label="Payment Status" />}
                            />
                        </Grid>

                        <Grid item xs={12} p={1} display="flex" alignItems="center">
                            <Button
                                component="label"
                                role={undefined}
                                variant="contained"
                                tabIndex={-1}
                                startIcon={<CloudUploadIcon />}
                            >
                                Upload PO Order
                                <VisuallyHiddenInput required onChange={(e) => handleFileUpload(e)} type="file" />
                            </Button>
                            <Typography color="red">{filename}</Typography>
                            <span style={{ color: "red", paddingLeft: "5px" }}>*</span>
                        </Grid>

                    </>
                    : <></>}

                <Grid item xs={12} p={1} >
                    <TextField
                        id="contact_address"
                        value={data['contact_address']}
                        sx={{ width: '100%' }}
                        label="Company Address"
                        onChange={handleAddInputData}
                    />
                </Grid>

                {(type != "sale" && action != "transfer")
                    ? <FormControlLabel control={<Switch id='followupcount' checked={data['is_Followup']} onChange={handleSwitchChange} />} label={<Typography fontWeight='600' fontFamily='Segoe UI'> Increase Followup Count <span style={{ fontWeight: '400', fontSize: '12px', color: 'red' }}>(Current Count: {data['Followup_Count']})</span></Typography>} />
                    : <></>
                }

                <Divider
                    sx={{
                        width: '100%',
                        borderColor: "#706f6f2e",
                        marginY: "1rem",
                        marginLeft: "auto"
                    }}
                />

                <Stack direction='row' spacing={0.5} alignItems='center' mb={0.5}>
                    <Typography variant='h6' pl={1} fontFamily='Segoe UI'>Notes</Typography>
                    <Tooltip title="Add new note">
                        <IconButton onClick={addNotes}>
                            <AddCircleIcon />
                        </IconButton>
                    </Tooltip>
                </Stack>

                {
                    data['NotesOnFollow']
                        ? data['NotesOnFollow'].map((value, key) => {
                            return (
                                <Grid container alignItems="center">
                                    <Grid item xs={9} p={1} >
                                        <TextField
                                            sx={{ width: '100%' }}
                                            value={data['NotesOnFollow'][key]['note']}
                                            onChange={(e) => handleAddNotes(e, key, 'note')}
                                            placeholder="Note here..."
                                        />
                                    </Grid>
                                    <Grid item xs={2.5} p={1} >
                                        <TextField
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            value={data['NotesOnFollow'][key]['date']}
                                            label="Note Date"
                                            sx={{ width: '100%' }}
                                            onChange={(e) => handleAddNotes(e, key, 'date')}
                                            type="date"
                                        />
                                    </Grid>
                                    <Grid item xs={0.5} p={1} >
                                        <Stack direction='row' justifyContent="center">
                                            <Tooltip title="Delete note">
                                                <ClearIcon onClick={() => DeleteNotes(key)} sx={{ color: "darkRed", cursor: "pointer" }} />
                                            </Tooltip>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            )
                        })
                        : null
                }
            </Grid>

            {projType ?

                <Dialog
                    sx={{ height: "100vh" }}
                    fullWidth
                    maxWidth="sm"
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="edit-apartment"
                >
                    <DialogTitle id="edit-apartment">Add new {alignment}</DialogTitle>
                    <DialogContent sx={{}}>
                        {/* <DialogContentText>
                            This Product information will be added to Quote Data.
                        </DialogContentText> */}

                        <ToggleButtonGroup
                            color="primary"
                            value={alignment}
                            exclusive
                            onChange={handleChange}
                            aria-label="Platform"
                        >
                            <ToggleButton sx={{ borderRadius: '0px !important' }} value="product">Product</ToggleButton>
                            <ToggleButton sx={{ borderRadius: '0px !important' }} value="service">Service</ToggleButton>
                        </ToggleButtonGroup>

                        {
                            alignment == "product"
                                ? <SimpleForms data={pdata} setData={setpData} type="product" />
                                : <SimpleForms data={sdata} setData={setsData} type="service" />
                        }

                    </DialogContent>
                    <DialogActions>
                        <Button sx={{ position: "absolute", top: 10, right: 20 }} onClick={handleClose} color="secondary">
                            <Cancel sx={{ fontSize: "1.8rem" }} />
                        </Button>
                        <Button variant='contained' onClick={submitAddForm} color="primary">
                            Add
                        </Button>
                    </DialogActions>
                </Dialog>
                : <></>}
        </Box >
    );
}