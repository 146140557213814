import { useState, useEffect, createContext } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Typography, Button, Stack, LinearProgress } from '@mui/material';
import Box from '@mui/material/Box';
import { ManageTimesheet } from './ManageTimesheet'
import DraftTimesheet from './DraftTimesheet';
import { SubmittedRecords } from './SubmittedRecords';
import { ActiveTimesheet } from './ActiveTimesheet';
import { AssignTimesheet } from './AssignTimesheet';
import { ReviewTimesheet } from './ReviewTimesheet';
import axios from '../../api/axios';
import Notify from '../../features/helpers/notifyConfig';
import { Token } from '../../constants';
import ReviewSheets from './Manager/ReviewSheets';
import SubmittedSheets from './Manager/SubmittedSheets';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box pt={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export const ButtonStyle = ({ title, handle }) => {
  return (
    <Button onClick={handle} variant='contained' sx={{ py: 1, px: 3, borderRadius: '5px', height: '35px' }}>
      <Typography variant='subtitle2'>{title}</Typography>
    </Button>
  )
}

export const inputStyle = {
  "& .MuiInputBase-input": {
    height: "10px"
  },
  "& .MuiInputBase-root": {
    borderRadius: "5px"
  },
  "& .MuiSelect-select": {
    paddingY: "4px"
  }
}

export const UserContext = createContext(); //Create Context for Manager Data

export default function ManagerTimeSheet() {
  const [value, setValue] = useState(0);
  // const [managerData, setManagerData] = useState();

  const boxStyle = {
    border: 'solid 1px',
    borderColor: 'dark_divider',
    // padding: '10px',
    borderRadius: '5px'
  }

  const billButtonStyle = {
    "& .MuiInputBase-input": {
      height: "18px"
    }
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  //temporary testing token
  const token = Token.Manmit;


  // Getting Manager's information first
  // const getManagerDetails = async () => {
  //   await axios.get("/timesheet/manager",
  //     {
  //       headers: {
  //         'Content-Type': 'application/json',
  //         'Authorization': `Bearer ` + localStorage.getItem('access')
  //         // 'Authorization': `Bearer ` + token
  //       }
  //     }
  //   ).then((response) => {
  //     console.log(response?.data?.managerProjectData);
  //     setManagerData(response?.data?.managerProjectData[0]);
  //     // Notify.success("Manager data retrieved successfully!")
  //   }).catch((error) => {
  //     // console.log(error)
  //     Notify.error("OOPS! Some Error Occured")
  //   });
  // }

  // useEffect(() => {
  //   getManagerDetails();
  // }, [])

  return (
    // managerData ?
      <Box sx={{ width: '100%' }} py={3} px={6}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab label="Review Sheets" {...a11yProps(0)} />
            {/* <Tab label="Assign Timesheet" {...a11yProps(1)} /> */}
            <Tab label="Submitted Sheets" {...a11yProps(1)} />
            {/* <Tab label="Draft Timesheet" {...a11yProps(2)} /> */}
            {/* <Tab label="Active TimeSheet" {...a11yProps(0)} />
          <Tab label="History TimeSheet" {...a11yProps(1)} /> */}
          </Tabs>
        </Box>

        {/* <UserContext.Provider value={managerData}> */}
          {/* <CustomTabPanel value={value} index={0}>
            <ManageTimesheet boxStyle={boxStyle} billButtonStyle={billButtonStyle} inputStyle={inputStyle} token={token} />
          </CustomTabPanel> */}

          {/* <CustomTabPanel value={value} index={1}>
        <AssignTimesheet boxStyle={boxStyle} billButtonStyle={billButtonStyle} token={token} />
      </CustomTabPanel> */}

          <CustomTabPanel value={value} index={0}>
            <ReviewSheets boxStyle={boxStyle} />
          </CustomTabPanel>

          <CustomTabPanel value={value} index={1}>
            <SubmittedSheets boxStyle={boxStyle} />
          </CustomTabPanel>
        {/* </UserContext.Provider> */}

        {/* <CustomTabPanel value={value} index={2}>
        <SubmittedRecords boxStyle={boxStyle} />
      </CustomTabPanel> */}
      </Box> 
      // :
      // <Stack justifyContent="center" alignItems="center" height="80vh">
      //   <Box pb={2}>Wait while we gather required information...</Box>
      //   <Stack gap={4} sx={{ width: '50%', color: 'grey.500' }} spacing={2}>
      //     <LinearProgress color="inherit" />
      //   </Stack>
      // </Stack>
  );
}