import React, { useEffect, useState } from 'react'
import axios from '../../api/axios'
import { Box, Button, Grid, Stack, Typography, DialogActions, Dialog, DialogTitle, DialogContent, DialogContentText, Snackbar, Skeleton, LinearProgress, TextField, InputLabel, FormControl, Select, MenuItem } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import Notify from '../../features/helpers/notifyConfig';
import { Cancel } from '@mui/icons-material';
import { TrainTable } from './TrainTable';
import { TrainForm } from './TrainForm';
import { isSuperAdmin } from '../../components/auth/userAuth';

export const TrainIndex = () => {
    const [type, selectType] = useState("Personal");
    const [ptrain, setPtrain] = useState(null);
    const [empList, setEmpList] = useState(null);
    const [mtrain, setMtrain] = useState("");
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [editCert, seteditCert] = useState("");
    const [data, setData] = useState({
        "Course_Name": "",
        "Issuing_Authority": "",
        "Issue_Date": "",
        "Certificate_ID": "",
        "Expiry_Date": "",
        "Document": "",
        "Tag": ""
    });

    const handleClose = () => {
        setOpen(false);
    };

    const handleClickOpen = () => {
        setOpen(true);
        seteditCert("");
        setData({
            "Course_Name": "",
            "Issuing_Authority": "",
            "Issue_Date": "",
            "Certificate_ID": "",
            "Expiry_Date": "",
            "Document": "",
            "Tag": ""
        });
    };

    useEffect(() => {
        if (editCert != "") {
            setOpen(true);
            setData(editCert);
        }
    }, [editCert])

    const submitAddCert = () => {
        let hasWarning = false;

        if (data.topic === "") {
            Notify.warning("Topic must be mentioned!");
            hasWarning = true;
        }
        else if (data.platform === "") {
            Notify.warning("Platform must be mentioned!");
            hasWarning = true;
        }
        else if (data.start_date === "") {
            Notify.warning("Start Date must be mentioned!");
            hasWarning = true;
        }
        else if (data.end_date === "") {
            Notify.warning("End Date must be mentioned!");
            hasWarning = true;
        }
        else if (data.assigned_to === "") {
            Notify.warning("Assign atleast 1 assignee!");
            hasWarning = true;
        }

        if (!hasWarning) {
            // 1. Adding New Certificate
            // const finData = {
            //     "Certificates": [
            //         data
            //     ]
            // }
            const addCert = async () => {
                await axios.post
                    ("/dashboard/training/add", JSON.stringify(data),
                        {
                            headers: {
                                'Access-Control-Allow-Origin': '*',
                                'Content-Type': 'application/json',
                                withCredentials: true,
                                mode: 'no-cors',
                                'Authorization': `Bearer ` + localStorage.getItem('access')
                            }
                        }).then((response) => {
                            console.log(response);
                            setOpen(false);
                            setLoading(prev => !prev);
                            Notify.success("Training Assigned Successfully!")
                        }).catch((error) => {
                            console.log(error.response);
                            Notify.error("Error Occurred. Try Again!");
                        })
            }
            addCert();
        }
    }

    // 1. Get All Personal Training List
    const getptraining = async () => {
        await axios.get("/dashboard/trainingEmp",
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ` + localStorage.getItem('access'),
                    'Access-Control-Allow-Origin': '*',
                    mode: 'no-cors'
                }
            }
        ).then((response) => {
            console.log(response?.data);
            setPtrain(response?.data);
        }).catch((error) => {
            console.log(error.response)
        });
    }


    // 2. Get All Managing Trainings
    const getReviewcerts = async () => {
        await axios.get("/dashboard/trainingMgr",
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ` + localStorage.getItem('access'),
                    'Access-Control-Allow-Origin': '*',
                    mode: 'no-cors'
                }
            }
        ).then((response) => {
            // console.log(response?.data);
            setMtrain(response?.data);
        }).catch((error) => {
            console.log(error.response)
        });
    }

    useEffect(() => {
        getptraining();
        getReviewcerts();
    }, [loading])

    useEffect(() => {
      const getEmployees = async () => {
        await axios.get("/blog-tracking/get-all-employees",
          {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ` + localStorage.getItem('access'),
              'Access-Control-Allow-Origin': '*',
              mode: 'no-cors'
            }
          }).then((response) => {
            console.log(response);
            setEmpList(response?.data?.message);
          }).catch((error) => {
            console.log(error.response)
          })
      }
      getEmployees();
    }, [])

    return (
        <Box py={2} px={4}>
            {
                (ptrain && empList)
                    ?
                    <Box>
                        <Stack direction='row' pb={2} justifyContent='space-between'>
                        <Typography variant='h5' fontFamily='Segoe UI' fontWeight={600}>{type == "Personal" ? "Your Trainings"
                        :<Button variant='contained' onClick={handleClickOpen} size='small'>Add Training</Button> }</Typography>
                        {isSuperAdmin(
                        <FormControl sx={{width:'140px'}}>
                            <InputLabel id="demo-simple-select-label">Training type</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={type}
                                label="Training type"
                                onChange={() => {
                                    type == "Personal" ? selectType("Assign") : selectType("Personal");
                                }}
                            >
                                <MenuItem value="Assign"><Typography variant='subtitle2'>Assign</Typography></MenuItem>
                                <MenuItem value="Personal"><Typography variant='subtitle2'>Personal</Typography></MenuItem>
                            </Select>
                        </FormControl>,<></>)
                        }
                        </Stack>

                        {
                           type == "Assign" 
                           ?<TrainTable table_data={mtrain} setLoading={setLoading} type="assign" empList={empList}/>
                           :<TrainTable table_data={ptrain} setLoading={setLoading} empList={empList} />
                        }
                        <Dialog
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="edit-apartment"
                            fullWidth
                            maxWidth='sm'
                        >
                            <DialogTitle sx={{ backgroundColor: "#F6F5F5" }} id="edit-apartment">Add Training</DialogTitle>
                            <DialogContent sx={{ backgroundColor: "#F6F5F5" }}>
                                <TrainForm data={data} setData={setData} empList={empList} />
                            </DialogContent>
                            <DialogActions sx={{ backgroundColor: "#F6F5F5" }}>
                                <Button sx={{ position: "absolute", top: 10, right: 0 }} onClick={handleClose} color="secondary">
                                    <Cancel sx={{ fontSize: "1.8rem" }} />
                                </Button>
                                <Button variant='contained' onClick={() => submitAddCert()} color="primary">Add</Button>
                            </DialogActions>
                        </Dialog>
                    </Box>
                    :
                    <Stack justifyContent="center" alignItems="center" height="70vh">
                        <Box pb={2}>Wait while we gather required information...</Box>
                        <Stack gap={4} sx={{ width: '50%', color: 'grey.500' }} spacing={2}>
                            <LinearProgress color="inherit" />
                        </Stack>
                    </Stack>
            }
        </Box>
    )
}
