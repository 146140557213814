import React from 'react'

export const DownloadIcon = ({width}) => {
    return (
        <svg width={width} height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.9999 21.333L9.33325 14.6663L11.1999 12.733L14.6666 16.1997V5.33301H17.3333V16.1997L20.7999 12.733L22.6666 14.6663L15.9999 21.333ZM7.99992 26.6663C7.26659 26.6663 6.63903 26.4055 6.11725 25.8837C5.59547 25.3619 5.33414 24.7339 5.33325 23.9997V19.9997H7.99992V23.9997H23.9999V19.9997H26.6666V23.9997C26.6666 24.733 26.4057 25.361 25.8839 25.8837C25.3621 26.4063 24.7341 26.6672 23.9999 26.6663H7.99992Z" fill="white" />
        </svg>
    )
}

