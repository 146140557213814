import { Box, colors } from "@mui/material"

const {red, lightBlue, green, pink, purple, orange, brown, blueGrey  } = colors;
const variantNum = {
    "lighter": 200,
    "light": 300,
    "dark": 500,
    "darker": 700
}

export const RandomColorGenerator = (count, variant) => {
    const muiColors = [red, lightBlue, green, pink, purple, orange, brown, blueGrey];
    const colorArray = [];
    const start = variantNum[variant];

    for (let shade = start; shade <= 900; shade += 100) {
        for (let color of muiColors) {
          if (color[shade]) {
            colorArray.push(color[shade]);
          }
    
          if (colorArray.length === count) {
            return colorArray;
          }
        }
      }

      return colorArray;
}
