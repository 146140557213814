import React, { useEffect, useState } from 'react';

export const PdfViewer = ({ base64String }) => {
  const [pdfUrl, setPdfUrl] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    console.log(base64String);
    if (base64String) {
      try {
        // Check if the Base64 string is valid
        if (base64String.trim() === '' || !/^data:application\/pdf;base64,/.test(base64String)) {
          throw new Error('Invalid Base64 string');
        }

        // Strip the base64 header if present
        const base64 = base64String.split(',')[1] || base64String;

        const byteCharacters = atob(base64);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);
        setPdfUrl(url);

        // Cleanup URL object when component unmounts
        return () => URL.revokeObjectURL(url);
      } catch (e) {
        setError(e.message);
      }
    }
  }, [base64String]);

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      {pdfUrl ? (
        <iframe src={pdfUrl} width="100%" height="600px" />
      ) : (
        <p>Loading PDF...</p>
      )}
    </div>
  );
};