import { Box, Grid, Button, Typography, Stack, Skeleton, AccordionActions, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Scrollbars } from 'react-custom-scrollbars';
import { TimeSheetHours, getHours, getMinutes } from '../../features/components/TimeSheet/TimeSheetHours';
import UserIcon from '@mui/material/Avatar';
import { useEffect, useState, version } from 'react';
import axios from '../../api/axios';
import dateFormat from 'dateformat';
import { TimeSheetTable } from '../../features/components/TimeSheet/TimeSheetTable';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { Token } from '../../constants';
import { LoadingButton } from '@mui/lab';
import ReplayIcon from '@mui/icons-material/Replay';
import { NodataFound } from '../NodataFound';
import Notify from '../../features/helpers/notifyConfig';
import { TimeSheetNewTable, TimeSheetTitles } from '../../features/components/TimeSheet/TimeSheetNewTable';


const SubmittedRecords = ({ boxStyle, billButtonStyle }) => {
  const [submit, triggerSubmit] = useState();
  // const [sets, handleSets] = useState([defaultState]);
  const [draftSheet, setdraftSheet] = useState();
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState();
  const [type, selectType] = useState("Reviewing");

  const inputStyle = {
    "& .MuiInputBase-input": {
      height: "10px"
    },
    "& .MuiInputBase-root": {
      borderRadius: "5px"
    },
    "& .MuiSelect-select": {
      paddingY: "4px"
    }
  }

  const handleSubmit = (temp) => {
    console.log(temp);
    setLoading("Submit");
    triggerSubmit({
      "timesheet": {
        "action": "Submit",
        "employeeSheetObjects": [
          ...temp
        ]
      }
    })
  }

  const getSubmittedTimesheet = async () => {
    await axios.get("/timesheet/employee/submitted",
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ` + localStorage.getItem('access')
        }
      }
    ).then((response) => {
      console.log(response);
      setdraftSheet((response?.data?.employeeSheets) ? (response.data.employeeSheets) : "nodata");
      // setRefresh(false);
    }).catch((error) => {
      console.log(error.response);
      Notify.error("Error Occurred")
      // setRefresh(false);
    });
  }

  useEffect(() => {
    getSubmittedTimesheet();
  }, [type])

  return (
    <>
      <Stack alignItems='end'>
        <FormControl>
          <InputLabel id="demo-simple-select-label">Status</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={type}
            label="Status"
            onChange={() => {
              type == "Submitted" ? selectType("Reviewing") : selectType("Submitted");
            }}
          >
            <MenuItem value="Submitted"><Typography variant='subtitle2'>Submitted</Typography></MenuItem>
            <MenuItem value="Reviewing"><Typography variant='subtitle2'>Reviewing</Typography></MenuItem>
          </Select>
        </FormControl>
      </Stack>

      {draftSheet == "nodata" ? <NodataFound /> :
        <Box mt={3} sx={{ overflow: 'auto', height: '70vh'}}>
          <Scrollbars>
            <Grid pr={2} pb={3}>
              {draftSheet ?
                draftSheet[type] ? draftSheet[type].map((val, key) => {
                  const sheetID = val.employeeSheetID;
                  const endDate = val.endDate;
                  const emp_name = val.emp_name;
                  const startDate = val.startDate;
                  const billable_hours = val.billable_hours;
                  const non_billable_hours = val.non_billable_hours;
                  var temp = [];
                  // alert(endDate);
                  return (
                    <Accordion defaultExpanded={key == 0 ? true : false} sx={{ width: '100%', mt: '18px' }} key={sheetID}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                      >
                        <Grid container alignItems="center" justifyContent="space-between">
                          <Typography variant='subtitle1'>{dateFormat(startDate, "d mmmm, yyyy")}  -  {dateFormat(endDate, "d mmmm, yyyy")}</Typography>
                          <Typography sx={{ position: 'absolute', left: '290px' }} pl={6}><strong style={{ color: 'primary.main' }}>Billable:</strong> {getHours(billable_hours) + "h " + getMinutes(billable_hours) + "m"}</Typography>
                          <Typography sx={{ position: 'absolute', left: '440px' }} pl={6}><strong style={{ color: 'primary.main' }}>Non-Billable:</strong> {getHours(non_billable_hours) + "h " + getMinutes(non_billable_hours) + "m"}</Typography>

                          <Stack pr={3} direction="row" alignItems="center"><FiberManualRecordIcon sx={{ transform: "scale(0.5)", color: type == "Submitted" ? "blue" : "green" }} />{type}</Stack>
                        </Grid>
                      </AccordionSummary>
                      <AccordionDetails>

                        {/* <Box mt={1} sx={{ height: '60vh', overflowY: 'auto', overflowX: 'hidden' }}> */}
                        <Box>
                          <Box my={2} border='1px solid' borderColor="dark_divider">
                            <Grid gap={1} container columns={16.5} py={0.3} px={1} alignItems='center' sx={{
                              backgroundColor: 'primary.light',
                              color: 'primary.white'
                            }}>
                              {TimeSheetTitles.map((title, key) => {

                                return (
                                  <Grid key={key} item my={1} md={title == "Project" || title == "Task" || title == "Description" ? 2 : 1}>
                                    <Typography
                                      variant='subtitle2'>
                                      {title}
                                    </Typography>
                                  </Grid>
                                )
                              })}
                            </Grid>

                            <Grid gap={1} container columns={16.5} py={1} px={1} alignItems='center'>
                              {val.employeeSheetObjects.map((data) => {
                                temp.push({
                                  "projectID": data.Project.projectID,
                                  "taskID": data.Task.taskID,
                                  "billable": data.billable,
                                  "description": data.description,
                                  "workDay": data.workDay
                                })

                                return (
                                  <TimeSheetNewTable otherempData={data} />
                                )
                              })}
                            </Grid>
                          </Box>
                        </Box>
                      </AccordionDetails>
                    </Accordion>)
                }) : <NodataFound />
                :
                <Stack gap={2} width="100%">
                  <Skeleton animation="wave" variant="rectangular" width="100%" height={80} />
                  <Skeleton animation="wave" variant="rectangular" width="100%" height={80} />
                </Stack>
              }

            </Grid>
          </Scrollbars>
        </Box>}
    </>
  )
}

export default SubmittedRecords