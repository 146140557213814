import { Autocomplete, Box, Grid, Paper, Stack, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import UserIcon from '@mui/material/Avatar';
import { BarChart } from '@mui/x-charts/BarChart';
import { axisClasses } from '@mui/x-charts';
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';

const nameLabel = {
    'employeeTotalProjectHours': 'Total Project Hours',
    'employeeTotalProjectBillableHours': 'Project Billable Hours',
    'employeeTotalProjectNonBillableHours': 'Project Non Billable Hours',
    'employeeTotalTaskBillableHours': 'Task Billable Hours',
    'employeeTotalTaskHours': 'Total Task Hours',
    'employeeTotalTaskNonBillableHours': 'Task Non Billable Hours',
}

const GraphDisplay = ({ pdata, type }) => {
    const data = pdata.map((item, index) => ({
        id: index,
        value: item[type],
        label: item.employeeName,
    }));

    return (
        <Box sx={{ border: 'solid 1px', borderRadius: '5px', padding: '10px' }}>
            <Typography mb={3} variant='subtitle1' fontWeight='600'>{nameLabel[type]}</Typography>
            <PieChart
                series={[
                    {
                        arcLabel: (item) => `${item.value}h`,
                        arcLabelMinAngle: 45,
                        data,
                        cornerRadius: 5,
                        innerRadius: 30,
                    },
                ]}

                sx={{
                    [`& .${pieArcLabelClasses.root}`]: {
                        fill: 'white',
                        fontWeight: 'bold',
                    },
                }}
                width={400}
                height={200}
            />
        </Box>
    )
}

const BarGraph =({pdata})=>{

    const chartSetting = {
        yAxis: [
          {
            label: 'Total Burned Hours',
          },
        ],
        width: 1000,
        height: 400,
        sx: {
          [`.${axisClasses.left} .${axisClasses.label}`]: {
            transform: 'translate(-10px, 0)',
          },
        },
      };

      let cdata =[];
      pdata.map((val)=>{
        cdata.push({
            'task': val.taskName,
            'billable': val.totalTaskBillableHours,
            'nonBillable': val.totalNonTaskBillableHours
        })
    })
    
    const dataset = cdata
      
    const valueFormatter = (value) => `${value} hours`;
    return (
        <BarChart
          dataset={dataset}
          xAxis={[{ scaleType: 'band', dataKey: 'task', categoryGapRatio: 0.8 }]}
          series={[
            { dataKey: 'billable', label: 'Billable', valueFormatter },
            { dataKey: 'nonBillable', label: 'Non-Billable', valueFormatter },
          ]}
          {...chartSetting}
        />
      );
}

export const ProjectStats = ({ statData }) => {
    const [data, setData] = useState(statData.ProjectTaskDetails);
    const [globalData, setglobalData] = useState(statData.EmployeeProjectContribution);
    const [selTask, setTask] = useState(statData.ProjectTaskDetails[0].taskName ? statData.ProjectTaskDetails[0].taskName : "");
    const [filteredData, setFilteredData] = useState(null);

    const allTasks = [];
    data.map((val) => {
        allTasks.push(val.taskName);
    })

    const chngTask = (e, val) => {
        if (val == null) val = "";
        setTask(val);
    }

    useEffect(() => {
        const filterDataByName = (taskName) => {
            const filtered = statData.ProjectTaskDetails.filter(
                (item) => item.taskName === taskName
            );
            if(filtered.length!==0) setFilteredData(filtered);
        };
        filterDataByName(selTask);
    }, [selTask])

    return (
        <Box>

            <Paper sx={{ mt: '2rem', p: '15px' }}>
                <Grid container alignItems='center'>
                    <Grid item md={3}>
                        <Stack direction='row' alignItems='center' spacing={2}>
                            <Typography variant='h6'>{statData.projectName}</Typography>
                        </Stack>
                    </Grid>

                    <Grid textAlign='center' item md={3}>
                        <Typography>Total Hours:</Typography>
                        <Typography variant='h5'>{statData.totalProjectHours}</Typography>
                    </Grid>

                    <Grid textAlign='center' item md={3}>
                        <Typography>Total Billable:</Typography>
                        <Typography variant='h5'>{statData.totalProjectBillableHours} hours</Typography>
                    </Grid>

                    <Grid textAlign='center' item md={3}>
                        <Typography>Total Non-Billable:</Typography>
                        <Typography variant='h5'>{statData.totalProjectNonBillableHours} hours</Typography>
                    </Grid>
                </Grid>
            </Paper>
            {console.log(statData)}
            <Paper sx={{ mt: '1rem', p: '15px' }}>
                <Grid my={0.5} spacing={2} container justifyContent='center'>
                    {
                        globalData.length > 0
                            ?
                            <>
                                {statData.totalProjectHours==0?<></>:<Grid item xl={4}><GraphDisplay pdata={globalData} type='employeeTotalProjectHours' /></Grid>}
                                {statData.totalProjectBillableHours==0?<></>:<Grid item xl={4}><GraphDisplay pdata={globalData} type='employeeTotalProjectBillableHours' /></Grid>}
                                {statData.totalProjectNonBillableHours==0?<></>:<Grid item xl={4}><GraphDisplay pdata={globalData} type='employeeTotalProjectNonBillableHours' /></Grid>}
                                <Box mt={8}><BarGraph pdata={statData.ProjectTaskDetails} /></Box>
                            </>
                            : <Grid item xl={4}><Typography>No Projects yet!</Typography></Grid>
                    }
                </Grid>
            </Paper>

            <Box mt={8}>
                <Autocomplete
                    id="taskName"
                    value={selTask}
                    sx={{ width: "250px" }}
                    // freeSolo
                    options={allTasks.map((option) => option)}
                    renderInput={(params) => <TextField {...params} required label="Select Task" />}
                    onChange={chngTask}
                />

                {filteredData ? <><Paper sx={{ mt: '2rem', p: '15px' }}>
                    <Grid container alignItems='center'>
                        <Grid item md={3}>
                            <Stack direction='row' alignItems='center' spacing={2}>
                                <Typography variant='h6'>{filteredData[0].taskName}</Typography>
                            </Stack>
                        </Grid>

                        <Grid textAlign='center' item md={3}>
                            <Typography>Total Hours:</Typography>
                            <Typography variant='h5'>{filteredData[0].totalTaskHours}</Typography>
                        </Grid>

                        <Grid textAlign='center' item md={3}>
                            <Typography>Total Billable:</Typography>
                            <Typography variant='h5'>{filteredData[0].totalTaskBillableHours} hours</Typography>
                        </Grid>

                        <Grid textAlign='center' item md={3}>
                            <Typography>Total Non-Billable:</Typography>
                            <Typography variant='h5'>{filteredData[0].totalNonTaskBillableHours} hours</Typography>
                        </Grid>
                    </Grid>
                </Paper>
                    {console.log(filteredData)}
                    <Paper sx={{ mt: '1rem', p: '15px' }}>
                        <Grid my={0.5} spacing={2} container justifyContent='center'>
                            {
                                filteredData.length > 0
                                    ?
                                    <>
                                        {filteredData[0].totalTaskHours==0?<></>:<Grid item xl={4}><GraphDisplay pdata={filteredData[0].EmployeeTaskContribution} type='employeeTotalTaskHours' /></Grid>}
                                        {filteredData[0].totalTaskBillableHours==0?<></>:<Grid item xl={4}><GraphDisplay pdata={filteredData[0].EmployeeTaskContribution} type='employeeTotalTaskBillableHours' /></Grid>}
                                        {filteredData[0].totalNonTaskBillableHours==0?<></>:<Grid item xl={4}><GraphDisplay pdata={filteredData[0].EmployeeTaskContribution} type='employeeTotalTaskNonBillableHours' /></Grid>}
                                    </>
                                    : <Grid item xl={4}><Typography>No Projects yet!</Typography></Grid>
                            }
                        </Grid>
                    </Paper>

                </> : <></>}
            </Box>
        </Box>
    )
}
