import { Box, Dialog, Grid, Stack, Tooltip, Typography } from '@mui/material'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { PdfViewer } from '../../../components/PdfViewer';
import { useState } from 'react';
import axios from '../../../api/axios';
import Notify from '../../../features/helpers/notifyConfig';
import CircularProgress from '@mui/material/CircularProgress';

export const ExpenseInfo = ({ data }) => {
    const CURRENCY = data.details.currency;
    const [open, setOpen] = useState(null);
    const [loading, setLoading] = useState(false);


    const getExpDocument = async (url) => {
        await axios.get(url,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ` + localStorage.getItem('access')
                }
            }
        ).then((response) => {
            setLoading(false);
            setOpen(response.data.receipt);
        }).catch((error) => {
            setLoading(false);
            Notify.error("Request Timed Out. Please Try Again");
        });
    }

    const viewPdf = (url) => {
        setLoading(true);
        getExpDocument(`/expense-report/receipts/${url}`);
    }
    const handleClose = () => {
        setOpen(null);
    }
    const categoryTot = data.details.categoryTotals;
    const summary = data.details.summary;

    const TableView = ({ bgcolor = '', color = '', border = '', borderStyle = '', data, padding, total = '' }) => {
        return (
            <Grid
                mb={1.3} border={border} container spacing={1} borderRadius={0.4} bgcolor={bgcolor} color={color} p={padding} sx={{ borderStyle: borderStyle }}>
                {data.map((item, index) => (
                    <Grid item xs={index === 0 ? 1.3 : (index === 1 ? 2 : (index === data.length - 1 ? 0.7 : 1))} key={index}>
                        {
                            total != '' && index === data.length - 2
                                ?
                                <Stack>
                                    <Typography overflow='hidden' textOverflow='ellipsis' whiteSpace='nowrap' textAlign='center' color='primary.main' variant="subtitle1" fontWeight='600'>&nbsp;&nbsp;{CURRENCY+""+item.subtotal}</Typography>
                                    <Tooltip
                                        slotProps={{
                                            popper: {
                                                modifiers: [
                                                    {
                                                        name: 'offset',
                                                        options: {
                                                            offset: [0, -5],
                                                        },
                                                    },
                                                ],
                                            },
                                        }}
                                        open={open ? false : true} title='advances' placement='right' arrow><Typography overflow='hidden' textOverflow='ellipsis' whiteSpace='nowrap' textAlign='center' color='error' variant="subtitle2" fontWeight='600'>-&nbsp;{CURRENCY+""+item.advances}</Typography></Tooltip>
                                    <Box sx={{ width: '100%', height: '2px', bgcolor: 'black', transform: 'translateX(3px)' }}></Box>
                                    <Typography overflow='hidden' textOverflow='ellipsis' whiteSpace='nowrap' textAlign='center' color='#198f51' variant="subtitle1" fontWeight='600'>&nbsp;&nbsp;{CURRENCY+""+item.total}</Typography>
                                </Stack>
                                : <Typography overflow='hidden' textOverflow='ellipsis' whiteSpace='nowrap' textAlign='center' variant="subtitle1"
                                    // sx={((index === data.length - 2) && color == '')?{textAlign:'right'}:{}} 
                                    fontWeight={(((index === data.length - 2) && color == '') || total == 'total') ? '600' : ''}>{(index >= 2 && index <= 9 && color == '') ? `${CURRENCY+""+item}` : item}</Typography>
                        }
                    </Grid>
                ))}
            </Grid>
        );
    };

    return (
        <Box mt={2}>
            <TableView
                bgcolor='primary.main'
                color='primary.white'
                padding={1.3}
                data={['Date', 'Description', 'Entertainment', 'Fuel', 'Hotel', 'Meals', 'Transport', 'Phone', 'Misc', 'Total', 'Receipt']}
            />

            <Box mt={2}>
                {data.details.categoryMatrixView.map((item, index) =>
                    <TableView
                        bgcolor='#e3e3e3'
                        border='solid 1px darkgray'
                        padding={0.8}
                        data={[item.Date, item.Description, item.Entertainment, item.Fuel, item.Hotel, item.Meals, item.Transport, item.Phone, item.Misc, item.Total,
                        loading
                            ?
                            <CircularProgress size='18px'/>
                            :<RemoveRedEyeIcon
                            sx={{
                                cursor: 'pointer',
                                transition: 'all ease-in-out 0.2s',
                                ':hover': {
                                    transform: 'scale(1.2)'
                                }
                            }}
                            onClick={() => viewPdf(item.Receipt)} />]} />
                )}
            </Box>

            <Box mt={1.6}>
                <TableView
                    total='total'
                    bgcolor='#e3e3e3'
                    padding={0.8}
                    border='2px dashed darkgray'
                    data={['Total', '', categoryTot.Entertainment, categoryTot.Fuel, categoryTot.Hotel, categoryTot.Meals, categoryTot.Transport, categoryTot.Phone, categoryTot.Misc, summary, '']}
                />
            </Box>

            <Dialog onClose={handleClose} open={open ? true : false} fullWidth={true} maxWidth='lg'>
                {open ? <PdfViewer base64String={open} /> : <></>}
            </Dialog>

        </Box>
    )
}
