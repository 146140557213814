import React from 'react'

export const Plane = ({width,height}) => {
    return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            width={width} height={height} viewBox="0 0 128.000000 128.000000"
            preserveAspectRatio="xMidYMid meet">
            <metadata>
                Created by potrace 1.10, written by Peter Selinger 2001-2011
            </metadata>
            <g transform="translate(0.000000,128.000000) scale(0.100000,-0.100000)"
                fill="#000000" stroke="none">
                <path d="M1158 1260 c-9 -6 -63 -26 -120 -46 -57 -20 -224 -85 -373 -144 -148
-60 -313 -124 -365 -144 -52 -19 -137 -51 -189 -70 -147 -54 -146 -64 1 -96
53 -11 128 -33 165 -49 l68 -28 6 -164 c10 -244 28 -349 60 -349 5 0 30 28 57
62 26 34 71 89 101 122 29 34 65 82 80 108 26 44 27 46 51 32 14 -7 60 -25
103 -39 43 -14 91 -32 106 -40 23 -11 31 -12 39 -2 5 6 44 120 87 252 42 132
106 308 141 391 35 83 67 165 70 182 6 32 6 32 -32 32 -22 0 -47 -5 -56 -10z
m-1 -171 c-35 -79 -101 -255 -148 -392 -67 -199 -87 -246 -99 -240 -8 3 -45
16 -82 29 -36 12 -101 39 -145 59 -120 56 -150 66 -162 52 -5 -7 -12 -35 -16
-61 -3 -27 -8 -60 -11 -73 -4 -13 -2 -23 3 -23 5 0 15 28 21 61 6 34 13 63 15
65 2 3 29 -7 60 -21 l57 -25 -59 -77 c-32 -43 -65 -84 -74 -91 -9 -8 -30 -36
-48 -63 -17 -27 -33 -48 -36 -46 -2 3 5 38 16 78 11 41 27 119 35 174 l15 100
193 188 c202 197 336 314 428 377 30 21 64 47 74 59 11 12 21 20 22 18 2 -2
-25 -68 -59 -148z m-154 46 c-123 -87 -384 -255 -443 -285 -22 -11 -75 -47
-117 -79 -71 -55 -78 -58 -98 -45 -34 22 -134 55 -210 68 l-70 12 75 27 c204
71 360 132 760 293 85 35 161 63 168 63 7 1 -22 -24 -65 -54z m-250 -247
c-302 -290 -279 -263 -292 -348 -27 -174 -46 -269 -52 -262 -9 9 -39 284 -39
354 l0 53 82 60 c45 33 87 62 93 64 14 6 251 152 299 185 21 14 41 26 43 26 2
0 -59 -60 -134 -132z"/>
                <path d="M553 485 c-15 -17 -17 -55 -3 -55 6 0 10 10 10 23 0 16 3 18 9 8 11
-17 25 3 17 24 -7 19 -17 18 -33 0z"/>
                <path d="M458 695 c2 -14 9 -24 15 -22 16 5 32 35 24 44 -5 4 -12 1 -17 -7 -7
-10 -10 -11 -10 -2 0 6 -4 12 -9 12 -4 0 -6 -11 -3 -25z"/>
                <path d="M420 671 c0 -22 16 -36 24 -22 8 12 -3 41 -15 41 -5 0 -9 -9 -9 -19z" />
                <path d="M202 548 c-19 -30 -21 -48 -3 -48 4 0 17 18 30 40 18 32 19 40 8 40
-8 0 -24 -14 -35 -32z"/>
                <path d="M151 419 c-13 -39 -14 -79 -2 -79 14 0 18 9 25 63 8 54 -6 64 -23 16z" />
                <path d="M154 245 c-12 -31 26 -122 46 -110 8 5 -27 125 -36 125 -3 0 -7 -7
-10 -15z"/>
                <path d="M850 228 c-42 -29 -53 -53 -19 -43 59 19 110 46 104 55 -10 17 -50
11 -85 -12z"/>
                <path d="M1020 240 c-13 -8 -13 -9 0 -13 8 -2 31 -10 50 -16 56 -21 77 -24 84
-12 4 6 -8 20 -26 31 -35 21 -83 26 -108 10z"/>
                <path d="M1205 170 c-3 -6 8 -24 25 -41 23 -23 34 -29 42 -21 9 9 6 17 -8 33
-26 29 -51 41 -59 29z"/>
                <path d="M695 114 c-69 -36 -84 -76 -17 -49 39 17 72 43 72 57 0 12 -22 10
-55 -8z"/>
                <path d="M260 70 c23 -32 49 -50 74 -50 26 0 18 14 -26 48 -54 42 -77 43 -48
2z"/>
                <path d="M457 33 c-46 -7 -19 -23 39 -23 40 0 55 4 52 13 -5 13 -45 17 -91 10z" />
            </g>
        </svg>

    )
}
