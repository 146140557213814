import { Box, Grid, Paper, Stack, Typography } from '@mui/material'
import React, { useState } from 'react'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { Line } from 'react-chartjs-2';
import theme from '../../theme';
import { advancedDate } from '../../pages/Events'

const CustomLegend = ({ payload, total = '' }) => {
    return (
        <Stack direction='row' spacing={5} justifyContent='center'>
            {payload.map((entry, index) => (
                <div key={`item-${index}`} style={{ marginBottom: '10px' }}>
                    <span style={{ backgroundColor: entry.color, width: '20px', height: '15px' }}>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <span>&nbsp;{entry.value}</span>
                </div>
            ))
            }
            {total != '' ? <Typography fontFamily='Segoe UI' color={theme.palette.success.main}><strong>Total Project Revenue:</strong> ${total}</Typography> : <></>}
        </Stack>
    );
};

const quartDetail = {
    'All': 'All',
    'Q1': 'Quarter1 (Jan-Mar)',
    'Q2': 'Quarter2 (Apr-Jun)',
    'Q3': 'Quarter3 (Jul-Sep)',
    'Q4': 'Quarter4 (Oct-Dec)'
}

const CustomTooltip = ({ active, payload, label, type }) => {
    if (active && payload && payload.length) {
        // console.log(payload);
        return (
            <Paper>
                <Box className="custom-tooltip" paddingX={2.5} paddingY={0.4}>
                    <Typography mb={0.5} color='primary' fontFamily='Segoe UI' variant='subtitle1' fontWeight={700} className="label">{type == 'a' ? quartDetail[label] : label}</Typography>
                    {
                        type == 'q'
                            ? (<><Typography variant='subtitle2' className="label">{`Project Revenue : $${payload[0].payload['ProjectSize']}`}</Typography>
                                <Typography variant='subtitle2' className="label">{`Project Type : ${payload[0].payload['TypeOfProject']}`}</Typography>
                                <Typography variant='subtitle2' className="label">{`Sold Date : ${advancedDate(payload[0].payload['DateSold'])}`}</Typography>
                            </>)
                            : <Typography variant='subtitle2' className="label">{`Total Project Size : ${payload[0].payload['totalProjectSize']}`}</Typography>}
                </Box>
            </Paper>
        );
    }

    return null;
};

export const GraphReport = ({ data, type }) => {
    const combinedProjectData = data.groupedProjectData;
    const singleProjectData = data.specificProjectData;
    const quarterData = data.annualSalesData;
    const allTypes = ['All', 'QuarterWise'];
    const [viewType, setviewType] = useState(allTypes[0]);
    const [selectedProject, setSelectedProject] = useState(singleProjectData ? singleProjectData[0]._id : "nodata");
    const [selectedQuarter, setSelectedQuarter] = useState(quarterData != "" ? 'All' : "");
    if (selectedProject == "nodata") return (<></>)

    var Annualtotal = 0;
    if (quarterData) {
        quarterData.map((data) => {
            Annualtotal += data.totalProjectSize;
        })
    }

    const handleProjectChange = (event) => {
        setSelectedProject(event.target.value);
    };
    const handleTypeChange = (event) => {
        setviewType(event.target.value);
    };
    const handleQuarterChange = (event) => {
        setSelectedQuarter(event.target.value);
    };
    const filteredProject = singleProjectData.find(project => project._id === selectedProject);
    const pdata = filteredProject ? filteredProject.customer_info : [];
    const QuarterfilteredProject = quarterData != '' ? quarterData.find(quarter => quarter._id === selectedQuarter) : null;
    const qdata = QuarterfilteredProject ? QuarterfilteredProject : [];

    return (
        <Grid container my={2} rowSpacing={10} direction='column' alignItems='center'>
            <Grid item md={12}>
                <Typography fontWeight='600' variant='h5' mb={3}>Projects (Combined Customers)</Typography>
                {quarterData != ""
                    ? <Stack direction='row' spacing={3}>
                        <FormControl variant="outlined" style={{ minWidth: 200, marginBottom: '1rem' }}>
                            <InputLabel id="project-select-label">Type</InputLabel>
                            <Select
                                labelId="type-label"
                                id="type-select"
                                value={viewType}
                                onChange={handleTypeChange}
                                label="Type"
                            >
                                {allTypes.map((val, key) => (
                                    <MenuItem key={key} value={val}>{val}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl sx={{ visibility: viewType == 'All' ? 'hidden' : 'visible' }} variant="outlined" style={{ minWidth: 200, marginBottom: '1rem' }}>
                            <InputLabel id="project-select-label">Quarter</InputLabel>
                            <Select
                                labelId="type-label"
                                id="type-select"
                                value={selectedQuarter}
                                onChange={handleQuarterChange}
                                label="Quarter"
                            >
                                {Object.keys(quartDetail).map(data => (
                                    <MenuItem key={data} value={data}>{quartDetail[data]}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Stack>
                    : <></>
                }

                {
                    (viewType == "All")
                        ? <BarChart
                            width={1000}
                            height={500}
                            data={combinedProjectData}
                            margin={{
                                top: 20,
                                right: 30,
                                left: 20,
                                bottom: 5
                            }}
                        >
                            <CartesianGrid strokeDasharray="4 4" />
                            <XAxis dataKey="_id" angle={-20} textAnchor="end" interval={0} height={100} />
                            <YAxis />
                            <Tooltip />
                            {/* <Legend iconType='triangle' verticalAlign="top" height={50} margin={{ top: 50, left: 50, right: 50, bottom: 50 }} /> */}
                            <Legend verticalAlign="top" height={50} content={<CustomLegend />} />
                            <Bar name="Total Project Size" dataKey="totalProjectSize" stackId="a" fill="#1B2C58" barSize={30} radius={[5, 5, 0, 0]} />
                            <Bar name="Total Customers" dataKey="totalCustomers" stackId="a" fill="#79AC78" barSize={30} radius={[5, 5, 0, 0]} />
                        </BarChart>
                        : <BarChart
                            width={1000}
                            height={500}
                            data={selectedQuarter == "All" ? quarterData : qdata.customerProjectInfo}
                            margin={{
                                top: 20,
                                right: 30,
                                left: 20,
                                bottom: 5
                            }}
                        >
                            <CartesianGrid strokeDasharray="4 4" />
                            <XAxis dataKey={selectedQuarter == "All" ? "_id" : "Customer"} angle={-20} textAnchor="end" interval={0} height={100} />
                            {(selectedQuarter == "All" || qdata.customerProjectInfo.customerProjectInfo) ? <YAxis /> : <YAxis domain={[0, 100]} />}
                            <Tooltip content={<CustomTooltip type={selectedQuarter == "All" ? "a" : "q"} />} />
                            <Legend verticalAlign="top" height={50} content={<CustomLegend total={selectedQuarter == "All" ? Annualtotal : (qdata.totalProjectSize ?? '0')} />} />
                            <Bar name='Project Size' dataKey={selectedQuarter == "All" ? "totalProjectSize" : "ProjectSize"} fill="#1B2C58" barSize={30} radius={[5, 5, 0, 0]} />
                        </BarChart>
                }
            </Grid>


            <Grid item md={12}>
                <Typography fontWeight='600' variant='h5' mb={2}>Individual Projects</Typography>
                <FormControl variant="outlined" style={{ minWidth: 200, marginBottom: '1rem' }}>
                    <InputLabel id="project-select-label">Select a project</InputLabel>
                    <Select
                        labelId="project-select-label"
                        id="project-select"
                        value={selectedProject}
                        onChange={handleProjectChange}
                        label="Select a project"
                    >
                        {/* <MenuItem value="Select a project">
                            <em>Select a project</em>
                        </MenuItem> */}
                        {singleProjectData.map(project => (
                            <MenuItem key={project._id} value={project._id}>{project._id}</MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <BarChart
                    width={1000}
                    height={500}
                    data={pdata}
                    margin={{
                        top: 20,
                        right: 30,
                        left: 20,
                        bottom: 5
                    }}
                >
                    <CartesianGrid strokeDasharray="4 4" />
                    <XAxis dataKey="customer" angle={-20} textAnchor="end" interval={0} height={100} />
                    <YAxis />
                    <Tooltip />
                    <Legend verticalAlign="top" height={50} content={<CustomLegend />} />
                    <Bar name='Project Size' dataKey="project_size" fill="#1B2C58" barSize={30} radius={[5, 5, 0, 0]} />
                </BarChart>
            </Grid>
        </Grid>
    )
}
