import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Fab, Grid, Input, Stack, TextField, Typography } from "@mui/material"
import React, { useState, useEffect } from 'react'
import axios from "../../api/axios";
import { useLocation } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import Notify from "../../features/helpers/notifyConfig";
import AddIcon from '@mui/icons-material/Add';
import { AssetForm } from "./AssetForm";
import { Cancel } from "@mui/icons-material";

export const EditEmployeeData = () => {
  let location = useLocation();
  const userDetails = location.state;
  const [userData, setuserData] = useState(userDetails);
  const [userInfo, senduserInfo] = useState(false);
  const [btnStatus, setbtnStatus] = useState("none");
  const [open, setOpen] = useState(false);
  const initialData =userDetails;

  const handleClose = () => {
    setOpen(false);
  };

  const handlestate = (e) => {
    const val = e.target.value;
    const naam = e.target.name;
    setuserData(prevState => ({
      ...prevState,
      [naam]: val
    }), setbtnStatus("block")
    )
  }

  const handleChanges = (e) => {
    if (userData.ContactNo.length != 10) Notify.warning("Contact No should be of 10 numbers!");
    else senduserInfo(true);
  }

  const revertChanges =(e) => {
    setuserData(userDetails);
    setbtnStatus("none");
  }

  const sendEmplyeeData = async () => {
    const response = await axios.put("/dashboard/employeedata/edit",
      JSON.stringify(userData),
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ` + localStorage.getItem('access')
        }
      }
    ).then((response) => {
      console.log(response);
      setbtnStatus("none")
      senduserInfo(false);
      Notify.success("Profile Updated Successfully!")
    }).catch((error) => {
      console.log(error.response?.data + " " + localStorage.getItem('access'))
      senduserInfo(false);
      Notify.success("Some Error Occurred. Try Again!")
    });
  }

  useEffect(() => {
    // sendEmplyeeData();
    if (userInfo) {
      sendEmplyeeData();
    }
    // console.log(userData);
  }, [userInfo])

  return (
    <Box p={2} mt={1} component="form" noValidate autoComplete="off">
      <Box p={2} border={1} borderRadius={0.5} borderColor="dark_divider" bgcolor="background.light_gray">
        <Grid container rowGap={4}>
          <Grid item md={6}>
            <Typography variant="subtitle1" fontWeight={600}>Basic Info</Typography>
            <Grid container pt={3} sx={{ color: 'dark_divider' }}>
              <Grid item md>
                <Typography variant="subtitle2">Employee ID</Typography>
              </Grid>

              <Grid item md>
                <Typography variant="subtitle2">{userData.emp_id}</Typography>
              </Grid>
            </Grid>

            <Grid container pt={3}>
              <Grid item md>
                <Typography variant="subtitle2">First Name</Typography>
              </Grid>

              <Grid item md>
                <TextField
                  variant="standard"
                  inputProps={{ style: { fontSize: 14, color: 'blue' } }}
                  InputProps={{
                    disableUnderline: userData.FirstName ? true : false,
                  }}
                  size="small"
                  color="warning"
                  name="FirstName"
                  value={userData.FirstName}
                  onChange={handlestate}
                />
              </Grid>
            </Grid>

            <Grid container pt={3}>
              <Grid item md>
                <Typography variant="subtitle2">Last Name</Typography>
              </Grid>

              <Grid item md>
                <TextField
                  variant="standard"
                  inputProps={{ style: { fontSize: 14, color: 'blue' } }}
                  InputProps={{
                    disableUnderline: userData.LastName ? true : false,
                  }}
                  size="small"
                  color="warning"
                  name="LastName"
                  value={userData.LastName}
                  onChange={handlestate}
                />
              </Grid>
            </Grid>

            <Grid container pt={3} sx={{ color: 'dark_divider' }}>
              <Grid item md>
                <Typography variant="subtitle2">Email ID</Typography>
              </Grid>

              <Grid item md>
                <Typography variant="subtitle2">{userData.mail}</Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item md={6}>
            <Typography variant="subtitle1" fontWeight={600}>User</Typography>
            <Grid container pt={3}>
              <Grid item md>
                <Typography variant="subtitle2">Contact Number</Typography>
              </Grid>

              <Grid item md>
                <TextField
                  variant="standard"
                  inputProps={{ style: { fontSize: 14, color: 'blue' } }}
                  InputProps={{
                    disableUnderline: userData.ContactNo ? true : false,
                  }}
                  size="small"
                  color="warning"
                  name="ContactNo"
                  value={userData.ContactNo}
                  onChange={handlestate}
                />
              </Grid>
            </Grid>

            <Grid container pt={3}>
              <Grid item md>
                <Typography variant="subtitle2">Address</Typography>
              </Grid>

              <Grid item md>
                <TextField
                  variant="standard"
                  inputProps={{ style: { fontSize: 14, color: 'blue' } }}
                  InputProps={{
                    disableUnderline: userData.Address ? true : false,
                  }}
                  size="small"
                  color="warning"
                  name="Address"
                  value={userData.Address}
                  onChange={handlestate}
                />
              </Grid>
            </Grid>

            <Grid container pt={3}>
              <Grid item md>
                <Typography variant="subtitle2">Date Of Birth</Typography>
              </Grid>

              <Grid item md>
                <TextField
                  variant="standard"
                  inputProps={{ style: { fontSize: 14, color: userData.Date_of_Birth ? 'blue' : 'black' } }}
                  size="small"
                  color="warning"
                  name="Date_of_Birth"
                  value={userData.Date_of_Birth}
                  onChange={handlestate}
                  type="date"
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item md={6}>
            <Typography variant="subtitle1" fontWeight={600}>Emergency Contact</Typography>
            <Grid container pt={3}>
              <Grid item md>
                <Typography variant="subtitle2">Emergency Contact Name</Typography>
              </Grid>

              <Grid item md>
                <TextField
                  variant="standard"
                  inputProps={{ style: { fontSize: 14, color: 'blue' } }}
                  InputProps={{
                    disableUnderline: userData.Emergency_Contact_Name ? true : false,
                  }}
                  size="small"
                  color="warning"
                  name="Emergency_Contact_Name"
                  value={userData.Emergency_Contact_Name}
                  onChange={handlestate}
                />
              </Grid>
            </Grid>

            <Grid container pt={3}>
              <Grid item md>
                <Typography variant="subtitle2">Emergency Contact</Typography>
              </Grid>

              <Grid item md>
                <TextField
                  variant="standard"
                  inputProps={{ style: { fontSize: 14, color: 'blue' } }}
                  InputProps={{
                    disableUnderline: userData.Emergency_Contact_Number ? true : false,
                  }}
                  size="small"
                  color="warning"
                  name="Emergency_Contact_Number"
                  value={userData.Emergency_Contact_Number}
                  onChange={handlestate}
                />
              </Grid>
            </Grid>

            <Grid container pt={3}>
              <Grid item md>
                <Typography variant="subtitle2">Relation</Typography>
              </Grid>

              <Grid item md>
                <TextField
                  variant="standard"
                  inputProps={{ style: { fontSize: 14, color: 'blue' } }}
                  InputProps={{
                    disableUnderline: userData.Emergency_Relation ? true : false,
                  }}
                  size="small"
                  color="warning"
                  name="Emergency_Relation"
                  value={userData.Emergency_Relation}
                  onChange={handlestate}
                />
              </Grid>
            </Grid>

          </Grid>

          <Grid item md={6}>
            <Typography variant="subtitle1" fontWeight={600}>Work</Typography>
            <Grid container pt={3}>
              <Grid item md>
                <Typography variant="subtitle2">Date of joining</Typography>
              </Grid>

              <Grid item md>
                <TextField
                  variant="standard"
                  inputProps={{ style: { fontSize: 14, color: userData.Date_of_Joining ? 'blue' : 'black' } }}
                  size="small"
                  color="warning"
                  name="Date_of_Joining"
                  value={userData.Date_of_Joining}
                  onChange={handlestate}
                  type="date"
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item md={6}>
            <Stack direction='row' alignItems='center' spacing={1}>
            <Typography variant="subtitle1" fontWeight={600}>Assets</Typography>
            <Button variant='outlined'
              onClick={() => setOpen(true)}
              sx={{
                padding: '1px 10px',
                marginTop: '4px'
              }}
            >Manage</Button>
            </Stack>
            {
              userData.Assets.map((val,key) => (
                <>
                  <Grid key={key} container pt={3}>
                    <Grid item md>
                      <Typography variant="subtitle2">{val.type+" "+val.device}</Typography>
                    </Grid>

                    <Grid item md>
                    <Typography color='blue' variant="subtitle2">{val.mac_address}&nbsp;&nbsp; {val.type=='Personal'?'':<span style={{color:'green'}}>(${val.price})</span>}</Typography>
                    </Grid>
                  </Grid>
                </>
              ))}

          </Grid>
        </Grid>

        <Stack justifyContent='end' direction='row' gap={1}>
          <LoadingButton loading={userInfo} onClick={handleChanges} variant="contained" size="small" sx={{ display: btnStatus }}>Save Changes</LoadingButton>
          <Button onClick={revertChanges} variant="outlined" size="small" sx={{ display: btnStatus }}>Undo</Button>
        </Stack>

        {/* POPUP BOX FOR ADDING A NEW ASSET */}
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="edit-apartment"
          fullWidth
          maxWidth='sm'
        >
          {/* <DialogTitle sx={{ backgroundColor: "#F6F5F5" }} id="edit-apartment">Add your  Assets</DialogTitle> */}
          <DialogContent sx={{ backgroundColor: "#F6F5F5" }}>
            <AssetForm userData={userData} setuserData={setuserData} handleClose={handleClose} setbtnStatus={setbtnStatus}/>
            <Button sx={{ position: "absolute", top: 10, right: 5 }} onClick={handleClose} color="secondary">
              <Cancel sx={{ fontSize: "1.6rem" }} />
            </Button>
          </DialogContent>
        </Dialog>

      </Box>
    </Box>
  )
}
