import { Box, Button, Grid, Stack, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import axios from '../../../api/axios';
import Notify from '../../../features/helpers/notifyConfig';

const status = [{
    "status": "Started",
    "label": "Started (0%)"
},
{
    "status": "Active",
    "label": "Active (25%)"
},
{
    "status": "In Progress",
    "label": "In Progress (50%)"
},
{
    "status": "Almost Completed",
    "label": "Almost Completed (75%)"
},
{
    "status": "Completed",
    "label": "Completed (100%)"
}
]

const num = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

export const EvaluateForm = ({ data, setEvaluation, type = '' }) => {
    const [update, setUpdate] = useState({
        "uuid": type == '' ? data.uuid : '',
        "goals": type == '' ?
            [
                {
                    "goal": data.goal,
                    "goal_id": data.goal_id,
                    "status": "Not Started",
                    "remark": ""
                }
            ]
            : []
    });
    const [finalpoint, setPoint] = useState(0);
    const [load, setLoading] = useState(false);

    const handleChange = (e, target) => {
        if (type != '') {
            setPoint(Number(e.target.value));
        }
        else {
            setUpdate((prevUpdate) => ({
                ...prevUpdate,
                goals: [{
                    ...prevUpdate.goals[0],
                    [target]: e.target.value
                }]
            }));
        }
    }

    // Update Half Yearly Goal
    const HalfYearlyUpdate = async () => {
        await axios.post("/appraisal/half-year-updates",
            JSON.stringify(update),
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json',
                    withCredentials: true,
                    mode: 'no-cors',
                    'Authorization': `Bearer ` + localStorage.getItem('access')
                }
            }).then((response) => {
                Notify.success('Goal Updated Successfully!');
                setEvaluation(false);
            }).catch((error) => {
                Notify.error(error?.response?.data?.error);
            })
    }

    const triggerUpdate = () => {
        setLoading(true);
    }

    const finalUpdate = () => {
        console.log(finalpoint);
        const YearlyUpdate = async () => {
            await axios.post("/appraisal/mgr-endyear-goalpoints",
                JSON.stringify({
                    "goal_id": data.goal_id,
                    "points": finalpoint
                }),
                {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Content-Type': 'application/json',
                        withCredentials: true,
                        mode: 'no-cors',
                        'Authorization': `Bearer ` + localStorage.getItem('access')
                    }
                }).then((response) => {
                    Notify.success('Final Remarks Added Successfully!');
                    setEvaluation(false);
                }).catch((error) => {
                    Notify.error(error?.response?.data?.error);
                })
        }
        YearlyUpdate();
    }

    useEffect(() => {
        if (load && type == '') {
            HalfYearlyUpdate();
            setLoading(false);
        }
    }, [load])

    return (
        <Box pt={2}>
            <Grid container rowSpacing={4}>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={3}>
                            <Typography fontFamily='Segoe UI' fontWeight='700'>Goal</Typography>
                        </Grid>
                        <Grid item xs={9}>
                            <Typography fontFamily='Segoe UI'>{data.goal}</Typography>
                        </Grid>
                    </Grid>
                </Grid>

                {type == ''
                    ? <>
                        <Grid item xs={12}>
                            <Grid container alignItems='center'>
                                <Grid item xs={3}>
                                    <Typography fontFamily='Segoe UI' fontWeight='700'>Status</Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <TextField
                                        size='small'
                                        sx={{
                                            width: '14rem', '& .MuiInputBase-input': {
                                                height: 'auto !important'
                                            },
                                            '& .MuiInputBase-root': {
                                                bgcolor: 'white'
                                            }
                                        }}
                                        select
                                        // label="Goal Status"
                                        // value={currency}
                                        onChange={(e) => handleChange(e, 'status')}
                                        SelectProps={{
                                            native: true,
                                        }}
                                        variant="outlined">
                                        {status.map((option, key) => (
                                            <option key={key} value={option.status}>
                                                <Typography variant='subtitle2'>{option.label}</Typography>
                                            </option>
                                        ))}
                                    </TextField>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={3}>
                                    <Typography fontFamily='Segoe UI' fontWeight='700'>Remarks</Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <TextField
                                        fullWidth
                                        multiline
                                        onChange={(e) => handleChange(e, 'remark')}
                                        sx={{
                                            '& .MuiInputBase-input': {
                                                height: 'auto !important'
                                            },
                                            '& .MuiInputBase-root': {
                                                bgcolor: 'white'
                                            }
                                        }}
                                        rows={4}
                                        placeholder='if any'
                                        variant="outlined"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </>
                    :
                    <Grid item xs={12}>
                        <Grid container alignItems='center'>
                            <Grid item xs={3}>
                                <Typography fontFamily='Segoe UI' fontWeight='700'>Status</Typography>
                            </Grid>
                            <Grid item xs={9}>
                                <TextField
                                    size='small'
                                    sx={{
                                        width: '14rem', '& .MuiInputBase-input': {
                                            height: 'auto !important'
                                        },
                                        '& .MuiInputBase-root': {
                                            bgcolor: 'white'
                                        }
                                    }}
                                    select
                                    // label="Goal Status"
                                    // value={currency}
                                    onChange={(e) => handleChange(e, 'status')}
                                    SelectProps={{
                                        native: true,
                                    }}
                                    variant="outlined">
                                    {num.map((option, key) => (
                                        <option key={key} value={option}>
                                            <Typography variant='subtitle2'>{option}</Typography>
                                        </option>
                                    ))}
                                </TextField>
                            </Grid>
                        </Grid>
                    </Grid>
                }
            </Grid>

            <Stack mt={2} sx={{ transform: 'translateY(5px)' }} direction='row' justifyContent='end'>
                <Button onClick={type == '' ? triggerUpdate : finalUpdate} variant='contained' sx={{ px: 3, background: '#198f51' }} color="primary">
                    Update Status
                </Button>
            </Stack>
        </Box>
    )
}
