import {useState} from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Typography, Button} from '@mui/material';
import Box from '@mui/material/Box';
import { Quotes } from './Quotes';
import { QuoteCompany } from './QuoteCompany';
import { QuoteProducts } from './QuoteProducts';
import { QuoteServices } from './QuoteServices';


function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box pt={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export const ButtonStyle = ({ title, handle }) => {
  return (
    <Button onClick={handle} variant='contained' sx={{ py: 1, px: 3, borderRadius: '5px', height: '35px' }}>
      <Typography variant='subtitle2'>{title}</Typography>
    </Button>
  )
}

export const inputStyle = {
  "& .MuiInputBase-input": {
    height: "10px"
  },
  "& .MuiInputBase-root": {
    borderRadius: "5px"
  },
  "& .MuiSelect-select": {
    paddingY: "4px"
  }
}
export default function QuoteIndex() {
  const [value, setValue] = useState(0);
  const [managerData, setManagerData] = useState();

  const boxStyle = {
    border: 'solid 1px',
    borderColor: 'dark_divider',
    borderRadius: '5px'
  }

  const billButtonStyle = {
    "& .MuiInputBase-input": {
      height: "18px"
    }
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    
    <Box sx={{ width: '100%' }} py={2} px={3}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Quotes" {...a11yProps(0)} />
          <Tab label="Companies" {...a11yProps(1)} />
          <Tab label="Products" {...a11yProps(2)} />
          <Tab label="Services" {...a11yProps(3)} />
        </Tabs>
      </Box>

      <CustomTabPanel value={value} index={0}>
        <Quotes/>
      </CustomTabPanel>

      <CustomTabPanel value={value} index={1}>
        <QuoteCompany />
      </CustomTabPanel>

      <CustomTabPanel value={value} index={2}>
        <QuoteProducts/>
      </CustomTabPanel>

      <CustomTabPanel value={value} index={3}>
        <QuoteServices/>
      </CustomTabPanel>

      </Box>
  )
}