import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Typography, Button, Box, Tabs, Tab, Stack, Skeleton } from '@mui/material';
import Notify from '../../features/helpers/notifyConfig';
import axios from '../../api/axios';
import { ExpenseClaim } from './Personal/ExpenseClaim';
import { ExpenseHistory } from './Personal/ExpenseHistory';
import { styled } from '@mui/material/styles';

const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    textTransform: 'none',
    fontFamily: 'Segoe UI',
    fontSize: 16
}));

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box pt={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function ExpenseIndex() {
    const [value, setValue] = useState(0);
    const [infodata, setInfodata] = useState(null);
    const [reload, setReload] = useState(false);

    //1. get projects and manager info regarding expense
    const getExpInfo = async () => {
        await axios.get("/expense-report",
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ` + localStorage.getItem('access')
                }
            }
        ).then((response) => {
            // console.log(response);
            setInfodata(response?.data?.expenseReportDependancies);
        }).catch((error) => {
            Notify.error(error.response ? (error?.response?.data?.error) : "Request Timed Out");
        });
    }

    useEffect(() => {
        getExpInfo();
    }, [reload])

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: '100%' }} py={1} px={3}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <AntTab label="Claim  Reimbursement" {...a11yProps(0)} />
                    <AntTab label="History" {...a11yProps(1)} />
                </Tabs>
            </Box>

            <CustomTabPanel value={value} index={0}>
                {infodata
                    ? <ExpenseClaim setReload={setReload} infodata={infodata} />
                    : <Stack gap={2} width="100%">
                        <Skeleton animation="wave" variant="rectangular" width="100%" height={80} />
                        <Skeleton animation="wave" variant="rectangular" width="100%" height={80} />
                    </Stack>
                }
            </CustomTabPanel>

            <CustomTabPanel value={value} index={1}>
                {infodata
                    ? <ExpenseHistory setReload={setReload} />
                    : <Stack gap={2} width="100%">
                        <Skeleton animation="wave" variant="rectangular" width="100%" height={80} />
                        <Skeleton animation="wave" variant="rectangular" width="100%" height={80} />
                    </Stack>
                }
            </CustomTabPanel>

        </Box>
    );
}