import { Box } from '@mui/material'
import React from 'react'
import logo from '../404-error.png'

export const PageNotFound = () => {
  return (
    <Box display='flex' alignItems='center' justifyContent='center' height='80vh'>
        <Box width='20rem' height='20rem' sx={{backgroundImage:`url(${logo})`,backgroundSize:'10rem', backgroundRepeat:'no-repeat', backgroundPosition:'center'}}></Box>
    </Box>
  )
}
