import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { Box, Button, CircularProgress, Dialog, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, Paper, Stack, Tooltip } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import axios from '../../api/axios';
import { Cancel, Delete, VisibilityOff } from '@mui/icons-material';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import { advancedDate, postData } from '../../pages/Events';

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        backgroundColor: '#44b700',
        color: '#44b700',
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: 'ripple 1.2s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""',
        },
    },
    '@keyframes ripple': {
        '0%': {
            transform: 'scale(.8)',
            opacity: 1,
        },
        '100%': {
            transform: 'scale(2.4)',
            opacity: 0,
        },
    },
}));

const formatDate = (dateString) => {
    const date = new Date(dateString);
    // Get the components of the date
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-indexed
    const day = date.getDate().toString().padStart(2, '0');
    const hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const ampm = hours >= 12 ? 'pm' : 'am';
    const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
    const formattedDate = `${year}-${month}-${day}  ${formattedHours}:${minutes} ${ampm}`;
    return formattedDate;
};

export function MessageBox({ data, setLoading, loading }) {
    const [cdata, setCdata] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const [wait, setWait] = React.useState(null);

    const handleClose = () => {
        setOpen(false);
    };

    const ManageNotification = (id, action) => {
        setWait({
            "id": id,
            "action": action
        });

        const finData =
            id != ""
                ? {
                    "notificationID": id,
                    "action": action
                }
                : {
                    "action": action
                }

        const setView = async () => {
            await axios.put(`/notifications`,
                JSON.stringify(finData),
                {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Content-Type': 'application/json',
                        withCredentials: true,
                        mode: 'no-cors',
                        'Authorization': `Bearer ` + localStorage.getItem('access')
                    }
                }).then(() => {
                    setLoading(true);
                    setWait(null);
                }).catch((error) => {
                    console.log(error.response);
                    setWait(null);
                })
        }
        setView();
    }

    const openNotification = (val) => {
        setCdata(val);
        setOpen(true);
        ManageNotification(val.notificationID, "seen");
    }

    return (
        <>
            <Paper elevation={4}>
                <Stack px={2} direction='row' borderRadius='6px 6px 0 0' color='white' bgcolor='#344955' alignItems='center' py={1.5} justifyContent='space-between'>
                    <Typography fontFamily='Segoe UI' variant='subtitle1' fontWeight='600' textAlign="center">Notifications</Typography>
                    <Stack direction='row' spacing={1} alignItems='center' sx={{ cursor: 'pointer', transform: 'translateY(2px)' }} onClick={() => ManageNotification("", "read_all")}>
                        <Typography
                        fontFamily='Segoe UI' 
                        fontSize='12px'
                        sx={{
                            padding: '2px 8px',
                            borderRadius: '5px',
                            ':hover': {
                                background: 'grey',
                            }
                        }}
                        >Mark all as read</Typography>
                    </Stack>
                </Stack>
                <Divider />
                <List sx={{
                    width: 350,
                    minHeight: 300,
                    maxHeight: 350,
                    bgcolor: 'background.paper',
                    borderRadius: '6px',
                    overflow: 'auto'
                }}>

                    {
                        wait != null && wait.action == "read_all"
                            ? <ListItem sx={{ cursor: 'pointer' }} alignItems="center" >
                                <Box margin='auto'><CircularProgress size='13px'/></Box>
                            </ListItem>
                            : <></>
                    }
                    {
                        data ? data.map((val, key) => {
                            return (
                                <>
                                    <ListItem key={key} sx={{ cursor: 'pointer' }} alignItems="center" >
                                        <Stack direction='row' spacing={2} alignItems='center'>

                                            <StyledBadge
                                                overlap="circular"
                                                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                                variant={val.seen ? "" : "dot"}
                                            >
                                                <Avatar sx={{ bgcolor: 'orange', width: '30px', height: '30px' }}>
                                                    <EmailIcon fontSize='8px' />
                                                </Avatar>
                                            </StyledBadge>

                                            <Box onClick={() => openNotification(val)}>
                                                <Typography
                                                    fontWeight={val.seen ? '500' : '700'}
                                                    variant="subtitle1"
                                                    color="primary.main"
                                                    fontSize='13px'
                                                    lineHeight='1.3'
                                                    fontFamily='Segoe UI'
                                                >
                                                    {val.title}
                                                </Typography>
                                                <Typography
                                                    color={val.seen ? 'grey' : '#0a5dc2'}
                                                    mt={0.3}
                                                    fontSize='11px'
                                                    fontFamily='Segoe UI'
                                                    variant="subtitle2">{formatDate(val.date)}</Typography>
                                            </Box>

                                            <Box>
                                                <Stack direction='row' spacing={0.3}>
                                                    <IconButton onClick={() => ManageNotification(val.notificationID, val.seen ? 'unseen' : 'seen')}>
                                                        {
                                                            wait != null && wait.id == val.notificationID && wait.action != 'delete'
                                                                ? <CircularProgress size='17px' />
                                                                : (val.seen
                                                                    ? <Tooltip arrow title='Mark as unseen'> <VisibilityOff sx={{ fontSize: '17px' }} /> </Tooltip>
                                                                    : <Tooltip arrow title='Mark as seen'> <RemoveRedEyeIcon sx={{ fontSize: '17px' }} /> </Tooltip>
                                                                )
                                                        }
                                                    </IconButton>

                                                    <IconButton onClick={() => ManageNotification(val.notificationID, "delete")}>
                                                        {
                                                            wait != null && wait.id == val.notificationID && wait.action == 'delete'
                                                                ? <CircularProgress size='17px' />
                                                                : <Delete color='error' sx={{ fontSize: '17px' }} />
                                                        }
                                                    </IconButton>
                                                </Stack>
                                            </Box>
                                        </Stack>
                                    </ListItem>
                                    <Divider variant="inset" component="li" />
                                </>
                            )
                        })
                            :
                            <>
                                <Typography variant='subtitle1' fontWeight='600' textAlign='center' pt='10px'>No new notifications!</Typography>
                                {/* <Divider variant="inset" component="li" /> */}
                            </>
                    }
                </List>
            </Paper>

            {cdata ?
                <Dialog
                    maxWidth="xs"
                    fullWidth={true}
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="edit-apartment"
                    // sx={{ marginRight: '8rem', zIndex: '9999', border: '1px solid' }}
                >

                    <DialogTitle sx={{py:'10px'}}>
                        <Grid container spacing={1} direction='row' alignItems='center'>
                        <Grid item xs={10.5}>
                            <Typography fontSize='18px' fontFamily='Segoe UI' fontWeight='600'>{cdata.title}</Typography>
                            </Grid>
                            <Grid item xs={1.5}>
                        <Button onClick={handleClose} color="error">
                            <Cancel sx={{ fontSize: "1.5rem" }} />
                        </Button>
                        </Grid>
                        </Grid>
                    </DialogTitle>
                    <DialogContent style={{ minHeight: '30vh' }}>
                        <DialogContentText fontWeight='600'>
                        </DialogContentText>
                        <Typography sx={{ overflowWrap: 'anywhere', fontFamily: 'Segoe UI' }} fontSize='14px' pr={1} borderRadius='5px' variant='subtitle1'>{cdata.content}</Typography>
                        <Box 
                        sx={{
                            bgcolor: '#e0e0e0',
                            borderRadius: '5px',
                            padding: '3px 8px',
                            display: 'inline-block',
                        }}
                        mt={1.5}
                        ><Typography color='grey' fontSize='11px'>{advancedDate(postData(formatDate(cdata.date)))}</Typography></Box>
                    </DialogContent>
                </Dialog>
                : <></>}


        </>
    );
}