import { useEffect, useRef, useState } from "react";
import { formatDate } from '@fullcalendar/core'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Scrollbars } from 'react-custom-scrollbars';
import {
  Autocomplete,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  List,
  ListItem,
  ListItemText,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import axios from "../../api/axios";
import { LoadingButton } from "@mui/lab";
import Notify from "../../features/helpers/notifyConfig";
import { EventsForm } from "./EventsForm";
import { EventsView } from "./EventsView";
import { currentUser, permAllowed, showRoute, specificpermCheck } from "../../Router";
import { TableView } from "./TableView";

const ColoredBox = ({ label, textcolor, boxcolor }) => {
  return (
    <Box sx={{ backgroundColor: boxcolor, border: `solid 1px ${textcolor}`, width: 'max-content', padding: '3px 10px', borderRadius: '5px' }}>
      <Typography variant='subtitle1' fontWeight='600' sx={{ color: textcolor }}>{label}</Typography>
    </Box>
  )
}

export const advancedDate = (dateString) => {
  const [year, month, day] = dateString.split('-').map(Number);
  const monthNames = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];
  const monthName = monthNames[month - 1];
  return `${day} ${monthName} ${year}`;
};

export const postData = (dateString) => {
  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();

  return `${year}-${month}-${day}`;
  // return date;
}

export const Index = () => {
  const [currentEvents, setCurrentEvents] = useState([]);
  const [eventDialog, openDialog] = useState(false);
  const [empList, setEmpList] = useState(null);
  const [loading, setLoading] = useState(false);
  const [eventTitle, setEventTitle] = useState("");
  const [evntchng, triggerevntchg] = useState(null);
  const [selectedDate, setDate] = useState("");
  const [blogStat, setStat] = useState(null);
  const [view, setView] = useState(null);
  const [edit, setEdit] = useState(null);
  const [finData, setFinData] = useState(
    {
      "start_date": "",
      "task_info": [
        {
          "event_type": "",
          "event_title": "",
          "assigned_to": [],
          "end_date": "",
          "start_time": "",
          "end_time": "",
          "is_full_day": false,
          "description": ""
        }
      ]
    }
  );
  const { user_id, pEvents } = currentUser();
  const currentDate = new Date();
  const currentMonthNumber = currentDate.getMonth() + 1;
  const currentYearNumber = currentDate.getFullYear();
  const [currentMonth, setCurrentMonth] = useState(currentMonthNumber);
  const [currentYear, setCurrentYear] = useState(currentYearNumber);

  const handleClose = () => {
    openDialog(false);
    setView(null);
    setEdit(null);
  };

  const submitAddForm = async () => {
    await axios.post(`/events/add`,
      JSON.stringify(finData),
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          withCredentials: true,
          mode: 'no-cors',
          'Authorization': `Bearer ` + localStorage.getItem('access')
        }
      }).then((response) => {
        // console.log(response);
        openDialog(false);
        Notify.success(`New Activity has been added!`);
        triggerevntchg((prev) => !prev);
        setStat(true);
      }).catch((error) => {
        console.log(error.response);
        Notify.error("Some Error Occurred. Try Again!")
      })
  }

  const UpdateForm = async () => {
    await axios.put(`/events/edit`,
      JSON.stringify(edit),
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          withCredentials: true,
          mode: 'no-cors',
          'Authorization': `Bearer ` + localStorage.getItem('access')
        }
      }).then((response) => {
        // console.log(response);
        handleClose();
        Notify.success(`Activity has been updated!`);
        triggerevntchg((prev) => !prev);
        setStat(true);
      }).catch((error) => {
        console.log(error.response);
        Notify.error("Some Error Occurred. Try Again!")
      })
  }

  const blogCompleted = async (empId, blogId, sad) => {
    await axios.put(`/blog-tracking/update-by-id`,
      JSON.stringify({
        "is_complete": true,
        "emp_id": empId,
        "_id": blogId
      }),
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          withCredentials: true,
          mode: 'no-cors',
          'Authorization': `Bearer ` + localStorage.getItem('access')
        }
      }).then((response) => {
        // console.log(response);
        openDialog(false);
        triggerevntchg((prev) => !prev);
        Notify.success(`Your blog has been marked completed!`);
        setStat(true);
      }).catch((error) => {
        console.log(error.response);
        Notify.error("Some Error Occurred. Try Again!")
      })
  }

  const handleDateClick = (selected) => {
    openDialog(true);
    const title = eventTitle;
    const calendarApi = selected.view.calendar;
    setDate(selected.startStr);
    // setFinData({ ...finData, start_date: postData(selected.startStr) });
    setFinData({
      "start_date": selected.startStr,
      "task_info": [
        {
          "event_type": "",
          "event_title": "",
          "assigned_to": [],
          "end_date": "",
          "start_time": "",
          "end_time": "",
          "is_full_day": false,
          "description": ""
        }
      ]
    });
    calendarApi.unselect();

    if (blogStat) {
      setStat(null);
      const reqData = "Blog"
      calendarApi.addEvent({
        id: `${selected.dateStr}-${title}`,
        reqData,
        start: selected.startStr,
        end: selected.endStr,
        allDay: selected.allDay,
      });
    }
  };

  const handleEventClick = (selected) => {
    // alert(selected.event.extendedProps.end_time);
    const eventValues = selected.event.extendedProps;
    setView(eventValues);
    //Closing popover on event click
    var popovers = document.querySelectorAll('.fc-popover');
    popovers.forEach(function (popover) {
      popover.style.display = 'none';
    });
    openDialog(true);
  };

  useEffect(() => {
    const getEmployees = async () => {
      await axios.get("/blog-tracking/get-all-employees",
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ` + localStorage.getItem('access'),
            'Access-Control-Allow-Origin': '*',
            mode: 'no-cors'
          }
        }).then((response) => {
          console.log(response);
          setEmpList(response?.data?.message);
        }).catch((error) => {
          console.log(error.response)
        })
    }
    getEmployees();
  }, [])

  const getCurrentMonthEvents = async () => {
    try {
      const currentDate = new Date();
      // const currentMonthNumber = currentDate.getMonth() + 1;
      const response = await axios.get(`/blog-tracking/get-all-for-month/${currentMonth}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ` + localStorage.getItem('access'),
          'Access-Control-Allow-Origin': '*',
          mode: 'no-cors'
        }
      });
      const data = response.data.message;
      const events = Object.keys(data).reduce((acc, keyName) => {
        const mappedEvents = data[keyName].map((value) => ({
          id: value._id,
          // title: value.event_title ? `${value.event_title} -${empList[value?.assigned_to[0]]?.employee_name ?? "All"}` : `${value.type} -${empList[value?.assigned_to[0]]?.employee_name ?? "All"}`,
          title: value.type == 'Blog' ? `${value.type} - ${value?.assigned_to[0]}` : `${value.event_type}`,
          start: keyName,
          end: value.end_date ? value.end_date : keyName,
          event_type: value.event_type,
          type: value.type,
          event_title: value.event_title,
          assigned_to: value.assigned_to,
          last_date: value.last_date ?? "",
          end_date: value.end_date,
          start_date: keyName,
          start_time: value.start_time,
          end_time: value.end_time,
          is_full_day: value.is_full_day,
          description: value.description,
          emp_id: value.emp_id ?? "",
          is_complete: value.is_complete ?? "",
          ied: value._id
        }));
        return [...acc, ...mappedEvents];
      }, []);
      return events; // Return the events array
    } catch (error) {
      console.error(error);
      return []; // Return an empty array in case of error
    }
  };


  useEffect(() => {
    // if(!refresh){
    if (currentMonth)
      getCurrentMonthEvents().then(events => setCurrentEvents(events));
    // setRefresh(true);
    // }
  }, [currentMonth])


  // DELETE A EVENT
  const deleteEvent = async (type, ied) => {
    await axios.delete(`/events/delete`,
      {
        headers: {
          'Authorization': `Bearer ` + localStorage.getItem('access')
        },
        data: {
          "event_type": type,
          "_id": ied
        }
      }).then((response) => {
        Notify.success('Event Deleted Successfully!');
        triggerevntchg((prev) => !prev);
        handleClose();
      }).catch((error) => {
        Notify.error('Event Deletion Faild!');
      })
  }

  //EDIT EVENT
  const editEvent = (editData) => {
    // console.log(editData);
    setEdit({
      "_id": editData.ied,
      "event_type": editData.type,
      "event_title": editData.event_title,
      "start_date": editData.start_date,
      "end_date":"",
      "start_time": "",
      "end_time": "",
      "is_full_day":true,
      "assigned_to": [Object.keys(empList).find(key => empList[key].employee_name === editData.assigned_to[0])],
      "description": editData.description
    });
  }

  return (

    <Box my={2.5} mx={3}>
      {empList ?
        <>
          <Box display="flex" justifyContent="space-between">

            {/* CALENDAR */}
            <Box borderRadius="8px" overflow='hidden' flex="1 1 100%" mr="20px" ml="5px">
              {/* <h2>Current Month: {currentMonth}</h2> */}
              <TableView currentMonth={currentMonth} setCurrentMonth={setCurrentMonth} setCurrentYear={setCurrentYear} handleDateClick={handleDateClick} handleEventClick={handleEventClick} evntchng={evntchng} />
            </Box>

            {/* CALENDAR SIDEBAR */}
            {/* <Box
              height="85.6vh"
              overflow='auto'
              flex="1 1 20%"
              p="15px"
              backgroundColor="#283241"
              borderRadius="6px"
            >
              <Scrollbars>
                <Typography color='primary.white' variant="h6" fontWeight="600">Activities</Typography>
                <List>
                  {currentEvents.map((event) => (
                    <ListItem
                      key={event.id}
                      sx={{
                        backgroundColor: 'grey',
                        color: 'white',
                        fontWeight: '600',
                        margin: "10px 0",
                        borderRadius: "6px",
                      }}
                    >
                      <ListItemText
                        primary={event.title}
                        secondary={event.type == "Blog" ?
                          <Typography>
                            <span style={{ fontWeight: '600' }}>End:</span> {formatDate(event.start, {
                              year: "numeric",
                              month: "short",
                              day: "numeric",
                            })}
                          </Typography>
                          : <>
                            <Typography color='white'>
                              <span style={{ fontWeight: '600' }}>Start:</span> {formatDate(event.start, {
                                year: "numeric",
                                month: "short",
                                day: "numeric",
                              })}
                            </Typography>

                            <Typography color='white'>
                              <span style={{ fontWeight: '600' }}>End:</span> {formatDate(event.end, {
                                year: "numeric",
                                month: "short",
                                day: "numeric",
                              })}
                            </Typography>
                          </>
                        }
                      />
                    </ListItem>
                  ))}
                </List>
              </Scrollbars>
            </Box> */}
          </Box>

          {/* Dialog Box for adding event */}
          <Dialog
            maxWidth="sm"
            fullWidth={true}
            open={eventDialog}
            onClose={handleClose}
            aria-labelledby="edit-apartment"
          >

            <DialogTitle><Typography variant="h6" fontFamily='Segoe UI'>
              {view ?
                <Stack justifyContent='end' alignItems='center' direction='row' spacing={3}>
                  {view.type == "Blog" ? <ColoredBox label={view.is_complete ? 'Completed' : 'Pending'} textcolor={view.is_complete ? '#198F51' : '#f04949'} boxcolor={view.is_complete ? '#49db8e42' : '#FFDADA'} /> : <></>}
                  {showRoute(specificpermCheck(pEvents,['Edit Events']),<Tooltip title="Edit Event"><EditIcon sx={{ cursor: 'pointer', "&:hover": { color: "info.main" } }} onClick={() => editEvent(view)} /></Tooltip>,"")}
                  {showRoute(specificpermCheck(pEvents,['Delete Events']),<Tooltip title="Delete Event"><DeleteIcon sx={{ cursor: 'pointer', "&:hover": { color: "error.main" } }} onClick={() => deleteEvent(view.event_type ?? "Blog", view.ied)} /></Tooltip>,"")}
                </Stack>
                : <>Add Event - <span style={{ textDecoration: 'underline' }}>{advancedDate(selectedDate)}</span></>}
            </Typography></DialogTitle>
            <DialogContent>
              {view ? (edit ? <EventsView editData={edit} setEdit={setEdit} empList={empList} /> : <EventsView data={view} />) : <EventsForm finData={finData} setFinData={setFinData} empList={empList} />}
            </DialogContent>

            <DialogActions sx={{ backgroundColor: 'ButtonFace' }}>
              {view ? 
              (edit ?
                <LoadingButton size="sm" loading={loading} variant='contained' onClick={() => UpdateForm()}>Update</LoadingButton>
              :(
                view.type == "Blog" && (user_id == view.emp_id) && !view.is_complete
                  ? <LoadingButton size="sm" loading={loading} variant='contained' onClick={() => blogCompleted(view.emp_id, view.ied, view.is_complete)}>Completed</LoadingButton>
                  : <></>
              ))
                : 
              <LoadingButton size="sm" loading={loading} variant='contained' onClick={() => submitAddForm()}>Save</LoadingButton>
              }
            </DialogActions>

          </Dialog>
        </>
        : <> </>}
    </Box >
  );
};