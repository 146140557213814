import { Box, Grid, Button, Typography, Stack, Skeleton, Card, CardContent, CardActions, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, FormControl, InputLabel, Select, MenuItem, IconButton, Icon, Avatar, Tooltip } from '@mui/material';
import { useEffect, useState, version } from 'react';
import AddIcon from '@mui/icons-material/Add';
import axios from '../../api/axios';
import { LoadingButton } from '@mui/lab';
import { NodataFound } from '../NodataFound';
import { PrjctForm } from './PrjctForm';
import Notify from '../../features/helpers/notifyConfig';
import AssessmentIcon from '@mui/icons-material/Assessment';
import { ProjectStats } from './ProjectStats';
import { Cancel } from '@mui/icons-material';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';

const progessColor = (val) => {
  if (val <= 90) return "success";
  else if (val > 90 && val<=100) return "info";
  return "error";
}

const progessTextColor = (val) => {
  if (val <= 90) return "green";
  else if (val > 90 && val<=100) return "orange";
  return "error";
}

const status = {
  "Testing": 'red',
  "Ongoing": 'green',
  "Active": "blue"
}

export const Projects = ({ boxStyle, billButtonStyle }) => {
  const [projects, setprojects] = useState();
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const [addData, setaddData] = useState();
  const [viewData, setviewData] = useState();
  const [statData, setstatData] = useState(null);
  const [open, setOpen] = useState(false);
  const [openStat, setOpenStat] = useState(false);
  const [confirm, setConfirm] = useState("");
  const [openView, setOpenView] = useState(false);
  const [addForm, setAddForm] = useState(false);
  const [content, contentChange] = useState(false);
  const [type, selectType] = useState("All");
  const [data, setData] = useState({
    name: "", description: "", managerID: "", status: "", allocatedHours: 0, taskList: [], employees: []
  });


  const selectedChange = (e, val) => {
    selectType(val.props.value);
  }

  const inputStyle = {
    "& .MuiInputBase-input": {
      height: "10px"
    },
    "& .MuiInputBase-root": {
      borderRadius: "5px"
    },
    "& .MuiSelect-select": {
      paddingY: "4px"
    }
  }

  const handleClose = () => {
    setOpen(false);
    setstatData(null);
    setOpenStat(false);
    setOpenView(false);
    setConfirm("");
    setData({
      name: "", description: "", managerID: "", status: "", allocatedHours: 0, taskList: [], employees: []
    });
    setviewData();
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const submitAddForm = () => {
    setAddForm(true);
  }

  //1. Get Projects list
  const getProjects = async () => {
    await axios.get("/projects",
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ` + localStorage.getItem('access')
        }
      }
    ).then((response) => {
      console.log(response);
      setprojects((response?.data?.managerProjectData) ? response.data.managerProjectData : "nodata");
    }).catch((error) => {
      console.log(error.response);
    });
  }

  useEffect(() => {
    getProjects();
  }, [refresh])

  //2. Get Employees, Managers and Tasks list for creating new Project
  const getEmployeeData = async () => {
    await axios.get("/projects/create",
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ` + localStorage.getItem('access')
        }
      }).then((response) => {
        setaddData(response.data)
      }).catch((error) => {
        console.log(error.response);
        Notify.error("Unable to fetch some data. Refresh!")
      })
  }

  useEffect(() => {
    getEmployeeData();
  }, [])


  //3. Add input data from form to add project
  const addNewProject = async () => {
    let employeeIds = [];
    if (data.employees.length > 0) {
      employeeIds = data.employees.map(employee => employee._id);
    }
    const arr = {
      name: data['name'], description: data['description'], managerID: data['managerID'], status: data['status'],
      allocatedHours: data['allocatedHours'], taskList: data['taskList'], employees: employeeIds
    }

    await axios.post("/projects/create",
      JSON.stringify({ "project": arr }),
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          withCredentials: true,
          mode: 'no-cors',
          'Authorization': `Bearer ` + localStorage.getItem('access')
        }
      }).then((response) => {
        console.log(response);
        setAddForm(false);
        handleClose();
        setRefresh(prev => !prev);
        Notify.success("Project has been added successfully!");
      }).catch((error) => {
        console.log(error.response.data)
        Notify.error("Some error occurred!");
        setAddForm(false);
      })
  }

  //4. Edit input data from form to add project
  const editProject = async () => {
    let employeeIds = [];
    if (data.employees.length > 0) {
      employeeIds = data.employees.map(employee => employee._id);
    }
    const arr = {
      name: data['name'], description: data['description'], managerID: data['managerID'], status: data['status'],
      allocatedHours: data['allocatedHours'], taskList: data['taskList'], employees: employeeIds, projectID: viewData.projectID
    }

    await axios.post("/projects/create",
      JSON.stringify({ "project": arr }),
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          withCredentials: true,
          mode: 'no-cors',
          'Authorization': `Bearer ` + localStorage.getItem('access')
        }
      }).then((response) => {
        handleClose();
        setRefresh(prev => !prev);
        Notify.success("Project has been edited successfully!");
      }).catch((error) => {
        // console.log(error.response.data)
        Notify.error("Some error occurred. Try Again!");
      })
  }

  // 5. Delete Project
  const delProject = (prjct_id) => {
    const deletePrjct = async () => {
      await axios.delete(`/projects/delete`,
        {
          headers: {
            'Authorization': `Bearer ` + localStorage.getItem('access')
          },
          data: {
            "projectID": prjct_id
          }
        }).then((response) => {
          Notify.success('Project Deleted Successfully!');
          handleClose();
          setRefresh(prev => !prev);
        }).catch((error) => {
          Notify.error("Record Can't be Deleted. Try Again!")
        })
    }
    deletePrjct();
  }

  // 6. Open Statistics of Project
  const triggerStat = async (pid) => {
    setOpenStat(true);
    await axios.post("/projects/details",
      JSON.stringify({ "projectID": pid }),
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          withCredentials: true,
          mode: 'no-cors',
          'Authorization': `Bearer ` + localStorage.getItem('access')
        }
      }).then((response) => {
        console.log(response);
        setstatData(response?.data?.ProjectDetails);
      }).catch((error) => {
        Notify.error("Some error occurred. Try Again!");
      })
  }

  const viewProject = (prjct) => {
    contentChange(false);
    setOpenView(true);
    let emp_array = [];
    let task_array = [];
    prjct['Employees'].map((val) => {
      emp_array.push({ "name": val['employeeName'], "_id": val['employeeID'] });
    })
    prjct['Tasks'].map((val) => {
      task_array.push(val['taskName']);
    })

    setData({
      name: prjct['projectName'], description: prjct['description'], managerID: prjct['Manager']['_id'],
      status: prjct['status'], allocatedHours: prjct['allocatedHours'], taskList: task_array, employees: emp_array
    });
    setviewData(prjct);
  }

  useEffect(() => {
    if (addForm)
      addNewProject();
  }, [addForm])

  return (

    <Box pt={3}>
      <Stack justifyContent="space-between" direction="row">
        {addData ? <><Button
          onClick={handleClickOpen}
          endIcon={<AddIcon />}
          variant="contained"
        >Add Project</Button>

          <FormControl sx={{ minWidth: '120px' }}>
            <InputLabel size='small' id="demo-simple-select-label">Status</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              size='small'
              value={type}
              label="Status"
              onChange={(e, val) => {
                selectedChange(e, val);
              }}
            >
              <MenuItem value="All"><Typography variant='subtitle2'>All</Typography></MenuItem>
              <MenuItem value="Ongoing"><Typography variant='subtitle2'>Ongoing</Typography></MenuItem>
              <MenuItem value="Active"><Typography variant='subtitle2'>Active</Typography></MenuItem>
              <MenuItem value="Finished"><Typography variant='subtitle2'>Finished</Typography></MenuItem>
            </Select>
          </FormControl>
        </>
          : <></>}
      </Stack>

      {/* Dialog box to create project */}

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="edit-apartment"
      >
        <DialogTitle sx={{ backgroundColor: "#F6F5F5" }} id="edit-apartment">Add New Project</DialogTitle>
        <DialogContent sx={{ backgroundColor: "#F6F5F5" }}>
          <DialogContentText>
            Please fill the required information.
          </DialogContentText>
          {addData ? <PrjctForm prop={addData.projectDependencies} data={data} setData={setData} /> : <></>}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <LoadingButton variant="contained" loading={addForm} onClick={submitAddForm} color="primary">
            Create
          </LoadingButton>
        </DialogActions>
      </Dialog>


      {/* Dialog box to open Statistics of Project */}

      <Dialog
        open={openStat}
        onClose={handleClose}
        aria-labelledby="open-stat"
        maxWidth='xl'
        fullWidth='true'
      >
        <DialogTitle sx={{ backgroundColor: "#F6F5F5" }} id="open-stat">{statData ? statData.projectName : ""} Performance</DialogTitle>
        <DialogContent sx={{ backgroundColor: "#F6F5F5", minHeight: '60vh' }}>
          {
            statData
              ?
              <ProjectStats statData={statData} />
              : <Stack gap={2} width="100%">
                <Skeleton animation="wave" variant="rectangular" width="100%" height={80} />
                <Skeleton animation="wave" variant="rectangular" width="100%" height={80} />
              </Stack>
          }
        </DialogContent>

        <DialogActions>
          <Button sx={{ position: "absolute", top: 10, right: 0 }} onClick={handleClose} color="error">
            <Cancel sx={{ fontSize: "1.8rem" }} />
          </Button>
        </DialogActions>
      </Dialog>

      {projects == "nodata" ? <NodataFound /> :
        <Box>
          <Box mt={2}>
            <Box>
              {projects ?
                <Grid container columnSpacing={4}>
                  {projects.map((data, key) => {
                    const val = data.Project;
                    return (
                      (val.status == type || type == "All") ?
                        <>
                          <Grid key={key} item sm={6} lg={4} xl={3} py={2}>
                            <Card elevation={2} sx={{ height: "100%" }}>
                              <CardContent sx={{ position: 'relative' }}>
                                <IconButton onClick={() => triggerStat(val.projectID)} sx={{ position: 'absolute', right: '2px', top: '1px' }}><AssessmentIcon sx={{ fontSize: '30px', color: 'primary.main' }} /></IconButton>
                                <Typography fontWeight={'600'} variant='subtitle1' color={status[val.status]} gutterBottom>
                                  {val.status}
                                </Typography>
                                <Typography fontWeight={'600'} variant="h6" component="div">
                                  {val.projectName}
                                </Typography>
                                <Grid container columnSpacing={2} alignItems='center'>
                                  <Grid item xs={8}>
                                    <Typography mt={1} variant="body2">
                                      <b>Allocated Hours</b>: <span>{val.allocatedHours ?? "None"}</span>
                                    </Typography>
                                  </Grid>

                                  <Tooltip title={
                                    <Box>
                                      <Typography fontWeight={600} variant='caption'>{Math.floor((val.totalSpentHours / val.allocatedHours) * 100)}% completion</Typography><br />
                                      <Typography variant='caption'>Total Spent Hours: {val.totalSpentHours}</Typography><br />
                                    </Box>
                                  }>
                                    <Grid item xs={4}>
                                      <Stack direction='row' alignItems='center' justifyContent='center'>
                                        {/* <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                                        <LinearProgress color={val.allocatedHours ? progessColor(Math.floor((val.totalSpentHours/val.allocatedHours)*100)) : "secondary"} value={val.allocatedHours ? Math.floor((val.totalSpentHours/val.allocatedHours)*100) : 100} variant='determinate' />
                                        <Box
                                          sx={{
                                            top: 0,
                                            left: 0,
                                            bottom: 0,
                                            right: 0,
                                            position: 'absolute',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                          }}
                                        >
                                          <Typography
                                            variant="caption"
                                            component="div"
                                            fontWeight={600}
                                            color={val.allocatedHours ? progessTextColor(Math.floor((val.totalSpentHours/val.allocatedHours)*100)) : "secondary"}
                                          >{val.allocatedHours ? Math.floor((val.totalSpentHours/val.allocatedHours)*100)+"%" : "-"}</Typography>
                                        </Box>
                                      </Box> */}
                                        <Box sx={{ width: '100%', position: 'relative' }}>
                                          <LinearProgress color={val.allocatedHours ? progessColor(Math.floor((val.totalSpentHours / val.allocatedHours) * 100)) : "secondary"} value={val.allocatedHours ? Math.floor((val.totalSpentHours / val.allocatedHours) * 100) : 100} variant='determinate' />
                                          <Box
                                            sx={{
                                              top: -30,
                                              bottom: 0,
                                              right: 0,
                                              position: 'absolute',
                                              display: 'flex',
                                              alignItems: 'center',
                                              justifyContent: 'center',
                                            }}
                                          >
                                            <Typography
                                              variant="subtitle1"
                                              fontFamily="Segoe UI"
                                              component="div"
                                              fontWeight={600}
                                              color={val.allocatedHours ? progessTextColor(Math.floor((val.totalSpentHours / val.allocatedHours) * 100)) : "secondary"}
                                            >{val.allocatedHours ? Math.floor((val.totalSpentHours / val.allocatedHours) * 100) + "%" : "Null"}</Typography>
                                          </Box>
                                        </Box>
                                      </Stack>
                                    </Grid>
                                  </Tooltip>

                                </Grid>
                              </CardContent>
                              <CardActions>
                                <Button onClick={() => viewProject(val)} size="small" variant='outlined'>View</Button>
                                <Button onClick={() => setConfirm([val.projectID, val.projectName])} size="small" color='error' variant='outlined'>Delete</Button>
                              </CardActions>
                            </Card>
                          </Grid>
                        </>
                        : <></>
                    )
                  }
                  )
                  }
                </Grid>
                :
                <Stack gap={2} width="100%">
                  <Skeleton animation="wave" variant="rectangular" width="100%" height={80} />
                  <Skeleton animation="wave" variant="rectangular" width="100%" height={80} />
                </Stack>
              }

              {/* Dialog box to edit project */}
              {viewData ? <Dialog
                open={openView}
                onClose={handleClose}
                aria-labelledby="edit-apartment"
              >
                <DialogTitle sx={{ backgroundColor: "#F6F5F5" }} id="edit-apartment">{viewData.projectName}</DialogTitle>
                <DialogContent sx={{ backgroundColor: "#F6F5F5" }}>
                  {/* <DialogContentText sx={{fontStyle:"italic"}}>
                    {viewData.description??"No description available"}
                  </DialogContentText> */}
                  {addData ? <PrjctForm prop={addData.projectDependencies} data={data} setData={setData} type="edit" contentChange={contentChange} /> : <></>}
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} color="secondary">
                    Cancel
                  </Button>
                  {/* <LoadingButton variant="contained" loading={addForm} onClick={submitAddForm} color="primary">
                    Save Changes
                  </LoadingButton> */}
                  <Button disabled={!content} variant="contained" onClick={editProject} color="primary">
                    Save Changes
                  </Button>
                </DialogActions>
              </Dialog> : <></>}

              {/* Dialog box to confirm deletion of project */}
              <Dialog
                open={confirm == "" ? false : true}
                onClose={handleClose}
                aria-labelledby="edit-apartment"
              >
                <DialogTitle sx={{ backgroundColor: "#F6F5F5" }} id="edit-apartment">Confirm Delete</DialogTitle>
                <DialogContent sx={{ backgroundColor: "#F6F5F5" }}>
                  {/* <DialogContentText>
                  </DialogContentText> */}
                  <Typography>Do you want to delete <b>{confirm[1]}</b> Project?</Typography>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} color="secondary">
                    Cancel
                  </Button>
                  <Button onClick={() => delProject(confirm[0])} size="small" color='error' variant='contained'>Delete</Button>
                </DialogActions>
              </Dialog>

            </Box>
          </Box>

        </Box>}
    </Box>
  )
}