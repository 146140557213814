import React, { useEffect, useRef, useState } from 'react'
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import FullCalendar from "@fullcalendar/react";
import styled from "@emotion/styled";
import { containRoles } from "../../components/auth/userAuth";
import axios from '../../api/axios';
import { currentUser, permAllowed, showRoute, specificpermCheck } from "../../Router";

export const date_diff = (endDate) => {
  const currentDate = new Date();
  const targetDate = new Date(endDate);
  const timeDifference = currentDate - targetDate;
  const diff = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  // console.log(endDate+" "+diff);
  return diff;
}

export const StyleWrapper = styled.div`
  table, .fc-daygrid-body {
    width: 100% !important; 
  },
  .fc td {
    border: 1px solid #a9a9a9
  },
  .fc th {
    border-bottom: none !important;
    border-left: none !important;
    border-right: none !important;
  },
  .fc-scrollgrid-liquid {
    border: none !important;
  },
  .fc-col-header-cell-cushion {
    color: #1B2C58;
  },
  .fc-event {
    height:30px;
    font-family: 'Segoe UI';
    padding-left: 4px;
    border: solid 1px steelblue;
    margin: 0px 4px 2px !important;
  },
  .fc-event.completed {
    background-color: #198f51 !important; /* Green color for completed events */
    border-color: #15892f !important;
    color: white;
  },
  .fc-event.not_started {
    background-color: #1B2C58 !important; /* Original color for unfinished events */
    border-color: #1B2C58 !important;
    color: white;
  },
  .fc-event.unfinished {
    background-color: #ed912c !important;
    border-color: orange !important;
    color: white;
  },
  .fc-event.delayed {
    background-color: #BF3131 !important;
    border-color: #BF3131 !important;
    color: white;
  },
  .fc-day-today {
    background-color: #7b98e1b5 !important;
  },
  .fc-daygrid-day-number {
    padding: 4px 10px;
    font-size: 18px;
  },
  .fc-daygrid-event-harness {
    margin-top: 2px !important;
  },
  .fc-toolbar-chunk h2{
    border: solid 2px;
    padding: 2px 12px;
    border-radius: 6px;
    font-family: 'Segoe UI'
  },
  .fc-button {
    background-color: #1B2C58;
    border: solid 1px white !important;
  },
  .fc-daygrid-more-link {
    margin: 5px 0;
    font-weight: 600;
    width: 100%;
    text-align: center;
  },
  .fc-popover {
    border-radius: 6px;
    box-shadow: -5px 8px 8px 0px #878787;
    overflow: hidden;
  },
  .fc-popover-header {
    background: steelblue;
    color: white;
    font-family: Segoe UI;
    font-weight: 600;
  }
`

export const TableView = ({ handleEventClick, handleDateClick, evntchng, currentMonth, setCurrentMonth, setCurrentYear }) => {
  const calendarRef = useRef(null);
  const [events, setEvents] = useState([]);
  const { pEvents } = currentUser();

  const fetchEvents = async () => {
    try {
      const response = await axios.get(`/blog-tracking/get-all-for-month/${currentMonth}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ` + localStorage.getItem('access'),
          'Access-Control-Allow-Origin': '*',
          mode: 'no-cors'
        }
      });
      // console.log(response);
      const data = response.data.message;
      const events = Object.keys(data).reduce((acc, keyName) => {
        const mappedEvents = data[keyName].map((value) => ({
          id: value._id,
          // title: value.event_title ? `${value.event_title} -${empList[value?.assigned_to[0]]?.employee_name ?? "All"}` : `${value.type} -${empList[value?.assigned_to[0]]?.employee_name ?? "All"}`,
          title: value.type == 'Blog' ? `${value.type} - ${value?.assigned_to[0]}` : `${value.event_type}`,
          start: keyName,
          end: value.end_date ? value.end_date : keyName,
          event_type: value.event_type,
          type: value.type,
          event_title: value.event_title,
          assigned_to: value.assigned_to,
          end_date: value.end_date,
          start_date: keyName,
          start_time: value.start_time,
          end_time: value.end_time,
          is_full_day: value.is_full_day,
          description: value.description,
          emp_id: value.emp_id ?? "",
          is_complete: value.is_complete ?? "",
          ied: value._id
        }));
        return [...acc, ...mappedEvents];
      }, []);
      setEvents(events);
    } catch (error) {
      console.error(error);
      return []; // Return an empty array in case of error
    }
  };

  useEffect(() => {
    fetchEvents();
  }, [currentMonth, evntchng])

  const handleDatesSet = (info) => {
    const start = info.end;
    var monthNumber = start.getMonth();
    var year = start.getFullYear();
    if(monthNumber == 0) {
      monthNumber =12; // For December month
      year = year - 1; // Decrement year by 1 for previous month
    }
    setCurrentMonth(monthNumber);
    setCurrentYear(year);
    // fetchEvents(monthNumber);
  };

  const eventClassNames = (arg) => {
    return (arg.event.extendedProps.type == "Blog") && (arg.event.extendedProps.is_complete ? 'completed' : (date_diff(arg.event.extendedProps.start_date) < 0 ? 'not_started' : (date_diff(arg.event.extendedProps.start_date) >= 7 ? 'delayed' : 'unfinished')));
  };

  return (
    <StyleWrapper>
      <FullCalendar
        ref={calendarRef}
        timeZone="America/Chicago"
        className="custom-full-calendar"
        height="85vh"
        plugins={[
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin,
          listPlugin,
        ]}
        headerToolbar={{
          // left: "prev,next today",
          left: "prev,next",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay",
        }}
        initialView="dayGridMonth"
        editable={true}
        selectable={true}
        selectMirror={true}
        eventColor="#1B2C58"
        dayMaxEvents={3}
        select={showRoute(specificpermCheck(pEvents,['Add Events']), handleDateClick, "")}
        eventClick={handleEventClick}
        eventClassNames={eventClassNames}
        events={events}
        datesSet={handleDatesSet}
      />
    </StyleWrapper>
  )
}
