import { Box, Grid, Stack, Typography } from '@mui/material'
import React from 'react'
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import zIndex from '@mui/material/styles/zIndex';

export const Progressor = ({ label }) => {
    // if(label=='')

    const progressStyle = () => {
        return {
            width: '90%',
            height: '10px',
            backgroundColor: '#f0f0f0',
            // borderRadius: 5,
            position: 'relative',

            '&::after': {
                content: '""',
                position: 'absolute',
                top: 0,
                left: 0,
                width: `${label}%`,
                height: '100%',
                backgroundColor: '#198f51',
                borderRadius: 5,
                transition: 'width 0.5s ease-in-out'
            }
        }
    }

    const levels = ({ position, color }) => {
        // console.log(position);
        return {
            position: 'absolute',
            width: '18px',
            height: '18px',
            left: `${position}`,
            backgroundColor: `${color}`,
            borderRadius: '50%',
            transform: 'translateY(-23%)',
            zIndex: '1',

            '&::after': {
                content: '""',
                position: 'absolute',
                top: '50%',
                left: '50%',
                width: '30%',
                height: '30%',
                borderRadius: '50%',
                transform: `translate(-55%, -45%)`,
                backgroundColor: 'white',
                transition: 'background-color 0.5s ease-in-out'
            }
        }
    }

    return (
        <Box my={2}>
            <Stack direction='row' alignItems='center' mb={1}>
                <HourglassTopIcon sx={{ fontSize: '15px' }} />
                <Typography color='primary.main' fontFamily='Segoe UI' variant='subtitle2' fontWeight='700'>Progress</Typography>
            </Stack>
            <Box sx={() => progressStyle({ progress: '' })}>
                <Box sx={() => levels({ position: '0%', color: (label && label >= 0) ? '#198f51' : '#C80036' })}></Box>
                <Box sx={() => levels({ position: '24.5%', color: (label && label >= 25) ? '#198f51' : '#1b2c58' })}></Box>
                <Box sx={() => levels({ position: '49.5%', color: (label && label >= 50) ? '#198f51' : '#1b2c58' })}></Box>
                <Box sx={() => levels({ position: '74.5%', color: (label && label >= 75) ? '#198f51' : '#1b2c58' })}></Box>
                <Box sx={() => levels({ position: '99.5%', color: (label && label >= 100) ? '#198f51' : '#1b2c58' })}></Box>
                {/* <Box sx={() => levels({ position: '99%', color: (label && label >= '100') ? '#198f51' : '#1b2c58' })}></Box> */}
            </Box>
        </Box>
    )
}
