import { Box, Grid, Button, Typography, Stack, Skeleton, AccordionActions, Dialog, DialogTitle, DialogContent, TextField, DialogActions } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useEffect, useRef, useState, version } from 'react';
import axios from '../../../api/axios';
import dateFormat from 'dateformat';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { LoadingButton } from '@mui/lab';
import ReplayIcon from '@mui/icons-material/Replay';
import { NodataFound } from '../../NodataFound';
import Notify from '../../../features/helpers/notifyConfig';
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';
import { TimeSheetNewTable, TimeSheetTitles } from '../../../features/components/TimeSheet/TimeSheetNewTable';
import { TextareaAutosize } from '@material-ui/core';
import { getHours, getMinutes } from '../../../features/components/TimeSheet/TimeSheetHours';

const ReviewSheets = ({ boxStyle, billButtonStyle }) => {
    const [reviewSheet, setreviewSheet] = useState();
    const [dialog, setDialog] = useState("");
    const [returnmsg, setReturnMsg] = useState(false);
    const [approvemsg, setApproveMsg] = useState(false);
    const [msg, changemsg] = useState();
    const [data, changeData] = useState({
        "timesheet": {
            "employeeSheetID": "",
            "managerSheetID": "",
        }
    })
    const [submit, triggerSubmit] = useState(false);
    const [draftSheet, setdraftSheet] = useState();
    const [refresh, setRefresh] = useState(false);
    const [loading, setLoading] = useState();

    const openDialog = (emp_id) => {
        setDialog(emp_id);
        changemsg("");
    }

    const input = useRef();

    const handleClose = () => {
        setDialog("");
    }

    const msgChange = (e) => {
        changemsg(e.target.value);
    }

    const submitReturn = (emp, manager, status) => {
        if (status == "return") {
            changeData((prev) => ({
                ...prev,
                timesheet: {
                    ...prev.timesheet, "employeeSheetID": emp, "managerSheetID": manager, "returnMessage": msg
                }
            }))
            setReturnMsg(true);
            setLoading("return");
        }
        else if (status == "approve") {
            changeData((prev) => ({
                ...prev,
                timesheet: {
                    ...prev.timesheet, "employeeSheetID": emp, "managerSheetID": manager
                }
            }))
            setApproveMsg(true);
            setLoading("approve")
        }
    }

    const inputStyle = {
        "& .MuiInputBase-input": {
            height: "10px"
        },
        "& .MuiInputBase-root": {
            borderRadius: "5px"
        },
        "& .MuiSelect-select": {
            paddingY: "4px"
        }
    }

    // 2.  Manager gets employee sheets
    const getDraftTimesheet = async () => {
        await axios.get("/timesheet/manager/review",
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ` + localStorage.getItem('access')
                }
            }
        ).then((response) => {
            console.log(response);
            setdraftSheet((response?.data?.managerSheets) ? (response.data.managerSheets) : "nodata");
        }).catch((error) => {
            console.log(error.response);
            Notify.error("Error Occurred")
        });
    }

    useEffect(() => {
        getDraftTimesheet();
    }, [refresh])



    //3. Return Timesheet with message
  const ReturnTimesheet = async () => {
    await axios.post("/timesheet/manager/return",
      JSON.stringify(data),
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          withCredentials: true,
          mode: 'no-cors',
          // 'Authorization': `Bearer ` + Token.Manmit
          'Authorization': `Bearer ` + localStorage.getItem('access')
        }
      }).then((response) => {
        handleClose();
        setReturnMsg(false);
        setRefresh(prev=>!prev)
        Notify.success("Timesheet Returned Successfully!");
      }).catch((error) => {
        Notify.error(error);
        setReturnMsg(false);
      })
  }

  useEffect(() => {
    if (returnmsg)
      ReturnTimesheet();
  }, [returnmsg])

   //4. Approve Timesheet 
   const ApproveTimesheet = async () => {
    await axios.post("/timesheet/manager/approve",
      JSON.stringify(data),
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          withCredentials: true,
          mode: 'no-cors',
          // 'Authorization': `Bearer ` + Token.Manmit
          'Authorization': `Bearer ` + localStorage.getItem('access')
        }
      }).then((response) => {
        setApproveMsg(false);
        setRefresh(prev=>!prev)
        Notify.success("Timesheet Approved Successfully!");
      }).catch((error) => {
        setApproveMsg(false);
        Notify.error(error);
      })
  }

  useEffect(() => {
    if (approvemsg)
      ApproveTimesheet();
  }, [approvemsg])


    return (
        draftSheet == "nodata" ? <NodataFound /> :
            <Box sx={{ overflow: 'auto', height: '80vh', p: '5px', pb: "2.5rem" }}>
                <Grid container gap={2} mt={3}>
                    {draftSheet ?
                        draftSheet?.map((val,key) => {
                            const manager_sheetID = val.managerSheetID;
                            const emp_sheetID = val.employeeSheetID;
                            const sheet_status = val.Status;
                            const endDate = val.endDate;
                            const billable_hours = val.billable_hours;
                            const non_billable_hours = val.non_billable_hours;
                            const startDate = val.startDate;
                            const emp_name = val.Employee.employeeName;
                            var temp = [];
                            // alert(endDate);
                            return (
                                <Accordion defaultExpanded={key==0?true:false} sx={{ width: '100%' }} key={manager_sheetID}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                    >
                                        <Grid container alignItems="center" justifyContent="space-between">
                                            <Box display="flex">
                                            <Typography variant='subtitle1'>{dateFormat(startDate, "d mmmm, yyyy")}  -  {dateFormat(endDate, "d mmmm, yyyy")}</Typography>
                                            <Typography sx={{position:'absolute', left: '300px'}} pl={6}>{emp_name}</Typography>
                                            <Typography sx={{position:'absolute', left: '400px'}} pl={6}><strong style={{color:'primary.main'}}>Billable:</strong> {getHours(billable_hours)+"h "+getMinutes(billable_hours)+"m"}</Typography>
                                            <Typography sx={{position:'absolute', left: '550px'}} pl={6}><strong style={{color:'primary.main'}}>Non-Billable:</strong> {getHours(non_billable_hours)+"h "+getMinutes(non_billable_hours)+"m"}</Typography>
                                            </Box>
                                            <Stack pr={3} direction="row" alignItems="center"><FiberManualRecordIcon sx={{ transform: "scale(0.5)", color: "grey" }} />{sheet_status}</Stack>
                                        </Grid>
                                    </AccordionSummary>
                                    <AccordionDetails>

                                        <Dialog
                                            open={dialog==emp_sheetID?true:false}
                                            maxWidth='md'
                                            fullWidth
                                            onClose={handleClose}
                                            aria-labelledby="edit-apartment"
                                        >
                                            <DialogTitle id="edit-apartment">Please enter the reason of return</DialogTitle>
                                            <DialogContent>
                                                {/* <TextField
                                                    ref={input}
                                                    sx={{ width: "100%" }}
                                                    placeholder="Reason of Return"
                                                    multiline
                                                    rows={5}
                                                    maxRows={8}
                                                    value={msg}
                                                    name='returnmsg'
                                                    onChange={msgChange}
                                                /> */}
                                                <TextareaAutosize value={msg} onChange={msgChange} name='returnmsg' ref={input} minRows={8} style={{width:"100%", fontSize:"1rem"}}  placeholder="Reason of Return" />
                                                {/* <Form prop={addData.message} data={data} setData={setData} /> */}
                                            </DialogContent>
                                            <DialogActions>
                                                <Button onClick={handleClose} color="secondary">
                                                    Cancel
                                                </Button>
                                                <LoadingButton loading={returnmsg} disabled={!msg ? true : false} variant='outlined' onClick={() => submitReturn(emp_sheetID, manager_sheetID, "return")} color="error">
                                                    Return
                                                </LoadingButton>
                                            </DialogActions>
                                        </Dialog>

                                        {/* <Box mt={1} sx={{ height: '60vh', overflowY: 'auto', overflowX: 'hidden' }}> */}
                                        <Box>
                                            <Box my={2} border='1px solid' borderColor="dark_divider">
                                                <Grid gap={1} container columns={16.5} py={0.3} px={1} alignItems='center' sx={{
                                                    backgroundColor: 'primary.light',
                                                    color: 'primary.white'
                                                }}>
                                                    {TimeSheetTitles.map((title, key) => {

                                                        return (
                                                            <Grid key={key} item my={1} md={title == "Project" || title == "Task" || title == "Description" ? 2 : 1}>
                                                                <Typography
                                                                    variant='subtitle2'>
                                                                    {title}
                                                                </Typography>
                                                            </Grid>
                                                        )
                                                    })}
                                                </Grid>

                                                <Grid gap={1} container columns={16.5} py={1} px={1} alignItems='center'>
                                                    {val?.employeeSheetObjects.map((data) => {
                                                        temp.push({
                                                            "projectID": data.Project.projectID,
                                                            "taskID": data.Task.taskID,
                                                            "billable": data.billable,
                                                            "description": data.description,
                                                            "workDay": data.workDay
                                                        })

                                                        return (
                                                            <TimeSheetNewTable otherempData={data} />
                                                        )
                                                    })}
                                                </Grid>
                                            </Box>
                                        </Box>

                                        <AccordionActions>
                                            <Stack direction="row" gap={2} justifyContent="end">
                                                <Button disabled={approvemsg} onClick={()=>openDialog(emp_sheetID)} sx={{ background: "#d84d4d" }} variant='contained' size='small'>Return &nbsp;<ClearIcon /></Button>
                                                <LoadingButton
                                                    onClick={() => submitReturn(emp_sheetID, manager_sheetID, "approve")}
                                                    loading={approvemsg}
                                                    disabled={returnmsg}
                                                    // disabled={loading ? (loading != "Draft") ?? true : false}
                                                    // loadingPosition="start"
                                                    variant="contained"
                                                    sx={{ background: "#79AC78" }}
                                                    size='small'
                                                >Approve &nbsp;<CheckIcon /></LoadingButton>

                                                {/* <Button onClick={() => submitReturn(data.employeeSheetID, data.managerSheetID, "approve")}
                         sx={{ background: "#79AC78" }} variant='contained' size='small'>Approve &nbsp;<CheckIcon /></Button> */}
                                            </Stack>
                                        </AccordionActions>
                                    </AccordionDetails>
                                </Accordion>)
                        })
                        :
                        <Stack gap={2} width="100%">
                            <Skeleton animation="wave" variant="rectangular" width="100%" height={80} />
                            <Skeleton animation="wave" variant="rectangular" width="100%" height={80} />
                        </Stack>
                    }

                </Grid>
            </Box>
    )
}

export default ReviewSheets