import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { LinearProgress, Stack } from '@mui/material';
import { CurrentSalesPipeline } from './CurrentSalesPipeline';
import { ArchivedSalesPipeline } from './ArchivedSalesPipeline';
import { NavLink } from 'react-router-dom';
import axios from '../../api/axios';
import { useEffect, useState } from 'react';

export const SalesPipeline = () => {
    const[yearData,setyearData] =useState();

    const getSalesPipeline = async () => {
        await axios.get("/dashboard/salespipeline",
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ` + localStorage.getItem('access')
                }
            }).then((response) => {
                setyearData(response.data)
            }).catch((error) => {
                console.log(error.response)
            })
    }

    useEffect(()=>{
        getSalesPipeline();
    },[])

    const cardContainer =(title,years) =>(
        <Card sx={{ width: 275 }}>
            <CardContent>
                <Typography variant="h5" component="div">
                    {title}
                </Typography>
            </CardContent>
            <CardActions>
                <Stack direction="row" gap={1} flexWrap="wrap">
                {years.map( (data)=>
                <Button component={NavLink} to={title=="Current"?`current/${data}`:`current/${data}`} key={data} size="small" variant='contained'>{data}</Button>
                )}
                </Stack>
            </CardActions>
        </Card>
    );

    return (
        yearData?<Stack direction="row" gap={4} height="80vh" justifyContent="center" alignItems="center">
            {cardContainer("Current",[yearData.current])}
            {cardContainer("Archived",yearData.archieve)}
        </Stack>:
        <Stack justifyContent="center" alignItems="center" height="80vh">
        <Box pb={2}>Wait while we gather required information...</Box>
        <Stack gap={4} sx={{ width: '50%', color: 'grey.500' }} spacing={2}>
            <LinearProgress color="inherit" />
        </Stack>
        </Stack>
    );

}