import { Stack, Button, Badge, IconButton, Typography, Box, Grid, Divider, ClickAwayListener } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import UserIcon from '@mui/material/Avatar';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useNavigate } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import React, { useEffect, useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Logout from '@mui/icons-material/Logout';
import DescriptionIcon from '@mui/icons-material/Description';
import Skeleton from '@mui/material/Skeleton';
import { currentUser } from '../Router';
import { Dehaze } from '@mui/icons-material';
import axios from '../api/axios';
import Popper from '@mui/material/Popper';
import Fade from '@mui/material/Fade';
import { MessageBox } from '../features/components/MessageBox';

const HeadNavigation = ({ open, setOpen }) => {
  const { user_first_name, user_last_name, user_role_name, profileImg } = currentUser();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openAnchor = Boolean(anchorEl);
  const [tokenStatus, setTokenStatus] = useState(false);
  const [openNotify, setOpenNotify] = React.useState(false);
  const [anchorNotify, setAnchorNotify] = React.useState(null);
  const [ndata, setNdata] = useState();
  const [loading, setLoading] = React.useState(true);

  const handleClickNotify = (event) => {
    setAnchorNotify(event.currentTarget);
    setOpenNotify((previousOpen) => !previousOpen);

    // When notification bell is clicked so make all as viewed
    const allSeen = async () => {
      await axios.put(`/notifications`,
        JSON.stringify({ "action": "seen_all" }),
        {
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            withCredentials: true,
            mode: 'no-cors',
            'Authorization': `Bearer ` + localStorage.getItem('access')
          }
        }).then(() => {
          setLoading(true);
        }).catch((error) => {
          console.log(error.response);
        })
    }
    allSeen();
  };

  const handleClickAway = () => {
    setAnchorNotify(null);
    setOpenNotify(false);
  };

  const canBeOpen = openNotify && Boolean(anchorNotify);
  const id = canBeOpen ? 'transition-popper' : undefined;

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  useEffect(() => {
    if (localStorage.getItem('access'))
      setTokenStatus(true);
  }, [])

  const { instance } = useMsal();
  const handleLogout = () => {
    window.localStorage.clear();
    instance.logout();
  }

  const navigate = useNavigate();

  const buttonStyle = {
    backgroundColor: "common.white",
    px: 1.5,
    color: "primary.main",
    "&:hover": {
      backgroundColor: "common.white"
    }
  };

  const iconStyle = {
    width: 30,
    height: 30
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const loaderStyle = {
    borderRadius: '5px',
    width: '100%'
  }

  const getNotification = async () => {
    await axios.get("/notifications",
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ` + localStorage.getItem('access')
        }
      }).then((response) => {
        // console.log(response);
        setNdata(response?.data);
        setLoading(false);
      }).catch((error) => {
        // console.log(error.response)
      })
  }

  useEffect(() => {
    if (loading) getNotification();
  }, [loading])

  // useEffect (()=> {
  //   getNotification();
  // },[])

  return (
    <Box sx={{ height: 70, width: '100%', borderBottom: 'solid 2px', borderColor: 'divider', px: 2 }}>
      <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} height={'100%'}>
        <Box>
          <IconButton onClick={toggleDrawer(!open)}>
            <Dehaze />
          </IconButton>

          <Button
            variant="contained"
            sx={{ ...buttonStyle, marginLeft: "10px" }}
            onClick={() => navigate(-1)}>
            <KeyboardBackspaceIcon sx={{ mr: 0.5 }} />
            Go back
          </Button>
        </Box>


        <Stack spacing={2} direction={'row'} alignItems={'center'} justifyContent={'center'}>
          <ClickAwayListener onClickAway={handleClickAway}>

            {ndata
              ? <Badge
                color="error"
                sx={{ "& .MuiBadge-badge": { color: 'white' } }}
                max={10}
                badgeContent={ndata.new}
              >
                <IconButton aria-describedby={id} onClick={handleClickNotify} sx={{
                  backgroundColor: "divider",
                  borderRadius: '50% !important',
                  p: 0.5,
                }}>
                  <NotificationsIcon />
                </IconButton>
                {/* <ClickAwayListener onClickAway={handleClickAway}> */}
                <Popper sx={{ zIndex: "999" }} placement="top-start" id={id} open={openNotify}
                  modifiers={[
                    {
                      name: 'offset',
                      options: {
                        offset: [0, 10],
                      },
                    },
                  ]}
                  anchorEl={anchorNotify}
                  transition>
                  {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                      <Box>
                        <MessageBox data={ndata.notifications} setLoading={setLoading} loading={loading} />
                      </Box>
                    </Fade>
                  )}
                </Popper>
              </Badge>

              : <></>
            }
          </ClickAwayListener>



          <Stack direction={'row'} alignItems={'center'}>
            <IconButton
              onClick={handleClick}
              aria-controls={openAnchor ? 'account-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={openAnchor ? 'true' : undefined}
            >
              {(tokenStatus && profileImg) ?
                <Box sx={{
                  width: "2rem",
                  height: "2rem",
                  backgroundImage: `url(${profileImg})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  borderRadius: '50%'
                }}></Box> :
                <UserIcon sx={{ ...iconStyle }} />}
            </IconButton>


            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={openAnchor}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,
                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  '&::before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              <MenuItem onClick={handleClose}>
                <Typography variant='subtitle1' fontWeight='600'>Version 1.0.4</Typography>
              </MenuItem>

              <Divider sx={{ my: '1px' }}></Divider>

              <MenuItem onClick={handleClose}>
                <ListItemIcon>
                  <DescriptionIcon fontSize="small" />
                </ListItemIcon>
                Documentation
              </MenuItem>

              <MenuItem onClick={handleLogout}>
                <ListItemIcon>
                  <Logout fontSize="small" />
                </ListItemIcon>
                Logout
              </MenuItem>
            </Menu>

            {/* {console.log(user_first_name)} */}

            <Stack direction={'column'} justifyContent={'center'}>
              {/* <Button variant="contained" color="warning" onClick={handleLogout}>Logout</Button> */}
              {/* <button onClick={handleLogout}>Logout</button> */}
              {tokenStatus ?
                <><Typography variant='caption' sx={{ lineHeight: 1.2, fontSize: 13, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '7rem' }}><strong>{user_first_name + " "}{user_last_name}</strong></Typography>
                  <Typography variant='caption' sx={{ lineHeight: 1.2 }}>{user_role_name}</Typography></>
                : <><Stack spacing={1}>
                  <Skeleton animation="wave" variant="rectangular" width={35} height={10} />
                  <Skeleton animation="wave" variant="rectangular" width={20} height={10} /></Stack></>
              }
            </Stack>
          </Stack>
        </Stack>

      </Stack>
    </Box>
  )
}

export default HeadNavigation