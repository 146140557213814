import { Alert, Box, Button, Stack, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import AddIcon from '@mui/icons-material/Add';
import { ContributionInput } from "../ContributionInput";

export const toggleStyle = () => {
  return {
    '.MuiButtonBase-root': {
      padding: '0.4rem 0.8rem',
      fontSize: '0.9rem'
    },
    '.MuiToggleButtonGroup-firstButton': {
      borderTopRightRadius: '0 !important',
      borderBottomRightRadius: '0 !important',
    },
    '.MuiToggleButtonGroup-lastButton': {
      borderTopLeftRadius: '0 !important',
      borderBottomLeftRadius: '0 !important',
    },
    '.MuiToggleButtonGroup-middleButton': {
      borderRadius: '0 !important',
    }
  }
}

const categories = {
  'blog': 'Blog',
  'video': 'Video',
  'ppt': 'PPT',
  'document': 'Document',
  'other': 'Other'
}

export const ContributionForm = ({setContribution, contribution}) => {
  const [alignment, setAlignment] = useState('blog');

  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  const pushupdates =() => {
    const updatedArray = [...contribution[alignment]];
    updatedArray.push('');
    setContribution({...contribution, [alignment]: updatedArray });
  }

  const addCategory = () => {
    (alignment in contribution)
      ? pushupdates()
      : setContribution((prev) => ({
        ...prev, [alignment]: ['']
      }));
  }

  return (
    <Box>
      <Stack direction='row' justifyContent='center' mb={3}>
        <ToggleButtonGroup
          color="primary"
          value={alignment}
          exclusive
          onChange={handleChange}
          aria-label="Platform"
          sx={toggleStyle}
        >
          <ToggleButton value="blog">Blog</ToggleButton>
          <ToggleButton value="video">Video</ToggleButton>
          <ToggleButton value="ppt">PPT</ToggleButton>
          <ToggleButton value="document">Document</ToggleButton>
          {/* <ToggleButton value="other">Other</ToggleButton> */}
        </ToggleButtonGroup>
      </Stack>

      {
        alignment
          ?
          <Box>
            <Button variant="outlined" className="w-100" onClick={addCategory}>Add {categories[alignment]} <AddIcon fontSize="sm" /></Button>
            <ContributionInput setContribution={setContribution} contribution={contribution} alignment={alignment} />
          </Box>
          : <Alert severity="warning">Select a category first</Alert>
      }
    </Box>
  )
}
