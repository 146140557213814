import { LogLevel } from "@azure/msal-browser";

export const msalConfig = {
    auth: {
        // clientId: '1e97ea80-9ef3-4ad7-bdbe-6b43fdcaf559',
        // authority: 'https://login.microsoftonline.com/EncryptionIntern.onmicrosoft.com',  //tenant subdomain
        // redirectUri: '/',   //Register this URI on azure portal
        // postLogoutRedirectUri: '/', //Indicates page to navigate after logout
        // navigateToLoginRequestUri: false,
        clientId: '07c5226c-d895-4217-a104-031bc346a4b9',
        authority: 'https://login.microsoftonline.com/encryptionconsulting.com',  //tenant subdomain
        redirectUri: '/',   //Register this URI on azure portal
        postLogoutRedirectUri: '/', //Indicates page to navigate after logout
        navigateToLoginRequestUri: false,
    },
    cache: {
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: false,
    },
    system: {
        loggerOptions: {
            // logLevel: LogLevel.Trace,
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        console.log(message);
                        return;
                }
            }
        }
    },
}


//These are the scopes which will be prompted for user consent during sign-in
export const loginRequest ={
    scopes: ['user.read'],
};