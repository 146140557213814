import { Paper, styled } from '@material-ui/core'
import { Box, LinearProgress, Grid, Stack, Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Skeleton, Button } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import axios from '../../api/axios'
import UserIcon from '@mui/material/Avatar';
import Notify from '../../features/helpers/notifyConfig'
import { Tree, TreeNode } from 'react-organizational-chart';
import { Cancel } from '@mui/icons-material';

export const isPic = (pic_id, color = "white") => {
    return (
        pic_id == ""
            ? <Box><UserIcon sx={{ width: '3.5rem', height: '3.5rem', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)', borderRadius: '50%', border: `solid ${color}` }} /></Box>
            : <Box
                sx={{
                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)',
                    width: '3.5rem',
                    height: '3.5rem',
                    backgroundImage: `url(${pic_id})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    borderRadius: '50%',
                    border: `solid ${color}`
                }}></Box>
    )
}

export const ConnectionPage = () => {
    const [connection, setConnection] = useState(null);
    const [open, setOpen] = useState(false);
    const [data, setData] = useState(null);
    const [naam, setNaam] = useState();
    const containerRef = useRef(null);
    const [justifyContent, setJustifyContent] = useState('center');

    const handleClose = () => {
        setOpen(false);
    };

    const getUserConnection = (id, name) => {
        setOpen(true);
        setNaam(name);
        setData(null);
        const getConn = async () => {
            await axios.post(`/connections`,
                JSON.stringify({ "employeeID": id }),
                {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Content-Type': 'application/json',
                        withCredentials: true,
                        mode: 'no-cors',
                        'Authorization': `Bearer ` + localStorage.getItem('access')
                    }
                }).then((response) => {
                    console.log(response);
                    setData(response?.data)
                }).catch((error) => {
                    console.log(error.response);
                    Notify.error("Error Occurred. Try Again!")
                })
        }
        getConn();
    }

    const getConnections = async () => {
        await axios.get("/connections",
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ` + localStorage.getItem('access'),
                    'Access-Control-Allow-Origin': '*',
                    mode: 'no-cors'
                }
            }
        ).then((response) => {
            // console.log(response?.data?.employees);
            setConnection(response?.data?.employees);
        }).catch((error) => {
            console.log(error);
            Notify.error("Some error occurred. Try Again!")
        });
    }

    useEffect(() => {
        getConnections();
    }, [])

    useEffect(() => {
        if(data!=null){
        const container = containerRef.current;
        if (container!=null && container.scrollWidth > container.clientWidth) {
          setJustifyContent('flex-start');
        } else {
          setJustifyContent('center');
        }}
    }, [data]);

    const UserCard = ({ data }) => {
        return (
            <Stack justifyContent='center' direction='row'>
                <Stack sx={{ cursor: 'pointer', backgroundColor: data.thisEmployee ? '#1B2C58' : '', color: data.thisEmployee ? 'white' : '' }} onClick={() => getUserConnection(data.employeeID, data.employeeName)} boxShadow='0px 4px 8px rgba(0, 0, 0, 0.5)' direction='row' alignItems='center' py={0.5} pl={0.4} pr={1.5} minWidth='13rem' spacing={1.5} borderRadius='140px'>
                    {isPic(data.pic_icon, "white")}
                    <Box>
                        <Typography textAlign='left' variant='subtitle1' fontWeight='600'>{data.employeeName}</Typography>
                        <Typography lineHeight='1' textAlign='left' variant='subtitle2'>{data.designation == "" ? "No Designation" : data.designation}</Typography>
                    </Box>
                </Stack>
            </Stack>
        )
    }

    const Hierarchy = ({ hdata }) => {
        return (
            hdata.length != 0
                ? hdata.map((val) => {
                    return (
                        val.manages.length > 0
                            ? <TreeNode label={<UserCard data={val} />}>
                                <Hierarchy hdata={val.manages} />
                            </TreeNode>
                            : <TreeNode label={<UserCard data={val} />} />
                    )
                })
                : <></>
        )
    }

    return (
        <Box px={4} py={2}>
            <Typography mb={2} variant='h5' fontWeight='600'>Connections</Typography>
            {
                connection
                    ? <Paper style={{overflow:'hidden'}}>
                        <Box p={2}>
                            <Grid container alignItems="center" rowSpacing={3}>
                                {
                                    connection.map((val, key) => {
                                        return (
                                            <Grid item key={key} lg={4} md={6}>
                                                <Stack onClick={() => getUserConnection(val.employeeID, val.employeeName)} borderRadius='5px' p={2} ml={5} direction='row' spacing={2} alignItems='center'
                                                    sx=
                                                    {{
                                                        cursor: 'pointer',
                                                        "&:hover": {
                                                            backgroundColor: 'divider',
                                                            color: 'primary.main',
                                                        }
                                                    }}
                                                >
                                                    {
                                                        isPic(val.pic_icon)
                                                    }
                                                    <Typography overflow='hidden' textOverflow='ellipsis' whiteSpace='nowrap' variant='h6' fontWeight='400'>{val.employeeName}</Typography>
                                                </Stack>
                                            </Grid>
                                        )
                                    })
                                }
                            </Grid>
                        </Box>
                    </Paper>
                    :
                    <Stack justifyContent="center" alignItems="center" height="70vh">
                        <Box pb={2}>Wait while we gather required information...</Box>
                        <Stack gap={4} sx={{ width: '50%', color: 'grey.500' }} spacing={2}>
                            <LinearProgress color="inherit" />
                        </Stack>
                    </Stack>
            }

            <Dialog
                maxWidth="lg"
                fullWidth={true}
                open={open}
                onClose={handleClose}
                aria-labelledby="edit-apartment"
            >

                <DialogTitle textAlign='center' variant='h6'>{naam ? `Hierarchy Tree of ${naam}` : "Hierarchy Tree"}</DialogTitle>
                <DialogContent style={{ height: '90vh' }}>
                    {data
                        ?
                        <Grid ref={containerRef} mt={4} container justifyContent={justifyContent}>
                            <Tree
                                lineWidth={'3px'}
                                lineHeight={'2.5rem'}
                                nodePadding={'2rem'}
                                lineColor={'#1B2C58'}
                                lineBorderRadius={'5px'}
                                label={<UserCard data={data} />}
                            >
                                <Hierarchy hdata={data.manages} />
                            </Tree>
                        </Grid>

                        : <Stack gap={2} mt={6} width="100%">
                            <Skeleton animation="wave" variant="rectangular" width="100%" height={80} />
                            <Skeleton animation="wave" variant="rectangular" width="100%" height={80} />
                        </Stack>
                    }

                    <Button sx={{ position: "absolute", top: 10, right: 0 }} onClick={handleClose} color="secondary">
                        <Cancel sx={{ fontSize: "1.8rem" }} />
                    </Button>
                </DialogContent>
            </Dialog>
        </Box>
    )
}
