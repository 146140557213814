import { NavLink } from "react-router-dom";

import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { isAccounting, isEmployee, isManager, isMarketing, isSuperAdmin, userAuth, isLead, isMarketingLead, containRoles } from "./auth/userAuth";
import { Box, Button, IconButton, MenuItem, Select, Stack, Typography } from "@mui/material";
import { ECLogo } from "./Icons/ECLogo";
import { useState } from "react";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { TimesheetIcon } from "./Icons/TimesheetIcon";
import { currentUser, permAllowed, showRoute, specificpermCheck } from "../Router";

const SideBar = ({ open, setOpen }) => {
    const { pARR, pConnections, pPerformance, pAppraisal, pCertificates, pEmpData, pEvents, pGeneral, pQG, pReports, pSalespipeline, pTimesheet, pTraining, pWorkPerm, pWorkPrjcts, pWorkUsers } = currentUser();
    const [showMenu, setMenu] = useState("none");
    const [showBMenu, setBMenu] = useState("none");
    const [showUserSpaceMenu, setUserSpaceMenu] = useState("none")
    const [showExpenseMenu, setExpenseMenu] = useState("none")

    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
    };

    const linksArr = [
        {
            linkText: "Dashboard",
            url: "/",
            // icons: <Dashboard width={18}/>
        },
        showRoute((permAllowed(pEvents) && specificpermCheck(pEvents, ['View Events'])), {
            linkText: "Events",
            url: "Events",
        }),
        showRoute(permAllowed(pTimesheet), {
            linkText: "Timesheet",
            url: "Timesheet",
            // icons: <TimesheetIcon width={18} />
        }),
        showRoute((permAllowed(pEmpData) || permAllowed(pTraining) || permAllowed(pCertificates) || permAllowed(pAppraisal)), {
            linkText: "User Space",
            url: "EmployeeData",

        }),
        showRoute((permAllowed(pWorkUsers) || permAllowed(pWorkPrjcts) || permAllowed(pWorkPerm)), {
            linkText: "Workspace",
            url: "Workspace",

        }),
        {
            linkText: "Expense Report",
            url: "ExpenseReport",
        },
        showRoute((permAllowed(pARR) || permAllowed(pQG) || permAllowed(pSalespipeline)), {
            linkText: "Business Development",
            url: "",

        }),
        showRoute(permAllowed(pReports) && specificpermCheck(pReports, ["View Reports"]), {
            linkText: "Reports",
            url: "Reports",

        }),
        showRoute(permAllowed(pPerformance) && specificpermCheck(pPerformance, ["View Leave History", "View Escalation History"]), {
            linkText: "Performance",
            url: "Performance",

        }),
        showRoute(permAllowed(pConnections) && specificpermCheck(pConnections, ["View Connections"]), {
            linkText: "Connections",
            url: "Connections",
        })
    ].filter(item => item !== null);

    const showChildMenu = () => {
        setMenu(showMenu == "none" ? "flex" : "none");
    }

    const showBChildMenu = () => {
        setBMenu(showBMenu == "none" ? "flex" : "none");
    }

    const showUserChildMenu = () => {
        setUserSpaceMenu(showUserSpaceMenu == "none" ? "flex" : "none");
    }

    const showExpenseChildMenu = () => {
        setExpenseMenu(showExpenseMenu == "none" ? "flex" : "none");
    }

    function DrawerContent() {

        const routesContainer = {
            mb: 1,
            display: "block",
            color: "common.white",
            "&.active": {
                bgcolor: "common.white",
                pointerEvents: "none",
                borderRadius: "5rem 0 0 5rem",
                color: "common.black"
            },
            "&:hover": {
                bgcolor: "grey.50",
                opacity: 0.8,
                borderRadius: "5rem 0 0 5rem",
                color: "common.black"
            },
            "&:focus": {
                bgcolor: "secondary.dark",
            },
        };

        const TimesheetSubMenu = [
            showRoute((specificpermCheck(pTimesheet, ['View Personal Timesheet'])), { key: 'self', route: 'Timesheet', name: 'Personal' }),
            showRoute((specificpermCheck(pTimesheet, ['View Manager Timesheet'])), { key: 'manage', route: 'Timesheet/manager', name: 'Manage' }),
            showRoute((specificpermCheck(pTimesheet, ['Add Leave', 'View All Leaves', 'Review Leave'])), { key: 'pto', route: 'Leaves', name: 'PTO' }),
        ].filter(item => item !== null);

        const ExpenseSubMenu = [
            { key: 'expense_index', route: 'ExpenseReport', name: 'Personal' },
            isLead({ key: 'expense_manage', route: 'ExpenseReport/manager', name: 'Manage' },null),
        ].filter(item => item !== null);

        const UserSpaceSubMenu = [
            showRoute(permAllowed(pEmpData) && specificpermCheck(pEmpData, ['View Employee Data']), { key: 'empdata', route: 'EmployeeData', name: 'Employee Data' }),
            showRoute(permAllowed(pCertificates) && specificpermCheck(pCertificates, ['View All Certificates', 'Review Certificates']), { key: 'certs', route: 'Certificates', name: 'Certificates' }),
            showRoute(permAllowed(pTraining) && specificpermCheck(pTraining, ['View Training', 'View Manager Training']), { key: 'trainings', route: 'Training', name: 'Training' }),
            showRoute(permAllowed(pAppraisal) && specificpermCheck(pAppraisal, ['View Goals', 'Add Goals']), { key: 'appraisal', route: 'Appraisal', name: 'Appraisal' }),
        ].filter(item => item !== null);

        const BusinessSubMenu = [
            showRoute(permAllowed(pARR) && specificpermCheck(pARR, ['View Account']), { key: 'arr', route: 'contractsigning', name: 'Annual Recurring Revenue' }),
            showRoute(permAllowed(pQG), { key: 'qg', route: 'quotegeneration', name: 'Quote Generation' }),
            showRoute(permAllowed(pSalespipeline) && specificpermCheck(pSalespipeline, ['View Records']), { key: 'salespipeline', route: 'salespipeline', name: 'Sales Pipeline' }),
        ].filter(item => item !== null);

        return (
            <>
                <Box width={220}>
                    <Stack sx={{ mr: 2 }} alignItems="center" justifyContent="center">
                        <ECLogo width={60} />
                    </Stack>
                    <List sx={{ py: 4, ml: 3, overflow: "auto" }}>
                        {linksArr.map(({ linkText, url, icons }, index) => (
                            (linkText == "Timesheet") ?
                                <>
                                    <ListItem
                                        key={index}
                                        disablePadding
                                        onClick={showChildMenu}
                                        sx={{ ...routesContainer }}
                                        secondaryAction={
                                            showMenu == "none" ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />
                                        }
                                    >
                                        <ListItemButton sx={{ pl: 3 }}>
                                            <ListItemText primary={linkText} />
                                        </ListItemButton>
                                    </ListItem>

                                    <Stack display={showMenu}>
                                        {TimesheetSubMenu.map(({ key, route, name }) => (
                                            <ListItem
                                                key={key}
                                                disablePadding
                                                component={NavLink}
                                                to={route}
                                            >
                                                <ListItemButton sx={{ pl: 6, color: "divider" }}>
                                                    {name}
                                                </ListItemButton>
                                            </ListItem>
                                        ))}
                                    </Stack>

                                </>
                                :
                                (linkText == "Expense Report") ?
                                    <>
                                        <ListItem
                                            key={index}
                                            disablePadding
                                            onClick={showExpenseChildMenu}
                                            sx={{ ...routesContainer }}
                                            secondaryAction={
                                                showExpenseMenu == "none" ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />
                                            }
                                        >
                                            <ListItemButton sx={{ pl: 3 }}>
                                                <ListItemText primary={linkText} />
                                            </ListItemButton>
                                        </ListItem>

                                        <Stack display={showExpenseMenu}>
                                            {ExpenseSubMenu.map(({ key, route, name }) => (
                                                <ListItem
                                                    key={key}
                                                    disablePadding
                                                    component={NavLink}
                                                    to={route}
                                                >
                                                    <ListItemButton sx={{ pl: 6, color: "divider" }}>
                                                        {name}
                                                    </ListItemButton>
                                                </ListItem>
                                            ))}
                                        </Stack>

                                    </>
                                    :
                                    (linkText == "User Space") ?
                                        <>
                                            <ListItem
                                                key={index}
                                                disablePadding
                                                onClick={showUserChildMenu}
                                                sx={{ ...routesContainer }}
                                                secondaryAction={
                                                    showUserSpaceMenu == "none" ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />
                                                }
                                            >
                                                <ListItemButton sx={{ pl: 3 }}>
                                                    <ListItemText primary={linkText} />
                                                </ListItemButton>
                                            </ListItem>

                                            <Stack display={showUserSpaceMenu}>
                                                {UserSpaceSubMenu.map(({ key, route, name }) => (
                                                    <ListItem
                                                        key={key}
                                                        disablePadding
                                                        component={NavLink}
                                                        to={route}
                                                    >

                                                        <ListItemButton sx={{ pl: 6, color: "divider" }}>
                                                            {name}
                                                        </ListItemButton>
                                                    </ListItem>
                                                ))}
                                            </Stack>

                                        </>
                                        :
                                        (linkText == "Business Development") ?
                                            <>
                                                <ListItem
                                                    key={index}
                                                    disablePadding
                                                    onClick={showBChildMenu}
                                                    sx={{ ...routesContainer }}
                                                    secondaryAction={
                                                        showBMenu == "none" ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />
                                                    }
                                                >
                                                    <ListItemButton sx={{ pl: 3 }}>
                                                        <ListItemText primary={linkText} />
                                                    </ListItemButton>
                                                </ListItem>

                                                <Stack display={showBMenu}>
                                                    {BusinessSubMenu.map(({ key, route, name }) => (
                                                        <ListItem
                                                            key={key}
                                                            disablePadding
                                                            component={NavLink}
                                                            to={route}
                                                        >

                                                            <ListItemButton sx={{ pl: 6, color: "divider" }}>
                                                                {name}
                                                            </ListItemButton>
                                                        </ListItem>
                                                    ))}
                                                </Stack>
                                            </>
                                            :
                                            <ListItem
                                                key={index}
                                                component={NavLink}
                                                to={url}
                                                disablePadding
                                                sx={{ ...routesContainer }}
                                            >
                                                <ListItemButton sx={{ pl: 3 }}>
                                                    {icons}
                                                    <ListItemText primary={linkText} />
                                                </ListItemButton>
                                            </ListItem>
                        ))}
                    </List>
                </Box>
            </>
        );
    }

    const sideBarContainer = {
        width: open ? 220 : "auto",
        "& .MuiDrawer-paper": {
            // overflowY: "hidden",
            borderRight: "none",
            bgcolor: "primary.main",
            // borderRadius: "0 1.2rem 1.2rem 0",
            pt: 2,
            height: '100vh',
            // overflowY: 'auto',
            overflowX: 'hidden'
        },
    };

    return (
        <>
            <Drawer
                open={open} onClose={toggleDrawer(false)}
                variant='persistent'
                sx={{ ...sideBarContainer }}
            >
                <DrawerContent />
            </Drawer>
        </>
    )
}

export default SideBar