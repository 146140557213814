import { useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Pagination, Skeleton, Stack, Tab, Typography } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import axios from '../../../api/axios';
import { Form } from '../Form';
import Notify from '../../../features/helpers/notifyConfig';
import { Cancel, Delete } from '@mui/icons-material';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import { makeStyles } from "@material-ui/core/styles";
import { DataGrid } from '@mui/x-data-grid';
import { containRoles, isMarketingLead } from '../../auth/userAuth';

export const SkeletonLoad = () => {
    return (
        <Stack gap={2} width="100%">
            <Skeleton animation="wave" variant="rectangular" width="100%" height={80} />
            <Skeleton animation="wave" variant="rectangular" width="100%" height={80} />
        </Stack>
    )
}

const cow_color = (chance) => {
    if(chance === "High") return "green";
    else if(chance === "Medium") return "orange";
    else if(chance === "Low") return "red";
}

export const useStyles = makeStyles({
    table: {
        //   minWidth: 650,
        "& .MuiTableCell-root": {
            borderLeft: "1px solid rgba(224, 224, 224, 1)",
            borderBottom: "1px solid rgba(224, 224, 224, 1)"
        }
    }
});

function createData(id, customer, project_type, channel, reseller, ec_poc, client_poc, client_poc_email, stage, prjct_size, winning_chances, wonorlost, notes_on_follow) {
    return { id, customer, project_type, channel, reseller, ec_poc, client_poc, client_poc_email, stage, prjct_size, winning_chances, wonorlost, notes_on_follow };
}

export function NewPipelineTable({ table_data, setotherAction }) {
    const classes = useStyles();
    const requested_year = useParams().year;
    const [open, setOpen] = useState(false);
    const [addForm, setAddForm] = useState(false);
    // const [sale_data, setsale_data] = useState();
    const [value, setValue] = useState('1');
    const [addData, setaddData] = useState();
    const [data, setData] = useState({
        _id: "", Type: "Current", Customer: "", TypeOfProject: "", Channel: "", Reseller: "", EC_PointOfContact: "", Client_POC_Email: "", Client_PointOfContact: "",
        Stage: "", ProjectSize: "", ChancesOfWinning: "", WonLost: "", NotesOnFollow: "", is_Followup: true, Followup_Date: "", Followup_Count: 0, contact_address: ""
    });

    const [transferData, settransferData] = useState();
    const [tdata, setTData] = useState({
        _id: "", Type: "Sale", Customer: "", TypeOfProject: "", Channel: "", Reseller: "", EC_PointOfContact: "", DateSold: "", Quarter: Math.ceil((new Date().getMonth() + 1) / 3), Year: new Date().getFullYear(),
        Stage: "", ProjectSize: "", InvoiceIssueDate: "", PaymentTerms: 0, DelinquentTerms: 0, PaymentStatus: "", PO_Order: "", NotesOnFollow: "", contact_address: ""
    });

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const deleteRecord = (id) => {
        // console.log(id);
        setotherAction(true);
        const deleteSale = async () => {
            await axios.delete(`/dashboard/salespipeline/${requested_year}/current/delete`,
                {
                    headers: {
                        'Authorization': `Bearer ` + localStorage.getItem('access')
                    },
                    data: {
                        "Current": {
                            "_id": id
                        }
                    }
                }).then((response) => {
                    Notify.success('Record Deleted Successfully!');
                    setotherAction(false);
                    handleClose();
                }).catch((error) => {
                    Notify.error("Record Can't be Deleted!")
                })
        }
        deleteSale();
    }

    const viewCustomer = ({ _id, Channel, Client_PointOfContact, Client_POC_Email, Customer, EC_PointOfContact, NotesOnFollow, Followup_Date, Followup_Count, ProjectSize, TypeOfProject, Reseller, Stage, ChancesOfWinning, WonLost, contact_address }) => {
        setData((prev) => ({
            ...prev, _id: _id, Customer: Customer, TypeOfProject: TypeOfProject, Channel: Channel, Reseller: Reseller, EC_PointOfContact: EC_PointOfContact, Client_POC_Email: Client_POC_Email, Client_PointOfContact: Client_PointOfContact,
            Stage: Stage, ProjectSize: ProjectSize, ChancesOfWinning: ChancesOfWinning, WonLost: WonLost, NotesOnFollow: NotesOnFollow, Followup_Date: Followup_Date, Followup_Count: Followup_Count, contact_address: contact_address
        }));

        setTData((prev) => ({
            ...prev, _id: _id, Customer: Customer, TypeOfProject: TypeOfProject, Reseller: Reseller, EC_PointOfContact: EC_PointOfContact,
            Stage: Stage, ProjectSize: ProjectSize, NotesOnFollow: NotesOnFollow, contact_address: contact_address
        }));
        setOpen(true);
    }

    // const transferCustomer = ({ id, channel, client_poc, client_poc_email, customer, ec_poc, notes_on_follow, prjct_size, project_type, reseller, stage, winning_chances, wonorlost }) => {

    // }

    const submitAddForm = (type) => {
        let hasWarning = false;

        if (type == 'Submit') {
            if (data.Customer === "") {
                Notify.warning("Customer field cannot be empty!");
                hasWarning = true;
            }
            else if (data.TypeOfProject === "") {
                Notify.warning("Project Type field cannot be empty!");
                hasWarning = true;
            }
            else if (data.Channel === "") {
                Notify.warning("Channel field cannot be empty!");
                hasWarning = true;
            }
            else if (data.Reseller === "") {
                Notify.warning("Reseller Type field cannot be empty!");
                hasWarning = true;
            }
            else if (data.EC_PointOfContact === "") {
                Notify.warning("Choose Point of Contact!");
                hasWarning = true;
            }
            else if (data.is_Followup === true && data.Followup_Date === "") {
                Notify.warning("Follow Date cannot be empty when Followup Count is enabled!");
                hasWarning = true;
            }
        }

        if (type == 'Transfer') {
            if (tdata.Customer === "") {
                Notify.warning("Customer field cannot be empty!");
                hasWarning = true;
            }
            else if (tdata.TypeOfProject === "") {
                Notify.warning("Project Type field cannot be empty!");
                hasWarning = true;
            }
            else if (tdata.Channel === "") {
                Notify.warning("Channel field cannot be empty!");
                hasWarning = true;
            }
            else if (tdata.Reseller === "") {
                Notify.warning("Reseller Type field cannot be empty!");
                hasWarning = true;
            }
            else if (tdata.EC_PointOfContact === "") {
                Notify.warning("Choose Point of Contact!");
                hasWarning = true;
            }
            else if (tdata?.PO_Order === "") {
                Notify.warning("PO Order must be uploaded!");
                hasWarning = true;
            }
        }

        if (hasWarning) return;

        setAddForm(true);
        setotherAction(true);
    }

    const rows = Object.keys(table_data).map((key, val) => ({
        id: val,
        ...table_data[key],
    }));

    //PAGINATION 
    const pageSize = 9;
    const [page, setPage] = useState(0);
    const pageCount = Math.ceil(rows.length / pageSize);
    const rowsOnPage = rows.slice(page * pageSize, (page + 1) * pageSize);

    const columns = [
        {
            field: 'Customer', headerName: 'Customer',
            minWidth: 250,
            flex: 1,
            headerClassName: 'whiteMenuIcon sticky-header',
            cellClassName: 'sticky-cell first-sticky-cell',
            sticky: 'left',
            renderCell: (params) =>
            params.row.Customer == "" ? "-" : <Link style={{ textDecoration: "none", fontWeight: "600", color: cow_color(params.row.ChancesOfWinning) }} onClick={() => viewCustomer(params.row)}>{params.row.Customer}</Link>
        },
        { 
            field: 'TypeOfProject',
            headerName: 'Type of Project',
            cellClassName: 'sticky-cell',
            minWidth: 250,
            flex: 1,
            headerClassName: 'whiteMenuIcon',
        },
        {
            field: 'Channel',
            headerName: 'Referral Channel',
            minWidth: 200,
            flex: 1,
            align: 'center',
            headerClassName: 'centerStyle',
        },
        {
            field: 'Reseller',
            headerName: 'Reseller',
            minWidth: 150,
            align: 'center',
            flex: 1,
            align: 'center',
            headerClassName: 'centerStyle',

        },
        {
            field: 'EC_PointOfContact',
            headerName: 'EC-Point of contact',
            minWidth: 200,
            align: 'center',
            flex: 1,
            filterable: false,
            headerClassName: 'centerStyle',

        },
        {
            field: 'Client_PointOfContact',
            headerName: 'Client Name',
            minWidth: 150,
            align: 'center',
            flex: 1,
            filterable: false,
            headerClassName: 'centerStyle',

        },
        {
            field: 'Client_POC_Email',
            headerName: 'Client Email',
            minWidth: 200,
            align: 'center',
            flex: 1,
            filterable: false,
            headerClassName: 'centerStyle',
        },
        {
            field: 'Followup_Date',
            headerName: 'Followup Date',
            minWidth: 180,
            align: 'center',
            flex: 1,
            filterable: false,
            headerClassName: 'centerStyle',
        },
        {
            field: 'Followup_Count',
            headerName: 'Total Followup',
            minWidth: 140,
            align: 'center',
            flex: 1,
            filterable: false,
            headerClassName: 'centerStyle',
        },
        {
            field: 'Year',
            headerName: 'Year',
            minWidth: 120,
            align: 'center',
            flex: 1,
            headerClassName: 'centerStyle',
        },
        {
            field: 'Stage',
            headerName: 'Stage',
            minWidth: 120,
            align: 'center',
            flex: 1,
            headerClassName: 'centerStyle',
        },
        {
            field: 'ProjectSize',
            headerName: 'Project Size',
            minWidth: 120,
            flex: 1,
            align: 'center',
            headerClassName: 'whiteMenuIcon',
        },
        {
            field: 'ChancesOfWinning',
            headerName: 'Chances Of Winning',
            minWidth: 200,
            align: 'center',
            flex: 1,
            filterable: false,
            headerClassName: 'centerStyle',
            renderCell: (params) =>
            params.row.ChancesOfWinning == "" ? "-" : <Typography fontWeight={'600'} color={cow_color(params.row.ChancesOfWinning)}>{params.row.ChancesOfWinning}</Typography> 
        },
        {
            field: 'WonLost',
            headerName: 'Won/Lost',
            minWidth: 150,
            align: 'center',
            flex: 1,
            filterable: false,
            headerClassName: 'centerStyle',
        },
        {
            field: 'contact_address',
            headerName: 'Company Address',
            cellClassName: 'sticky-cell',
            minWidth: 300,
            sortable: false,
            flex: 1,
            headerClassName: 'whiteMenuIcon',
            renderCell: (params) =>
                params.row.contact_address == "" ? <Typography width='100%' textAlign='center'>-</Typography> : <Typography fontWeight={'600'}>{params.row.contact_address}</Typography> 
        },
        // {
        //     field: 'NotesOnFollow',
        //     headerName: 'Notes on Follow',
        //     cellClassName: 'sticky-cell',
        //     minWidth: 300,
        //     sortable: false,
        //     flex: 1,
        //     headerClassName: 'whiteMenuIcon',
        // },
    ];

    //1. Get Add Current info of given year (for dropdowns)
    const getAddSalespipelineData = async () => {
        await axios.get(`/dashboard/salespipeline/${requested_year}/current/add`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ` + localStorage.getItem('access')
                }
            }).then((response) => {
                setaddData(response.data)
                // setLoading(false);
            }).catch((error) => {
                console.log(error.response)
            })
    }

    //2. Edit Current Pipeline info
    const editSalesDetails = async () => {
        const { Followup_Count, ...rest } = data;
        await axios.put(`/dashboard/salespipeline/${requested_year}/current/edit`,
            JSON.stringify({ Current: rest }),
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json',
                    withCredentials: true,
                    mode: 'no-cors',
                    'Authorization': `Bearer ` + localStorage.getItem('access')
                }
            }).then((response) => {
                console.log(response);
                setAddForm(false);
                setotherAction(false);
                Notify.success("New Sales Record Added Successfully!")
                handleClose();
            }).catch((error) => {
                console.log(error.response);
                Notify.error("Record Can't Be Added. Try Again!")
                setAddForm(false);
            })
    }

    //3. Get Transfer info of given year (for dropdowns)
    const getTransferSalespipelineData = async () => {
        await axios.get(`/dashboard/salespipeline/${requested_year}/current/transfer`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ` + localStorage.getItem('access')
                }
            }).then((response) => {
                settransferData(response.data)
                // setLoading(false);
            }).catch((error) => {
                console.log(error.response)
            })
    }

    //4. Transfer Current Pipeline to Sale
    const TransfertoSale = async () => {
        await axios.post(`/dashboard/salespipeline/${requested_year}/current/transfer`,
            JSON.stringify({ Sale: tdata }),
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json',
                    withCredentials: true,
                    mode: 'no-cors',
                    'Authorization': `Bearer ` + localStorage.getItem('access')
                }
            }).then((response) => {
                console.log(response);
                setAddForm(false);
                Notify.success("Record Transferred to Sale!")
                handleClose();
            }).catch((error) => {
                console.log(error.response);
                Notify.error("Record Can't Be Transferred. Try Again!")
                setAddForm(false);
            })
    }

    useEffect(() => {
        if (addForm) {
            value == 1 ? editSalesDetails() : TransfertoSale();
        }
    }, [addForm])


    useEffect(() => {
        if (open) {
            value == 1 ? getAddSalespipelineData() : getTransferSalespipelineData();
        }
    }, [open, value])

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden'}} elevation={4}>
            <DataGrid
                rows={rowsOnPage}
                columns={columns}
                pageSize={pageSize}
                disableColumnSelector
                hideFooter
                showCellVerticalBorder
                disableSelectionOnClick
                rowHeight={65}
                style={{ backgroundColor: 'white' }}
                sx={{
                    '& .MuiDataGrid-columnHeaders': {
                        backgroundColor: '#657EBF'
                    },
                    '& .MuiDataGrid-cell': {
                        fontSize: '15px',
                        borderColor: 'divider',
                    },
                    '& .MuiDataGrid-columnHeaderTitle': {
                        fontWeight: 'bold',
                        fontSize: '16px'
                    },
                    '& .MuiDataGrid-columnSeparator': {
                        display: 'none'
                    },
                    '& .MuiInputBase-input': {
                        height: '20px !important'
                    },
                    '& .MuiDataGrid-cell:focus, & .MuiDataGrid-columnHeader:focus': {
                        outline: 'none',
                        boxShadow: 'none',
                        border: 'none'
                    },
                }}
            />
            <Stack borderRadius='10px' bgcolor='white' mt={0.2} py={1.5} justifyContent='center' direction='row' width='100%'>
                <Pagination
                    count={pageCount}
                    page={page + 1}
                    onChange={(event, value) => setPage(value - 1)} // Adjust for 0-based index
                    shape="rounded"
                />
            </Stack>

            {/* EDIT - DELETE - TRANSFER */}
            {data ? <Dialog
                maxWidth="lg"
                fullWidth={true}
                open={open}
                onClose={handleClose}
                aria-labelledby="edit-apartment"
            >
                <DialogContent style={{ height: '100vh' }}>
                    <DialogTitle id="edit-apartment">{addData ? data['Customer'] : ""}</DialogTitle>

                    <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleTabChange} aria-label="lab API tabs example">
                                <Tab label="Edit Record" value="1" />
                                <Tab label="Transfer to Sale" value="2" />
                            </TabList>
                        </Box>
                        <TabPanel value="1">
                            {addData ? <Form prop={addData.message} data={data} setData={setData} /> : <SkeletonLoad />}
                        </TabPanel>
                        <TabPanel value="2">
                            {transferData ? <Form prop={transferData.message} data={tdata} setData={setTData} action="transfer" /> : <SkeletonLoad />}
                        </TabPanel>
                    </TabContext>

                </DialogContent>
                <DialogActions sx={{ backgroundColor: "#EEEDEB" }}>
                    <Button sx={{ position: "absolute", top: 10, right: 20 }} onClick={handleClose} color="secondary">
                        <Cancel sx={{ fontSize: "1.8rem" }} />
                    </Button>
                    {value == 1 ?
                        <>
                            {
                            containRoles(["SUPERADMIN_PERMISSION", "MARKETING_LEAD_PERMISSION"],
                            <IconButton onClick={() => deleteRecord(data['_id'])}>
                                <Delete sx={{ color: "red" }}></Delete>
                            </IconButton>,<></>)
                            }
                            <Button variant='contained' onClick={() => submitAddForm("Submit")}>
                                Submit
                            </Button></> :
                        <Button startIcon={<SwapHorizIcon />} variant='outlined' onClick={() => submitAddForm("Transfer")}>
                            Transfer
                        </Button>
                    }
                </DialogActions>
            </Dialog> : <></>}

            {/* <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      /> */}
        </Paper>
    );
}