import React, { useEffect, useState } from 'react'
import axios from '../../api/axios'
import { Token } from '../../constants'
import { SingleTable } from '../../features/components/Table/SingleTable'
import { Box, Button, Grid, Stack, Typography, DialogActions, Dialog, DialogTitle, DialogContent, DialogContentText, Snackbar, Skeleton, LinearProgress, TextField, InputLabel, FormControl } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import Notify from '../../features/helpers/notifyConfig';
import { FormikQuoteTable } from './Tables/FormikQuoteTable';
import { CompanyTable } from './Tables/CompanyTable';
import { SimpleForms } from './Forms/SimpleForms';
import { Cancel } from '@mui/icons-material';
import { Form } from '../../components/ui/Form';

export const QuoteCompany = () => {
    const [companies, setCompanies] = useState();
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [editAccount, setEditAccount] = useState("");
    const [addForm, setAddForm] = useState(false);
    const [addData, setaddData] = useState();
    const [data, setData] = useState({
        Type: "Current", Customer: "", TypeOfProject: "", Channel: "", Reseller: "", EC_PointOfContact: "", Client_POC_Email: "", Client_PointOfContact: "",
        Stage: "", ProjectSize: "", ChancesOfWinning: "", WonLost: "", NotesOnFollow: "", is_Followup: true, Followup_Date: "", Followup_Count: 0, contact_address: ""
    });

    const handleClose = () => {
        setOpen(false);
    };

    const handleClickOpen = () => {
        setOpen(true);
        setEditAccount("");
    };

    useEffect(() => {
        if (editAccount != "") {
            setOpen(true);
            setData(editAccount);
        }
    }, [editAccount])

    const submitAddForm = () => {
        let hasWarning = false;
        if (data.Customer === "") {
            Notify.warning("Customer field cannot be empty!");
            hasWarning = true;
        }
        else if (data.TypeOfProject === "") {
            Notify.warning("Project Type field cannot be empty!");
            hasWarning = true;
        }
        else if (data.Channel === "") {
            Notify.warning("Channel field cannot be empty!");
            hasWarning = true;
        }
        else if (data.Reseller === "") {
            Notify.warning("Reseller Type field cannot be empty!");
            hasWarning = true;
        }
        else if (data.EC_PointOfContact === "") {
            Notify.warning("Choose Point of Contact!");
            hasWarning = true;
        }
        else if (data.is_Followup === true && data.Followup_Date === "") {
            Notify.warning("Follow Date cannot be empty when Followup Count is enabled!");
            hasWarning = true;
        }


        if (hasWarning) return;

        setAddForm(true);
    }

    // 1. Get All Companies
    const getCompanies = async () => {
        await axios.get("/quote-generation/company/get",
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ` + localStorage.getItem('access'),
                    'Access-Control-Allow-Origin': '*',
                    mode: 'no-cors'
                }
            }
        ).then((response) => {
            console.log(response);
            setCompanies(response?.data?.message);
        }).catch((error) => {
            console.log(error.response)
        });
    }

    //2. Add new Current Pipeline info
    const addNewSalesDetails = async () => {
        await axios.post(`/dashboard/salespipeline/${new Date().getFullYear()}/current/add`,
            JSON.stringify({ Current: data }),
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json',
                    withCredentials: true,
                    mode: 'no-cors',
                    'Authorization': `Bearer ` + localStorage.getItem('access')
                }
            }).then((response) => {
                console.log(response);
                setAddForm(false);
                Notify.success("New Sales Record Added Successfully!")
                handleClose();
                setData({
                    Type: "Current", Customer: "", TypeOfProject: "", Channel: "", Reseller: "", EC_PointOfContact: "", Client_POC_Email: "", Client_PointOfContact: "",
                    Stage: "", ProjectSize: "", ChancesOfWinning: "", WonLost: "", NotesOnFollow: "", is_Followup: true, Followup_Date: "", Followup_Count: 0, contact_address: ""
                });
            }).catch((error) => {
                console.log(error.response);
                Notify.error("Record Can't Be Added. Try Again!")
                setAddForm(false);
            })
    }

    //3. Get Add Current info of given year (for dropdowns)
    const getAddSalespipelineData = async () => {
        await axios.get(`/dashboard/salespipeline/${new Date().getFullYear()}/current/add`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ` + localStorage.getItem('access')
                }
            }).then((response) => {
                setaddData(response.data)
                setLoading(false);
            }).catch((error) => {
                console.log(error.response)
            })
    }

    useEffect(() => {
        getCompanies();
    }, [loading])

    useEffect(() => {
        if (addForm)
            addNewSalesDetails();
    }, [addForm])

    useEffect(() => {
        if (open)
            getAddSalespipelineData();
    }, [open])

    return (
        <Box>
            {
                companies
                    ?
                    <Stack>
                        <Button sx={{ marginBottom: '1rem' }} onClick={handleClickOpen} variant='contained' size='small'>Add&nbsp;&nbsp;<AddIcon /></Button>
                        <CompanyTable table_data={companies} setLoading={setLoading} />
                        {addData ?
                        <Dialog
                            sx={{ height: "100vh" }}
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="edit-apartment"
                            fullWidth
                            maxWidth='lg'
                        >
                            <DialogTitle sx={{ backgroundColor: "#F6F5F5" }} id="edit-apartment">Add Company</DialogTitle>
                            <DialogContent sx={{ backgroundColor: "#F6F5F5" }}>
                            <Form prop={addData.message} setData={setData} data={data} />
                            </DialogContent>
                            <DialogActions sx={{ backgroundColor: "#F6F5F5" }}>
                                <Button sx={{ position: "absolute", top: 10, right: 0 }} onClick={handleClose} color="secondary">
                                    <Cancel sx={{ fontSize: "1.8rem" }} />
                                </Button>
                                <Button variant='contained' onClick={() => submitAddForm()} color="primary">Add</Button> :
                            </DialogActions>
                        </Dialog>
                        :<></>
                        }
                    </Stack>
                    :
                    <Stack justifyContent="center" alignItems="center" height="80vh">
                        <Box pb={2}>Wait while we gather required information...</Box>
                        <Stack gap={4} sx={{ width: '50%', color: 'grey.500' }} spacing={2}>
                            <LinearProgress color="inherit" />
                        </Stack>
                    </Stack>
            }
        </Box>
    )
}
