import React, { useEffect, useState } from 'react'
import axios from '../../api/axios'
import { Box, Button, Grid, Stack, Typography, DialogActions, Dialog, DialogTitle, DialogContent, DialogContentText, Snackbar, Skeleton, LinearProgress, TextField, InputLabel, FormControl } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import Notify from '../../features/helpers/notifyConfig';
import { SimpleForms } from './Forms/SimpleForms';
import { Cancel } from '@mui/icons-material';
import { ServiceTable } from './Tables/ServiceTable';

export const QuoteServices = () => {
    const [services, setServices] = useState();
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({
        "service_name":"",
        "service_code":"", 
        "alias": "" 
    });

    const handleClose = () => {
        setOpen(false);
    };

    const handleClickOpen = () => {
        setOpen(true);
        setData({
            "service_name":"",
            "service_code":"", 
            "alias": "" 
        });
    };

    const submitAddForm = () => {
        let hasWarning = false;

        if (data.service_name === "") {
            Notify.warning("Service Name must be mentioned!");
            hasWarning = true;
        }
        else if (data.service_code === "") {
            Notify.warning("Service Code must be mentioned!");
            hasWarning = true;
        }

        if (!hasWarning) {
            // 1. Adding New Service
            const addService = async () => {
                await axios.post
                    ("/quote-generation/service/add", JSON.stringify(data),
                        {
                            headers: {
                                'Access-Control-Allow-Origin': '*',
                                'Content-Type': 'application/json',
                                withCredentials: true,
                                mode: 'no-cors',
                                'Authorization': `Bearer ` + localStorage.getItem('access')
                            }
                        }).then((response) => {
                            console.log(response);
                            setOpen(false);
                            setLoading(prev => !prev);
                            setData({
                                "service_name":"",
                                "service_code":"", 
                                "alias": "" 
                            });
                            Notify.success("Service Added Successfully!")
                        }).catch((error) => {
                            console.log(error.response);
                            Notify.error("Error Occurred. Try Again!");
                        })
            }
            addService();
        }
    }

    // 1. Get All Services
    const getServices = async () => {
        await axios.get("/quote-generation/service/get",
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ` + localStorage.getItem('access'),
                    'Access-Control-Allow-Origin': '*',
                    mode: 'no-cors'
                }
            }
        ).then((response) => {
            console.log(response?.data?.message);
            setServices(response?.data?.message);
        }).catch((error) => {
            console.log(error.response)
        });
    }

    useEffect(() => {
        getServices();
    }, [loading])

    return (
        <Box>
            {
                services
                    ?
                    <Stack>
                        <Button sx={{marginBottom:'1rem'}} onClick={handleClickOpen} variant='contained' size='small'>Add&nbsp;&nbsp;<AddIcon /></Button>
                        <ServiceTable table_data={services} setLoading={setLoading} />
                        <Dialog
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="edit-apartment"
                            fullWidth
                            maxWidth='sm'
                        >
                            <DialogTitle sx={{ backgroundColor: "#F6F5F5" }} id="edit-apartment">Add Service</DialogTitle>
                            <DialogContent sx={{ backgroundColor: "#F6F5F5" }}>
                                <SimpleForms data={data} setData={setData} type="service" />
                            </DialogContent>
                            <DialogActions sx={{ backgroundColor: "#F6F5F5" }}>
                                <Button sx={{ position: "absolute", top: 10, right: 0 }} onClick={handleClose} color="secondary">
                                    <Cancel sx={{ fontSize: "1.8rem" }} />
                                </Button>
                                    <Button variant='contained' onClick={() => submitAddForm()} color="primary">Add</Button> :
                            </DialogActions>
                        </Dialog>
                    </Stack>
                    :
                    <Stack justifyContent="center" alignItems="center" height="80vh">
                        <Box pb={2}>Wait while we gather required information...</Box>
                        <Stack gap={4} sx={{ width: '50%', color: 'grey.500' }} spacing={2}>
                            <LinearProgress color="inherit" />
                        </Stack>
                    </Stack>
            }
        </Box>
    )
}
