import React, { useEffect, useState } from 'react'
import { NodataFound } from '../../NodataFound'
import { ExpenseRequests } from '../Manager/ExpenseRequests'
import axios from '../../../api/axios';
import Notify from '../../../features/helpers/notifyConfig';
import { Skeleton, Stack } from '@mui/material';

export const ExpenseHistory = () => {
  const [histdata, setHistory] = useState(null);

  //1. get expense history of employee
  const gethistoryData = async () => {
    await axios.get("/expense-report/history",
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ` + localStorage.getItem('access')
        }
      }
    ).then((response) => {
      setHistory(response?.data?.expenseReportHistory);
    }).catch((error) => {
      Notify.error("Request Timed Out");
    });
  }

  useEffect(() => {
    gethistoryData();
  }, [])

  return (
    // <NodataFound msg="No History Found" />
    histdata
      ? <ExpenseRequests reqdata={histdata} history='history' />
      : <Stack gap={2} width="100%">
        <Skeleton animation="wave" variant="rectangular" width="100%" height={80} />
        <Skeleton animation="wave" variant="rectangular" width="100%" height={80} />
      </Stack>
  )
}
