import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Paper, Stack, TextField, Typography } from '@mui/material'
import UserIcon from '@mui/material/Avatar';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { advancedDate, postData } from '../../Events';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import VerifiedUserTwoToneIcon from '@mui/icons-material/VerifiedUserTwoTone';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { NodataFound } from '../../NodataFound';
import { useState } from 'react';
import { ExpenseInfo } from './ExpenseInfo';
import Notify from '../../../features/helpers/notifyConfig';
import axios from '../../../api/axios';
import { styled } from '@mui/material/styles';
import { Cancel } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';

const CustomTooltip = styled(({ className, ...props }) => (
    <Tooltip arrow {...props} classes={{ popper: className }} enterNextDelay={200} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: 'white',
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[4],
        //   fontSize: 11,
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: '#e0e0e0',
    },
}));

export const TitleandDesc = (title, desc, gate) => {
    return (
        <Box position='relative'>
            <Typography color='primary.main' fontFamily='Segoe UI' variant='subtitle2' fontWeight='700'>{title}</Typography>
            <Typography textAlign='center' fontFamily='Segoe UI' mt='-2px' variant='body2' fontWeight='500'>{desc}</Typography>
            {!gate ? <Box
                position='absolute'
                right={-20}
                top={0}
                bottom={0}
                marginBottom='auto'
                marginTop='auto'
                width='2px'
                height='70%'
                bgcolor='primary.main'
            ></Box> : <></>}
        </Box>
    )
}

export const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

const stat = {
    "pending": {
        color: '#e7700d',
        icon: <HourglassTopIcon sx={{ color: '#e7700d' }} fontSize='10px' />
    },
    "approved": {
        color: '#18864b',
        icon: <CheckCircleOutlineIcon sx={{ color: '#18864b' }} fontSize='10px' />
    },
    "rejected": {
        color: '#BF3131',
        icon: <CancelOutlinedIcon sx={{ color: '#BF3131' }} fontSize='10px' />
    },
}

const statPara = (val) => {
    return (
        <Stack justifyContent='center' direction='row' spacing={0.5} alignItems='center'>
            <Typography color={stat[val].color} variant='subtitle2'>{capitalizeFirstLetter(val)}</Typography>
            {stat[val].icon}
        </Stack>
    )
}

export const ExpenseRequests = ({ setReload = '', infodata = '', reqdata, history = '', value = 'Pending' }) => {
    const [manData, setData] = useState(null);
    const [reject, setReject] = useState(null);
    const [msg, chngMsg] = useState("");
    const [loading, setLoading] = useState(false);

    const manageRequests = (data) => {
        setData(data);
    }

    const rejectionMsg = (e) => {
        chngMsg(e.target.value);
    }

    const onClose = () => {
        setData(null);
    }

    const onRejectClose = () => {
        setReject(null);
        chngMsg("");
    }

    const RejectReasonBox = (data) => {
        console.log(data);
        setReject(data);
    }

    const ExpenseAction = async (data, action, note = 'This Reimbursement Claim is correct') => {
        if (note == '') {
            Notify.warning("Please mention the reason of rejection!");
            return;
        }
        else if (note.length <= 10) {
            Notify.warning("Note should be more than 10 characters!");
            return;
        }
        else {
            setLoading(true);
            const findata = {
                "reimbursementRecordID": data.reimbursementRecordID,
                "statementNumber": data.statementNumber,
                "action": action,
                "note": note
            }
            await axios.post
                ('expense-report/action', JSON.stringify(findata),
                    {
                        headers: {
                            'Access-Control-Allow-Origin': '*',
                            'Content-Type': 'application/json',
                            withCredentials: true,
                            mode: 'no-cors',
                            'Authorization': `Bearer ` + localStorage.getItem('access')
                        }
                    }).then((response) => {
                        setLoading(false);
                        setData(null);
                        setReject(null);
                        chngMsg("");
                        Notify.success(`Expense Report has been ${action}ed!`);
                        setReload(prev => !prev);
                    }).catch((error) => {
                        setLoading(false);
                        Notify.error("Error Occurred. Try Again!");
                    })
        }
    }

    return (
        <>
            <Box>
                {
                    reqdata.length != 0
                        ? reqdata.map((val, key) => {
                            return (
                                <Grid
                                    onClick={() => manageRequests(val)}
                                    sx={{
                                        cursor: 'pointer',
                                        transition: 'ease-in-out 0.3s',
                                        borderLeft: '6px solid white',
                                        ':hover': {
                                            boxShadow: 4,
                                            borderLeft: '6px solid #1b2c58',
                                        }
                                    }} container boxShadow={2} bgcolor='white' mb={2} borderRadius='20px' p={2} alignItems='center'>
                                    {
                                        history == ''
                                            ? <Grid item xs={2} borderRight='solid 2px' borderColor='#c8c5c5'>
                                                <Stack direction='row' spacing={1.5} alignItems='center'>
                                                    {(val.employee_picture == "" || val.employee_picture == null) ? <Box><UserIcon sx={{ width: '3rem', height: '3rem', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)', borderRadius: '50%' }} /></Box>
                                                        : <Box
                                                            sx={{
                                                                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)',
                                                                width: '3.2rem',
                                                                height: '3.2rem',
                                                                backgroundImage: `url(${val.employee_picture})`,
                                                                backgroundSize: 'cover',
                                                                backgroundPosition: 'center',
                                                                borderRadius: '50%',
                                                            }}></Box>}
                                                    <Box>
                                                        <Typography color='primary' fontWeight='600' variant='subtitle1' fontFamily='Segoe UI'>{val.employeeName}</Typography>
                                                        <Typography color='gray' fontWeight='400' fontSize='9px' fontFamily='Segoe UI'>Employee</Typography>
                                                    </Box>
                                                </Stack>
                                            </Grid>
                                            : <></>}

                                    <Grid item xs={2}>
                                        <Stack alignItems='center'>
                                            <Typography color='primary.main' fontFamily='Segoe UI' fontSize='14px' variant='subtitle2' fontWeight='400'>Project Name</Typography>
                                            <Typography textAlign='center' fontFamily='Segoe UI' fontSize='14px' fontWeight='600'>{val.details.projectName}</Typography>
                                        </Stack>
                                    </Grid>

                                    <Grid item xs={2}>
                                        <CustomTooltip title={val.details.approvals.map((vaal, key) => {
                                            return (
                                                <Stack m={1} key={key}>
                                                    <Grid container mb={1} alignItems='center'>
                                                        <Grid item xs={12}>
                                                            <Stack direction='row' spacing={1}>
                                                                <Typography fontFamily='Segoe UI' variant='subtitle2' fontWeight='600'>{vaal.role}</Typography>
                                                                <Typography fontFamily='Segoe UI' color='secondary' variant='subtitle2' fontWeight='600'>({vaal.approverName})</Typography>
                                                            </Stack>
                                                        </Grid>
                                                        <Grid item xs={5}>
                                                            <Stack direction='row' spacing={0.5} alignItems='center'>
                                                                <Typography fontFamily='Segoe UI' color={stat[vaal.approveStatus].color} variant='subtitle2'>{capitalizeFirstLetter(vaal.approveStatus)}</Typography>
                                                                {stat[vaal.approveStatus].icon}
                                                            </Stack>
                                                        </Grid>
                                                        {
                                                            vaal.approveStatus != 'pending'
                                                                ? <Grid item xs={7}><Typography fontFamily='Segoe UI' variant='subtitle2' fontWeight='500' color='dark_divider'>-{advancedDate(postData(vaal.approveDate))}</Typography></Grid>
                                                                : <></>
                                                        }

                                                        {
                                                            vaal.approveStatus == 'rejected'
                                                                ? <Grid item xs={12} mt={0.6}>
                                                                    <Typography color='error' fontFamily='Segoe UI' fontSize='10px'><strong>Problem specified:</strong> {vaal.notes}</Typography>
                                                                </Grid>
                                                                : <></>
                                                        }
                                                    </Grid>
                                                </Stack>
                                            )
                                        })
                                        }>
                                            <Stack alignItems='center'>
                                                <Typography color='primary.main' fontFamily='Segoe UI' fontSize='14px' variant='subtitle2' fontWeight='400'>Overall Status</Typography>
                                                <Typography textAlign='center' fontFamily='Segoe UI' fontSize='14px' fontWeight='600'>{statPara(val.status)}</Typography>
                                            </Stack>
                                        </CustomTooltip>
                                    </Grid>

                                    <Grid item xs={2}>
                                        <Stack alignItems='center' justifyContent='center'>
                                            <Typography textAlign='center' color='primary.main' fontFamily='Segoe UI' fontSize='14px' variant='subtitle2' fontWeight='400'>Statement Number</Typography>
                                            <Typography textAlign='center' fontFamily='Segoe UI' fontSize='14px' fontWeight='600'>{val.statementNumber}</Typography>
                                        </Stack>
                                    </Grid>

                                    <Grid item xs={2}>
                                        <Stack alignItems='center'>
                                            <Typography color='primary.main' fontFamily='Segoe UI' fontSize='14px' variant='subtitle2' fontWeight='400'>Created On</Typography>
                                            <Typography textAlign='center' fontFamily='Segoe UI' fontSize='14px' fontWeight='600'>{advancedDate(postData(val.createdOn))}</Typography>
                                        </Stack>
                                    </Grid>

                                    <Grid item xs={2}>
                                        <Stack alignItems='center'>
                                            <Typography color='primary.main' fontFamily='Segoe UI' fontSize='14px' variant='subtitle2' fontWeight='400'>Total Expense</Typography>
                                            <Typography textAlign='center' fontFamily='Segoe UI' fontSize='14px' fontWeight='600'>{val.details.currency +" " + val.details.summary.total}</Typography>
                                        </Stack>
                                    </Grid>

                                    {history != ''
                                        ? <Grid item xs={2}>
                                            <Stack alignItems='center'>
                                                <Typography color='primary.main' fontFamily='Segoe UI' fontSize='14px' variant='subtitle2' fontWeight='400'>Duration</Typography>
                                                <Typography textAlign='center' fontFamily='Segoe UI' fontSize='14px' fontWeight='600'>{advancedDate(postData(val.details.duration.from))}-{advancedDate(postData(val.details.duration.to))}</Typography>
                                            </Stack>
                                        </Grid>
                                        : <></>
                                    }
                                </Grid>
                            )
                        })
                        : <NodataFound msg={history != '' ? "No Reimbursement History Found!" : `No ${value} claims here`} />
                }
            </Box>

            {/* 1. Dialog box to show details of the respective request */}
            <Dialog fullWidth={true} maxWidth='lg' open={manData ? true : false} onClose={onClose}>
                {manData
                    ? <>
                        <DialogTitle>Bill Information - {manData.statementNumber}</DialogTitle>
                        <DialogContent>
                            <ExpenseInfo data={manData} />
                        </DialogContent>

                        <DialogActions sx={{ pb: '15px', pr: '20px' }}>
                            <Button sx={{ position: "absolute", top: 15, right: 10 }} onClick={onClose} color="error">
                                <Cancel sx={{ fontSize: "1.8rem" }} />
                            </Button>
                            {history == '' && manData.status=='pending'
                                ? <><Button onClick={() => RejectReasonBox(manData)} variant='contained' sx={{ bgcolor: '#BF3131' }}>Reject</Button>
                                    <LoadingButton loading={loading} onClick={() => ExpenseAction(manData, "approve")} variant='contained' sx={{ bgcolor: '#198f51' }}>Accept</LoadingButton></>
                                : <></>}
                        </DialogActions>
                    </>
                    : <></>}
            </Dialog>

            {/* 2. Dialog box to give reason of reject of the Expense Report */}
            <Dialog fullWidth={true} maxWidth='md' open={reject ? true : false} onClose={onRejectClose}>
                {reject
                    ? <>
                        <DialogTitle>Reason Of Rejection</DialogTitle>
                        <DialogContent>
                            <TextField
                                // ref={input}
                                sx={{ width: "100%", mt: '10px', '.MuiInputBase-input': { height: 'auto !important' } }}
                                placeholder="Please specify the reason of your rejection"
                                multiline
                                rows={5}
                                maxRows={8}
                                value={msg}
                                name='returnmsg'
                                onChange={(e) => rejectionMsg(e)}
                            />
                        </DialogContent>

                        <DialogActions sx={{ pb: '15px', pr: '20px' }}>
                            <Button sx={{ position: "absolute", top: 15, right: 10 }} onClick={onRejectClose} color="error">
                                <Cancel sx={{ fontSize: "1.8rem" }} />
                            </Button>
                            <LoadingButton loading={loading} onClick={() => ExpenseAction(reject, "reject", msg)} variant='contained' sx={{ bgcolor: '#BF3131' }}>Confirm Rejection</LoadingButton>
                        </DialogActions>
                    </>
                    : <></>}
            </Dialog>
        </>
    )
}
