// import { useIsAuthenticated, useMsal,MsalProvider } from "@azure/msal-react";
// // import { useDispatch, useSelector } from "react-redux";
// import { useEffect } from "react";

// export function Login() {
//   const { instance, accounts, inProgress } = useMsal();
//   const isAuthenticated = useIsAuthenticated();

//   useEffect(() => {
//     console.log(accounts, inProgress, isAuthenticated);
//   });

//   if (accounts.length > 0) {
//     return <span>There are currently {accounts.length} users signed in!</span>;
//   } else if (inProgress === "login") {
//     return <span>Login is currently in progress!</span>;
//   } else {
//     return (
//       <MsalProvider>
//         <span>There are currently no users signed in!</span>
//         <button onClick={() => instance.loginRedirect()}>Login</button>
//       </MsalProvider>
//     );
//   }
// }
import { Box, Stack, Grid, Card, Typography, Button } from '@mui/material';
import { blue } from '@mui/material/colors';
import React from 'react';
import { ECLogo } from '../components/Icons/ECLogo';
// import { ForwardIcon } from '../components/Icons/ForwardIcon';
import { WrappedView } from '../App'

export const Login = () => {
  // console.log(WrappedView());
  const boxBackgroundColor = blue[50];
  const middleLabel = {
    background: "linear-gradient(90.07deg, #1B2C58 -1.98%, #125593 49.04%, #1B2C58 100.07%)",
    width: "100vw",
    height: "40vh"
  }

  const centerElement = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }

  const middleCircle = {
    position: "relative",
    background: "white",
    width: "10rem",
    height: "10rem",
    borderRadius: "50%",
    ...centerElement
  }

  const innerCircle = {
    position: "absolute",
    width: "7rem",
    height: "7rem",
    borderRadius: "50%",
    zIndex: '1',
    ...centerElement
  }

  const loginCard = {
    position: "absolute",
    left: '50%',
    ...centerElement,
    // height:'80vh',
    width: '40rem',
    padding: '5rem'
  }

  return (
      <Grid container alignItems='center' sx={{ bgcolor: boxBackgroundColor, width: '100vw', height: '100vh' }} >
        <Grid item sx={{ ...middleLabel }}>
          <Box display='flex' justifyContent="center" alignItems="center" height='100%'>
            <Box sx={{ ...middleCircle }}>
              <Box sx={{ bgcolor: 'primary.main', ...innerCircle }}>
                <ECLogo width={65} />
              </Box>

              <Card sx={{...loginCard}} elevation={3}>
                <Box>
                <Typography variant='h3' fontWeight='600' color='primary.main' pb='15px'>Welcome to Employee Portal!</Typography>
                <Typography variant='h6' fontWeight='400' pb='3rem'>Please enter your Microsoft login details registered with the office Active 
                Directory tenant.</Typography>
                <WrappedView/>
                </Box>
              </Card>
            </Box>
          </Box>
        </Grid>
      </Grid>
  )
}

