import { useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Skeleton, Stack, Tab, Typography } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import axios from '../../../api/axios';
import { Form } from '../Form';
import Notify from '../../../features/helpers/notifyConfig';
import { Cancel, Delete } from '@mui/icons-material';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import { makeStyles } from "@material-ui/core/styles";

export const SkeletonLoad = () => {
    return (
        <Stack gap={2} width="100%">
            <Skeleton animation="wave" variant="rectangular" width="100%" height={80} />
            <Skeleton animation="wave" variant="rectangular" width="100%" height={80} />
        </Stack>
    )
}

const columns = [
    { id: 'customer', label: 'Customer', minWidth: 250 },
    { id: 'project_type', label: 'Type of Project', minWidth: 250 },
    {
        id: 'channel',
        label: 'Referral Channel',
        minWidth: 150,
    },
    {
        id: 'reseller',
        label: 'Reseller',
        minWidth: 150,
    },
    {
        id: 'ec_poc',
        label: 'EC-Point of contact',
        minWidth: 200,
    },
    {
        id: 'client_poc',
        label: 'Client-Point of Contact',
        minWidth: 200,
    },
    {
        id: 'client_poc_email',
        label: 'Client-POC Email',
        minWidth: 200,
    },
    // {
    //     id: 'dateSold',
    //     label: 'Date Sold',
    //     minWidth: 150,
    // },
    // {
    //     id: 'quarter',
    //     label: 'Quarter',
    //     minWidth: 100,
    // },
    {
        id: 'year',
        label: 'Year',
        minWidth: 100,
    },
    {
        id: 'stage',
        label: 'Stage',
        minWidth: 120,
    },
    {
        id: 'prjct_size',
        label: 'Project Size',
        minWidth: 120,
    },
    // {
    //     id: 'type',
    //     label: 'Type',
    //     minWidth: 120,
    // },
    {
        id: 'winning_chances',
        label: 'Chances of Winning',
        minWidth: 200,
    },
    {
        id: 'wonorlost',
        label: 'Won/Lost',
        minWidth: 80,
    },
    {
        id: 'contact_address',
        label: 'Company Address',
        minWidth: 250,
    },
    {
        id: 'notes_on_follow',
        label: 'Notes on Follow',
        minWidth: 250,
    },
];

export const useStyles = makeStyles({
    table: {
        //   minWidth: 650,
        "& .MuiTableCell-root": {
            borderLeft: "1px solid rgba(224, 224, 224, 1)",
            borderBottom: "1px solid rgba(224, 224, 224, 1)"
        }
    }
});

function createData(id, customer, project_type, channel, reseller, ec_poc, client_poc, client_poc_email, stage, prjct_size, winning_chances, wonorlost, notes_on_follow) {
    return { id, customer, project_type, channel, reseller, ec_poc, client_poc, client_poc_email, stage, prjct_size, winning_chances, wonorlost, notes_on_follow };
}

export function MainTable({ table_data, setotherAction }) {
    const classes = useStyles();
    const requested_year = useParams().year;
    const [open, setOpen] = useState(false);
    const [addForm, setAddForm] = useState(false);
    // const [sale_data, setsale_data] = useState();
    const [value, setValue] = useState('1');
    const [addData, setaddData] = useState();
    const [data, setData] = useState({
        _id: "", Type: "Current", Customer: "", TypeOfProject: "", Channel: "", Reseller: "", EC_PointOfContact: "", Client_POC_Email: "", Client_PointOfContact: "",
        Stage: "", ProjectSize: "", ChancesOfWinning: "", WonLost: "", NotesOnFollow: "", contact_address: ""
    });

    const [transferData, settransferData] = useState();
    const [tdata, setTData] = useState({
        _id: "", Type: "Sale", Customer: "", TypeOfProject: "", Channel: "", Reseller: "", EC_PointOfContact: "", DateSold: "", Quarter: Math.ceil((new Date().getMonth() + 1) / 3), Year: new Date().getFullYear(),
        Stage: "", ProjectSize: "", InvoiceIssueDate: "", PaymentTerms: 0, DelinquentTerms: 0, PaymentStatus: "", PO_Order: "", NotesOnFollow: "", contact_address: ""
    });

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const deleteRecord = (id) => {
        // console.log(id);
        setotherAction(true);
        const deleteSale = async () => {
            await axios.delete(`/dashboard/salespipeline/${requested_year}/current/delete`,
                {
                    headers: {
                        'Authorization': `Bearer ` + localStorage.getItem('access')
                    },
                    data: {
                        "Current": {
                            "_id": id
                        }
                    }
                }).then((response) => {
                    Notify.success('Record Deleted Successfully!');
                    setotherAction(false);
                    handleClose();
                }).catch((error) => {
                    Notify.error("Record Can't be Deleted!")
                })
        }
        deleteSale();
    }

    const viewCustomer = ({ id, channel, client_poc, client_poc_email, customer, ec_poc, notes_on_follow, prjct_size, project_type, reseller, stage, winning_chances, wonorlost, contact_address }) => {
        setData((prev) => ({
            ...prev, _id: id, Customer: customer, TypeOfProject: project_type, Channel: channel, Reseller: reseller, EC_PointOfContact: ec_poc, Client_POC_Email: client_poc_email, Client_PointOfContact: client_poc,
            Stage: stage, ProjectSize: prjct_size, ChancesOfWinning: winning_chances, WonLost: wonorlost, NotesOnFollow: notes_on_follow, contact_address: contact_address
        }));

        setTData((prev) => ({
            ...prev, _id: id, Customer: customer, TypeOfProject: project_type, Reseller: reseller, EC_PointOfContact: ec_poc,
            Stage: stage, ProjectSize: prjct_size, NotesOnFollow: notes_on_follow, contact_address: contact_address
        }));
        setOpen(true);
    }

    // const transferCustomer = ({ id, channel, client_poc, client_poc_email, customer, ec_poc, notes_on_follow, prjct_size, project_type, reseller, stage, winning_chances, wonorlost }) => {

    // }

    const submitAddForm = (type) => {
        let hasWarning = false;

        if(type=='Submit'){
            if (data.Customer === "") {
                Notify.warning("Customer field cannot be empty!");
                hasWarning = true;
            } 
            else if (data.TypeOfProject === "") {
                Notify.warning("Project Type field cannot be empty!");
                hasWarning = true;
            } 
            else if (data.Channel === "") {
                Notify.warning("Channel field cannot be empty!");
                hasWarning = true;
            }
            else if (data.Reseller === "") {
                Notify.warning("Reseller Type field cannot be empty!");
                hasWarning = true;
            }
            else if (data.EC_PointOfContact === "") {
                Notify.warning("Choose Point of Contact!");
                hasWarning = true;
            }
        }

        if(type=='Transfer'){
            if (tdata.Customer === "") {
                Notify.warning("Customer field cannot be empty!");
                hasWarning = true;
            } 
            else if (tdata.TypeOfProject === "") {
                Notify.warning("Project Type field cannot be empty!");
                hasWarning = true;
            } 
            else if (tdata.Channel === "") {
                Notify.warning("Channel field cannot be empty!");
                hasWarning = true;
            }
            else if (tdata.Reseller === "") {
                Notify.warning("Reseller Type field cannot be empty!");
                hasWarning = true;
            }
            else if (tdata.EC_PointOfContact === "") {
                Notify.warning("Choose Point of Contact!");
                hasWarning = true;
            }
            else if (tdata?.PO_Order==="") {
                Notify.warning("PO Order must be uploaded!");
                hasWarning = true;
            }    
        }

        if (hasWarning) return;

        setAddForm(true);
        setotherAction(true);
    }

    const rows = [];
    Object.keys(table_data).map((e) => {
        const { _id, Customer, TypeOfProject, Channel, Reseller, EC_PointOfContact, Client_PointOfContact, Client_POC_Email, Stage, ProjectSize, ChancesOfWinning, WonLost, NotesOnFollow } = table_data[e];
        rows.push(createData(_id, Customer, TypeOfProject, Channel, Reseller, EC_PointOfContact, Client_PointOfContact, Client_POC_Email, Stage, ProjectSize, ChancesOfWinning, WonLost, NotesOnFollow));
    })

    //1. Get Add Current info of given year (for dropdowns)
    const getAddSalespipelineData = async () => {
        await axios.get(`/dashboard/salespipeline/${requested_year}/current/add`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ` + localStorage.getItem('access')
                }
            }).then((response) => {
                setaddData(response.data)
                // setLoading(false);
            }).catch((error) => {
                console.log(error.response)
            })
    }

    //2. Edit Current Pipeline info
    const editSalesDetails = async () => {
        await axios.put(`/dashboard/salespipeline/${requested_year}/current/edit`,
            JSON.stringify({ Current: data }),
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json',
                    withCredentials: true,
                    mode: 'no-cors',
                    'Authorization': `Bearer ` + localStorage.getItem('access')
                }
            }).then((response) => {
                console.log(response);
                setAddForm(false);
                setotherAction(false);
                Notify.success("New Sales Record Added Successfully!")
                handleClose();
            }).catch((error) => {
                console.log(error.response);
                Notify.error("Record Can't Be Added. Try Again!")
                setAddForm(false);
            })
    }

    //3. Get Transfer info of given year (for dropdowns)
    const getTransferSalespipelineData = async () => {
        await axios.get(`/dashboard/salespipeline/${requested_year}/current/transfer`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ` + localStorage.getItem('access')
                }
            }).then((response) => {
                settransferData(response.data)
                // setLoading(false);
            }).catch((error) => {
                console.log(error.response)
            })
    }

    //4. Transfer Current Pipeline to Sale
    const TransfertoSale = async () => {
        await axios.post(`/dashboard/salespipeline/${requested_year}/current/transfer`,
            JSON.stringify({ Sale: tdata }),
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json',
                    withCredentials: true,
                    mode: 'no-cors',
                    'Authorization': `Bearer ` + localStorage.getItem('access')
                }
            }).then((response) => {
                console.log(response);
                setAddForm(false);
                Notify.success("Record Transferred to Sale!")
                handleClose();
            }).catch((error) => {
                console.log(error.response);
                Notify.error("Record Can't Be Transferred. Try Again!")
                setAddForm(false);
            })
    }

    useEffect(() => {
        if (addForm) {
            value == 1 ? editSalesDetails() : TransfertoSale();
        }
    }, [addForm])


    useEffect(() => {
        if (open) {
            value == 1 ? getAddSalespipelineData() : getTransferSalespipelineData();
        }
    }, [open, value])

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden', borderRadius: "5px" }} elevation={4}>
            <TableContainer sx={{
                maxHeight: "62vh",
                overflowX: "scroll",
                '&::-webkit-scrollbar': {
                    width: '10px'
                },
                '&::-webkit-scrollbar:horizontal': {
                    height: '10px',
                },
                '&::-webkit-scrollbar-track': {
                    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                    webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
                },
                '&::-webkit-scrollbar-thumb': {
                    backgroundColor: '#C7C8CC',
                }
            }}>
                <Table className={classes.table} stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map((column, key) => (
                                <TableCell
                                    key={key} sx={column.label == "Customer"?{"position":"sticky", left:"0", background: "#F3F3F3", zIndex:"1000"}:{}}
                                    style={{ minWidth: column.minWidth }}
                                    align={column.label == "Customer" || column.label == "Type of Project" ? 'left' : 'center'}
                                >
                                    <Typography fontWeight="600" color="primary.main" variant='subtitle2'> {column.label} </Typography>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows
                            .map((row) => {
                                return (
                                    <>
                                        <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                            {/* {console.log(row.id)} */}
                                            {columns.map((column) => {
                                                var value = row[column.id];
                                                if (column.id == "customer") {
                                                    value = row[column.id] == "" ? "-" : <Link style={{ textDecoration: "none", fontWeight: "600" }} onClick={() => viewCustomer(row)}>{row[column.id]}</Link>
                                                }
                                                return (
                                                    <TableCell
                                                        sx={column.label == "Customer"?{"position":"sticky", left:"0", background: "white"}:{}}
                                                        key={column.id}
                                                        align={column.label == "Customer" || column.label == "Type of Project" ? 'left' : 'center'}
                                                    >
                                                        {value == "" ? "-" : value}
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    </>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* EDIT - DELETE - TRANSFER */}
            {data ? <Dialog
                maxWidth="lg"
                fullWidth={true}
                open={open}
                onClose={handleClose}
                aria-labelledby="edit-apartment"
            >
                <DialogContent style={{ height: '100vh' }}>
                    <DialogTitle id="edit-apartment">{addData ? data['Customer'] : ""}</DialogTitle>

                    <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleTabChange} aria-label="lab API tabs example">
                                <Tab label="Edit Record" value="1" />
                                <Tab label="Transfer to Sale" value="2" />
                            </TabList>
                        </Box>
                        <TabPanel value="1">
                            {addData ? <Form prop={addData.message} data={data} setData={setData} /> : <SkeletonLoad/>}
                        </TabPanel>
                        <TabPanel value="2">
                            {transferData ? <Form prop={transferData.message} data={tdata} setData={setTData} action="transfer" /> : <SkeletonLoad/>}
                        </TabPanel>
                    </TabContext>

                </DialogContent>
                <DialogActions sx={{ backgroundColor: "#EEEDEB" }}>
                    <Button sx={{ position: "absolute", top: 10, right: 20 }} onClick={handleClose} color="secondary">
                        <Cancel sx={{ fontSize: "1.8rem" }} />
                    </Button>
                    {value == 1 ?
                        <>
                            <IconButton onClick={() => deleteRecord(data['_id'])}>
                                <Delete sx={{ color: "red" }}></Delete>
                            </IconButton>
                            <Button variant='contained' onClick={()=>submitAddForm("Submit")}>
                                Submit
                            </Button></> :
                        <Button startIcon={<SwapHorizIcon />} variant='outlined' onClick={()=>submitAddForm("Transfer")}>
                            Transfer
                        </Button>
                    }
                </DialogActions>
            </Dialog> : <></>}

            {/* <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      /> */}
        </Paper>
    );
}