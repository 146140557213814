import React, { useEffect, useState } from 'react'
import axios from '../../../api/axios'
import { Box, LinearProgress } from '@mui/material';

export const ClaimDate = ({date}) => {
    const [data, setData] = useState(null);

    useEffect(() => {
        const getMonthlyClaim = async () => {
            await axios.get(`/expense-report/summary/?filter=${date}`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ` + localStorage.getItem('access')
                    }
                }).then((response) => {
                    // setCredData(response?.data);
                }).catch((error) => {
                    console.log(error.response);
                })
        }
        getMonthlyClaim();
    }, [])

    return (
        <>
        {
            data ?
            <Box>

            </Box>
            : <LinearProgress />
        }
        </>    
    )
}
