import { useEffect, useState } from 'react'
import axios from '../../api/axios';
import { Box, Button, Grid, Stack, Typography, DialogActions, Dialog, DialogTitle, DialogContent, DialogContentText, Snackbar, Skeleton, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import Paper from '@mui/material/Paper';
import { MainTable } from '../../components/ui/Table/MainTable';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import AddIcon from '@mui/icons-material/Add';
import LoadingButton from '@mui/lab/LoadingButton';
import Description from '@mui/icons-material/Description';
import { Link, useParams } from 'react-router-dom';
import Notify from '../../features/helpers/notifyConfig';
import { Cancel, Download } from '@mui/icons-material';
import { EmployeeTable } from '../../components/ui/Table/EmployeeTable';
import { EmployeeForm } from '../../components/ui/EmployeeForm';

export const UserManagement = () => {
  const border = {
    borderRadius: "10px"
  }

  const [currentEmployee, setcurrentEmployee] = useState();
  const [open, setOpen] = useState(false);
  const [addForm, setAddForm] = useState(false);
  const [otherAction, setotherAction] = useState(false);
  const [addData, setaddData] = useState();
  const [loading, setLoading] = useState(false);
  const [submitLoad, setSubmitLoad] = useState(false);
  const [data, setData] = useState({
    Address: "", Assets: [], ContactNo: "", Country: "", Date_of_Joining: "", Designation: "", FirstName: "",
    LastName: "", givenName: "", mail: "", tempPassword: "Puneet@123", usageLocation: "", status: "", team: "", team_id: [], ReportingTo: "",
    role_string_id: ['Employee'], accessSharepoint:false

  });

  const handleClickOpen = () => {
    setOpen(true);
    setLoading(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const submitAddForm = () => {
    let hasWarning = false;
    if (data.FirstName === "") {
      Notify.warning("First Name field cannot be empty!");
      hasWarning = true;
    }
    else if (data.LastName === "") {
      Notify.warning("Last Name field cannot be empty!");
      hasWarning = true;
    }
    else if (data.givenName === "") {
      Notify.warning("UserName field cannot be empty!");
      hasWarning = true;
    }
    else if (data.tempPassword === "") {
      Notify.warning("Please provide a temporary password for first time login of user!");
      hasWarning = true;
    }
    else if (data.Country === "") {
      Notify.warning("Country field cannot be empty!");
      hasWarning = true;
    }
    else if (data.status === "") {
      Notify.warning("Status field cannot be empty!");
      hasWarning = true;
    }
    else if (data.ReportingTo === "") {
      Notify.warning("Reporting To field cannot be empty!");
      hasWarning = true;
    }
    else if (data.role_string_id.length == 0) {
      Notify.warning("Atleat 1 role should be selected!");
      hasWarning = true;
    }
    else if (data.ContactNo != "" && data.ContactNo.length != 10) {
      Notify.warning("Contact number should be of 10 numbers only!");
      hasWarning = true;
    }

    if (hasWarning) return;

    setAddForm(true);
  }

  //1. Get info of all employees
  const getallEmployees = async () => {
    await axios.get(`/admin/users`,
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ` + localStorage.getItem('access')
        }
      }
    ).then((response) => {
      console.log(response);
      setcurrentEmployee(response.data)
    }).catch((error) => {
      console.log(error.response)
    });
  }

  //2. Get required info for adding a employee (dropdowns and pre-existing names,mail-ids)
  const getAddEmployeeData = async () => {
    await axios.get(`/admin/users/add`,
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ` + localStorage.getItem('access')
        }
      }).then((response) => {
        setaddData(response.data)
        setLoading(false);
      }).catch((error) => {
        console.log(error.response)
      })
  }

  //3. Add new Employee
  const addNewEmployee = async () => {
    await axios.post(`/admin/users/add`,
      JSON.stringify(data),
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          withCredentials: true,
          mode: 'no-cors',
          'Authorization': `Bearer ` + localStorage.getItem('access')
        }
      }).then((response) => {
        console.log(response);
        setAddForm(false);
        Notify.success("New Employee Added Successfully!")
        handleClose();
        setSubmitLoad(false);
      }).catch((error) => {
        const err =error.response?.data?.ERROR;
        err=="No available licenses"?Notify.error(err):Notify.error("Some Error Occurred. Try Again!");
        setSubmitLoad(false);
        setAddForm(false);
      })
  }

  useEffect(() => {
    if (!addForm || !otherAction) {
      getallEmployees();
    }

    if (addForm){
      setSubmitLoad(true);
      addNewEmployee();
    }

  }, [addForm, otherAction])

  useEffect(() => {
    if (open)
      getAddEmployeeData();
  }, [open])

  return (
    <Box pt={3}>
      {currentEmployee ?
        <Box>
          <Stack mb={2} direction="row" justifyContent="start" alignItems="center">

            <LoadingButton
              onClick={handleClickOpen}
              endIcon={<AddIcon />}
              loading={loading}
              loadingPosition="end"
              variant="contained"
            >Add New</LoadingButton>

          </Stack>
          {addData ?
            <Dialog
              sx={{ height: "100vh" }}
              // fullWidth
              maxWidth="lg"
              open={open}
              onClose={handleClose}
              aria-labelledby="edit-apartment"
            >
              <DialogTitle id="edit-apartment">Add New Employee</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Please fill the required information of employee.
                </DialogContentText>
                  <EmployeeForm data={data} prop={addData} setData={setData} />
              </DialogContent>
              <DialogActions sx={{ backgroundColor: "#EEEDEB" }}>
                <Button sx={{ position: "absolute", top: 10, right: 0 }} onClick={handleClose} color="secondary">
                  <Cancel sx={{ fontSize: "1.8rem" }} />
                </Button>
                <LoadingButton loading={submitLoad} variant='contained' onClick={submitAddForm} color="primary">
                  Submit
                </LoadingButton>
              </DialogActions>
            </Dialog>
            : <></>}

          <Box mt={1}>
            <EmployeeTable table_data={currentEmployee.message} setotherAction={setotherAction} />
          </Box>

        </Box> :
        <Stack gap={2} width="100%">
          <Skeleton animation="wave" variant="rectangular" width="100%" height={80} />
          <Skeleton animation="wave" variant="rectangular" width="100%" height={80} />
        </Stack>
      }
    </Box>
  )
}
