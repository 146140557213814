import axios from "axios";

export default axios.create({
    // baseURL: 'https://employee.encryptionconsulting.com/api'
    // baseURL: 'http://127.0.0.1:5000/api',
    // baseURL: 'https://192.168.1.47:8443/api'
    baseURL: 'https://employeeportal.encryptionconsulting.com/api'
    // baseURL: window.location.protocol+ '//' +window.location.host+'/api'
})

export const CryptoJSsecretKey ="EE6FB85D7AFDA465D3951177E9DAC";