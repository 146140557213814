import { FormLabel, Grid, InputLabel, Stack, TextField, Typography } from '@mui/material'
import { FaStopwatch } from "react-icons/fa";

export const getHours = (val)=>{
    return Math.floor(val);
}

export const getMinutes = (val)=>{
    return (val-Math.floor(val))*60
}

export const TimeSheetHours = ({boxStyle,billButtonStyle,billable,nonbillable}) => {
    return (
        <Grid item>
            <Grid container gap={2} direction="row" alignItems="center" sx={{...boxStyle}} px={2} py={1.3}>
                <Stack direction="row" alignItems="center" gap={0.5}>
                    <FaStopwatch size="1.5rem" color='#D46D23' />
                    <Typography variant='subtitle2' sx={{ fontWeight: '600' }}>Total Hours:</Typography>
                </Stack>

                <Stack direction="row" gap={2}>
                    <FormLabel>
                    {/* <InputLabel>sds</InputLabel> */}
                    <TextField inputProps={{ spellCheck: 'false' }} label="Billable" id="outlined-basic" value={getHours(billable)+" hrs - " +getMinutes(billable)+" min"} variant="outlined" size="small" sx={{ width: '9rem', textAlign:"center", background: 'divider', ...billButtonStyle}} />
                    </FormLabel>
                    <TextField inputProps={{ spellCheck: 'false' }} label="Non-Billable" id="outlined-basic" value={getHours(nonbillable)+" hrs - " +getMinutes(nonbillable)+" min"} variant="outlined" size="small" sx={{ width: '9rem', ...billButtonStyle }} />
                    {/* <TextField inputProps={{ spellCheck: 'false' }} label="Non-Billable" id="outlined-basic" value={nonbillable+" hrs"} variant="outlined" size="small" sx={{ width: '11rem', ...billButtonStyle }} /> */}
                </Stack>
            </Grid>
        </Grid>
    )
}