import { useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Pagination, Stack, Tab, Typography } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
// import { Button } from 'rsuite';
import { LoadingButton, TabContext, TabList, TabPanel } from '@mui/lab';
import axios from '../../../api/axios';
import { Form } from '../Form';
import Notify from '../../../features/helpers/notifyConfig';
import { Cancel, Delete } from '@mui/icons-material';
import { SkeletonLoad, useStyles } from './MainTable';
import { DataGrid } from '@mui/x-data-grid';
import { containRoles, isMarketingLead } from '../../auth/userAuth';

function createData(id, customer, project_type, channel, reseller, ec_poc, dateSold, quarter, year, stage, prjct_size, type, delinquent_terms, invoice_issue_date, po_order, payment_status, payment_terms, notes_on_follow,contact_address) {
    return { id, customer, project_type, channel, reseller, ec_poc, dateSold, quarter, year, stage, prjct_size, type, delinquent_terms, invoice_issue_date, po_order, payment_status, payment_terms, notes_on_follow,contact_address };
}

export function NewSaleTable({ table_data, setotherAction }) {
    const classes = useStyles();
    const requested_year = useParams().year;
    const [open, setOpen] = useState(false);
    const [openPO, setOpenPO] = useState(false);
    const [po_data, show_po] = useState();
    const [addForm, setAddForm] = useState(false);
    // const [sale_data, setsale_data] = useState();
    const [value, setValue] = useState('1');
    const [addData, setaddData] = useState();
    const [data, setData] = useState({
        _id: "", Type: "Sale", Customer: "", TypeOfProject: "", Channel: "", Reseller: "", EC_PointOfContact: "", DateSold: "",
        Quarter: Math.ceil((new Date().getMonth() + 1) / 3), Year: new Date().getFullYear(), Stage: "", ProjectSize: "", InvoiceIssueDate: "",
        PaymentTerms: 0, DelinquentTerms: "", PaymentStatus: "", PO_Order: "", NotesOnFollow: "", contact_address: ""
    });
    const [loading, setLoading] = useState(null);

    const rows = Object.keys(table_data).map((key, val) => ({
        id: val,
        ...table_data[key],
    }));

    //PAGINATION 
    const pageSize = 9;
    const [page, setPage] = useState(0);
    const pageCount = Math.ceil(rows.length / pageSize);
    const rowsOnPage = rows.slice(page * pageSize, (page + 1) * pageSize);

    const columns = [
        {
            field: 'Customer', headerName: 'Customer',
            minWidth: 200,
            flex: 1,
            headerClassName: 'whiteMenuIcon sticky-header',
            // headerClassName: 'sticky-header', 
            cellClassName: 'first-sticky-cell',
            // sticky: 'left',
            renderCell: (params) =>
            params.row.Customer == "" ? "-" : <Link style={{ textDecoration: "none", fontWeight: "600" }} onClick={() => viewCustomer(params.row)}>{params.row.Customer}</Link>
        },

        { 
            field: 'TypeOfProject',
            headerName: 'Type of Project',
            cellClassName: 'sticky-cell',
            minWidth: 200,
            flex: 1,
            headerClassName: 'whiteMenuIcon',
        },
        {
            field: 'Channel',
            headerName: 'Referral Channel',
            minWidth: 150,
            flex: 1,
            align: 'center',
            headerClassName: 'whiteMenuIcon',
        },
        {
            field: 'Reseller',
            headerName: 'Reseller',
            minWidth: 150,
            align: 'center',
            flex: 1,
            align: 'center',
            headerClassName: 'centerStyle',

        },
        {
            field: 'EC_PointOfContact',
            headerName: 'EC-Point of contact',
            minWidth: 200,
            align: 'center',
            flex: 1,
            filterable: false,
            headerClassName: 'centerStyle',

        },
        {
            field: 'DateSold',
            headerName: 'Date Sold',
            minWidth: 150,
            align: 'center',
            flex: 1,
            headerClassName: 'centerStyle',

        },
        {
            field: 'Quarter',
            headerName: 'Quarter',
            minWidth: 120,
            flex: 1,
            align: 'center',
            headerClassName: 'centerStyle',
        },
        {
            field: 'Year',
            headerName: 'Year',
            minWidth: 120,
            align: 'center',
            flex: 1,
            headerClassName: 'centerStyle',
        },
        {
            field: 'Stage',
            headerName: 'Stage',
            minWidth: 120,
            align: 'center',
            flex: 1,
            headerClassName: 'centerStyle',
        },
        {
            field: 'ProjectSize',
            headerName: 'Project Size',
            minWidth: 120,
            flex: 1,
            align: 'center',
            headerClassName: 'whiteMenuIcon',
        },
        {
            field: 'DelinquentTerms',
            headerName: ' Delinquent Terms',
            minWidth: 160,
            align: 'center',
            flex: 1,
            filterable: false,
            headerClassName: 'whiteMenuIcon',
        },
        {
            field: 'InvoiceIssueDate',
            headerName: 'Invoice Issue Date',
            minWidth: 160,
            align: 'center',
            flex: 1,
            headerClassName: 'whiteMenuIcon',
        },
        {
            field: 'PO_Order',
            headerName: 'PO Order',
            minWidth: 150,
            flex: 1,
            disableColumnMenu: true,
            sortable: false,
            headerClassName: 'centerStyle',
            align: 'center',
            renderCell: (params) =>
            !params.row.PO_Order ? "-" : <LoadingButton loading={loading==params.row._id} size='small' variant='contained' onClick={() => viewPO(params.row._id)}>Download</LoadingButton>
        },
        {
            field: 'PaymentStatus',
            headerName: 'Payment Status',
            minWidth: 150,
            align: 'center',
            flex: 1,
            headerClassName: 'whiteMenuIcon',
        },
        {
            field: 'PaymentTerms',
            headerName: 'Payment Terms',
            minWidth: 150,
            align: 'center',
            flex: 1,
            filterable: false,
            headerClassName: 'whiteMenuIcon',
        },
        {
            field: 'contact_address',
            headerName: 'Company Address',
            cellClassName: 'sticky-cell',
            minWidth: 300,
            sortable: false,
            flex: 1,
            headerClassName: 'whiteMenuIcon',
            renderCell: (params) =>
                params.row.contact_address == "" ? <Typography width='100%' textAlign='center'>-</Typography> : <Typography fontWeight={'600'}>{params.row.contact_address}</Typography> 
        },
        // {
        //     field: 'NotesOnFollow',
        //     headerName: 'Notes on Follow',
        //     cellClassName: 'sticky-cell',
        //     minWidth: 180,
        //     sortable: false,
        //     flex: 1,
        //     headerClassName: 'whiteMenuIcon'
        // },
    ];


    const handleClose = () => {
        setOpen(false);
        setOpenPO(false);
    };

    const viewPO = async(id) => {
        setLoading(id);
        await axios.post(`/dashboard/salespipeline/${requested_year}/sale/po`,
            JSON.stringify({ Sale: {
                "_id": id
            } }),
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json',
                    withCredentials: true,
                    mode: 'no-cors',
                    'Authorization': `Bearer ` + localStorage.getItem('access')
                }
            }).then((response) => {
                const src = response.data.po_order;
                const linkSource = `${src}`;
                const downloadLink = document.createElement("a");
                const randomNumber = Math.floor(10000 + Math.random() * 90000).toString();
                downloadLink.href = linkSource;
                downloadLink.download = "Sale" + randomNumber;
                downloadLink.click();
                setLoading(null);
            }).catch((error) => {
                // console.log(error.response);
                Notify.error("Some Error Occurred. Try Again!");
                setLoading(null);
            })
    }

    // Delete Sale record from pipeline
    const deleteRecord = (id) => {
        setotherAction(true);
        const deleteSale = async () => {
            await axios.delete(`/dashboard/salespipeline/${requested_year}/sale/delete`,
                {
                    headers: {
                        'Authorization': `Bearer ` + localStorage.getItem('access')
                    },
                    data: {
                        "Sale": {
                            "_id": id
                        }
                    }
                }).then((response) => {
                    Notify.success('Record Deleted Successfully!');
                    setotherAction(false);
                    handleClose();
                }).catch((error) => {
                    Notify.error("Record Can't be Deleted!")
                })
        }
        deleteSale();
    }

    const viewCustomer = ({ _id, Customer, TypeOfProject, Channel, Reseller, EC_PointOfContact, DateSold, Quarter, Year, Stage, ProjectSize, Type, DelinquentTerms, InvoiceIssueDate, PO_Order, PaymentStatus, PaymentTerms, NotesOnFollow, contact_address }) => {
        setData((prev) => ({
            ...prev, _id: _id, Customer: Customer, TypeOfProject: TypeOfProject, Channel: Channel, Reseller: Reseller, EC_PointOfContact: EC_PointOfContact, DateSold: DateSold,
            Stage: Stage, ProjectSize: ProjectSize, InvoiceIssueDate: InvoiceIssueDate, PO_Order: PO_Order,
            PaymentTerms: PaymentTerms, DelinquentTerms: DelinquentTerms, PaymentStatus: PaymentStatus, NotesOnFollow: NotesOnFollow, contact_address: ""
        }));
        setOpen(true);
    }

    const submitAddForm = () => {
        let hasWarning = false;
        if (data.Customer === "") {
            Notify.warning("Customer field cannot be empty!");
            hasWarning = true;
        }
        else if (data.TypeOfProject === "") {
            Notify.warning("Project Type field cannot be empty!");
            hasWarning = true;
        }
        else if (data.Channel === "") {
            Notify.warning("Channel field cannot be empty!");
            hasWarning = true;
        }
        else if (data.Reseller === "") {
            Notify.warning("Reseller Type field cannot be empty!");
            hasWarning = true;
        }
        else if (data.EC_PointOfContact === "") {
            Notify.warning("Choose Point of Contact!");
            hasWarning = true;
        }
        else if (data.PO_Order === "") {
            Notify.warning("PO Order must be uploaded!");
            hasWarning = true;
        }

        if (hasWarning) return;

        setAddForm(true);
        setotherAction(true);
    }

    //1. Get Add Sale info of given year (for dropdowns)
    const getAddSalespipelineData = async () => {
        await axios.get(`/dashboard/salespipeline/${requested_year}/sale/add`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ` + localStorage.getItem('access')
                }
            }).then((response) => {
                setaddData(response.data)
            }).catch((error) => {
                console.log(error.response)
            })
    }

    //2. Edit Sale Pipeline info
    const editSalesDetails = async () => {
        await axios.put(`/dashboard/salespipeline/${requested_year}/sale/edit`,
            JSON.stringify({ Sale: data }),
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json',
                    withCredentials: true,
                    mode: 'no-cors',
                    'Authorization': `Bearer ` + localStorage.getItem('access')
                }
            }).then((response) => {
                console.log(response);
                setAddForm(false);
                setotherAction(false);
                Notify.success("Record Edited Successfully!")
                handleClose();
            }).catch((error) => {
                console.log(error.response);
                Notify.error("Record Can't Be Edited. Try Again!")
                setAddForm(false);
            })
    }

    useEffect(() => {
        if (addForm) {
            editSalesDetails();
        }
    }, [addForm])


    useEffect(() => {
        if (open) {
            getAddSalespipelineData();
        }
    }, [open])

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden'}} elevation={2}>
            <DataGrid
                rows={rowsOnPage}
                columns={columns}
                pageSize={pageSize}
                disableColumnSelector
                hideFooter
                showCellVerticalBorder
                disableSelectionOnClick
                rowHeight={65}
                style={{ backgroundColor: 'white' }}
                sx={{
                    '& .MuiDataGrid-columnHeaders': {
                        backgroundColor: '#657EBF'
                    },
                    '& .MuiDataGrid-cell': {
                        fontSize: '15px',
                        borderColor: 'divider',
                    },
                    '& .MuiDataGrid-columnHeaderTitle': {
                        fontWeight: 'bold',
                        fontSize: '16px'
                    },
                    '& .MuiDataGrid-columnSeparator': {
                        display: 'none'
                    },
                    '& .MuiInputBase-input': {
                        height: '20px !important'
                    },
                    '& .MuiDataGrid-cell:focus, & .MuiDataGrid-columnHeader:focus': {
                        outline: 'none',
                        boxShadow: 'none',
                        border: 'none'
                    },
                }}
            />
            <Stack borderRadius='10px' bgcolor='white' mt={0.2} py={1.5} justifyContent='center' direction='row' width='100%'>
                <Pagination
                    count={pageCount}
                    page={page + 1}
                    onChange={(event, value) => setPage(value - 1)} // Adjust for 0-based index
                    shape="rounded"
                />
            </Stack>

            {/* EDIT - DELETE */}
            {data ? <Dialog
                sx={{ height: "100vh" }}
                maxWidth="lg"
                fullWidth={true}
                open={open}
                onClose={handleClose}
                aria-labelledby="edit-apartment"
            >
                <DialogContent style={{ height: '100vh' }}>
                    <DialogTitle id="edit-apartment">{addData ? data['Customer'] : ""}</DialogTitle>

                    <TabContext value={'1'}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList aria-label="lab API tabs example">
                                <Tab label="Edit Record" value="1" />
                            </TabList>
                        </Box>
                        <TabPanel value="1">
                            {addData ? <Form type='sale' prop={addData.message} data={data} setData={setData} /> : <SkeletonLoad />}
                        </TabPanel>
                    </TabContext>

                </DialogContent>
                <DialogActions sx={{ backgroundColor: "#EEEDEB" }}>
                    <Button sx={{ position: "absolute", top: 10, right: 20 }} onClick={handleClose} color="secondary">
                        <Cancel sx={{ fontSize: "1.8rem" }} />
                    </Button>
                    {
                    containRoles(["SUPERADMIN_PERMISSION", "MARKETING_LEAD_PERMISSION"],
                    <IconButton onClick={() => deleteRecord(data['_id'])}>
                        <Delete sx={{ color: "red" }}></Delete>
                    </IconButton>,<></>)
                    }
                    <Button variant='contained' onClick={submitAddForm}>
                        Submit
                    </Button>
                </DialogActions>
            </Dialog> : <></>}
        </Paper>
    );
}