import { Box, FormControl, InputLabel, MenuItem, Select, Typography, Button, styled, Stack, Grid, TextField, Autocomplete, Skeleton, IconButton } from '@mui/material'
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { DownloadIcon } from '../../components/Icons/DownloadIcon';
import { useEffect, useState } from 'react';
import axios, { CryptoJSsecretKey } from '../../api/axios';
import Notify from '../../features/helpers/notifyConfig';
import { LoadingButton } from '@mui/lab';
import EditNoteIcon from '@mui/icons-material/EditNote';
import CryptoJS from 'crypto-js';
import { currentUser } from '../../Router';

const inputStyle = {
    '& .MuiInputBase-input': {
        height: '25px !important',
        backgroundColor: "white",
        overflow: 'hidden',
        borderRadius: '5px'
    }
}

const defaultBankDetails = {
    "Bank_Details": {
        "Bank_Ac": "",
        "Bank_Name": "",
        "Ifsc_Code": ""
    }
}

export const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

export const EmployeeDocument = () => {

    const [doctype, setdocType] = useState("");
    const [file, setFile] = useState();
    const [data, setData] = useState();
    const [uploaded, setuploaded] = useState(false);
    const [filename, setFilename] = useState("");
    const [loading, setLoading] = useState(false);
    const [bankType, setBankType] = useState("Loading");
    const [bankLoad, setBankLoad] = useState(false);
    const [bankData, setBankData] = useState({
        "Bank_Ac": "",
        "Bank_Name": "",
        "Ifsc_Code": ""
    });
    const [editBank, setEditBank] = useState(false);
    const {usageLocation} = currentUser();

    const isSave = (a, b) => {
        if (bankType == "Save" && editBank == false) return a;
        return b;
    }

    const AllDocs = ['Aadhar (Front)','Aadhar (Back)','PAN (Front)','PAN (Back)'];
    const documents = {'Aadhar (Front)':'aadhar_front','Aadhar (Back)':'aadhar_back','PAN (Front)':'pan_front','PAN (Back)':'pan_back'};
    const showDocuments = {'aadhar_front':'Aadhar (Front)','aadhar_back':'Aadhar (Back)','pan_front':'PAN (Front)','pan_back':'PAN (Back)'};

    const docChange = (e, val) => {
        const vaal = documents[val];
        setdocType(vaal);
    }

    const triggerBankBtn = () => {
        setBankLoad(true);
    }

    const decryptData = (encryptedData) => {
        const decryptedData = {};
        for (const prop in encryptedData) {
            const bytes = CryptoJS.AES.decrypt(encryptedData[prop], CryptoJSsecretKey);
            decryptedData[prop] = bytes.toString(CryptoJS.enc.Utf8);
        }
        return decryptedData;
    }

    const bankChange = (e) => {
        const ied = e.target.id;
        const vaal = e.target.value;
        setBankData(prev => ({
            ...prev, [ied]: vaal
        }))
    }

    const handleChange = (e) => {
        const file = e.target.files[0];
        console.log(file);
        var fileExt = file.name.split('.').pop().toLowerCase();
        if (fileExt != "jpg" && fileExt != "png" && fileExt != "jpeg" && fileExt != "svg") {
            setFile();
            setFilename();
            Notify.error("Currently only Image Formats are allowed!")
        }
        else {
            if (file.size < 15000000) {
                setFile(file);
                setFilename(file.name);
                console.log(file);
            }
            else {
                setFile();
                setFilename();
                Notify.error("File Should not be greater than 15MB")
            }
        }
    }

    function submitDoc(e) {
        e.preventDefault();
        setLoading(true);
        const formData = new FormData();
        formData.append(doctype, file);
        const config = {
            headers: {
                'Content-type': 'multipart/form-data',
                'Authorization': `Bearer ` + localStorage.getItem('access'),
                'Access-Control-Allow-Origin': '*',
                withCredentials: true,
                mode: 'no-cors',
            },
        };
        axios.post("/dashboard/employeedata/documents", formData, config)
            .then((response) => {
                console.log(response.data);
                setuploaded(prev => !prev);
                setLoading(false);
                Notify.success("Document Uploaded Successfully!")
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
                Notify.error("Document Can't Uploaded. Try Again!")
            });
    }

    function downloadDoc(e) {
        e.preventDefault();
        const target = e.target.id;
        axios.get(`/dashboard/employeedata/documents/${target}`,
            {
                responseType: 'blob',
                headers: {
                    'Content-Type': 'blob',
                    'Authorization': `Bearer ` + localStorage.getItem('access'),
                }
            }).then((response) => {
                const href = URL.createObjectURL(response.data);
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', `${target}.png`);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(href);
            }).catch((error) => {
                console.log(error.response)
            })
    }

    const addBankDetails = async () => {
        const encryptedBankDetails = {
            "Bank_Details": {
                "Bank_Ac": CryptoJS.AES.encrypt(bankData.Bank_Ac, CryptoJSsecretKey).toString(),
                "Bank_Name": CryptoJS.AES.encrypt(bankData.Bank_Name, CryptoJSsecretKey).toString(),
                "Ifsc_Code": CryptoJS.AES.encrypt(bankData.Ifsc_Code, CryptoJSsecretKey).toString()
            }
        }

        await axios.post("/dashboard/employeedata/bankdetails",
            JSON.stringify(encryptedBankDetails),
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, PUT, DELETE',
                    'Access-Control-Allow-Headers': 'Content-Type, Origin, Authorization',
                    'Content-Type': 'application/json',
                    withCredentials: true,
                    mode: 'no-cors',
                    'Authorization': `Bearer ` + localStorage.getItem('access'),
                }
            }).then((response) => {
                console.log(response);
                setBankType("Save");
                setEditBank(false);
                Notify.success("Bank Details Addded Successfully!");
                setBankLoad(false);
            }).catch((error) => {
                console.log(error.response);
                Notify.success("Some Error Occurred. Try Again!");
                setBankLoad(false);
            })
    }

    useEffect(() => {
        if (bankLoad)
            addBankDetails();
    }, [bankLoad])

    useEffect(() => {
        const uploadedDocs = async () => {
            await axios.get("/dashboard/employeedata/documents",
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ` + localStorage.getItem('access')
                    }
                }).then((response) => {
                    setBankData(decryptData(response?.data?.Bank_Details));
                    setData(response?.data?.message);
                    if (Object.keys(response.data.Bank_Details).length === 0 ||
                        (JSON.stringify(response.data.Bank_Details) == JSON.stringify(defaultBankDetails.Bank_Details))) setBankType("Submit")
                    else setBankType("Save")
                }).catch((error) => {
                    console.log(error.response)
                })
        }

        uploadedDocs();
    }, [uploaded])

    return (
        <>
            {usageLocation=="IN"?<Box p={3}>
                <Box>
                    <Typography variant='h6' fontWeight="600">Documents</Typography>
                    <Grid container gap={2} my={2}>
                        <Autocomplete
                            id="Customer"
                            // freeSolo
                            options={AllDocs}
                            sx={{ width: "14rem" }}
                            onInputChange={docChange}
                            renderInput={(params) => <TextField {...params} label="Select Type of Document" />}
                        />

                        <Button
                            component="label"
                            sx={{ borderStyle: "dashed", borderRadius: "5px", borderWidth: "1px" }}
                            tabIndex={-1}>
                            <CloudUploadIcon />
                            &nbsp;&nbsp;Upload your document here
                            <VisuallyHiddenInput onChange={handleChange} type="file" />
                        </Button>

                    </Grid>
                </Box>

                <Stack gap={2} direction="row" alignItems="center">
                    <LoadingButton loading={loading} onClick={submitDoc} disabled={(doctype != "" && file) ? false : true} variant='contained'>Upload Document</LoadingButton>
                    <Typography color="red">{filename}</Typography>
                </Stack>

            </Box>:<></>}

            <Box bgcolor="#EEEEEE">
                {bankData ? <Box p={3}>
                    <Typography variant='h6' fontWeight="600">Bank Details
                        {bankType=="Save"?<span style={{marginLeft: '5px'}}>
                            <IconButton sx={{padding: '1px'}} onClick={() => setEditBank(prev => !prev)}>
                                <EditNoteIcon color='info' sx={{ fontSize: '20px' }} /><span style={{fontSize: '10px', color: '#0288D1', fontWeight: '600'}}>Edit</span>
                            </IconButton>
                        </span>:<></>}
                    </Typography>
                    <Stack pt={2} direction='row' columnGap={3}>
                        <Box>
                            <Typography variant='subtitle2'>Bank Name</Typography>
                            <TextField id='Bank_Name' disabled={isSave(true, false)} value={bankData['Bank_Name'] ? bankData['Bank_Name'] : ""} sx={{ minWidth: 200, ...inputStyle }} size='small' variant="outlined" onChange={bankChange} />
                        </Box>

                        <Box>
                            <Typography variant='subtitle2'>Bank Account Number</Typography>
                            <TextField id='Bank_Ac' disabled={isSave(true, false)} value={bankData['Bank_Ac'] ? bankData['Bank_Ac'] : ""} sx={{ minWidth: 150, ...inputStyle }} size='small' variant="outlined" onChange={bankChange} />
                        </Box>

                        <Box>
                            <Typography variant='subtitle2'>IFSC Code / Routing No.</Typography>
                            <TextField id='Ifsc_Code' disabled={isSave(true, false)} value={bankData['Ifsc_Code'] ? bankData['Ifsc_Code'] : ""} sx={{ minWidth: 150, ...inputStyle }} size='small' variant="outlined" onChange={bankChange} />
                        </Box>
                    </Stack>

                    <Box pt={2}>
                        <LoadingButton disabled={isSave(true, false)} loading={bankLoad} onClick={triggerBankBtn} variant='contained' size='small'>{bankType}</LoadingButton>
                    </Box>
                </Box> : <></>}

                <hr color='#e4e4e4' />

                <Box p={3}>
                    <Typography variant='h6' fontWeight="600">Uploaded Document</Typography>
                    <Grid container gap={2} my={1}>
                        {(data && data!="No File Data") ? Object.keys(data).map((key) =>
                            <Button size='small' onClick={downloadDoc} id={key} key={key} color='info' variant='contained'>{showDocuments[key]?showDocuments[key]:key}&nbsp;&nbsp;<DownloadIcon width={22} /></Button>
                        ) :
                            <>
                                <Typography variant='subtitle2'>No Data Found!</Typography>
                            </>
                        }
                    </Grid>
                </Box>
            </Box>
        </>
    )
}
