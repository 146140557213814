import { useEffect, useState } from 'react';
import { Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, Menu, MenuItem, Modal, Pagination, Skeleton, Stack, Tab, Tooltip, Typography } from '@mui/material';
import UserIcon from '@mui/material/Avatar';
import { DataGrid } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';
import { Cancel } from '@mui/icons-material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Excel } from '../../../components/Icons/Excel';
import axios from '../../../api/axios';
import Notify from '../../../features/helpers/notifyConfig';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';

export const SkeletonLoad = () => {
    return (
        <Stack gap={2} width="100%">
            <Skeleton animation="wave" variant="rectangular" width="100%" height={80} />
            <Skeleton animation="wave" variant="rectangular" width="100%" height={80} />
        </Stack>
    )
}

const versionColor = (val) => {
    if(val==1) return "green";
    return "orange";
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const userDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: 'numeric', month: 'short', year: 'numeric' };
    return (date.toLocaleDateString('en-US', options));
}

export function FormikQuoteTable({ table_data, setLoading, setEditAccount }) {
    const [viewData, setviewData] = useState();
    const [openViewBox, setViewOpen] = useState(false);
    const [anchorElMap, setAnchorElMap] = useState({});
    const [confirm, isConfirm] = useState("");

    const handleClick = (event, id) => {
        setAnchorElMap(prevMap => ({
            ...prevMap,
            [id]: event.currentTarget,
        }));
    };

    const viewQuote = (val) => {
        setviewData(val);
        setViewOpen(true);
    }

    const handleClose = () => {
        setViewOpen(false);
        isConfirm("");
        setAnchorElMap({});
    };

    const openEdit = (val) => {
        setAnchorElMap({});
        setEditAccount(
            {
                "_id": val.id,
                "company_id": val.company_id,
                "company_name": val.company_name,
                "quote_date": new Date(val.quote_date).toISOString().split('T')[0],
                "quote_expiration_date": new Date(val.quote_expiration_date).toISOString().split('T')[0],
                "total_additional_charges": val.total_additional_charges,
                "products": val.products,
                "discount": val.discount,
                "create_new_version": false
            }
        )
    }

    const openDelete = (delData) => {
        handleClose();
        isConfirm(delData);
    };

    const rows = Object.keys(table_data).map((key, val) => ({
        id: key,
        ...table_data[key],
    }));

    const downloadExcel = (id) => {
        // 1. Get Excel file
        const getExcel = async () => {
            await axios.get(`/quote-generation/quote/create-excel/${id}`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ` + localStorage.getItem('access'),
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Expose-Headers': 'Content-Disposition',
                        mode: 'no-cors'
                    },
                    responseType: 'arraybuffer'
                }
            ).then((response) => {
                var fileName = response.headers['content-disposition'].split('filename=')[1].split(';')[0];
                fileName= fileName.replace(/"/g, '');
                console.log(fileName);                
                const binaryData = response.data;
                const blob = new Blob([binaryData], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
                const url = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);
                link.style.display = 'none';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(url);
                handleClose();
            }).catch((error) => {
                console.log(error.response)
            });
        }
        getExcel();
    }

    const pageSize = 10;
    const [page, setPage] = useState(0);
    const pageCount = Math.ceil(rows.length / pageSize);
    const rowsOnPage = rows.slice(page * pageSize, (page + 1) * pageSize);

    const columns = [
        {
            field: 'quote_number', headerName: 'Quote No.', flex: 1, headerClassName: 'centerStyle', align: 'center', renderCell: (params) =>
                <Link style={{ color: "#1B2C58", textDecoration: "none", fontWeight: '600' }} onClick={() => viewQuote(params.row)}>{params.row.quote_number} ~v{params.row.version}</Link>
        },
        // {
        //     field: 'version', headerName: 'Version', flex: 1, headerClassName: 'centerStyle', renderCell: (params) =>
        //         <Typography width='100%' textAlign='center'>{params.row.version}</Typography>
        // },
        { field: 'company_name', headerName: 'Company', flex: 1, headerClassName: 'whiteMenuIcon' },
        { field: 'contact_name', headerName: 'Contact Name', flex: 1, headerClassName: 'whiteMenuIcon' },
        {
            field: 'products', headerName: 'Products', flex: 1, headerClassName: 'whiteMenuIcon', renderCell: (params) =>
                <Box>
                    {
                        params.row.products.map((val) => {
                            return <Typography p={0.5} bgcolor="#dfdfdf94" borderRadius="5px" border="solid 1px lightgray" my={0.6}>{val.product_name}</Typography>
                        })
                    }
                </Box>
        },
        {
            field: 'quote_expiration_date', headerName: 'Expiration Date', flex: 1, headerClassName: 'whiteMenuIcon', renderCell: (params) =>
                <Typography>{new Date(params.row.quote_expiration_date).toDateString().replace(" ", ", ")}</Typography>
        },
        {
            field: 'Action', headerName: 'Action', flex: 1, headerClassName: 'whiteMenuIcon', renderCell: (params) => {
                return (<Box>
                    <IconButton
                        id={`basic-button-${params.id}`}
                        aria-controls={anchorElMap[params.id] ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={anchorElMap[params.id] ? 'true' : undefined}
                        onClick={(event) => handleClick(event, params.id)}
                    >
                        <MoreVertIcon />
                    </IconButton>
                    <Menu
                        id={`basic-menu-${params.id}`}
                        anchorEl={anchorElMap[params.id]}
                        open={Boolean(anchorElMap[params.id])}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                        PaperProps={{
                            style: {
                                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                            },
                        }}
                    >
                        <MenuItem onClick={() => openEdit(params.row)}>Edit</MenuItem>
                        <MenuItem onClick={() => downloadExcel(params.row.id)}>Download</MenuItem>
                        <MenuItem onClick={() => openDelete(params.row)}>Delete</MenuItem>
                    </Menu>
                </Box>)
            }
        },
    ];

    //Delete Company  
    const deleteRecord = (id) => {
        isConfirm("");
        const deleteCompany = async () => {
            await axios.delete(`/quote-generation/quote/delete`,
                {
                    headers: {
                        'Authorization': `Bearer ` + localStorage.getItem('access')
                    },
                    data: {
                        "_id": id
                    }
                }).then((response) => {
                    Notify.success('Quote Deleted Successfully!');
                    setLoading(prev => !prev);
                    handleClose();
                }).catch((error) => {
                    Notify.error("Quote Deletion Error");
                })
        }
        deleteCompany();
    }

    return (
        <>
            <DataGrid
                rows={rowsOnPage}
                columns={columns}
                // pageSize={pageSize}
                disableColumnSelector
                hideFooter
                // showCellVerticalBorder
                disableSelectionOnClick
                getRowHeight={() => 'auto'}
                style={{ backgroundColor: 'white' }}
                sx={{
                    '& .MuiDataGrid-columnHeaders': {
                        backgroundColor: '#657EBF'
                    },
                    '& .MuiDataGrid-cell': {
                        fontSize: '15px',
                        paddingY: '5px',
                        overflow: 'hidden',
                    },
                    '& .MuiDataGrid-columnHeaderTitle': {
                        fontWeight: 'bold',
                        fontSize: '16px'
                    },
                    '& .MuiDataGrid-columnSeparator': {
                        display: 'none'
                    },
                    '& .MuiInputBase-input': {
                        height: '20px !important'
                    },
                    '& .MuiDataGrid-cell:focus, & .MuiDataGrid-columnHeader:focus': {
                        outline: 'none',
                        boxShadow: 'none',
                        border: 'none'
                    },
                }}
            />
            <Stack borderRadius='10px' bgcolor='white' mt={0.2} py={1.5} justifyContent='center' direction='row' width='100%'>
                <Pagination
                    count={pageCount}
                    page={page + 1}
                    onChange={(event, value) => setPage(value - 1)} // Adjust for 0-based index
                    shape="rounded"
                />
            </Stack>

            {/* Show Quote Data in a Dialog Box */}
            {
                viewData ? <Dialog
                    maxWidth="sm"
                    fullWidth={true}
                    open={openViewBox}
                    onClose={handleClose}
                    aria-labelledby="edit-apartment"
                >
                    <DialogTitle sx={{ color: 'primary.main' }}>{viewData.quote_number}</DialogTitle>
                    <DialogContent sx={{ paddingBottom: '2rem' }}>

                        <Grid container md={12} mt={2}>
                            <Grid item>
                                <Tooltip title="Download this Quote" placement="right-start">
                                    <Button onClick={() => downloadExcel(viewData.id)}><Excel width={35} /></Button>
                                </Tooltip>
                            </Grid>
                            <Grid item></Grid>
                        </Grid>

                        <Grid container md={12} mt={2}>
                            <Grid item md={5}><Typography fontWeight='600' variant='subtitle1'>Quote Version</Typography></Grid>
                            <Grid item md={7}><Typography fontWeight={600} color={versionColor(viewData.version)} variant='subtitle1'>{viewData.version ? "v"+viewData.version :'-'}</Typography></Grid>
                        </Grid>

                        <Grid container md={12} mt={2}>
                            <Grid item md={5}><Typography fontWeight='600' variant='subtitle1'>Company Name</Typography></Grid>
                            <Grid item md={7}><Typography variant='subtitle1'>{viewData.company_name}</Typography></Grid>
                        </Grid>

                        <Grid container md={12} mt={2}>
                            <Grid item md={5}><Typography fontWeight='600' variant='subtitle1'>Contact Name</Typography></Grid>
                            <Grid item md={7}><Typography variant='subtitle1'>{viewData.contact_name}</Typography></Grid>
                        </Grid>

                        <Grid container md={12} mt={2}>
                            <Grid item md={5}><Typography fontWeight='600' variant='subtitle1'>Company Email</Typography></Grid>
                            <Grid item md={7}><Typography variant='subtitle1'>{viewData.contact_email}</Typography></Grid>
                        </Grid>

                        <Grid container md={12} mt={2}>
                            <Grid item md={5}><Typography fontWeight='600' variant='subtitle1'>Contact Address</Typography></Grid>
                            <Grid item md={7}><Typography variant='subtitle1'>{viewData.contact_address}</Typography></Grid>
                        </Grid>

                        <Grid container md={12} mt={2}>
                            <Grid item md={5}><Typography fontWeight='600' variant='subtitle1'>Quote Date</Typography></Grid>
                            <Grid item md={7}><Typography variant='subtitle1'>{viewData.quote_date}</Typography></Grid>
                        </Grid>

                        <Grid container md={12} mt={2}>
                            <Grid item md={5}><Typography fontWeight='600' variant='subtitle1'>Quote Expiration Date</Typography></Grid>
                            <Grid item md={7}><Typography variant='subtitle1'>{viewData.quote_expiration_date}</Typography></Grid>
                        </Grid>

                        <Grid container md={12} mt={2}>
                            <Grid item md={5}><Typography fontWeight='600' variant='subtitle1'>Total Additional Charges</Typography></Grid>
                            <Grid item md={7}><Typography variant='subtitle1'>{viewData.total_additional_charges}</Typography></Grid>
                        </Grid>

                        <Grid container md={12} mt={2}>
                            <Grid item md={5}><Typography fontWeight='600' variant='subtitle1'>Discount</Typography></Grid>
                            <Grid item md={7}><Typography variant='subtitle1'>{viewData.discount}</Typography></Grid>
                        </Grid>

                        <Grid container md={12} mt={2}>
                            <Grid item md={5}><Typography fontWeight='600' variant='subtitle1'>Products <Typography variant='caption' fontWeight='600' color="blue">{"("+viewData.products.length+")"}</Typography></Typography></Grid>
                            <Grid item md={7}>
                                <SimpleTreeView>
                                    {viewData.products.map((val, key) =>
                                        <TreeItem itemId={`grid-${key}`} label={val.product_name} sx={{marginBottom:'10px', border:'dashed 1px gray', borderRadius:'10px'}}>
                                            <TreeItem itemId={`grid-product_code-${key}`} label={<span><strong>Code: </strong>{val.product_code ?? '-'}</span>} />
                                            <TreeItem itemId={`grid-qty-${key}`} label={<span><strong>Quantity: </strong>{val.qty ?? '-'}</span>} />
                                            <TreeItem itemId={`grid-product_price-${key}`} label={<span><strong>Price: </strong>{val.product_price ?? '-'}</span>} />
                                            <TreeItem itemId={`grid-net_total-${key}`} label={<span><strong>Net Total: </strong>{val.net_total ?? '-'}</span>} />
                                            <TreeItem itemId={`grid-license_type-${key}`} label={<span><strong>License: </strong>{val.license_type ?? '-'}</span>} />
                                            <TreeItem itemId={`grid-duration-${key}`} label={<span><strong>Duration: </strong>{val.duration ?? '-'}</span>} />
                                        </TreeItem>
                                    )}
                                </SimpleTreeView>
                            </Grid>
                        </Grid>

                        <Button sx={{ position: "absolute", top: 10, right: 0 }} onClick={handleClose} color="secondary">
                            <Cancel sx={{ fontSize: "1.8rem" }} />
                        </Button>
                    </DialogContent>
                </Dialog>
                    : <></>}


            {/* Delete dialog box */}
            {confirm != "" ?
                <Dialog maxWidth='xs' onClose={handleClose} open={confirm != "" ? true : false}>
                    <DialogContent>
                        <Typography mb={4} variant='h6'>Are you sure you want to delete <span style={{ color: 'red' }}>{confirm.company_name}</span> ? </Typography>
                        <Button variant='contained' color='error' onClick={() => deleteRecord(confirm.id)}>Delete</Button>
                    </DialogContent>
                </Dialog>
                : <></>
            }
        </>
    );
}