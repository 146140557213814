import { Box, Button, CircularProgress, FormControl, FormControlLabel, Grid, IconButton, InputLabel, MenuItem, Paper, Select, Switch, TextField, Typography } from '@mui/material'
import AddIcon from '@mui/icons-material/Add';
import { Cancel } from '@mui/icons-material';
import axios from '../../../api/axios';
import { useState } from 'react';
import Notify from '../../../features/helpers/notifyConfig';
// import { addPoc } from './Contracts';

export const QuoteForm = ({ data, setData, companies, servprods, type = "" }) => {
  const [loader, setLoader] = useState('none');
  const selectServices = servprods.services ?? {};
  const selectProducts = servprods.products ?? {};
  // console.log(data);

  const handleAddInputData = (e) => {
    let { value, id, name } = e.target;
    if (id == 'total_additional_charges' || id == 'discount') value = value != "" ? Number(value) : "";

    else if (name == "company_id") {
      setLoader('flex');
      const getCompanyProducts = async () => {
        await axios.get(`/quote-generation/product/get-by-company-name/${value}`,
          {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ` + localStorage.getItem('access')
            }
          }).then((response) => {
            setData((prevState) => ({
              ...prevState,
              products: []
            }));
            const finData = response?.data?.message;
            const all_prods = finData.products ?? {};
            const all_servs = finData.services ?? {};

            var prod_len = 0, serv_len = 0;
            Object.keys(all_prods).map((ind, val) => {
              prod_len++;
              return ind != value ?
                setData((prevState) => ({
                  ...prevState,
                  products: [
                    ...prevState.products,
                    {
                      "product_name": all_prods[ind]['product_name'],
                      "product_code": all_prods[ind]['product_code'],
                      "qty": 1,
                      "id": ind,
                      "duration": "1 Year",
                      "license_type": all_prods[ind]['license_type'],
                      "product_price": 0,
                      "net_total": 0,
                    }
                  ]
                })) : null;
            })

            Object.keys(all_servs).map((ind, val) => {
              serv_len++;
              console.log("Services")
              return ind != value ?
                setData((prevState) => ({
                  ...prevState,
                  products: [
                    ...prevState.products,
                    {
                      "product_name": all_servs[ind]['service_name'],
                      "product_code": all_servs[ind]['service_code'],
                      "qty": 1,
                      "id": ind,
                      "duration": "1 Year",
                      "product_price": 0,
                      "net_total": 0,
                    }
                  ]
                })) : null;
            })

            setLoader('none');
            Notify.success((prod_len) + " Products and " + (serv_len) + " Services" + " addded!");
          }).catch((error) => {
            console.log(error.response)
            setLoader('none');
            if (error?.response?.data?.message == "Error fetching product/services automatically. You may have to insert manually") {
              Notify.warning("Select product/services manually for this company")
            }
            else {
              Notify.error("Some error occurred while fetching product/services")
            }
          })
      }
      getCompanyProducts();
    }
    const findata = name == "company_id" ? name : id;
    setData((prevState) => ({
      ...prevState,
      [findata]: value,
    }));
  };

  const addProductCard = (pname = "", pcode = "", plicense = "") => {
    const updated = [...data.products];
    updated.unshift({
      "product_name": pname,
      "product_code": pcode,
      "qty": 1,
      "id": "",
      "duration": "1 Year",
      "license_type": plicense,
      "product_price": 0,
      "net_total": 0,
    });
    console.log(updated);
    setData((prevState) => ({
      ...prevState,
      products: updated,
    }));
  };

  //   const addCertificateCard = () => {
  //     const updatedPoc = [...data.certificates_details];
  //     updatedPoc.unshift({
  //       "certificate_name": "",
  //       "expiry_date": "",
  //     });
  //     setData((prevState) => ({
  //       ...prevState,
  //       certificates_details: updatedPoc,
  //     }));
  //   }

  const handleProducts = (e, key, field) => {
    const { value, name } = e.target;
    const updated = [...data.products];
    // alert(typeof(value));

    if (field == "qty" || field == "product_price") updated[key][field] = value != "" ? Number(value) : "";
    else if (field == "duration") {
      let currDuration = updated[key][field];
      let durationArray = currDuration.split(" ");
      // console.log(durationArray[0]);
      if (name == "duration_num") {
        console.log(value);
        updated[key][field] = value + " " + durationArray[1];
      }
      else if (name == "duration_type")
        updated[key][field] = durationArray[0] + " " + value;
    }
    else if (field == "product_name") {
      var flag = false;
      Object.keys(selectServices).map((ind, val) => {
        if (ind == value) {
          flag = true;
          updated[key]["service_name"] = selectServices[ind]['service_name'];
          updated[key]["service_code"] = selectServices[ind]['service_code'];
          updated[key]["id"] = ind;
        }
      }
      )

      if (!flag) {
        Object.keys(selectProducts).map((ind, val) => {
          if (ind == value) {
            updated[key]["product_name"] = selectProducts[ind]['product_name'];
            updated[key]["product_code"] = selectProducts[ind]['product_code'];
            updated[key]["license_type"] = selectProducts[ind]['license_type'];
            updated[key]["id"] = ind;
          }
        }
        )
      }

      setLoader('flex');
      const getLinkedProducts = async () => {
        await axios.get(`/quote-generation/product/get/${value}`,
          {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ` + localStorage.getItem('access')
            }
          }).then((response) => {
            const finData = response?.data?.message;
            console.log(finData);
            var len = 0;
            Object.keys(finData).map((ind, val) => {
              len++;
              return ind != value ?
                setData((prevState) => ({
                  ...prevState,
                  products: [
                    ...prevState.products,
                    {
                      "product_name": finData[ind]['product_name'],
                      "product_code": finData[ind]['product_code'],
                      "qty": 1,
                      "id": ind,
                      "duration": "1 Year",
                      "license_type": finData[ind]['license_type'],
                      "product_price": 0,
                      "net_total": 0,
                    }
                  ]
                })) : null;
            })
            setLoader('none');
            Notify.success((len - 1) + " related Products addded!");
          }).catch((error) => {
            console.log(error.response)
          })
      }
      getLinkedProducts();
    }

    updated[key]["net_total"] = updated[key]["qty"] * updated[key]["product_price"];

    setData((prevState) => ({
      ...prevState,
      products: updated,
    }));
  };

  const removeProduct = (index) => {
    setData((prevState) => ({
      ...prevState,
      products: [
        ...prevState.products.filter((val, key) => {
          return key != index;
        })
      ]
    }))
  }

  const handleSwitchChange = (event) => {
    setData(prevState => ({
      ...prevState,
      create_new_version: event.target.checked
    }))
  };

  return (
    <Grid position='relative' container spacing={2} mt={1}>
      <Grid item xs={12} height='100%' width='100%' display={loader} alignItems='center' justifyContent='center' position='absolute' sx={{ zIndex: '77', backdropFilter: 'blur(2px)', background: 'rgba(255, 255, 255, 0.06)', boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)', borderRadius: '10px' }}>
        <CircularProgress />
      </Grid>
      <Grid item xs={12} sm={6}>
        {
          type == "" ?
            <FormControl fullWidth>
              <InputLabel id="complabel">Select Company</InputLabel>
              <Select
                labelId="complabel"
                name="company_id"
                id="company_id"
                value={data.company_id}
                label="Select Company"
                sx={{ height: "47px" }}
                onChange={handleAddInputData}
              >
                {Object.keys(companies).map((key, val) =>
                  <MenuItem key={val} className='status' value={key}>{companies[key]['company_name']}</MenuItem>
                )}
              </Select>
            </FormControl>
            :
            <TextField fullWidth id="company_id" value={data.company_name} label="Company Name" variant="outlined" />
        }
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          required
          value={data.quote_date}
          id="quote_date"
          label="Date"
          fullWidth
          type='date'
          InputLabelProps={{
            shrink: true,
          }}
          onChange={handleAddInputData}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          required
          value={data.quote_expiration_date}
          id="quote_expiration_date"
          label="Expiration Date"
          fullWidth
          onChange={handleAddInputData}
          type='date'
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          value={data.total_additional_charges}
          id="total_additional_charges"
          label="Total Additional Charges"
          type='number'
          fullWidth
          inputProps={{ inputMode: "numeric" }}
          onChange={handleAddInputData}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          value={data.discount}
          id="discount"
          label="Discount"
          type='number'
          fullWidth
          onChange={handleAddInputData}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          value={data.contact_address}
          id="contact_address"
          label="Company Address"
          fullWidth
          onChange={handleAddInputData}
        // InputLabelProps={{
        //   shrink: true,
        // }}
        />
      </Grid>
      {
        type == ""
          ? <></>
          : <Grid item xs={12}>
            <FormControlLabel control={<Switch id='accessSharepoint' checked={data.create_new_version} onChange={handleSwitchChange} />}
              label={
                <Typography fontWeight="600" fontFamily="Segoe UI" textAlign="left">
                  Create New Version of this Quote
                </Typography>
              } />
          </Grid>
      }

      <Grid item xs={12}>
        <Typography fontWeight="600" mt={3} textAlign="left">
          Add Products/Services &nbsp;&nbsp;
          <IconButton
            size="small"
            sx={{
              backgroundColor: "#C7C8CC",
              "&:hover": {
                backgroundColor: "#B4B4B8",
              },
            }}
            onClick={addProductCard}
          >
            <AddIcon fontSize="7px" />
          </IconButton>
        </Typography>
      </Grid>

      <Grid item xs={12}>
        {data.products.map((val, index) => (
          <Paper elevation={3} sx={{ marginBottom: "1.5rem", padding: "1rem", position: 'relative' }}>
            <Grid mt={0} container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel id="prodlabel">Select Product/Service</InputLabel>
                  <Select
                    labelId="prodlabel"
                    name="product_name"
                    id="product_name"
                    value={val.id ?? ""}
                    label="Select Product/Service"
                    sx={{ height: "47px" }}
                    onChange={(e) => handleProducts(e, index, "product_name")}
                  >
                    {Object.keys(selectProducts).map((key, val) =>
                      <MenuItem key={val} className='prods' value={key}>{selectProducts[key]['product_name']}</MenuItem>
                    )}
                    {Object.keys(selectServices).map((key, val) =>
                      <MenuItem key={val} className='servs' value={key}>{selectServices[key]['service_name']}</MenuItem>
                    )}
                  </Select>
                </FormControl>

              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  value={val.qty ?? ""}
                  id={`qty_${index}`}
                  label="Quantity"
                  type='number'
                  fullWidth
                  onChange={(e) => handleProducts(e, index, "qty")}
                  inputProps={{ inputMode: "numeric" }}
                />
              </Grid>
              <Grid item xs={6}>
                <Grid container columnSpacing={2} direction='row'>
                  <Grid item sm={6}>
                    <TextField
                      value={Number(val.duration.split(" ")[0]) ?? ""}
                      name='duration_num'
                      id={`duration_${index}`}
                      label="Duration"
                      fullWidth
                      type='number'
                      inputProps={{ inputMode: "numeric" }}
                      onChange={(e) => handleProducts(e, index, "duration")}
                    />
                  </Grid>

                  <Grid item sm={6}>
                    <FormControl fullWidth>
                      <InputLabel id="durationType">Type</InputLabel>
                      <Select
                        labelId="durationType"
                        value={val.duration.split(" ")[1] ?? ""}
                        label="Type"
                        name='duration_type'
                        id={`duration_type_${index}`}
                        sx={{ height: "47px" }}
                        onChange={(e) => handleProducts(e, index, "duration")}
                      >
                        <MenuItem value='Year'>Year</MenuItem>
                        <MenuItem value='Month'>Month</MenuItem>
                        <MenuItem value='Day'>Day</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={6}>
                <TextField
                  value={val.product_price ?? ""}
                  id={`product_price_${index}`}
                  label="Product Price"
                  type='number'
                  fullWidth
                  onChange={(e) => handleProducts(e, index, "product_price")}
                  inputProps={{ inputMode: "numeric" }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  value={val.net_total ?? ""}
                  id={`net_total_${index}`}
                  label="Net Total"
                  type='number'
                  fullWidth
                  disabled
                  onChange={(e) => handleProducts(e, index, "net_total")}
                  inputProps={{ inputMode: "numeric" }}
                />
              </Grid>
              <Grid item textAlign='end' xs={6}>
                <Button variant='outlined' onClick={() => removeProduct(index)} color="error">
                  Remove
                </Button>
              </Grid>

            </Grid>
          </Paper>
        ))}
      </Grid>
    </Grid>
    //   <Grid container spacing={2} mt={1}>
    //     <Grid item xs={12} sm={6}>
    //       <TextField
    //         required
    //         value={data.product_name}
    //         id="product_name"
    //         label="Product Name"
    //         fullWidth
    //         onChange={handleAddInputData}
    //       />
    //     </Grid>

    //     <Grid item xs={12} sm={6}>
    //       <TextField
    //         required
    //         type='number'
    //         value={data.duration}
    //         id="duration"
    //         label="Duration (in months)"
    //         fullWidth
    //         onChange={handleAddInputData}
    //       />
    //     </Grid>

    //     <Grid item xs={12} sm={6}>
    //       <TextField
    //         // required
    //         type='date'
    //         value={data.start_date}
    //         id="start_date"
    //         label="Start Date"
    //         fullWidth
    //         onChange={handleAddInputData}
    //         InputLabelProps={{
    //           shrink: true,
    //         }}
    //       />
    //     </Grid>

    //     <Grid item xs={12} sm={6}>
    //       <TextField
    //         // required
    //         type='date'
    //         value={data.end_date}
    //         id="end_date"
    //         label="End Date"
    //         fullWidth
    //         onChange={handleAddInputData}
    //         InputLabelProps={{
    //           shrink: true,
    //         }}
    //       />
    //     </Grid>

    //     <Grid item xs={12} sm={6}>
    //       <TextField
    //         value={data.price}
    //         type='number'
    //         id="price"
    //         label="Price"
    //         fullWidth
    //         onChange={handleAddInputData}
    //       />
    //     </Grid>

    //     <Grid item xs={12} sm={6}>
    //       <TextField
    //         value={data.quantity}
    //         type='number'
    //         id="quantity"
    //         label="Quantity"
    //         fullWidth
    //         onChange={handleAddInputData}
    //       />
    //     </Grid>

    //     <Grid item xs={12}>
    //       <Typography fontWeight="600" mt={3} textAlign="left">
    //         Add Certificates &nbsp;&nbsp;
    //         <IconButton
    //           size="small"
    //           sx={{
    //             backgroundColor: "#C7C8CC",
    //             "&:hover": {
    //               backgroundColor: "#B4B4B8",
    //             },
    //           }}
    //           onClick={addCertificateCard}
    //         >
    //           <AddIcon fontSize="7px" />
    //         </IconButton>
    //       </Typography>
    //     </Grid>
    //     <Grid item xs={12}>
    //       {data.certificates_details.map((val, index) => (
    //         <Paper elevation={3} sx={{ marginBottom: "1.5rem", padding: "1rem" }}>
    //           <Grid container spacing={2}>
    //             <Grid item xs={12} sm={6}>
    //               <TextField
    //                 value={val.certificate_name ?? ""}
    //                 id={`certificate_name_${index}`}
    //                 label="Certificate Name"
    //                 fullWidth
    //                 onChange={(e) => handelCert(e, index, "certificate_name")}
    //               />
    //             </Grid>
    //             <Grid item xs={12} sm={6}>
    //               <TextField
    //                 type='date'
    //                 value={val.expiry_date??""}
    //                 id={`expiry_date_${index}`}
    //                 label="Expiry Date"
    //                 fullWidth
    //                 onChange={(e) => handelCert(e, index, "expiry_date")}
    //                 InputLabelProps={{
    //                   shrink: true,
    //                 }}
    //               />
    //             </Grid>

    //           </Grid>
    //         </Paper>
    //       ))}
    //     </Grid>

    //   </Grid>
  );
};

