import { Routes, Route } from "react-router-dom";
import React, { useEffect } from 'react'
import { Layout } from "./pages/Layout"
import Dashboard from "./pages/DashBoard"
import Timesheet from "./pages/Timesheet/TimeSheet"
import { Login } from "./pages/Login"
import { EmployeeData } from "./pages/EmployeeData/EmployeeData"
import { EditEmployeeData } from "./pages/EmployeeData/EditEmployeeData"
import { EmployeeDocument } from "./pages/EmployeeData/EmployeeDocument"
import { PageNotFound } from "./pages/PageNotFound";
import { jwtDecode } from "jwt-decode";
import { isManager, isMarketing, isSuperAdmin, userAuth, isLead, containRoles } from "./components/auth/userAuth";
import ManagerTimeSheet from "./pages/Timesheet/ManagerTimesheet";
import { SalesPipeline } from "./pages/SalesPipeline/SalesPipeline";
import { CurrentSalesPipeline } from "./pages/SalesPipeline/CurrentSalesPipeline";
import { ArchivedSalesPipeline } from "./pages/SalesPipeline/ArchivedSalesPipeline";
import { Home } from "./pages/Home";
import { Contracts } from "./pages/ContractSigning/Contracts";
import { ContractInfo } from "./pages/ContractSigning/ContractInfo";
import WorkArea from "./pages/WorkSpace/WorkArea";
import { Sale } from "./pages/SalesPipeline/Sale";
import { PerformIndex } from "./pages/Performance/PerformIndex";
import { Projects } from "./pages/WorkSpace/Projects";
import { UserManagement } from "./pages/WorkSpace/UserManagement";
import { Quotes } from "./pages/QuoteGeneration/Quotes";
import { EscalationReport } from "./pages/Reports/EscalationReport";
import QuoteIndex from "./pages/QuoteGeneration/QuoteIndex";
import { ConnectionPage } from "./pages/Connections/ConnectionPage";
import { CertIndex } from "./pages/Certificates/CertIndex";
import { LeaveIndex } from "./pages/Leaves/LeaveIndex";
import { EscalationHistory } from "./pages/Reports/EscalationHistory";
import { Index as EventCalendar } from "./pages/Events";
import { TrainIndex } from "./pages/Training/TrainIndex";
import { AppraisalView } from "./pages/Appraisal/AppraisalView";
import ExpenseIndex from "./pages/ExpenseReport/ExpenseIndex";
import { ManageExpense } from "./pages/ExpenseReport/Manager/ManageExpense";

// Check if there is no specific permission inside master permission (e.g "Appraisal: []" means there is no Appraisal permission granted)
export const permAllowed = (perm_array) => {
    if (perm_array.length == 0) return false;
    return true;
};

//Check if specific permissions are present inside that permission master array (e.g: "View Events" inside "Events" )
export const specificpermCheck = (perm_array, perm_sub_array) => {
    return perm_sub_array.some(element => perm_array.includes(element));
};

export const showRoute = (status, item, error=null) => {
    return status ? item : error;
};

export const currentUser = () => {
    const token = localStorage.getItem('access');
    const profileImg = localStorage.getItem('profileImage');
    const decoded = token == null ? '' : jwtDecode(token);
    const user_first_name = decoded?.payload?.FirstName;
    const user_last_name = decoded?.payload?.LastName;
    const user_role_name = decoded?.payload?.Role.role_name;
    const user_role_id = decoded?.payload?.Role.role_id;
    const user_id = decoded?.payload?.id;
    const user_role_string_id = decoded?.payload?.Role.role_string_id;
    const token_expiry = decoded?.exp;
    const usageLocation = decoded?.payload?.usageLocation;
    const permissions = decoded?.payload?.permisssions;
    // console.log(permissions);
    // P E R M I S S I O N S
    const pARR = permissions['Annual Recurring Revenue'];
    const pAppraisal = permissions['Appraisal'];
    const pCertificates = permissions['Certificates'];
    const pConnections = permissions['Connections'];
    const pEmpData = permissions['Employee Data'];
    const pEvents = permissions['Events'];
    const pGeneral = permissions['General'];
    const pPerformance = permissions['Performance'];
    const pQG = permissions['Quote Generation'];
    const pReports = permissions['Reports'];
    const pSalespipeline = permissions['Salespipeline'];
    const pTimesheet = permissions['Timesheet'];
    const pTraining = permissions['Training'];
    const pWorkPerm = permissions['Workspace - Permissions'];
    const pWorkPrjcts = permissions['Workspace - Projects'];
    const pWorkUsers = permissions['Workspace - Users'];
    const pCredentials = permissions['Credentials'];
    return { token, pConnections, pPerformance, decoded, user_first_name, user_last_name, user_role_name, user_role_id, token_expiry, profileImg, user_role_string_id, usageLocation, user_id, permissions, pARR, pAppraisal, pCertificates, pEmpData, pEvents, pGeneral, pQG, pReports, pSalespipeline, pTimesheet, pTraining, pWorkPerm, pWorkPrjcts, pWorkUsers, pCredentials };
}

export const Router = () => {

    const LOGIN_URL = '/login'
    const current_user_role = currentUser()['user_role_id'];
    const perm_status = userAuth();
    const { pARR,pAppraisal,pCertificates,pEmpData,pEvents,pGeneral,pQG,pReports,pSalespipeline,pConnections,pPerformance,pTimesheet,pTraining,pWorkPerm,pWorkPrjcts,pWorkUsers } = currentUser();

    return (
        <>
            <Routes>
                {/* PUBLIC ROUTE */}
                <Route path="/login" element={<Login />} />

                {/* PRIVATE ROUTE */}
                <Route path="/" element={<Layout perm_status={perm_status} />}>
                    <Route path="/" element={<Home />} />

                    <Route path="/salespipeline" >
                        <Route index element={showRoute(permAllowed(pSalespipeline) && specificpermCheck(pSalespipeline,['View Records']), <SalesPipeline />, <PageNotFound />)} />
                        <Route path="current" >
                            <Route index element={<PageNotFound />} />
                            <Route path=":year" element={showRoute(permAllowed(pSalespipeline) && specificpermCheck(pSalespipeline,['View Records']), <CurrentSalesPipeline />, <PageNotFound />)} />
                        </Route>

                        <Route path="archived">
                            <Route index element={<PageNotFound />} />
                            <Route path=":year" element={showRoute(permAllowed(pSalespipeline) && specificpermCheck(pSalespipeline,['View Records']), <ArchivedSalesPipeline />, <PageNotFound />)} />
                        </Route>

                        <Route path="sale">
                            <Route index element={<PageNotFound />} />
                            <Route path=":year" element={showRoute(permAllowed(pSalespipeline) && specificpermCheck(pSalespipeline,['View Records']), <Sale />, <PageNotFound />)} />
                        </Route>
                    </Route>

                    <Route path="/contractsigning" >
                        <Route index element={showRoute(permAllowed(pARR) && specificpermCheck(pARR,['View Account']), <Contracts />, <PageNotFound />)} />
                        <Route path=":product_id" element={showRoute(permAllowed(pARR) && specificpermCheck(pARR,['View Specific Contract']), <ContractInfo />, <PageNotFound />)} />
                    </Route>

                    <Route path="/quotegeneration" >
                        <Route index element={showRoute(permAllowed(pQG) && specificpermCheck(pQG,['View Quotes','View Company Details','View Product Details','View Service Details']), <QuoteIndex />, <PageNotFound />)} />
                        {/* <Route path=":product_id" element={isSuperAdmin(<ContractInfo />,<PageNotFound />) || isMarketing(<ContractInfo />,<PageNotFound />)} /> */}
                    </Route>

                    <Route path="/Timesheet">
                        <Route index element={showRoute(permAllowed(pTimesheet) && specificpermCheck(pTimesheet,['View Personal Timesheet']), <Timesheet />, <PageNotFound />)} />
                        <Route path="manager" element={showRoute(permAllowed(pTimesheet) && specificpermCheck(pTimesheet,['View Manager Timesheet']), <ManagerTimeSheet />, <PageNotFound />)} />
                    </Route>

                    <Route path="/Connections" element={showRoute(permAllowed(pConnections) && specificpermCheck(pConnections,['View Connections']), <ConnectionPage />, <PageNotFound />)} />
                    
                    <Route path="/Appraisal" element={showRoute(permAllowed(pAppraisal) && specificpermCheck(pAppraisal,['View Goals','Add Goals']), <AppraisalView />, <PageNotFound />)} />

                    <Route path="/Certificates" element={showRoute(permAllowed(pCertificates) && specificpermCheck(pCertificates,['View All Certificates','Review Certificates']), <CertIndex />, <PageNotFound />)} />

                    <Route path="/Workspace">
                        <Route index element={showRoute((permAllowed(pWorkUsers) || permAllowed(pWorkPrjcts) || permAllowed(pWorkPerm)), <WorkArea />, <PageNotFound />)} />
                        {/* <Route path="Users" element={showRoute(permAllowed(pConnections) && specificpermCheck(pConnections,['View Connections']), <UserManagement />, <PageNotFound />)} /> */}
                        {/* <Route path="Projects" element={showRoute(permAllowed(pConnections) && specificpermCheck(pConnections,['View Connections']), <Projects />, <PageNotFound />)} /> */}
                    </Route>

                    <Route path="/Performance" element={showRoute(permAllowed(pPerformance) && specificpermCheck(pPerformance,["View Leave History","View Escalation History"]), <PerformIndex />, <PageNotFound />)} />
                    
                    <Route path="/Reports" element={showRoute(permAllowed(pReports) && specificpermCheck(pReports,['View Reports']), <EscalationReport />, <PageNotFound />)} />
                    
                    <Route path="/ExpenseReport" >
                        <Route index element={<ExpenseIndex />} />
                        <Route path="manager" element={isLead(<ManageExpense />, <PageNotFound />)} />
                    </Route>
                    
                    <Route path="/Events" element={showRoute(permAllowed(pEvents) && specificpermCheck(pEvents,['View Events']), <EventCalendar />, <PageNotFound />)} />
                    
                    <Route path="/Leaves" element={showRoute(permAllowed(pTimesheet) && specificpermCheck(pTimesheet,['Add Leave', 'View All Leaves', 'Review Leave']), <LeaveIndex />, <PageNotFound />)} />

                    <Route path="/Training" element={showRoute(permAllowed(pTraining) && specificpermCheck(pTraining,['View Training','View Manager Training']), <TrainIndex />, <PageNotFound />)} />

                    <Route path="/EmployeeData">
                        <Route index element={showRoute(permAllowed(pEmpData) && specificpermCheck(pEmpData,['View Employee Data']), <EmployeeData />, <PageNotFound />)} />
                        <Route path="edit/" element={showRoute(permAllowed(pEmpData) && specificpermCheck(pEmpData,['Edit Employee Data']), <EditEmployeeData />, <PageNotFound />)} />
                        <Route path="document/" element={showRoute(permAllowed(pEmpData) && specificpermCheck(pEmpData,['View Document Details']), <EmployeeDocument />, <PageNotFound />)} />
                    </Route>

                    <Route path="*" element={<PageNotFound />} />
                </Route>
            </Routes>
        </>
    )
}
