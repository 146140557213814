import { currentUser } from '../../Router'

const userPermissions =[];

export const userAuth = () => {
  // const{user_role_id} =currentUser();
  const{user_role_string_id} =currentUser();
  const roles ={
     100: "SuperAdmin",
     101: "Marketing",
     102: "Accounting",
     103: "Team Lead",
     104: "Employee",
     105: "Manager",
    };

    user_role_string_id.map((val)=>{
      if(val=="SuperAdmin") userPermissions.push("SUPERADMIN_PERMISSION"); 
      if(val=="Team Lead") userPermissions.push("LEAD_PERMISSION"); 
      if(val=="Finance") userPermissions.push("FINANCE_PERMISSION"); 
      if(val=="Marketing") userPermissions.push("MARKETING_PERMISSION"); 
      if(val=="Employee") userPermissions.push("EMPLOYEE_PERMISSION"); 
      if(val=="Project Manager") userPermissions.push("MANAGER_PERMISSION"); 
      if(val=="Marketing Lead") userPermissions.push("MARKETING_LEAD_PERMISSION"); 
    })

    return userPermissions;
}

export const containRoles =(roles,a,b) =>{
  return roles.some(elem => userPermissions.includes(elem))?a:b
}

export const isSuperAdmin =(a,b) =>{
  return userPermissions.includes("SUPERADMIN_PERMISSION")?a:b
}

export const isManager =(a,b) =>{
  return userPermissions.includes("MANAGER_PERMISSION")?a:b
}

export const isLead =(a,b) =>{
  return userPermissions.includes("LEAD_PERMISSION")?a:b
}

export const isAccounting =(a,b) =>{
  return userPermissions.includes("FINANCE_PERMISSION")?a:b
}

export const isMarketing =(a,b) =>{
  return userPermissions.includes("MARKETING_PERMISSION")?a:b
}

export const isMarketingLead =(a,b) =>{
  return userPermissions.includes("MARKETING_LEAD_PERMISSION")?a:b
}

export const isEmployee =(a,b) =>{
  return userPermissions.includes("EMPLOYEE_PERMISSION")?a:b
}