import { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Typography, Stack, Grid, Button, FormControl, InputLabel, Select, MenuItem, Tooltip, Box, Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Menu, Chip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Link, NavLink } from 'react-router-dom';
import Notify from '../../helpers/notifyConfig';
import axios from '../../../api/axios';
import AddIcon from '@mui/icons-material/Add';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { FormikForm } from '../../../pages/ContractSigning/FormikForm';
import { format } from 'date-fns';
import WarningIcon from '@mui/icons-material/Warning';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PersonIcon from '@mui/icons-material/Person';
import Person4Icon from '@mui/icons-material/Person4';

// import theme from '../../../theme';

const formattedDate = (myDate) => {
  return format(myDate, 'dd MMM yyyy');
}

const dateDiff = (myDate) => {
  const today = new Date();
  const targetDate = new Date(myDate);
  const differenceInMilliseconds = targetDate - today;
  const differenceInDays = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24));

  var ans = "";
  if (differenceInDays <= 60) ans = "error";
  else if (differenceInDays <= 180) ans = "warning";
  else ans = "success";

  return ans;
}

const dateIcons = (date) => {
  if (date == "error") return <WarningIcon />;
  else if (date == "warning") return <NotificationImportantIcon />;
  return <CheckCircleIcon />;
}

const defaultData = {
  "company_id": "",
  "product_name": "",
  "company_name": "",
  "duration": "",
  "start_date": "",
  "end_date": "",
  "price": 0,
  "quantity": 0,
  "certificates_details": [{
    "certificate_name": "",
    "expiry_date": "",
  }],
}

const color_cont = {
  'active': "#6895D2",
  'inactive': "red",
}

export function SingleTable({ titles, accountInfo, account = false, setLoading, setEditAccount, editOpen, setEditOpen, type = "", setRefresh, editData }) {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState(type == "edit" ? editData : defaultData);
  const [anchorElMap, setAnchorElMap] = useState({});
  // const openAction = Boolean(anchorEl);
  const handleClick = (event, id) => {
    setAnchorElMap(prevMap => ({
      ...prevMap,
      [id]: event.currentTarget,
    }));
  };
  // conso.le.log(editData);

  const handleClose = () => {
    setOpen(false);
    setAnchorElMap({});
    if (type == "edit") setEditOpen(false);
  };

  const submitAddForm = (type) => {
    let hasWarning = false;

    if (data.product_name === "") {
      Notify.warning("Product Name field cannot be empty!");
      hasWarning = true;
    }
    else if (data.duration === "") {
      Notify.warning("Duration field cannot be empty!");
      hasWarning = true;
    }
    else {
      // 1. Adding New Product in Contract Signing
      if (type == "add") {
        const addProduct = async () => {
          await axios.post("/dashboard/contract/addproduct",
            JSON.stringify(data),
            {
              headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                withCredentials: true,
                mode: 'no-cors',
                'Authorization': `Bearer ` + localStorage.getItem('access')
              }
            }).then((response) => {
              console.log(response);
              setOpen(false);
              setLoading(prev => !prev);
              setData(defaultData);
              Notify.success("Product Added Successfully!")
            }).catch((error) => {
              console.log(error.response);
              Notify.error("Error Occurred. Try Again!");
            })
        }
        addProduct();
      }
      else {
        const result = {
          "_id": data._id,
          "product_name": data.product_name,
          "certificates_details": data.certificates_details,
          "duration": data.duration,
          "start_date": data.start_date,
          "end_date": data.end_date,
          "price": data.price,
          "quantity": data.quantity,
        }
        const editProduct = async () => {
          await axios.post("/dashboard/contract/editproduct",
            JSON.stringify(result),
            {
              headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                withCredentials: true,
                mode: 'no-cors',
                'Authorization': `Bearer ` + localStorage.getItem('access')
              }
            }).then((response) => {
              console.log(response);
              setRefresh(prev => !prev);
              setEditOpen(false);
              Notify.success("Product Edited Successfully!")
            }).catch((error) => {
              console.log(error.response);
              Notify.error("Error Occurred. Try Again!");
            })
        }
        editProduct();
      }
    }
  }

  const handleClickOpen = (id, name) => {
    setOpen(true);
    setAnchorElMap({});
    setData({
      "company_id": id,
      "product_name": "",
      "company_name": name,
      "duration": "",
      "start_date": "",
      "end_date": "",
      "price": 0,
      "quantity": 0,
      "certificates_details": [{
        "certificate_name": "",
        "expiry_date": "",
      }],
    });
  };

  const headStyle = {
    backgroundColor: "primary.light",
  }

  // Delete Account
  const deleteAction = (id, type) => {
    // setAnchorElMap({});
    const deleteSale = async () => {
      await axios.delete(`/dashboard/contract/deleteaccount`,
        {
          headers: {
            'Authorization': `Bearer ` + localStorage.getItem('access')
          },
          data: {
            "_id": id
          }
        }).then((response) => {
          type == "account" ? Notify.success('Account Deleted Successfully!') : Notify.success('Product Deleted Successfully!');
          setLoading(prev => !prev)
        }).catch((error) => {
          type == "account" ? Notify.error('Account Deletion Failed. Try Again!') : Notify.error('Product Deletion Failed. Try Again!');
        })
    }
    deleteSale();
  }

  // Edit Account
  const editAction = (id, type) => {
    setAnchorElMap({});
    const editSale = async () => {
      await axios.put(`/dashboard/contract/editaccount`,
        JSON.stringify({ _id: id }),
        {
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            withCredentials: true,
            mode: 'no-cors',
            'Authorization': `Bearer ` + localStorage.getItem('access')
          }
        }).then((response) => {
          // console.log(response);
          setEditAccount(response?.data);
        }).catch((error) => {
          Notify.error("Try Again!")
        })
    }
    editSale();
  }

  return (
    <TableContainer component={Paper} sx={{ maxHeight: "75vh", borderRadius: "2px" }}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead sx={{ ...headStyle }}>
          <TableRow>
            {titles.map((val, index) =>
              <TableCell align={val == 'Action' || val == 'Expiry Date' ? 'center' : 'left'} sx={{ fontWeight: "600", color: "primary.white", borderRight: '1px solid white' }} key={index}>{val}</TableCell>)}
          </TableRow>
        </TableHead>
        <TableBody>
          {accountInfo.length == 0 ? <TableRow sx={{ textAlign: 'center' }}><Typography p={2} variant='h6'>No Data Found!</Typography></TableRow> :
            accountInfo.map((row, index) => (
              <>
                <TableRow
                  key={index}
                  sx={{ borderBottom: '1px solid #ccc' }}
                >
                  {/* <TableCell align="left"><Typography pl={2}>{row._id?row._id:row.certificate_name}</Typography></TableCell> */}
                  <TableCell align="left" sx={{ borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}>
                    {account ? <Typography fontWeight={'600'}>{row.company_name}</Typography> :
                      <Typography color="#1B2C58">{row.certificate_name}</Typography>}
                  </TableCell>

                  {account ? <TableCell align="left" sx={{ borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}>
                    <Typography>{row.status.toUpperCase()}</Typography>
                  </TableCell> : <></>}

                  {/* {account ? <TableCell align="left" sx={{ borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}>
                    <Typography>{row.company_address}</Typography>
                  </TableCell> : <></>} */}

                  {account ?
                    <TableCell align="center" sx={{ borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}>
                      {/* <Box py={1} display={'flex'}><Button onClick={() => handleClickOpen(row._id, row.company_name)} sx={{ paddingLeft: '0' }}><AddIcon sx={{ color: "green" }} /><Typography sx={{ color: "green" }}>Add New</Typography></Button></Box> */}
                      {row.products.map((val, index) => {
                        const product_route = `/contractsigning/${val.product_id}`;
                        return (
                          // <Box sx={index!=([row.products].length-1)?{borderBottom:"1px solid #ccc"}:""} key={index} py={1} display={'flex'}><Link style={{ textDecoration: "none", color: "#0C359E" }} to={product_route}><Typography>{val.product_name}</Typography></Link></Box>
                          <Box key={index} py={1} display={'flex'}><Link style={{ textDecoration: "none", color: "#0C359E" }} to={product_route}><Typography>{val.product_name}</Typography></Link></Box>
                        )
                      }
                      )}
                    </TableCell>
                    :
                    <TableCell sx={{ borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }} align="center"><Typography>{new Date(row.expiry_date).toLocaleString('en-US', { day: '2-digit', month: 'short', year: 'numeric', hour: 'numeric', minute: 'numeric' }).replace(',', '')}</Typography></TableCell>}

                  {/* REVENUE */}
                  {account ?
                    <TableCell align="center" sx={{ borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}>
                      {/* {const sd =""} */}
                      {row.products.map((val, index) => {
                        const product_route = `/contractsigning/${val.product_id}`;
                        return (
                          <Box key={index} py={1} display={'flex'}><Typography color='green' fontWeight='600'>${val.price}</Typography></Box>
                        )
                      }
                      )}
                    </TableCell>
                    : <></>}

                  {/* END DATE */}
                  {account ?
                    <TableCell align="center" sx={{ borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}>
                      {row.products.map((val, index) => {
                        return (
                          <Box key={index} py={1} display={'flex'}><Chip color={dateDiff(val.end_date)} icon={dateIcons(dateDiff(val.end_date))} label={formattedDate(val.end_date)} /></Box>
                        )
                      }
                      )}
                    </TableCell>
                    : <></>}

                  {/* POCs */}
                  {account ?
                    <TableCell align="center" sx={{ borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}>
                      {row.poc_data.map((val, index) => {
                        if (val.poc_type === "primary" || val.poc_type === "secondary") {
                          return (
                            <Stack mb={1} direction='row' spacing={0.5} alignItems='center'>
                              {
                                val.poc_type === "primary"
                                  ? <Tooltip title="Primary Contact"><Box sx={{transform: "rotateZ(350deg) translateY(4px)"}}><svg xmlns="http://www.w3.org/2000/svg" width="23px" height="23px" viewBox="0 0 24 24"><g fill="#133E87"><path d="M13.25 11.25H10.5v-2.5h2.75a.75.75 0 0 1 .75.75v1a.75.75 0 0 1-.75.75"/><path d="M10.565 2.075a3.33 3.33 0 0 1 2.87 0c.394.189.755.497 1.26.928l.079.066c.48.41.939.604 1.58.655l.102.008c.662.053 1.135.09 1.547.236a3.33 3.33 0 0 1 2.03 2.029c.145.412.182.885.235 1.547l.008.102c.051.641.246 1.1.655 1.58l.066.078c.431.506.74.867.928 1.261a3.33 3.33 0 0 1 0 2.87c-.189.394-.497.755-.928 1.26l-.066.079c-.418.49-.605.951-.655 1.58l-.008.102c-.053.662-.09 1.135-.236 1.547a3.33 3.33 0 0 1-2.029 2.03c-.412.145-.885.182-1.547.235l-.102.008c-.641.051-1.1.246-1.58.655l-.079.066c-.505.431-.866.74-1.26.928a3.33 3.33 0 0 1-2.87 0c-.394-.189-.755-.497-1.26-.928l-.079-.066a2.56 2.56 0 0 0-1.58-.655l-.102-.008c-.662-.053-1.135-.09-1.547-.236a3.33 3.33 0 0 1-2.03-2.029c-.145-.412-.182-.885-.235-1.547l-.008-.102a2.56 2.56 0 0 0-.655-1.58l-.066-.079c-.431-.505-.74-.866-.928-1.26a3.33 3.33 0 0 1 0-2.87c.189-.394.497-.755.928-1.26l.066-.079a2.56 2.56 0 0 0 .655-1.58l.008-.102c.053-.662.09-1.135.236-1.547a3.33 3.33 0 0 1 2.029-2.03c.412-.145.885-.182 1.547-.235l.102-.008a2.56 2.56 0 0 0 1.58-.655l.078-.066c.506-.431.867-.74 1.261-.928M10.25 7.25C9.56 7.25 9 7.81 9 8.5V16a.75.75 0 0 0 1.5 0v-3.25h2.75a2.25 2.25 0 0 0 2.25-2.25v-1a2.25 2.25 0 0 0-2.25-2.25z"/></g></svg></Box></Tooltip>
                                  : <Tooltip title="Secondary Contact"><Box sx={{transform: "rotateZ(350deg) translateY(4px)"}}><svg xmlns="http://www.w3.org/2000/svg" width="23px" height="23px" viewBox="0 0 24 24"><path fill="#9B7EBD" d="M10.565 2.075a3.33 3.33 0 0 1 2.87 0c.394.189.755.497 1.26.928l.079.066c.48.41.939.604 1.58.655l.102.008c.662.053 1.135.09 1.547.236a3.33 3.33 0 0 1 2.03 2.029c.145.412.182.885.235 1.547l.008.102c.051.641.246 1.1.655 1.58l.066.078c.431.506.74.867.928 1.261a3.33 3.33 0 0 1 0 2.87c-.189.394-.497.755-.928 1.26l-.066.079c-.418.49-.605.951-.655 1.58l-.008.102c-.053.662-.09 1.135-.236 1.547a3.33 3.33 0 0 1-2.029 2.03c-.412.145-.885.182-1.547.235l-.102.008c-.641.051-1.1.246-1.58.655l-.079.066c-.505.431-.866.74-1.26.928a3.33 3.33 0 0 1-2.87 0c-.394-.189-.755-.497-1.26-.928l-.079-.066a2.56 2.56 0 0 0-1.58-.655l-.102-.008c-.662-.053-1.135-.09-1.547-.236a3.33 3.33 0 0 1-2.03-2.029c-.145-.412-.182-.885-.235-1.547l-.008-.102a2.56 2.56 0 0 0-.655-1.58l-.066-.079c-.431-.505-.74-.866-.928-1.26a3.33 3.33 0 0 1 0-2.87c.189-.394.497-.755.928-1.26l.066-.079a2.56 2.56 0 0 0 .655-1.58l.008-.102c.053-.662.09-1.135.236-1.547a3.33 3.33 0 0 1 2.029-2.03c.412-.145.885-.182 1.547-.235l.102-.008a2.56 2.56 0 0 0 1.58-.655l.078-.066c.506-.431.867-.74 1.261-.928M10.5 7.25A1.75 1.75 0 0 0 8.75 9v.984c0 .92.56 1.748 1.414 2.09l3.114 1.245a.75.75 0 0 1 .472.696V15a.25.25 0 0 1-.25.25h-3a.25.25 0 0 1-.25-.25v-.25a.75.75 0 0 0-1.5 0V15c0 .966.784 1.75 1.75 1.75h3A1.75 1.75 0 0 0 15.25 15v-.985c0-.92-.56-1.747-1.414-2.088l-3.114-1.246a.75.75 0 0 1-.472-.697V9a.25.25 0 0 1 .25-.25h3a.25.25 0 0 1 .25.25v.25a.75.75 0 0 0 1.5 0V9a1.75 1.75 0 0 0-1.75-1.75z"/></svg></Box></Tooltip>
                              }
                              <Typography variant='body1' fontFamily='Segoe UI'>{val.poc_name}</Typography>
                            </Stack>
                          )
                        }
                      }
                      )}
                    </TableCell>
                    : <></>}

                  <TableCell sx={{ borderBottom: '1px solid #ccc' }} align="center">
                    {account ?
                      <>
                        <Button
                          id={`basic-button-${row._id}`}
                          aria-controls={anchorElMap[row._id] ? 'basic-menu' : undefined}
                          aria-haspopup="true"
                          aria-expanded={anchorElMap[row._id] ? 'true' : undefined}
                          onClick={(event) => handleClick(event, row._id)}
                        >
                          <MoreHorizIcon />
                        </Button>
                        <Menu
                          id={`basic-menu-${row._id}`}
                          anchorEl={anchorElMap[row._id]}
                          open={Boolean(anchorElMap[row._id])}
                          onClose={handleClose}
                          MenuListProps={{
                            'aria-labelledby': 'basic-button',
                          }}
                        >
                          <MenuItem onClick={() => handleClickOpen(row._id, row.company_name)}><AddIcon sx={{ color: "green", fontSize: '22px' }} />&nbsp;&nbsp;Add Product</MenuItem>
                          <MenuItem id={"edit" + row._id} onClick={(e) => editAction(row._id, account ? "account" : "product")}><EditIcon sx={{ fontSize: '20px' }} />&nbsp;&nbsp;Edit Account</MenuItem>
                          <MenuItem id={"del" + row._id} onClick={(e) => deleteAction(row._id, account ? "account" : "product")}><DeleteIcon sx={{ color: "#D24545", fontSize: '20px' }} />&nbsp;&nbsp;Delete Account</MenuItem>
                        </Menu>
                        {/* <Tooltip title="Add Product"><Button onClick={() => handleClickOpen(row._id, row.company_name)}><AddIcon sx={{ color: "green" }} /></Button></Tooltip>
                  <Tooltip title="Edit Account"><Button id={"edit" + row._id} onClick={(e) => editAction(row._id, account ? "account" : "product")}><EditIcon /></Button></Tooltip>&nbsp;
                  <Tooltip title="Delete Account"><Button id={"del" + row._id} onClick={(e) => deleteAction(row._id, account ? "account" : "product")}><DeleteIcon sx={{color: "#D24545" }} /></Button></Tooltip> */}
                      </>
                      : <></>}
                  </TableCell>
                </TableRow>
              </>
            ))}
        </TableBody>
      </Table>

      {/* To add a new product for any account */}
      <Dialog
        open={editOpen ? editOpen : open}
        onClose={handleClose}
        aria-labelledby="edit-apartment"
        fullWidth
        maxWidth='sm'
      >
        <DialogTitle sx={{ backgroundColor: "#F6F5F5" }} id="edit-apartment">Add Product (for {data.company_name})</DialogTitle>
        <DialogContent sx={{ backgroundColor: "#F6F5F5" }}>
          <FormikForm data={data} setData={setData} type="product" />
        </DialogContent>
        <DialogActions sx={{ backgroundColor: "#F6F5F5" }}>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          {
            type == "edit"
              ? <Button variant='contained' onClick={() => submitAddForm("edit")} color="primary">Save</Button>
              : <Button variant='contained' onClick={() => submitAddForm("add")} color="primary">Add</Button>
          }
        </DialogActions>
      </Dialog>
    </TableContainer>
  );
}