import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import FilledInput from '@mui/material/FilledInput';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Button, Checkbox, FormControlLabel, Grid, MenuItem, Select, Switch, Typography } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';
import { countriesapi } from '../../api/countriesapi';
import { boolean } from 'yup';
import Notify from '../../features/helpers/notifyConfig';

// {ChancesOfWinning,Channel,Customers,EC_PointOfContact,Reseller,Stage,WonLost}
export function EmployeeForm({ prop, data = "", type = "", setData, action = "" }) {
    const { ReportingToUsers, existingTeams, givenNames, mails } = prop;
    const Status = ['Active', 'Non-Active','Away'];
    const SharePointAccess = [true, false];
    const AllRoles = ['SuperAdmin', 'Marketing', 'Finance', 'Project Manager', 'Team Lead', 'Employee', 'Marketing Lead'];
    const [selectedRoles, setSelectedRoles] = useState(data['role_string_id']);
    const [isSharePointAccessAllowed, setIsSharePointAccessAllowed] = useState(true);
    // const currentLocation ="@encryptionintern.onmicrosoft.com";
    const currentLocation ="@encryptionconsulting.com";

    const handleAddData = (e, val, id="") => {
        if (e && e.target) {
            const naam = e.target.id.split('-')[0];
            const newValue = val === null ? "" : val;
            const newId =id=="" ? naam : id;
            console.log(newValue+" "+newId);
            setData(prevState => ({
                ...prevState,
                [newId]: newValue
            }))
        }
    }

    const handleSwitchChange = (event) => {
        setIsSharePointAccessAllowed(event.target.checked);
        setData(prevState => ({
            ...prevState,
            accessSharepoint: event.target.checked
        }))
    };

    const handleAddInputData = (e) => {
        const val = e.target.value;
        const naam = e.target.id;
        const generatedMail =val + currentLocation;

        var flag = true;
        if (naam == "givenName") {
            if (givenNames.includes(val)) {
                Notify.warning("Username already exists. Try another username!");
                flag = false;
            }
            else if (mails.includes(generatedMail)) {
                Notify.warning("Mail already exists. Try another username!");
                flag = false;
            }
        }

        if (flag) {
            setData(prevState => {
                let updatedState = {
                    ...prevState,
                    [naam]: val
                };
                if (naam === "givenName") {
                    updatedState.mail = generatedMail;
                }
                return updatedState;
            })
        }
    }

    const handleRoleSelection = (event, value) => {
        setSelectedRoles(value);
    };

    const countryChoose = (event, val) => {

        setData(prevState => ({
            ...prevState,
            Country: val==null?"":val.label,
            usageLocation: val==null?"":val.code
        }))
    };

    const handleTeamsChange = (event, val) => {
        console.log(val);
        setData(prevState => ({
            ...prevState,
            team_id: val
        }))
    };

    useEffect(() => {
        setData(prevState => ({
            ...prevState,
            "role_string_id": selectedRoles
        }))
        console.log(data);
    }, [selectedRoles])

    return (
        <Box sx={{ display: 'flex', flexWrap: 'wrap' }} mt={1}>
            <Grid container>

                <Grid item xs={6} p={1}>
                    <TextField
                        required
                        value={data['FirstName']}
                        id="FirstName"
                        sx={{ width: "100%" }}
                        onChange={handleAddInputData}
                        label="FirstName"
                    />
                </Grid>

                <Grid item xs={6} p={1}>
                    <TextField
                        required
                        value={data['LastName']}
                        id="LastName"
                        sx={{ width: "100%" }}
                        onChange={handleAddInputData}
                        label="LastName"
                    />
                </Grid>

                <Grid item xs={6} p={1}>
                    <TextField
                        required
                        value={data['givenName']}
                        id="givenName"
                        sx={{ width: "100%" }}
                        onChange={handleAddInputData}
                        label="Username"
                    />
                </Grid>

                <Grid item xs={6} p={1}>
                    <TextField
                        disabled
                        value={data['mail']}
                        id="mail"
                        sx={{ width: "100%" }}
                        onChange={handleAddInputData}
                        label="Mail ID"
                    />
                </Grid>

                <Grid item xs={6} p={1}>
                    <TextField
                        required
                        value={data['tempPassword']}
                        id="tempPassword"
                        sx={{ width: "100%" }}
                        onChange={handleAddInputData}
                        label="Temporary Password"
                    />
                </Grid>

                <Grid item xs={6} p={1}>
                    <Autocomplete
                        id="Country"
                        required
                        sx={{ width: "100%" }}
                        value={countriesapi.find((value) => value.code === data['usageLocation'])}
                        options={countriesapi}
                        autoHighlight
                        getOptionLabel={(option) => option.label}
                        renderOption={(props, option) => (
                            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                <img
                                    loading="lazy"
                                    width="20"
                                    srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                    src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                    alt=""
                                />
                                {option.label} ({option.code}) +{option.phone}
                            </Box>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={<span>Choose a country <span style={{ color: 'red' }}>*</span></span>}
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'new-password',
                                }}
                            />
                        )}
                        onChange={(e, val) => countryChoose(e, val)}
                    />
                </Grid>

                <Grid item xs={6} p={1}>
                    <Autocomplete
                        id="status"
                        required
                        value={data['status']}
                        sx={{ width: '100%' }}
                        options={Status.map((option) => option)}
                        renderInput={(params) => <TextField {...params} required label="Status" />}
                        onInputChange={(e,val)=>handleAddData(e,val,"status")}
                    />
                </Grid>

                <Grid item xs={6} p={1}>
                    <TextField
                        value={data['ContactNo']}
                        id="ContactNo"
                        sx={{ width: "100%" }}
                        onChange={handleAddInputData}
                        label="Contact No"
                    />
                </Grid>

                <Grid item xs={6} p={1}>
                    <Autocomplete
                        id="ReportingTo"
                        required
                        value={data['ReportingTo']}
                        sx={{ width: '100%' }}
                        options={Object.values(ReportingToUsers)}
                        getOptionLabel={(user) => user?.givenName || data['ReportingTo']}
                        getOptionSelected={(user, value) => user._id === value._id}
                        renderInput={(params) => <TextField {...params} label={<span>Reporting To <span style={{ color: 'red' }}>*</span></span>} />}
                        onInputChange={(e,val)=>handleAddData(e,val,"ReportingTo")}
                    />
                </Grid>

                <Grid item xs={6} p={1}>
                    <TextField
                        value={data['Designation']}
                        id="Designation"
                        sx={{ width: "100%" }}
                        onChange={handleAddInputData}
                        label="Designation"
                    />
                </Grid>

                <Grid item xs={6} p={1}>
                    <TextField
                        value={data['Address']}
                        id="Address"
                        sx={{ width: "100%" }}
                        onChange={handleAddInputData}
                        label="Address"
                    />
                </Grid>

                <Grid item xs={6} p={1} >
                    <TextField
                        id="Date_of_Joining"
                        value={data['Date_of_Joining']}
                        label="Date of Joining"
                        sx={{ width: '100%' }}
                        onChange={handleAddInputData}
                        type="date"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>

                <Grid item xs={6} p={1}>
                    <Autocomplete
                        multiple
                        id="role_string_id"
                        value={data['role_string_id']}
                        sx={{ width: "100%" }}
                        options={AllRoles}
                        getOptionLabel={(option) => option}
                        renderInput={(params) => <TextField {...params} label={<span>Select Roles <span style={{ color: 'red' }}>*</span></span>}/>}
                        onChange={handleRoleSelection}
                        renderOption={(props, option, { selected }) => (
                            <li {...props}>
                                <FormControlLabel
                                    control={<Checkbox checked={selected} />}
                                    label={option}
                                />
                            </li>
                        )}
                    />
                </Grid>

                <Grid item xs={6} p={1}>
                    <Autocomplete
                        multiple
                        id="team_id"
                        value={data['team_id']}
                        sx={{ width: '100%' }}
                        options={Object.keys(existingTeams)}
                        getOptionLabel={(key) => existingTeams[key]}
                        renderInput={(params) => <TextField {...params} label="Select Teams" />}
                        onChange={(e, val) => handleTeamsChange(e, val)}
                    />

                </Grid>

                <Grid item xs={6} p={1}>
                    <FormControlLabel control={<Switch id='accessSharepoint' checked={data['accessSharepoint']} onChange={handleSwitchChange} />} label="Allow SharePoint Access" />
                </Grid>
            </Grid>
        </Box >
    );
}