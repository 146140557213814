import { Box, Button, Grid, IconButton, Link, Stack, Typography } from '@mui/material'
import React, { useState } from 'react'
import UserIcon from '@mui/material/Avatar';
// import HeaderBox from '../components/ui/HeaderBox';
import { HiOutlineUserGroup } from "react-icons/hi2";
import { FaRegUser } from "react-icons/fa";
import { FiPhone } from "react-icons/fi";
import { TbLocation } from "react-icons/tb";
import { NavLink } from 'react-router-dom';
import { useEffect } from 'react';
import axios from '../../api/axios';
import Skeleton from '@mui/material/Skeleton';
import CreateIcon from '@mui/icons-material/Create';
import { styled } from '@mui/material/styles';
import Notify from '../../features/helpers/notifyConfig';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const HeaderBox = ({ heading, content }) => {
    return (
        <Box border={1} borderRadius={0.5} borderColor="dark_divider" bgcolor="background.light_gray" sx={{ wordWrap: 'break-word' }}>
            <Box borderBottom={2} borderColor="dark_divider" pl={2}><Typography variant='subtitle1' sx={{ fontWeight: 600 }}>{heading}</Typography></Box>
            <Box>
                {content}
            </Box>
        </Box>
    )
}

const userContent = (team, designation, contact, address) => {
    return (
        <Box p={2}>
            <Grid container spacing={3}>
                {/* Category of Employee */}
                <Grid item xs="auto" md={6}>

                    <Grid container direction='row'>
                        <Grid item md={2}><HiOutlineUserGroup size={20} color='#648EFB' /></Grid>
                        <Grid item md={10}>
                            {
                                team.map((val) =>
                                    <Typography variant='subtitle2'>{val}</Typography>
                                )}
                        </Grid>
                    </Grid>
                </Grid>

                {/* Position of Employee */}
                <Grid item xs="auto" md={6}>
                    <Grid container direction='row'>
                        <Grid item md={2}><FaRegUser color='#648EFB' /></Grid>
                        <Grid item md={10}>
                            <Typography variant='subtitle2'>{designation}</Typography>
                        </Grid>
                    </Grid>
                </Grid>

                {/* Contact of Employee */}
                <Grid item xs="auto" md={6}>
                    <Grid container direction='row'>
                        <Grid item md={2}><FiPhone color='#648EFB' /></Grid>
                        <Grid item md={10}>
                            <Typography variant='subtitle2'>{contact}</Typography>
                        </Grid>
                    </Grid>
                </Grid>

                {/* Location of Employee */}
                <Grid item xs="auto" md={6}>
                    <Grid container direction='row'>
                        <Grid item md={2}><TbLocation color='#648EFB' /></Grid>
                        <Grid item md={10}>
                            <Typography variant='subtitle2'>{address}</Typography>
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>
        </Box >
    )
}

const basicInfoContent = (empid, lastname, firstname, email, lastModify, cert_name, Date_of_Birth) => {
    return (
        <Box>
            <Grid container spacing={3} p={2}>
                <Grid item md={12}>
                    <Grid container spacing={3}>
                        <Grid item sm={6}> <Typography variant='subtitle2'>Employee ID</Typography> </Grid>
                        <Grid item sm={6}> <Typography variant='subtitle2'>{empid}</Typography> </Grid>
                    </Grid>
                </Grid>

                <Grid item md={12}>
                    <Grid container spacing={3}>
                        <Grid item sm={6}> <Typography variant='subtitle2'>First Name</Typography> </Grid>
                        <Grid item sm={6}> <Typography variant='subtitle2'>{firstname}</Typography> </Grid>
                    </Grid>
                </Grid>

                <Grid item md={12}>
                    <Grid container spacing={3}>
                        <Grid item sm={6}> <Typography variant='subtitle2'>Last Name</Typography> </Grid>
                        <Grid item sm={6}> <Typography variant='subtitle2'>{lastname}</Typography> </Grid>
                    </Grid>
                </Grid>

                <Grid item md={12}>
                    <Grid container spacing={3}>
                        <Grid item sm={6}> <Typography variant='subtitle2'>Date of Birth</Typography> </Grid>
                        <Grid item sm={6}> <Typography variant='subtitle2'>{Date_of_Birth}</Typography> </Grid>
                    </Grid>
                </Grid>

                <Grid item md={12}>
                    <Grid container spacing={3}>
                        <Grid item sm={6}> <Typography variant='subtitle2'>Email ID</Typography> </Grid>
                        <Grid item sm={6}> <Typography variant='subtitle2'>{email}</Typography> </Grid>
                    </Grid>
                </Grid>

                <Grid item md={12}>
                    <Grid container spacing={3}>
                        <Grid item sm={6}> <Typography variant='subtitle2'>Last Modification</Typography> </Grid>
                        <Grid item sm={6}> <Typography variant='subtitle2'>{lastModify}</Typography> </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Box borderBottom={2} borderColor="dark_divider" mt={1} mb={2}><Typography px={2} variant='subtitle1' sx={{ fontWeight: 600 }}>Certification</Typography></Box>
            <Grid container spacing={3} px={2}>
                <Grid item md={12} pb={2}>

                    <Grid container columnSpacing={3} rowSpacing={2}>
                        {cert_name.map((val) =>
                            <>
                                <Grid item sm={6}> <Typography variant='subtitle2'>Certification Name</Typography> </Grid>
                                <Grid item sm={6}> <Typography variant='subtitle2'>{val}</Typography> </Grid></>)}
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    )
}

const workContent = (team, reportTo, status, doj, designation) => {
    return (
        <Box p={2}>
            <Grid container spacing={3}>
                <Grid item md={12}>
                    <Grid container spacing={3}>
                        <Grid item sm={6}> <Typography variant='subtitle2'>Team</Typography> </Grid>
                        <Grid item sm={6}>
                            {
                                team.map((val) =>
                                    <Typography variant='subtitle2'>{val}</Typography>
                                )}
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item md={12}>
                    <Grid container spacing={3}>
                        <Grid item sm={6}> <Typography variant='subtitle2'>Reporting to</Typography> </Grid>
                        <Grid item sm={6}> <Typography variant='subtitle2'>{reportTo}</Typography> </Grid>
                    </Grid>
                </Grid>

                <Grid item md={12}>
                    <Grid container spacing={3}>
                        <Grid item sm={6}> <Typography variant='subtitle2'>Status</Typography> </Grid>
                        <Grid item sm={6}> <Typography variant='subtitle2'>{status}</Typography> </Grid>
                    </Grid>
                </Grid>

                <Grid item md={12}>
                    <Grid container spacing={3}>
                        <Grid item sm={6}> <Typography variant='subtitle2'>Date of joining</Typography> </Grid>
                        <Grid item sm={6}> <Typography variant='subtitle2'>{doj}</Typography> </Grid>
                    </Grid>
                </Grid>

                <Grid item md={12}>
                    <Grid container spacing={3}>
                        <Grid item sm={6}> <Typography variant='subtitle2'>Designation</Typography> </Grid>
                        <Grid item sm={6}> <Typography variant='subtitle2'>{designation}</Typography> </Grid>
                    </Grid>
                </Grid>

            </Grid>
        </Box>
    )
}

const emergencyContent = (name, contact, relation) => {
    return (
        <Box p={2}>
            <Grid container spacing={3}>
                <Grid item md={12}>
                    <Grid container spacing={4}>
                        <Grid item sm={6}> <Typography variant='subtitle2'>Emergency Contact Name</Typography> </Grid>
                        <Grid item sm={6}> <Typography variant='subtitle2'>{name}</Typography> </Grid>
                    </Grid>
                </Grid>

                <Grid item md={12}>
                    <Grid container spacing={3}>
                        <Grid item sm={6}> <Typography variant='subtitle2'>Emergency Contact</Typography> </Grid>
                        <Grid item sm={6}> <Typography variant='subtitle2'>{contact}</Typography> </Grid>
                    </Grid>
                </Grid>

                <Grid item md={12}>
                    <Grid container spacing={3}>
                        <Grid item sm={6}> <Typography variant='subtitle2'>Relation</Typography> </Grid>
                        <Grid item sm={6}> <Typography variant='subtitle2'>{relation}</Typography> </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    )
}

const AssetContent = (asset) => {
    return (
        <Box p={2}>
            <Grid container spacing={3}>
                {asset.map((val) =>
                    <Grid item md={12}>
                        <Grid container spacing={4}>
                            <Grid item sm={6}> <Typography variant='subtitle2'>{val.type + " " + val.device}</Typography> </Grid>
                            <Grid item sm={6}> <Typography variant='subtitle2'>{val.mac_address}<br />{val.type=='Personal'?'':<span style={{ color: 'green' }}>${val.price}</span>}</Typography> </Grid>
                            {/* <Grid item sm={6}> <Typography variant='subtitle2'>{val.type}</Typography> </Grid>
                        <Grid item sm={6}> <Typography variant='subtitle2'>{val.device}</Typography> </Grid>
                        <Grid item sm={6}> <Typography variant='subtitle2'>{val.mac_address}</Typography> </Grid>
                        <Grid item sm={6}> <Typography color='green' variant='subtitle2'>$ {val.price}</Typography> </Grid> */}
                        </Grid>
                    </Grid>
                )}

            </Grid>
        </Box>
    )
}

export const EmployeeData = () => {
    const [userData, setuserData] = useState();
    const [file, setFile] = useState();
    const [refresh, setRefresh] = useState(false);

    const handleChange = (e) => {
        const file = e.target.files[0];
        if (!file) {
            setFile();
            return;
        }

        var fileExt = file.name.split('.').pop().toLowerCase();
        if (fileExt != "jpg" && fileExt != "png" && fileExt != "jpeg" && fileExt != "svg") {
            setFile();
            Notify.error("Only JPG, JPEG, PNG formats are allowed!")
        }
        else {
            if (file.size < 15000000) {
                setFile(file);
            }
            else {
                setFile();
                Notify.error("File Should not be greater than 15MB")
            }
        }
    }

    const handleUpload = async () => {
        try {
            const formData = new FormData();
            formData.append('profile_picture', file);

            const response = await axios.post('/dashboard/employeedata/uploadprofilepicture', formData, {
                headers: {
                    'Content-type': 'multipart/form-data',
                    'Authorization': `Bearer ` + localStorage.getItem('access'),
                    'Access-Control-Allow-Origin': '*',
                    withCredentials: true,
                    mode: 'no-cors',
                },
            });
            console.log('Upload success:', response.data);
            Notify.success("Profile Image Uploaded Successfully!");
            setFile();
            setRefresh(prev => !prev)
        } catch (error) {
            console.error('Upload error:', error);
            Notify.error("Profile Image Upload Failed!");
            setFile();
        }
    };

    useEffect(() => {
        if (file) {
            handleUpload();
        }
    }, [file])

    const Address = (userData?.Address) ? userData.Address : '-';
    const ContactNo = (userData?.ContactNo) ? userData.ContactNo : '-';
    const Date_of_Joining = (userData?.Date_of_Joining) ? userData.Date_of_Joining : '-';
    const Designation = (userData?.Designation) ? userData.Designation : '-';
    const Emergency_Contact_Name = (userData?.Emergency_Contact_Name) ? userData.Emergency_Contact_Name : '-';
    const Emergency_Contact_Number = (userData?.Emergency_Contact_Number) ? userData.Emergency_Contact_Number : '-';
    const Emergency_Relation = (userData?.Emergency_Relation) ? userData.Emergency_Relation : '-';
    const FirstName = (userData?.FirstName) ? userData.FirstName : '-';
    const LastName = (userData?.LastName) ? userData.LastName : '-';
    const ReportingTo = (userData?.ReportingTo) ? userData.ReportingTo : '-';
    const emp_id = (userData?.emp_id) ? userData.emp_id : '-';
    const id = (userData?.id) ? userData.id : '-';
    const mail = (userData?.mail) ? userData.mail : '-';
    const status = (userData?.status) ? userData.status : '-';
    const team = (userData?.team) ? userData.team : '-';
    const cert_name = (userData?.Certificate_Name) ? userData.Certificate_Name : '-';
    const Last_modification = (userData?.Last_modification) ? userData.Last_modification : '-';
    const Assets = (userData?.Assets) ? userData.Assets : '-';
    const Date_of_Birth = (userData?.Date_of_Birth) ? userData.Date_of_Birth : '-';

    const loaderStyle = {
        borderRadius: '5px',
        width: '100%'
    }

    const getEmplyeeData = async () => {
        const response = await axios.get("/dashboard/employeedata",
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ` + localStorage.getItem('access')
                }
            }
        ).then((response) => {
            console.log(response);
            setuserData(response.data);
        }).catch((error) => {
            console.log(error.response?.data)
        });
    }

    useEffect(() => {
        getEmplyeeData();
    }, [refresh])

    const boxBackground = {
        backgroundImage: "url(./assets/application-programming-interface-software.jpg)",
        boxShadow: "inset 0 0 0 1000px rgba(0,0,0,.3)"
    }

    const editProfile = {
        paddingRight: 5,
        paddingTop: 0.5,
        paddingBottom: 0.5,
        boxShadow: "0px 0px 6px #ae9f9f"
    }

    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         console.log("Sayonara!");
    //     }, 200);

    //     return () => {
    //         clearInterval(interval);
    //     };
    // }, [])
    // console.log(userData?userData.profile_picture:"");

    return (
        <Box>
            <Grid>
                {console.log(userData)}
                <Box p={2} sx={{ ...boxBackground }}>
                    {userData ?
                        <Stack direction='row' alignItems='baseline'>
                            {userData.profile_picture ?
                                <Box ml={1}
                                    sx={{
                                        width: '5rem',
                                        height: '5rem',
                                        backgroundImage: `url(${userData?.profile_picture})`,
                                        backgroundSize: 'cover', // Adjusted property
                                        backgroundPosition: 'center', // Optional: You can adjust the position
                                        borderRadius: '50%'
                                    }}></Box>
                                : <Box pl={1}><UserIcon sx={{ width: '5rem', height: '5rem' }} /></Box>}

                            <IconButton onc component="label">
                                <CreateIcon sx={{ color: "divider" }} />
                                <VisuallyHiddenInput onChange={handleChange} type="file" />
                            </IconButton>

                        </Stack> : <Box pl={1}><UserIcon sx={{ width: '5rem', height: '5rem' }} /></Box>}
                </Box>
            </Grid>

            <Stack direction="row-reverse" spacing={2} sx={{ ...editProfile }}>
                <NavLink style={{ textDecoration: "none", fontWeight: "bold" }} to="document">Document</NavLink>
                {/* <Link href="/EmployeeData/edit" underline="hover">Edit</Link> */}
                {userData ? <NavLink style={{ textDecoration: "none", fontWeight: "bold" }} to="edit" state={userData}>Edit</NavLink> : ""}
            </Stack>

            <Box px={2} py={2}>
                <Grid container spacing={1}>
                    <Grid item lg={4} md={6} sm={12}>
                        <Stack spacing={1}>
                            {!userData ?
                                <><Skeleton animation="wave" variant="rectangular" sx={{ ...loaderStyle }} height={140} />
                                    <Skeleton animation="wave" variant="rectangular" sx={{ ...loaderStyle }} height={250} /></> :
                                <><HeaderBox heading="User" content={userContent(team, Designation, ContactNo, Address)} />
                                    <HeaderBox heading="Work" content={workContent(team, ReportingTo, status, Date_of_Joining, Designation)} />
                                </>
                            }
                        </Stack>
                    </Grid>
                    <Grid item lg={5} md={6} sm={12}>
                        {!userData ?
                            <Skeleton animation="wave" variant="rectangular" sx={{ ...loaderStyle }} height={381} /> :
                            <HeaderBox heading="Basic Information" content={basicInfoContent(emp_id, LastName, FirstName, mail, Last_modification, cert_name, Date_of_Birth)} />
                        }
                    </Grid>
                    <Grid item lg={3} md={6} sm={12}>
                        <Stack spacing={1}>
                            {!userData ?
                                <>
                                    <Skeleton animation="wave" variant="rectangular" sx={{ ...loaderStyle }} height={197} />
                                    <Skeleton animation="wave" variant="rectangular" sx={{ ...loaderStyle }} height={197} /></> :
                                <>
                                    <HeaderBox heading="Emergency Contact" content={emergencyContent(Emergency_Contact_Name, Emergency_Contact_Number, Emergency_Relation)} />
                                    <HeaderBox heading="Assets" content={AssetContent(Assets)} />
                                </>
                            }
                        </Stack>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}