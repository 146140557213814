import { useEffect, useState } from 'react'
import axios from '../../api/axios';
import { Box, Button,Skeleton, Grid, Stack, Typography, DialogActions, Dialog, DialogTitle, DialogContent, DialogContentText } from '@mui/material';
import Paper from '@mui/material/Paper';
import { MainTable } from '../../components/ui/Table/MainTable';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import AdsClickIcon from '@mui/icons-material/AdsClick';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import AddIcon from '@mui/icons-material/Add';
import { Form } from '../../components/ui/Form';
import LoadingButton from '@mui/lab/LoadingButton';
import { useParams } from 'react-router-dom';
import {Token} from '../../constants'

export const ArchivedSalesPipeline = () => {

    const [currentSales, setcurrentSales] = useState();
    const [open, setOpen] = useState(false);
    const [addForm, setAddForm] = useState(false);
    const [addData, setaddData] = useState();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({
        Customer: "", TypeOfProject: "", Channel: "", Reseller: "", EC_PointOfContact: "", Client_POC_Email: "", Client_PointOfContact: "", DateSold: "",
        Quarter: Math.ceil((new Date().getMonth()+1)/3), Year: new Date().getFullYear(), Stage: "", ProjectSize: "", ChancesOfWinning: "", WonLost: "", NotesOnFollow: ""
    });

    const border = {
        borderRadius: "10px"
    }

    const requested_year =useParams().year;

    const handleClickOpen = () => {
        setOpen(true);
        setLoading(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const submitAddForm = () => {
        setAddForm(true);
    }

    const getCurrentSalespipeline = async () => {
        await axios.get(`/dashboard/salespipeline/${requested_year}`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ` + localStorage.getItem('access')
                }
            }
        ).then((response) => {
            console.log(response);
            setcurrentSales(response.data)
        }).catch((error) => {
            console.log(error.response)
        });
    }

    const getAddSalespipelineData = async () => {
        await axios.get("/dashboard/salespipeline/2024/add",
            {
                headers: {
                    'Content-Type': 'application/json',
                    // 'Authorization': `Bearer ` + Token.Test
                    'Authorization': `Bearer ` + localStorage.getItem('access')
                }
            }).then((response) => {
                setaddData(response.data)
                setLoading(false);
            }).catch((error) => {
                console.log(error.response)
            })
    }

    const addNewSalesDetails = async () => {
        await axios.post("/dashboard/salespipeline/2024/add",
            JSON.stringify({ "2024": data }),
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, PUT, DELETE',    
            'Access-Control-Allow-Headers': 'Content-Type, Origin, Authorization',
                    'Content-Type': 'application/json',
                    withCredentials: true,
                    mode: 'no-cors',
                    // 'Authorization': `Bearer ` + Token.Test
                    'Authorization': `Bearer ` + localStorage.getItem('access'),
                }
            }).then((response) => {
                console.log(response);
                setAddForm(false);
                handleClose();
            }).catch((error) => {
                console.log(error.response)
            })
    }

    useEffect(() => {
        getCurrentSalespipeline();

        if (addForm)
            addNewSalesDetails();

    }, [addForm])

    useEffect(() => {
        if (open)
            getAddSalespipelineData();
    }, [open])

    return (
        <Box p={4}>
        {currentSales ?
            <Box>
                <Grid container spacing={4}>
                    <Grid item xs>
                        <Paper sx={{ padding: "13px" }} elevation={2}>
                            <Stack direction="row" alignItems="center">
                                <AttachMoneyIcon sx={{ transform: "scale(1.8)", color: "dark_divider" }} />
                                <Box px={2}>
                                    <Typography color="primary.light" fontWeight="600" variant='subtitle2'>Current Year Project Total</Typography>
                                    <Typography variant='subtitle2'>{currentSales.calculation['Current_Year_Total']}</Typography>
                                </Box>
                            </Stack>
                        </Paper>
                    </Grid>
                    <Grid item xs>
                        <Paper sx={{ padding: "13px" }} elevation={2}>
                            <Stack direction="row" alignItems="center">
                                <MoneyOffIcon sx={{ transform: "scale(1.8)", color: "dark_divider" }} />
                                <Box px={2}>
                                    <Typography color="primary.light" fontWeight="600" variant='subtitle2'>Prior Year Project Total</Typography>
                                    <Typography variant='subtitle2'>{currentSales.calculation['Prior_Year_Total']}</Typography>
                                </Box>
                            </Stack>
                        </Paper>
                    </Grid>
                    <Grid item xs>
                        <Paper sx={{ padding: "13px" }} elevation={2}>
                            <Stack direction="row" alignItems="center">
                                <AdsClickIcon sx={{ transform: "scale(1.8)", color: "dark_divider" }} />
                                <Box px={3}>
                                    <Typography color="primary.light" fontWeight="600" variant='subtitle2'>Current Year Target Goal</Typography>
                                    <Typography variant='subtitle2'>{currentSales.calculation['Current_Year_Target_Goal']}</Typography>
                                </Box>
                            </Stack>
                        </Paper>
                    </Grid>
                    <Grid item xs>
                        <Paper sx={{ padding: "13px" }} elevation={2}>
                            <Stack direction="row" alignItems="center">
                                <EmojiEventsIcon sx={{ transform: "scale(1.8)", color: "dark_divider" }} />
                                <Box px={3}>
                                    <Typography color="primary.light" fontWeight="600" variant='subtitle2'>Goal Achievement</Typography>
                                    <Typography variant='subtitle2'>{currentSales.calculation['Goal_Achievement']}</Typography>
                                </Box>
                            </Stack>
                        </Paper>
                    </Grid>
                </Grid>

                <Box mt={3}>
                    <MainTable data={currentSales.message} />
                </Box>

            </Box> :
            <Box>
            <Grid container spacing={4}>
                <Grid item xs><Skeleton variant='rectangle' width="100%" height={80} sx={{ border }} /></Grid>
                <Grid item xs><Skeleton variant='rectangle' width="100%" height={80} sx={{ border }} /></Grid>
                <Grid item xs><Skeleton variant='rectangle' width="100%" height={80} sx={{ border }} /></Grid>
                <Grid item xs><Skeleton variant='rectangle' width="100%" height={80} sx={{ border }} /></Grid>
            </Grid>
        
        {/* <Button><Skeleton variant='rectangle' width="100%" height={80} sx={{ border }} /></Button> */}
        <Skeleton variant='rectangle' width="100%" height={350} sx={{ ...border, mt:"4rem" }} />
        </Box>}
        </Box>
    )
}
