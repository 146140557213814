import { useEffect, useState } from 'react';
import { Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, Menu, MenuItem, Modal, Pagination, Skeleton, Stack, Tab, Tooltip, Typography } from '@mui/material';
import UserIcon from '@mui/material/Avatar';
import { DataGrid } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';
import { Cancel } from '@mui/icons-material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { SimpleForms } from '../Forms/SimpleForms';
import Notify from '../../../features/helpers/notifyConfig';
import axios from '../../../api/axios';

export function CompanyTable({ table_data, setLoading }) {
    const [anchorElMap, setAnchorElMap] = useState({});
    const [open, setOpen] = useState(false);
    const [confirm, isConfirm] = useState("");
    const [data, setData] = useState({
        _id: "", company_name: "", contact_name: "", contact_email: "", contact_address: ""
    });

    const openEdit = ({ id, company_name, contact_address, contact_email, contact_name }) => {
        handleClose();
        setData(prevState => ({
            ...prevState,
            _id: id, company_name: company_name, contact_name: contact_name, contact_email: contact_email, contact_address: contact_address
        }));
        setOpen(true);
    };

    const openDelete = (delData) => {
        handleClose();
        isConfirm(delData);
    };

    const handleClick = (event, id) => {
        setAnchorElMap(prevMap => ({
            ...prevMap,
            [id]: event.currentTarget,
        }));
    };

    const handleClose = () => {
        setOpen(false);
        isConfirm("");
        setAnchorElMap({});
    };

    const rows = Object.keys(table_data).map((key, val) => ({
        id: key,
        ...table_data[key],
    }));

    const pageSize = 5;
    const [page, setPage] = useState(0);
    const pageCount = Math.ceil(rows.length / pageSize);
    const rowsOnPage = rows.slice(page * pageSize, (page + 1) * pageSize);

    const columns = [
        { field: 'company_name', headerName: 'Name', flex: 1, headerClassName: 'whiteMenuIcon' },
        { field: 'contact_name', headerName: 'Contact Name', flex: 1, headerClassName: 'whiteMenuIcon',
            renderCell: (params) => params.row.contact_name == "" ? <Typography width='100%' textAlign='center'>-</Typography> : <Typography>{params.row.contact_name}</Typography> 
         },
        { field: 'contact_email', headerName: 'Contact Email', flex: 1, headerClassName: 'whiteMenuIcon',
            renderCell: (params) => params.row.contact_email == "" ? <Typography width='100%' textAlign='center'>-</Typography> : <Typography>{params.row.contact_email}</Typography> 
        },
        { field: 'contact_address', headerName: 'Contact Address', flex: 1, headerClassName: 'whiteMenuIcon',
            renderCell: (params) => params.row.contact_address == "" ? <Typography width='100%' textAlign='center'>-</Typography> : <Typography fontWeight={'600'}>{params.row.contact_address}</Typography> 
         },
        {
            field: 'Action', headerName: 'Action', flex: 1, headerClassName: 'whiteMenuIcon', renderCell: (params) => {
                return (<Box>
                    <IconButton
                        id={`basic-button-${params.id}`}
                        aria-controls={anchorElMap[params.id] ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={anchorElMap[params.id] ? 'true' : undefined}
                        onClick={(event) => handleClick(event, params.id)}
                    >
                        <MoreVertIcon />
                    </IconButton>
                    <Menu
                        id={`basic-menu-${params.id}`}
                        anchorEl={anchorElMap[params.id]}
                        open={Boolean(anchorElMap[params.id])}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                        PaperProps={{
                            style: {
                                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                            },
                        }}
                    >
                        {/* <MenuItem onClick={() => openEdit(params.row)}>Edit</MenuItem> */}
                        <MenuItem onClick={() => openDelete(params.row)}>Delete</MenuItem>
                    </Menu>
                </Box>)
            }
        },
    ];

    //Edit Request for Company
    const submitAddForm = () => {
        let hasWarning = false;

        if (data.company_name === "") {
            Notify.warning("Company Name must be mentioned!");
            hasWarning = true;
        }
        else if (data.contact_name === "") {
            Notify.warning("Contact Name must be mentioned!");
            hasWarning = true;
        }
        else if (data.contact_email === "") {
            Notify.warning("Contact Email must be mentioned!");
            hasWarning = true;
        }

        if (!hasWarning) {
            const editCompany = async () => {
                await axios.put("/quote-generation/company/update", 
                        JSON.stringify(data),
                        {
                            headers: {
                                'Access-Control-Allow-Origin': '*',
                                'Content-Type': 'application/json',
                                withCredentials: true,
                                mode: 'no-cors',
                                'Authorization': `Bearer ` + localStorage.getItem('access')
                            }
                        }).then((response) => {
                            console.log(response);
                            setOpen(false);
                            setLoading(prev => !prev);
                            Notify.success("Company Changes has been Saved!")
                        }).catch((error) => {
                            console.log(error.data);
                            Notify.error("Error Occurred. Try Again!");
                        })
            }
            editCompany();
        }
    }

    //Delete Company  
    const deleteRecord = (id) => {
        isConfirm("");
        const deleteCompany = async () => {
            await axios.delete(`/quote-generation/company/delete`,
                {
                    headers: {
                        'Authorization': `Bearer ` + localStorage.getItem('access')
                    },
                    data: {
                        "_id": id
                    }
                }).then((response) => {
                    Notify.success('Company Deleted Successfully!');
                    setLoading(prev => !prev);
                    handleClose();
                }).catch((error) => {
                  Notify.error("Company Deletion Error");
                })
        }
        deleteCompany();
    }

    return (
        <>
            <DataGrid
                rows={rowsOnPage}
                columns={columns}
                // pageSize={pageSize}
                disableColumnSelector
                hideFooter
                // showCellVerticalBorder
                disableSelectionOnClick
                rowHeight={65}
                style={{ backgroundColor: 'white' }}
                sx={{
                    '& .MuiDataGrid-columnHeaders': {
                        backgroundColor: '#657EBF'
                    },
                    '& .MuiDataGrid-cell': {
                        fontSize: '15px',
                        paddingY: '25px'
                    },
                    '& .MuiDataGrid-columnHeaderTitle': {
                        fontWeight: 'bold',
                        fontSize: '16px'
                    },
                    '& .MuiDataGrid-columnSeparator': {
                        display: 'none'
                    },
                    '& .MuiInputBase-input': {
                        height: '20px !important'
                    },
                    '& .MuiDataGrid-cell:focus, & .MuiDataGrid-columnHeader:focus': {
                        outline: 'none',
                        boxShadow: 'none',
                        border: 'none'
                    },
                }}
            />
            <Stack borderRadius='10px' bgcolor='white' mt={0.2} py={1.5} justifyContent='center' direction='row' width='100%'>
                <Pagination
                    count={pageCount}
                    page={page + 1}
                    onChange={(event, value) => setPage(value - 1)} // Adjust for 0-based index
                    shape="rounded"
                />
            </Stack>

            {/* Edit Company Details */}
            {data ? <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="edit-apartment"
                fullWidth
                maxWidth='sm'
            >
                <DialogTitle sx={{ backgroundColor: "#F6F5F5" }} id="edit-apartment">Add Company</DialogTitle>
                <DialogContent sx={{ backgroundColor: "#F6F5F5" }}>
                    <SimpleForms data={data} setData={setData} type="company" />
                </DialogContent>
                <DialogActions sx={{ backgroundColor: "#F6F5F5" }}>
                    <Button sx={{ position: "absolute", top: 10, right: 0 }} onClick={handleClose} color="secondary">
                        <Cancel sx={{ fontSize: "1.8rem" }} />
                    </Button>
                    <Button variant='contained' color="primary" onClick={submitAddForm}>Save Changes</Button>
                </DialogActions>
            </Dialog> : <></>}

            {/* Delete dialog box */}
            {   confirm!=""?
                <Dialog maxWidth='xs' onClose={handleClose} open={confirm!=""?true:false}>
                    <DialogContent>
                        <Typography mb={4} variant='h6'>Are you sure you want to delete <span style={{color: 'red'}}>{confirm.company_name}</span> ? </Typography>
                        <Button variant='contained' color='error' onClick={()=>deleteRecord(confirm.id)}>Delete</Button>
                    </DialogContent>
                </Dialog>
                :<></>
            }
        </>
    );
}