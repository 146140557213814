import { Box, Grid, Button, Typography, Stack, Skeleton, AccordionActions } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { TimeSheetWeek } from '../../features/components/TimeSheet/TimeSheetWeek';
import { TimeSheetHours } from '../../features/components/TimeSheet/TimeSheetHours';
import UserIcon from '@mui/material/Avatar';
import { useEffect, useState, version } from 'react';
import axios from '../../api/axios';
import dateFormat from 'dateformat';
import { TimeSheetTable } from '../../features/components/TimeSheet/TimeSheetTable';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { Token } from '../../constants';
import { LoadingButton } from '@mui/lab';
import ReplayIcon from '@mui/icons-material/Replay';
import { NodataFound } from '../NodataFound';
import Notify from '../../features/helpers/notifyConfig';
import { TimeSheetNewTable, TimeSheetTitles } from '../../features/components/TimeSheet/TimeSheetNewTable';
import { EditTimesheet } from './EditTimeSheet';


const ReturnedTimesheet = ({ boxStyle, billButtonStyle, activeSheet, setActiveSheet }) => {
    const [submit, triggerSubmit] = useState();
    const [remain_hours, setHours] = useState({ mon: 8, tue: 8, wed: 8, thu: 8, fri: 8, sat: 0, sun: 0 });
    const defaultState = {
        "projectID": "",
        "taskID": "",
        "billable": true,
        "workDay": {
            "fri": {
                "comment": activeSheet.work_schedule.fri.comment ?? "",
                "hour": 0,
                "work": activeSheet.work_schedule.fri.work ?? false
            },
            "mon": {
                "comment": activeSheet.work_schedule.mon.comment ?? "",
                "hour": 0,
                "work": activeSheet.work_schedule.mon.work ?? false
            },
            "sat": {
                "comment": activeSheet.work_schedule.sat.comment ?? "",
                "hour": 0,
                "work": activeSheet.work_schedule.sat.work ?? false
            },
            "sun": {
                "comment": activeSheet.work_schedule.sun.comment ?? "",
                "hour": 0,
                "work": activeSheet.work_schedule.sun.work ?? false
            },
            "thu": {
                "comment": activeSheet.work_schedule.thu.comment ?? "",
                "hour": 0,
                "work": activeSheet.work_schedule.thu.work ?? false
            },
            "tue": {
                "comment": activeSheet.work_schedule.tue.comment ?? "",
                "hour": 0,
                "work": activeSheet.work_schedule.tue.work ?? false
            },
            "wed": {
                "comment": activeSheet.work_schedule.wed.comment ?? "",
                "hour": 0,
                "work": activeSheet.work_schedule.wed.work ?? false
            }
        },
        "description": ""
    }
    const TimeSheetTitles = ['Project', 'Task', 'Billable', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun', 'Description'];
    const [edit, setEdit] = useState(false);


    const [sets, handleSets] = useState([]);
    const [returnedSheet, setreturnedSheet] = useState();
    const [refresh, setRefresh] = useState(false);
    const [loading, setLoading] = useState();
    const [currentSheet, setCurrentSheet] = useState();

    // console.log(returnedSheet);
    const inputStyle = {
        "& .MuiInputBase-input": {
            height: "10px"
        },
        "& .MuiInputBase-root": {
            borderRadius: "5px"
        },
        "& .MuiSelect-select": {
            paddingY: "4px"
        }
    }

    const handleSubmit = (temp, sheetID) => {
        setLoading("Submit");
        triggerSubmit({
            "timesheet": {
                "action": "Submit",
                "employeeSheetID": sheetID,
                "employeeSheetObjects": [
                    ...temp
                ]
            }
        })
        // setLoading(action == "Submit" ? "Submit" : "Draft");
        // triggerSubmit({
        //     "timesheet": {
        //         "action": action == "Submit" ? "Submit" : "Draft",
        //         "employeeSheetObjects": [
        //             ...sets
        //         ]
        //     }
        // })
    }

    const triggerEdit = (sheet) => {
        setEdit(true);
        setCurrentSheet(sheet);
    }

    const triggerDelete = (sheet) => {
        setLoading("delete");
        const postTimeSheet = async () => {
            await axios.delete("/timesheet/employee/delete",{
                headers: {
                    'Authorization': `Bearer ` + localStorage.getItem('access')
                },
                data: {
                    "timesheet": {
                        "employeeSheetID": sheet
                      }
                
                }}).then((response) => {
                    console.log(response);
                    setLoading();
                    setRefresh(!refresh);
                    Notify.success("Timesheet Deleted Successfully!");
                }).catch((error) => {
                    setLoading();
                    Notify.error("Error Occurred. Try Again!");
                })
        }
        postTimeSheet();
    }

    // 1.  Employee submit/draft his/her timesheet
    const postTimeSheet = async () => {
        await axios.post("/timesheet/employee/action",
            JSON.stringify(submit),
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json',
                    withCredentials: true,
                    mode: 'no-cors',
                    'Authorization': `Bearer ` + localStorage.getItem('access')
                }
            }).then((response) => {
                console.log(response);
                setRefresh(!refresh);
                setLoading();
                Notify.success("Timesheet Submitted Successfully!");
            }).catch((error) => {
                // console.log(error.response)
                setRefresh(!refresh);
                setLoading();
                Notify.error("Error Occurred. Try Again!");
            })
    }
    useEffect(() => {
        if (submit) {
            postTimeSheet();
        }
    }, [submit])

    // 2.  Employee gets returned timesheets data.
    const getReturnTimesheet = async () => {
        await axios.get("/timesheet/employee/active",
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ` + localStorage.getItem('access')
                    // 'Authorization': `Bearer ` + Token.Manmit
                }
            }
        ).then((response) => {
            console.log(response);
            setreturnedSheet((response?.data?.employeeSheets?.Returned) ? (response.data.employeeSheets.Returned) : "nodata");
            // setRefresh(false);
        }).catch((error) => {
            console.log(error.response);
            Notify.error("Error Occurred")
            // setRefresh(false);
        });
    }

    useEffect(() => {
        getReturnTimesheet();
    }, [refresh])

    return (
        returnedSheet == "nodata" ? <NodataFound msg='No Returned TimeSheets Here!' /> :
            <Box mt={3}>
                <Grid container gap={2}>
                    {returnedSheet ?
                        returnedSheet.map((val, key) => {
                            const sheetID = val.employeeSheetID;
                            const endDate = val.endDate;
                            const startDate = val.startDate;
                            const message = val.ReturnedMessage;
                            var temp = [];
                            // alert(endDate);
                            return (
                                <Accordion defaultExpanded={key == 0 ? true : false} sx={{ width: '100%' }} key={sheetID}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                    >
                                        <Grid container alignItems="center" justifyContent="space-between">
                                            <Typography variant='subtitle1'>{dateFormat(startDate, "d mmmm, yyyy")}  -  {dateFormat(endDate, "d mmmm, yyyy")}</Typography>
                                            <Stack pr={3} direction="row" alignItems="center"><FiberManualRecordIcon sx={{ transform: "scale(0.5)", color: "red" }} />Returned</Stack>
                                        </Grid>
                                    </AccordionSummary>

                                    {
                                        (edit && currentSheet == sheetID) ?
                                            <EditTimesheet sheetData={val.employeeSheetObjects} activeSheet={activeSheet} employeeSheetID={sheetID} setRefresh={setRefresh} />
                                            :
                                            <AccordionDetails>
                                                <Box>
                                                    <Box my={2} border='1px solid' borderColor="dark_divider">
                                                        <Grid gap={1} container columns={16.5} py={0.3} px={1} alignItems='center' sx={{
                                                            backgroundColor: 'primary.light',
                                                            color: 'primary.white'
                                                        }}>
                                                            {TimeSheetTitles.map((title, key) => {

                                                                return (
                                                                    <Grid key={key} item my={1} md={title == "Project" || title == "Task" || title == "Description" ? 2 : 1}>
                                                                        <Typography
                                                                            variant='subtitle2'>
                                                                            {title}
                                                                        </Typography>
                                                                    </Grid>
                                                                )
                                                            })}
                                                        </Grid>
                                                        <Grid gap={1} container columns={16.5} py={1} px={1} alignItems='center'>
                                                            {val.employeeSheetObjects.map((data, key) => {
                                                                temp.push({
                                                                    "projectID": data.Project.projectID,
                                                                    "taskID": data.Task.taskID,
                                                                    "billable": data.billable,
                                                                    "description": data.description,
                                                                    "workDay": data.workDay
                                                                })
                                                                return (
                                                                    <TimeSheetNewTable key={key} otherempData={data} />
                                                                )
                                                            })}
                                                        </Grid>
                                                        <Box textAlign='center'><Box border='solid 1px' sx={{color:"primary.main"}} mx={5} mb={2} px={2} py={0.5} display='inline-block'><b>Reason Of Return: </b>{message}</Box></Box>
                                                    </Box>
                                                </Box>

                                                <AccordionActions>
                                                    <LoadingButton loading={loading=="delete"} onClick={() => triggerDelete(sheetID)} sx={{ bgcolor: "error.main" }} variant='contained'>Delete</LoadingButton>
                                                    <Button onClick={() => triggerEdit(sheetID)} variant='contained'>Edit</Button>
                                                    <LoadingButton loading={loading=="Submit"} onClick={() => handleSubmit(temp, sheetID)} variant='outlined'>Submit</LoadingButton>
                                                </AccordionActions>
                                            </AccordionDetails>}
                                </Accordion>)
                        })
                        :
                        <Stack gap={2} width="100%">
                            <Skeleton animation="wave" variant="rectangular" width="100%" height={80} />
                            <Skeleton animation="wave" variant="rectangular" width="100%" height={80} />
                        </Stack>
                    }

                </Grid>
            </Box>
    )
}

export default ReturnedTimesheet