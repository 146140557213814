import * as React from 'react';
// import { BarChart } from '@mui/x-charts/BarChart';
import axios from '../../api/axios';
import { useEffect } from 'react';
import { Box, Button, Dialog, DialogContent, DialogTitle, Grid, LinearProgress, Paper, Stack, Typography } from '@mui/material';
import { BarChart, Bar, Cell, Rectangle, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Cancel } from '@mui/icons-material';
import { LeaveAccordion } from './LeaveAccordion';

export function LeaveHistory() {
    const [data, setData] = useState(null);
    const [open, setOpen] = useState(null);

    const CustomLegend = ({ payload }) => {
        return (
            <Stack direction='row' spacing={5} justifyContent='center'>
                <div style={{ marginBottom: '10px' }}>
                    <span style={{ backgroundColor: "#1B2C58", width: '20px', height: '15px' }}>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <span>&nbsp;PTO Earned</span>
                </div>

                <div style={{ marginBottom: '10px' }}>
                    <span style={{ backgroundColor: "#82ca9d", width: '20px', height: '15px' }}>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <span>&nbsp;PTO Taken</span>
                </div>

                <div style={{ marginBottom: '10px' }}>
                    <span style={{ backgroundColor: "#EE4E4E", width: '20px', height: '15px' }}>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <span>&nbsp;PTO Exhausted</span>
                </div>
            </Stack>
        );
    };

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <Paper>
                    <Box className="custom-tooltip" paddingX={1.5} paddingY={0.1}>
                        <Typography variant='subtitle1' fontWeight={600} className="label">{label}</Typography>
                        <Typography variant='subtitle2' className="label">{`Total PTO Earned : ${payload[0].value} hours`}</Typography>
                        <Typography variant='subtitle2' className="label">{`Total PTO Taken : ${payload[1].value} hours`}</Typography>
                        <Typography variant='subtitle2' className="label">{`Remaining PTO : ${payload[2].value} hours`}</Typography>
                    </Box>
                </Paper>
            );
        }

        return null;
    };

    const showAllLeaves = (data) => {
        setOpen(data['Leave Requests']);
    }

    const handleClose = () => {
        setOpen(null);
    }

    useEffect(() => {
        const getLeavesInfo = async () => {
            await axios.get("/performance/ptoReports",
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ` + localStorage.getItem('access')
                    }
                }).then((response) => {
                    // console.log(response?.data?.pto_history);
                    setData(response?.data?.pto_history)
                }).catch((error) => {
                    console.log(error.response)
                })
        }

        getLeavesInfo();
    }, [])

    return (
        data ?
            <Grid container columnSpacing={5} rowSpacing={3} px={2} pb={3}>
                {
                    Object.keys(data).map((val, key) => {
                        var graphData = [];


                        data[val].map((vaal, kay) => {
                            graphData.push(
                                {
                                    name: vaal.employeeName,
                                    'Total PTO Earned': vaal.officially_acceptable_pto_till_now_HOURS,
                                    'PTO taken': vaal.total_pto_taken_till_now,
                                    'Remaining PTO': vaal.remaining_pto_YEARLY,
                                    'Leave Requests': vaal.LeaveRequests
                                }
                            );
                        });

                        return (
                            <Grid key={key} item md={12} lg={6}>
                                <Paper>
                                    <Box p={2}><Typography variant='h6'>{val}</Typography></Box>
                                    <Stack alignItems='center' pb={2}>
                                        <BarChart
                                            width={500}
                                            height={430}
                                            data={graphData}
                                            margin={{
                                                top: 5,
                                                right: 30,
                                                left: 0,
                                                bottom: 25,
                                            }}
                                        >
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="name" angle={-20} textAnchor="end" interval={0} />
                                            <YAxis domain={[0, 160]} />
                                            <Tooltip content={<CustomTooltip />} />
                                            <Legend verticalAlign="top" height={60} content={<CustomLegend />} />
                                            <Bar maxBarSize={25} dataKey="Total PTO Earned" fill="#1B2C58" radius={[5, 5, 0, 0]} />
                                            <Bar maxBarSize={25} dataKey="PTO taken" radius={[5, 5, 0, 0]}>
                                                {graphData.map((entry, index) => (
                                                    <Cell
                                                        style={{cursor: "pointer"}}
                                                        key={`cell-${index}`}
                                                        fill={entry['Total PTO Earned'] > entry['PTO taken'] ? '#82ca9d' : '#EE4E4E'}
                                                        onClick={() => showAllLeaves(entry)}
                                                    />
                                                ))}
                                            </Bar>
                                            <Bar dataKey="Remaining PTO" fill="transparent" barSize={0} />
                                            {/* <Data */}
                                        </BarChart>
                                    </Stack>
                                </Paper>
                            </Grid>
                        )
                    }
                    )}


                <Dialog sx={{'.MuiDialog-paper':{bgcolor: '#e9e9e9'}}} fullWidth maxWidth='sm' open={open ? true : false} onClose={handleClose}>
                    <DialogTitle>Detailed Leave History</DialogTitle>
                    <DialogContent>
                        {open
                            ?
                            open.map((value, key) => {
                                return <LeaveAccordion value={value} />
                            })
                            : <></>
                        }
                    </DialogContent>
                    <Button sx={{ position: "absolute", right: "8px", top: "13px" }} onClick={handleClose} color="secondary">
                        <Cancel color="error" />
                    </Button>
                </Dialog>
            </Grid >
            :
            <Stack justifyContent="center" alignItems="center" height="70vh">
                <Box pb={2}>Wait while we gather required information...</Box>
                <Stack gap={4} sx={{ width: '50%', color: 'grey.500' }} spacing={2}>
                    <LinearProgress color="inherit" />
                </Stack>
            </Stack>
    );
}