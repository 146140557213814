import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Skeleton, Stack, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { TimeSheetHours, getHours, getMinutes } from '../../features/components/TimeSheet/TimeSheetHours';
import { TimeSheetWeek } from '../../features/components/TimeSheet/TimeSheetWeek';
import { ButtonStyle } from './TimeSheet';
import { CreateTimesheet } from './CreateTimesheet';
import { UserContext } from './ManagerTimesheet';
import { TimesheetEntry } from '../../features/components/TimeSheet/TimesheetEntry';
import axios from '../../api/axios';
import { NodataFound } from '../NodataFound';
import Notify from '../../features/helpers/notifyConfig';
import { TimeSheetNewTable } from '../../features/components/TimeSheet/TimeSheetNewTable';
import { LoadingButton } from '@mui/lab';
import ReplayIcon from '@mui/icons-material/Replay';
import CloseIcon from '@mui/icons-material/Close';
import ReturnedSheet from './ReturnedSheet';
import WarningIcon from '@mui/icons-material/Warning';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

export const getCurrentWeekDates = (startDateStr, endDateStr) => {
    const startDate = new Date(startDateStr);
    const endDate = new Date(endDateStr);
    const startDay = startDate.getDay();
    const weekDates = [];

    for (let i = 0; i <= 6; i++) {
        const date = new Date(startDate);
        date.setDate(startDate.getDate() + i);
        weekDates.push(date);
    }

    // console.log(weekDates);
    return weekDates;
};

export const remTime =(status) =>{
    console.log(status);
    return status=="false"?0:8;
}

export const ActiveTimesheet = ({ boxStyle, billButtonStyle, activeSheet, setActiveSheet, setemplReload }) => {
    const schedule =activeSheet.work_schedule;
    const [remain_hours, setHours] = useState({ mon: remTime(schedule.mon.work), tue: remTime(schedule.tue.work), wed: remTime(schedule.wed.work), thu: remTime(schedule.thu.work), fri: remTime(schedule.fri.work), sat: 8, sun: 0 });
    // activeSheet.work_schedule.workDay.map((val)=>{
    //     console.log(activeSheet);
    // })
    const defaultState = {
        "projectID": "",
        "taskID": "",
        "billable": false,
        "workDay": {
            "fri": {
                "comment": activeSheet.work_schedule.fri.comment ?? "",
                "hour": 0,
                "work": activeSheet.work_schedule.fri.work ?? false
            },
            "mon": {
                "comment": activeSheet.work_schedule.mon.comment ?? "",
                "hour": 0,
                "work": activeSheet.work_schedule.mon.work ?? false
            },
            "sat": {
                "comment": activeSheet.work_schedule.sat.comment ?? "",
                "hour": 0,
                "work": activeSheet.work_schedule.sat.work ?? false
            },
            "sun": {
                "comment": activeSheet.work_schedule.sun.comment ?? "",
                "hour": 0,
                "work": activeSheet.work_schedule.sun.work ?? false
            },
            "thu": {
                "comment": activeSheet.work_schedule.thu.comment ?? "",
                "hour": 0,
                "work": activeSheet.work_schedule.thu.work ?? false
            },
            "tue": {
                "comment": activeSheet.work_schedule.tue.comment ?? "",
                "hour": 0,
                "work": activeSheet.work_schedule.tue.work ?? false
            },
            "wed": {
                "comment": activeSheet.work_schedule.wed.comment ?? "",
                "hour": 0,
                "work": activeSheet.work_schedule.wed.work ?? false
            }
        },
        "description": ""
    }
    const [refresh, setRefresh] = useState(false);
    const [rows, changeRows] = useState([1, 2, 3]);
    const [rowchange, trigerrow] = useState(false);
    const [counter, setCounter] = useState(3);
    const [loading, setLoading] = useState();
    const [create, triggerCreate] = useState(false);
    const managerData = useContext(UserContext);
    const [billable, setBillable] = useState(0);
    const [nonbillable, setNonBillable] = useState(0);
    const TimeSheetTitles = ['Project', 'Task', 'Billable', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun', 'Description'];
    const [submit, triggerSubmit] = useState();
    const [currentWeekDates, setCurrentWeekDates] = useState([]);

    const [currentSales, setcurrentSales] = useState();
    const [open, setOpen] = useState(false);
    const [status, setStatus] = useState(false);
    const [confirm, setConfirm] = useState(false);
    const [addForm, setAddForm] = useState(false);
    const [addData, setaddData] = useState();

    const handleClickOpen = () => {
        setOpen(true);
        // setLoading(true);
    };

    const confirmSubmit = () => {
        setConfirm(true);
    }

    const handleClose = () => {
        setOpen(false);
        setConfirm(false);
    };

    const [sets, handleSets] = useState([defaultState]);

    const changeRowsHandler = () => {
        handleSets(prev => [...prev, defaultState])
    }

    // Open Create TimeSheet popup box
    const openCreateDialog = () => {
        triggerCreate(true);
    }


    const handleRefresh = () => {
        setRefresh(true);
        setActiveSheet();
    }

    const inputStyle = {
        "& .MuiInputBase-input": {
            height: "10px"
        },
        "& .MuiInputBase-root": {
            borderRadius: "5px"
        },
        "& .MuiSelect-select": {
            paddingY: "4px"
        }
    }

    const handleSubmit = (action) => {
        let hasWarning = false;
        setStatus(action);
        sets.some((val, key) => {
            if (val.projectID === "") {
                Notify.warning("Project is not selected!");
                hasWarning = true;
                return true;  // Exit the loop
            } else if (val.taskID === "") {
                Notify.warning("Task is not selected!");
                hasWarning = true;
                return true;  // Exit the loop
            } else if (val.description === "") {
                Notify.warning("Description must be filled!");
                hasWarning = true;
                return true;  // Exit the loop
            }
            return false;  // Continue to the next iteration
        });

        if (hasWarning) {
            return;
        }

        setLoading(action == "Submit" ? "Submit" : "Draft");
        triggerSubmit({
            "timesheet": {
                "action": action == "Submit" ? "Submit" : "Draft",
                "employeeSheetObjects": [
                    ...sets
                ]
            }
        })
    }

    // Set the current week's dates when the component mounts
    useEffect(() => {
        // console.log(getCurrentWeekDates());
        handleSets([defaultState]);
        setBillable(0);
        setNonBillable(0);
        setHours({ mon: remTime(schedule.mon.work), tue: remTime(schedule.tue.work), wed: remTime(schedule.wed.work), thu: remTime(schedule.thu.work), fri: remTime(schedule.fri.work), sat: 0, sun: 0 });
        setCurrentWeekDates(() => getCurrentWeekDates(activeSheet.startDate, activeSheet.endDate));
    }, [activeSheet]);

    //1.  Employee submit his/her timesheet
    const postTimeSheet = async () => {
        await axios.post("/timesheet/employee/action",
            JSON.stringify(submit),
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json',
                    withCredentials: true,
                    mode: 'no-cors',
                    'Authorization': `Bearer ` + localStorage.getItem('access')
                }
            }).then((response) => {
                console.log(response);
                setLoading();
                setemplReload(prev => !prev);
                // handleSets([defaultState])
                handleClose();
                status == "Submit" ? Notify.success("Timesheet Submitted Successfully!") : Notify.success("Timesheet saved to Drafts!");
            }).catch((error) => {
                console.log(error.response)
                setLoading();
                Notify.error((error?.response?.data?.error) ?? "Error Occurred");
            })
    }
    useEffect(() => {
        if (submit) {
            postTimeSheet();
        }
    }, [submit])

    return (
        (currentWeekDates.length > 0) ? <>
            <Grid container justifyContent="space-between" alignItems="center">
                <TimeSheetHours boxStyle={boxStyle} billButtonStyle={billButtonStyle} billable={billable} nonbillable={nonbillable} />
                {activeSheet.isEscalated ? <Stack direction={'row'} alignItems='center'><WarningIcon sx={{ color: '#E36414', fontSize: "35px" }} /><Typography sx={{ color: '#E8751A' }} variant='h6'>Escalation Week</Typography></Stack> : ""}
                <LoadingButton onClick={handleClickOpen} startIcon={<ReplayIcon />} variant='contained' sx={{ bgcolor: "#BF3131" }} loading={loading}>Returned</LoadingButton>
            </Grid>

            <Box mt={1} sx={{ height: '60vh', overflowY: 'auto', overflowX: 'hidden' }}>
                <Box>
                    <Box my={2} border='1px solid' borderColor="dark_divider">
                        <Grid gap={1} container columns={16.5} py={0.3} px={1} alignItems='center' sx={{
                            backgroundColor: 'primary.light',
                            color: 'primary.white'
                        }}>
                            {TimeSheetTitles.map((title, key) => (
                                <Grid sx={{ position: 'relative' }} key={key} item my={1} md={title == "Project" || title == "Task" || title == "Description" ? 2 : 1}>
                                    <Typography
                                        variant={(key >= 3 && key < 10) ? 'subtitle2' : "subtitle1"} fontWeight={(key >= 3 && key < 10) ? '600' : '400'}>
                                        {title}
                                    </Typography>
                                    {(key >= 3 && key < 10)
                                        ?
                                        <Typography py={0.3} px={0.5} sx={{ backgroundColor: "#3e5e84", borderRadius: "3px" }} variant='caption'>{currentWeekDates[key - 3].toLocaleDateString('en-US', { day: 'numeric', month: 'numeric' })}</Typography>
                                        : ""}
                                </Grid>
                            ))}
                        </Grid>

                        <Grid gap={1} container columns={16.5} py={1} px={1} alignItems='center'>
                            {sets.length > 0
                                ? sets.map((val, key) => {
                                    // console.log(activeSheet,"activetimeshet");
                                    return (
                                        <TimeSheetNewTable
                                            sets={sets}
                                            handleSets={handleSets}
                                            employeeData={activeSheet.Projects}
                                            val={key}
                                            changeRows={changeRows}
                                            setBillable={setBillable}
                                            setNonBillable={setNonBillable}
                                            setHours={setHours}
                                            remain_hours={remain_hours}
                                            escalation ={activeSheet.isEscalated}
                                        />
                                    );
                                })
                                : ""}
                        </Grid>
                        <Grid gap={1} container columns={16.5} py={0.8} px={1} alignItems='center' bgcolor="#F68989">
                            <Grid item md={5.3} textAlign="center" fontWeight="600">Remaining Hours:</Grid>
                            {Object.entries(remain_hours).map(([day, hours]) => 
                            {
                                const hr =Math.floor(hours);
                                const min =(hours-Math.floor(hours))*60;
                                return (
                                <Grid item md={1}>{hr}h {min}m</Grid>
                            )})}
                        </Grid>
                    </Box>
                </Box>


                <Grid container justifyContent="space-between">
                    <Stack direction="row" gap={1}>
                        <Button disabled={loading ? true : false} onClick={changeRowsHandler} variant='contained' sx={{ py: 1, px: 3, borderRadius: '5px', height: '35px' }}>
                            <Typography variant='subtitle2'>Add Rows</Typography>
                        </Button>

                        <LoadingButton
                            onClick={() => handleSubmit("Draft")}
                            loading={(loading == "Draft") ? true : false}
                            disabled={loading ? (loading != "Draft") ?? true : false}
                            variant="outlined"
                            color='primary'
                            id='Save'
                        ><Typography variant='subtitle2'>Save Draft</Typography></LoadingButton>
                    </Stack>

                    <Stack direction="row" gap={1}>
                        <Button
                            onClick={() => confirmSubmit()}
                            disabled={loading ? (loading != "Submit") ?? true : false}
                            variant="contained"
                            color='primary'
                            id='Save'
                        ><Typography variant='subtitle2'>Submit for Approval</Typography></Button>
                    </Stack>
                </Grid>
            </Box>

            {/* CONFIRM SUBMIT/DRAFT DIALOG BOX */}
            <Dialog
                open={confirm}
                onClose={handleClose}
                aria-labelledby="edit-apartment"
                fullWidth={true}
                maxWidth='xs'
            >
                <DialogTitle style={{ backgroundColor: "#EEEDEB" }} id="edit-apartment">Are you sure you want to submit this TimeSheet?</DialogTitle>
                <DialogContent style={{ backgroundColor: "#EEEDEB" }}>
                    <Typography mt={1}>Your Manager: <b>{activeSheet.Manager.name}</b></Typography>
                    <Typography mt={1}>Total Remaining Hours: <b style={{ color: "red" }}>{getHours(remain_hours.mon + remain_hours.tue + remain_hours.wed + remain_hours.thu + remain_hours.fri)+"hours "+getMinutes(remain_hours.mon + remain_hours.tue + remain_hours.wed + remain_hours.thu + remain_hours.fri)+"minutes "}</b></Typography>
                </DialogContent>
                <DialogActions style={{ backgroundColor: "#EEEDEB" }}>
                    <Button onClick={handleClose} color="secondary">
                        Cancel
                    </Button>

                    <LoadingButton
                        onClick={() => handleSubmit("Submit")}
                        loading={(loading == "Submit") ? true : false}
                        disabled={loading ? (loading != "Submit") ?? true : false}
                        variant="contained"
                        color='primary'
                        id='Save'
                    ><Typography variant='subtitle2'>Submit</Typography></LoadingButton>

                </DialogActions>
            </Dialog>



            {/* RETURN DIALOG BOX */}
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="edit-apartment"
                fullWidth={true}
                maxWidth='lg'
            >
                <DialogTitle sx={{ backgroundColor: "#EEEDEB" }} id="edit-apartment">Returned TimeSheets</DialogTitle>
                <DialogContent style={{ height: '80vh', backgroundColor: "#EEEDEB" }}>
                    <DialogContentText>
                        Please fill the required information.
                    </DialogContentText>
                    <ReturnedSheet activeSheet={activeSheet} setActiveSheet={setActiveSheet} />
                </DialogContent>
                <Button sx={{ position: "absolute", right: "8px", top: "8px" }} onClick={handleClose} color="secondary">
                    <CloseIcon />
                </Button>

            </Dialog>
        </> : <></>
    )
}