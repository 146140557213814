import React, { useEffect, useState } from 'react';
import axios from '../api/axios';
import { Avatar, Box, Button, Card, CardContent, CardMedia, Grid, List, ListItem, ListItemAvatar, ListItemText, Paper, Typography } from '@mui/material';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Link } from 'react-router-dom';
import { currentUser } from '../Router';
import { LaunchPad } from '../components/LaunchPad';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { HolidaysBox } from '../components/HolidaysBox';
import { Birthday } from '../components/Icons/Birthday';
import { Happybd } from '../components/Icons/Happybd';
import { PendingBlogShow } from '../components/PendingBlogShow';

function isToday(dateString) {
    const givenDate = new Date(dateString);
    const today = new Date();
    return (

        givenDate.getMonth() === today.getMonth() &&
        givenDate.getDate() === today.getDate()
    );
}

const getStatusIcon = (val) => {
    if (val == true) return <CheckIcon color='success' />
    return <ClearIcon color='error' />
}

export const getLocalData = (date) => {
    const formattedDate = new Date(date).toLocaleDateString('en-GB', {
        day: '2-digit',
        month: 'short',
        // year: 'numeric'
    });
    return formattedDate;
}

// const ImageCard = ({ title, image, url, src }) => {
//     return (
//         <Card sx={{ display: 'flex', backgroundColor: '#f5f5f5', borderRadius: '8px' }} elevation={4}>
//             <CardMedia
//                 component="img"
//                 height={230}
//                 sx={{
//                     width: '55%',
//                     backgroundImage: `url(${image})`,
//                     backgroundSize: 'cover',
//                     backgroundPosition: 'center',
//                     backgroundRepeat: 'no-repeat',
//                 }}
//             />
//             <Box sx={{ display: 'flex', flexDirection: 'column', width: '45%' }}>
//                 <CardContent sx={{ flex: '1 0 auto', position: 'relative' }}>
//                     <Typography component="div" variant="subtitle2" fontWeight={'600'}>
//                         Latest News
//                     </Typography>
//                     <Typography variant="subtitle1" color="primary" component="div">
//                         {title}
//                     </Typography>

//                     <Button
//                         variant="contained"
//                         size="small"
//                         disableElevation
//                         sx={{ backgroundColor: '#3f51b5', color: '#fff', paddingX: '12px', paddingY: '4px', marginTop: '1rem' }}
//                     >
//                         <Link style={{ textDecoration: 'none', color: '#fff' }} to={url}>
//                             Read More
//                         </Link>
//                     </Button>
//                     <Typography position={'absolute'} bottom={4} variant="subtitle2">
//                         <i>Source:</i> {src}
//                     </Typography>
//                 </CardContent>
//             </Box>
//         </Card>
//     );
// };

// const ImageCard2 = ({ title, image, url }) => {
//     return (
//         <Link to={url} style={{ textDecoration: 'none', color: 'inherit' }}>
//             <Paper elevation={4}>
//                 <Box position="relative" borderRadius={0.3} height={290} overflow="hidden" sx={{ backgroundImage: `url(${image})`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
//                     <Box bgcolor="#f5f5f5" position="absolute" bottom={0} p={2}>
//                         <Typography>{title}</Typography>
//                     </Box>
//                 </Box>
//             </Paper>
//         </Link>
//     );
// };

// const settings = {
//     dots: true,
//     infinite: true,
//     speed: 2000, // Adjust speed as needed
//     slidesToShow: 1,
//     slidesToScroll: 1,
//     autoplay: true,
//     autoplaySpeed: 4000, // Adjust autoplay speed as needed
// };

export const Home = () => {
    const [isBirthday, setBirthday] = useState();
    const { user_first_name, usageLocation } = currentUser();
    const [credReview, isCredReview] = useState(null);

    useEffect(() => {
        const getTaskStatus = async () => {
            await axios.get("/dashboard",
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ` + localStorage.getItem('access')
                    }
                }).then((response) => {
                    setBirthday(response.data.birthdays_this_month)
                    isCredReview(response.data.approvalCount);
                }).catch((error) => {
                    console.log(error.response)
                })
        }
        getTaskStatus();
    }, [])

    // Existing code for fetching news API

    return (
        <Box sx={{ backgroundColor: '#E5E1DA', padding: 5, minHeight: "90vh", paddingBottom: 6 }}>
            <Grid container columnSpacing={4}>
                <Grid md={12}><Typography variant='h3' fontWeight='600' textAlign="center">Welcome, {user_first_name}!</Typography></Grid>

                <Grid lg={4} item mb={1} justifyContent='center' alignItems='center'>
                    <Paper sx={{ marginY: "1.5rem" }} elevation={3}>
                        <HolidaysBox />
                    </Paper>
                </Grid>

                <Grid lg={4} item mb={1} justifyContent='center' alignItems='center'>
                    <Paper sx={{ marginY: "1.5rem" }} elevation={3}>
                        <LaunchPad credReview={credReview} isCredReview={isCredReview} />
                    </Paper>

                    <Paper sx={{ marginY: "1.5rem" }} elevation={3}>
                        <PendingBlogShow />
                    </Paper>
                </Grid>

                {isBirthday ?
                    <Grid item lg={4} sx={{ marginY: "1.5rem" }} justifyContent='center' alignItems='center'>
                        <Card elevation={3} sx={{ margin: 'auto', borderRadius: '5px', bgcolor: '#F5FAFF'}}>
                            <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '1rem 1.5rem' }}>
                                <Typography variant="h6" color="primary" fontWeight='600' align="center" gutterBottom>
                                    This Month's Birthdays !
                                </Typography>
                                <Box mt={1}>
                                    <Birthday width='130px' />
                                </Box>
                                {isBirthday.length > 0 ?
                                    <List sx={{ width: '100%', maxHeight:'12rem', overflow:'auto', padding:'0 0.5rem'}}>
                                        {
                                            isBirthday.map((key, index) => {
                                                return (
                                                    <>
                                                        <ListItem sx={{ bgcolor: 'background.paper', my: '15px', boxShadow: '0px 4px 2px rgba(0, 0, 0, 0.1)', borderRadius: '5px' }}>
                                                            <ListItemAvatar>
                                                                <Avatar>

                                                                </Avatar>
                                                            </ListItemAvatar>
                                                            <ListItemText primary={<Typography fontWeight='bold'>{key.FirstName + " " + key.LastName}</Typography>} secondary={getLocalData(key.date_of_birth)} />
                                                            {isToday(key.date_of_birth) ? <Happybd width='50px' /> : <></>}
                                                        </ListItem>
                                                    </>
                                                )
                                            }
                                            )
                                        }
                                    </List>
                                    : <Typography mt={1} variant='subtitle1' fontWeight='bold'>No Birthdays this month</Typography>}
                            </CardContent>
                        </Card>
                    </Grid>
                    : <></>}

            </Grid>
        </Box>
    );
};