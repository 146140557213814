import { FormControl, Grid, InputAdornment, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import { InputHeight } from './CredentialsView'
import { css, Global } from '@emotion/react';
import { globalStyles } from '../../pages/Events/EventsForm';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useState } from 'react';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { isSuperAdmin } from '../auth/userAuth';

const IconStyle = {
    fontSize: '19px',
    color: 'gray',
    cursor: 'pointer',
    ':hover': {
        color: '#78A1DE',
        transform: 'scale(1.2)'
    }
}

export const CredentialForm = ({ addData, setAddData, editForm, approvers }) => {
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => {
        setShowPassword(prev => !prev);
    }

    const InputText = (e, naam) => {
        const val = e.target.value;
        setAddData((prev) =>
        ({
            ...prev,
            [naam]: val
        }));
    }

    const handleDescChange = (content, naam) => {
        setAddData((prev) =>
        ({
            ...prev,
            [naam]: content
        }));
    };

    const NoteStyle = {
        '.ql-container': {
            backgroundColor: 'red'
        }
    }

    return (
        <Grid mt={0.1} container rowSpacing={3} columnSpacing={1} justifyContent='center' alignItems='center'>
            {
                editForm
                    ? isSuperAdmin(<></>,<><Grid item xs={3}>Approver:<span style={{ color: 'red' }}>*</span></Grid>
                        <Grid item xs={9}>
                            <FormControl fullWidth sx={InputHeight}>
                                <Select
                                    // value={age}
                                    onChange={(e)=>InputText(e,'approver')}
                                >
                                    {
                                        approvers.map((vaal, key) => <MenuItem key={key} value={vaal} id={vaal.approver_emp_name}>{vaal.approver_emp_name}</MenuItem>)
                                    }
                                </Select>
                            </FormControl>
                        </Grid></>)
                    : <></>
            }

            <Grid item xs={3}>Name:<span style={{ color: 'red' }}>*</span></Grid>
            <Grid item xs={9}>
                <TextField fullWidth onChange={(e) => InputText(e, 'name')} name='name' value={addData['name']} placeholder='company name' sx={InputHeight}></TextField>
            </Grid>

            <Grid item xs={3}>Url:<span style={{ color: 'red' }}>*</span></Grid>
            <Grid item xs={9}>
                <TextField fullWidth onChange={(e) => InputText(e, 'url')} name='url' value={addData['url']} placeholder='website url' sx={InputHeight}></TextField>
            </Grid>

            <Grid item xs={3}>Username:<span style={{ color: 'red' }}>*</span></Grid>
            <Grid item xs={9}>
                <TextField fullWidth onChange={(e) => InputText(e, 'username')} name='username' value={addData['username']} placeholder='username' sx={InputHeight}></TextField>
            </Grid>

            <Grid item xs={3}>Password:<span style={{ color: 'red' }}>*</span></Grid>
            <Grid item xs={9}>
                <TextField fullWidth
                    InputProps={{
                        endAdornment: (
                            <InputAdornment>
                                {showPassword ? <VisibilityOff sx={IconStyle} onClick={handleClickShowPassword} /> : <Visibility sx={IconStyle} onClick={handleClickShowPassword} />}
                            </InputAdornment>
                        )
                    }}
                    onChange={(e) => InputText(e, 'password')} type={showPassword ? '' : 'password'} name='password' value={addData['password']} placeholder='password' sx={InputHeight}></TextField>
            </Grid>

            <Grid item xs={3}>Notes:</Grid>
            <Grid item xs={9}>
                <Global styles={globalStyles} />
                <ReactQuill style={{
                    // background: 'red',
                    '&.ql-container': {
                        backgroundColor: 'red !important'
                    }
                }} value={addData['notes']} onChange={(content) => handleDescChange(content, 'notes')} placeholder="Notes here(if any)" />
            </Grid>
        </Grid>
    )
}
