import { Box, Button, FormControl, Grid, IconButton, MenuItem, Select, Stack, TextField, TextareaAutosize, Tooltip, Typography } from '@mui/material'
import React, { useState, useEffect, useContext, useRef } from 'react'
// import { CheckBox } from '@mui/icons-material';
import Checkbox from '@mui/material/Checkbox';
import { RiDeleteBinLine } from "react-icons/ri";
import axios from '../../../api/axios';
import { DatePicker } from 'rsuite';
import { UserContext } from '../../../pages/Timesheet/ManagerTimesheet';
import { Token } from '../../../constants'
import Notify from '../../helpers/notifyConfig';
import LoadingButton from '@mui/lab/LoadingButton';
import Fade from '@mui/material/Fade';
import { DeleteIcon } from '../../../components/Icons/DeleteIcon';
import { Check } from '@mui/icons-material';
import { getHours, getMinutes } from './TimeSheetHours';
// import PushPinIcon from '@mui/icons-material/PushPin';
// import DeleteIcon from '@mui/icons-material/Delete';
export const TimeSheetTitles = ['Project', 'Task', 'Billable', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun', 'Description'];
const timestyle = {
  paddingX: '10px',
  py: '5px',
  bgcolor: 'primary.main',
  // mx: '0.5px',
  color: 'primary.white',
}

export const TimeSheetNewTable = ({ sets, handleSets, otherempData, employeeData = "", type = "", status = "", mngerData = "", setBillable, setNonBillable, val, changeRows, setHours, remain_hours, escalation }) => {
  const createSheetTitles = ['Project', 'Assignee Group', 'Start Date', 'End Date', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun', 'Description'];
  const days = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];
  const hours = [0, 1, 2, 3, 4, 5, 6, 7, 8];
  const mins = [0, 15, 30, 45];
  var dayOfWeek = new Date().getDay();
  const selectRef = useRef();
  const { Project, Task, description, billable, workDay, sheet_id } = otherempData ? otherempData : [];

  const [data, setData] = useState(employeeData);
  if (type == "edit") console.log(data);
  const [day, setDay] = useState();
  const [submit, setSubmit] = useState(false);
  const [createRequestType, setRequestType] = useState();
  const [editRequestType, setEditType] = useState();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState();
  const [editBtn, pressEdit] = useState(false);
  const [del, setDelete] = useState();
  const [billCheck, setBillCheck] = useState();
  const [prjct, prjctChange] = useState(100);
  const [localbill, setlocalbill] = useState(0);
  const [selectOpen, setSelectOpen] = useState(false);
  var temp = sets ? [...sets] : [];
  if (type == "edit") console.log(sets[val]);

  const defaultState = {
    "projectID": "",
    "taskID": "",
    "billable": true,
    "workDay": {
      "fri": {
        "comment": "",
        "hour": 0,
        "work": false
      },
      "mon": {
        "comment": "",
        "hour": 0,
        "work": false
      },
      "sat": {
        "comment": "",
        "hour": 0,
        "work": false
      },
      "sun": {
        "comment": "",
        "hour": 0,
        "work": false
      },
      "thu": {
        "comment": "",
        "hour": 0,
        "work": false
      },
      "tue": {
        "comment": "",
        "hour": 0,
        "work": false
      },
      "wed": {
        "comment": "",
        "hour": 0,
        "work": false
      }
    },
    "description": ""
  }

  const [createdSheet, setSheet] = useState(defaultState)

  const managerData = useContext(UserContext); //managerData provided from ManageTimeSheet 
  const handleSubmit = () => {
    setLoading("Submit");
    setSubmit(true);
  }

  const handleDelete = () => {
    if (type != "edit") {
      if (sets[val].billable) {
        setBillable(prev => prev - localbill);
      }
      else {
        setNonBillable(prev => prev - localbill);
      }
    }

    const updatedHours = Object.fromEntries(
      Object.entries(remain_hours).map(([day, hours]) => [day, hours + sets[val].workDay[day].hour])
    );
    setHours(updatedHours);

    temp.splice(val, 1);
    handleSets(temp);
  }
  // console.log(createdSheet)

  const editTrigger = () => {
    //if does not work -----remove managerSheetID
    setSheet((prev) => ({
      ...prev, "projectID": mngerData.Project.projectID,
      "startDate": mngerData['startDate'], "endDate": mngerData['endDate'], "workDay": mngerData.WorkDay,
      "description": mngerData.Description, "assignGroupID": mngerData.Assignee['_id'], "managerSheetID": mngerData.managerSheetID
    }));
    pressEdit(true);
  }

  const handleChange = (e, day, vaal, action) => {
    if (action == "hr") e.stopPropagation();
    const selected_day = day;
    const prevo = sets[val].workDay[selected_day].hour;
    var selected_hours = action == "min" ? vaal / 60 : vaal;

    if (action == "min") selected_hours = Math.floor(prevo) + selected_hours;
    const currBill = sets[val].billable;
    setDay(selected_day);
    const tot = selected_hours - prevo;
    if (type != "edit") currBill ? setBillable(prev => prev + tot) : setNonBillable(prev => prev + tot);
    setlocalbill(prev => prev + tot);

    const hours_remain = 8 - selected_hours;
    setHours(prev => ({
      ...prev, [selected_day]: prev[selected_day] - selected_hours + prevo
    }))
    // console.log(selected_hours);
    // setSheet((prev) => ({
    //   ...prev,
    //   workDay: {
    //     ...prev.workDay,
    //     [selected_day]: {
    //       ...prev.workDay[selected_day], hour: selected_hours, work: true
    //     }
    //   }
    // }));

    handleSets(prevSets => {
      return prevSets.map((set, index) => {
        if (index === val) {
          return {
            ...set,
            workDay: {
              ...set.workDay,
              [selected_day]: {
                ...set.workDay[selected_day], hour: selected_hours, work: true
              }
            }
          };
        }
        return set;
      });
    })
  }

  const handleClose = (event) => {
    event.stopPropagation();
    setSelectOpen(false);
  }

  const handleManagerChange = (e, val) => {
    const selected_val = e.target.value;
    const selected_elem = e.target.name;

    setSheet((prev) => ({
      ...prev, [selected_elem]: selected_val
    }))

    console.log(createdSheet);
  }

  const handleProjectChange = (e) => {
    const selected_val = e.target.value;
    const selected_elem = e.target.name;
    const selected = e.target.key;
    // if(selected_elem=="projectID") 
    // console.log(selected_val);
    // setSheet((prev) => ({
    //   ...prev, [selected_elem]: selected_val
    // }))

    handleSets(prevSets => {
      return prevSets.map((set, index) => {
        if (index === val) {
          if (selected_elem == "projectID") {
            return {
              ...set, [selected_elem]: selected_val, taskID: ""
            }
          } else {
            return {
              ...set, [selected_elem]: selected_val
            };
          }
        }
        return set;
      });
    })
    // console.log(val);    
    // temp[val][selected_elem] =selected_val;
    // console.log(temp[val][selected_elem])
  }

  const handleComment = (e) => {
    const new_comment = e.target.value
    if (day) {
      setData((prev) => ({
        ...prev,
        workDay: {
          ...prev.workDay,
          [day]: {
            ...prev.workDay[day], comment: new_comment
          }
        }
      }));
    }
  }

  const clearDefaults = (e) => {
    setSheet(defaultState)
    console.log(createdSheet);
  }

  const handleDayChange = (e) => {
    const day = e.target.name;
    setSheet((prev) => ({
      ...prev,
      workDay: {
        ...prev.workDay, [day]: !createdSheet.workDay[day]
      }
    }))
  }

  if (type == "edit") console.log(sets[val].taskID)

  const toggleCheck = (e) => {
    console.log(data);
    if (type != "edit") {
      if (sets[val].billable) {
        // if (createdSheet.billable) {
        setNonBillable(prev => prev + localbill);
        setBillable(prev => prev - localbill);
      }
      else {
        setNonBillable(prev => prev - localbill);
        setBillable(prev => prev + localbill);
      }
    }

    // setSheet((prev) => ({
    //   ...prev,
    //   billable: !createdSheet.billable
    // }))

    handleSets(prevSets => {
      return prevSets.map((set, index) => {
        if (index === val) {
          return {
            // ...set, billable: !createdSheet.billable
            ...set, billable: !(sets[val].billable)
          };
        }
        return set;
      });
    })
  }

  useEffect(() => {
    if (employeeData != "")
      setlocalbill(sets[val].workDay.mon.hour + sets[val].workDay.tue.hour + sets[val].workDay.wed.hour + sets[val].workDay.thu.hour + sets[val].workDay.fri.hour);
  }, [sets])

  const label = "Add comment (" + day + ")";
  return (
    <>
      <Grid item my={1} md={2}>
        {otherempData ?
          <Tooltip title={Project ? Project['projectName'] : ""}>
            <TextField
              inputProps={{ spellCheck: 'false', style: {fontSize: '14px'} }}
              value={Project ? Project['projectName'] : ""}
              id={Project ? Project['projectID'] : ""}
              size='small'
              sx={{fontSize: '14px'}}
              // disabled={true}
              variant="outlined" />
          </Tooltip>
          : <Select
            fullWidth
            sx={{fontSize: '14px'}}
            inputProps={{ 'aria-label': 'Without label' }}
            size='small'
            // value={sets[val].projectID == "" ? "none" : sets[val].projectID}
            value={sets[val].projectID == "" ? "none" : sets[val].projectID}
            onChange={handleProjectChange}
            name='projectID'
          >
            <MenuItem value="none">Select Project</MenuItem>
            {data.map((val, key) =>
              <MenuItem onClick={() => prjctChange(key)} key={key} value={val._id} id={val._id}>{val.name}</MenuItem>
            )}
          </Select>
        }
      </Grid>

      <Grid item my={1} md={2}>
        {otherempData ?
          <Tooltip
            title={Task ? Task['taskName'] : ""}
          >
            <TextField
              value={Task ? Task['taskName'] : ""}
              inputProps={{ spellCheck: 'false', style: {fontSize: '14px'} }}
              id={Task ? Task['taskID'] : ""}
              size='small'
              variant="outlined" />
          </Tooltip>
          : <Select
            fullWidth
            inputProps={{ 'aria-label': 'Without label' }}
            size='small'
            sx={{fontSize: '14px'}}
            value={sets[val].taskID == "" ? "none" : sets[val].taskID}
            onChange={handleProjectChange}
            name='taskID'
          >
            <MenuItem value="none">Select Task</MenuItem>
            {/* {data[prjct == 100 ? 0 : prjct].Tasks.map((val, key) =>
            <MenuItem key={key} value={val._id} id={val._id}>{val.name}</MenuItem>
          )} */}
            {
              data.map((prjctID, key) => {
                if (sets[val].projectID == prjctID._id) {
                  return prjctID.Tasks.map((val, key) =>
                    <MenuItem key={key} value={val._id} id={val._id}>{val.name}</MenuItem>)
                }
              }
              )
            }
          </Select>
        }
      </Grid>

      <Grid item my={1} md={1}>
        {/* <Checkbox name='billable' disabled={otherempData ? true : false} onChange={() => toggleCheck()} checked={otherempData ? billable : createdSheet.billable} /> */}
        <Checkbox name='billable' disabled={otherempData ? true : false} onChange={() => toggleCheck()} checked={otherempData ? billable : sets[val].billable} />
      </Grid>


      {
        days.map((currentDay, key) => {
          return (
            <Grid key={currentDay} item my={1} md={1} >
              {otherempData ?
                <Tooltip
                  title={workDay[currentDay].comment != "" ? workDay[currentDay].comment : getHours(workDay[currentDay].hour) + " hours " + getMinutes(workDay[currentDay].hour) + " minutes "}
                >
                  <TextField
                    sx={{ width: "62px",
                  "& .MuiInputBase-input":{
                    padding: '8px 7px'
                  }
                  }}
                    inputProps={{style: {fontSize: '14px'}}}
                    value={getHours(workDay[currentDay].hour) + "h " + getMinutes(workDay[currentDay].hour)+"m"}
                    size='small'
                    disabled={workDay[currentDay].work == "false"}
                    variant="outlined" />
                </Tooltip>
                :
                <Tooltip
                  TransitionComponent={Fade}
                  TransitionProps={{ timeout: 600 }}
                  title={selectOpen ? "" : (sets[val].workDay[currentDay].comment != "" ? sets[val].workDay[currentDay].comment : getHours(sets[val].workDay[currentDay].hour) + " hours " + getMinutes(sets[val].workDay[currentDay].hour) + " minutes ")}
                // slotProps={{
                //   popper: {
                //     modifiers: [
                //       {
                //         name: 'offset',
                //         options: {
                //           offset: [0, -12],
                //         },
                //       },
                //     ],
                //   },
                // }}
                >
                  <Select
                    // onChange={handleChange}
                    disabled={(escalation ? sets[val].workDay[currentDay].work == "false" : (sets[val].workDay[currentDay].work == "false" || dayOfWeek <= key))}
                    id={currentDay}
                    name={currentDay}
                    displayEmpty
                    value={sets[val].workDay[currentDay].hour}
                    renderValue={() => getHours(sets[val].workDay[currentDay].hour) + ":" + getMinutes(sets[val].workDay[currentDay].hour)}
                    onClose={handleClose}
                    onOpen={() => setSelectOpen(true)}
                    ref={selectRef}
                    size='small'
                    sx={{ fontSize: '14px' }}
                  >
                    <Grid container columnSpacing={0.1}>
                      {/* Hour */}
                      {/* <Grid item><Box sx={{...timestyle}}>Hours</Box>{hours.map((value, key) => (sets[val].workDay[currentDay].hour + remain_hours[currentDay] >= key) ? <MenuItem key={key} value={value}>{value}</MenuItem> : <></>)}</Grid> */}
                      <Grid item><Box sx={{ ...timestyle }}>Hours</Box>{hours.map((value, key) => <MenuItem onClick={(e) => handleChange(e, currentDay, value, "hr")} disabled={(Math.floor(sets[val].workDay[currentDay].hour) + remain_hours[currentDay] >= key) ? false : true} selected={key == (Math.floor(sets[val].workDay[currentDay].hour)) ? true : false} key={key} value={value}>{value}</MenuItem>)}</Grid>
                      {/* Minute */}
                      <Grid item><Box sx={{ ...timestyle }}>Minutes</Box>{mins.map((value, key) => <MenuItem onClick={(e) => handleChange(e, currentDay, value, "min")} disabled={((value / 60) <= remain_hours[currentDay]) ? false : true} selected={(value / 60) == ((sets[val].workDay[currentDay].hour) - Math.floor(sets[val].workDay[currentDay].hour)) ? true : false} key={key} value={value}>{value}</MenuItem>)}</Grid>
                      {/* <Button sx={{position:'absolute', top:'200px', right:'10px', width:'10px', padding:'2px', minWidth: '60px'}} size='small' variant='contained' color='success'><strong>Ok</strong></Button> */}
                    </Grid>
                  </Select>
                </Tooltip>
              }
            </Grid>
          )
        })
      }

      <Grid item my={1} md={2.5}>
        {otherempData ?
          <Tooltip title={description}>
            <TextField
              fullWidth
              name='description'
              value={description}
              size='small'
              // disabled={true}
              variant="outlined" />
          </Tooltip>
          :
          <textarea style={{ height: "2.5rem" }} value={sets[val].description} name='description' onChange={handleProjectChange} />
        }
      </Grid>

      {otherempData ?
        <></> :
        <IconButton disabled={(sets.length == 1) ? true : false} onClick={handleDelete}>
          <DeleteIcon width={18} />
        </IconButton>
      }
    </>
  )
}
