import { Box, Button, FormControl, Grid, MenuItem, Paper, Select, Skeleton, Stack, Switch, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import { Scrollbars } from 'react-custom-scrollbars';
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from '@mui/lab/TimelineOppositeContent';
import axios from '../../api/axios';
import Notify from '../../features/helpers/notifyConfig';

const allRoles = ['SuperAdmin', 'Marketing', 'Finance', 'Marketing Lead', 'Project Manager', 'Team Lead', 'Employee'];

export const Permissions = () => {
  const [role, setRole] = useState('SuperAdmin');
  const [data, setData] = useState(null);
  const [permStatus, chngPermStatus] = useState({ "message": [] })
  const [ChangeStatus, setChange] = useState("none");
  const handleChange = (event) => {
    setData(null);
    setRole(event.target.value);
  };

  const permChange = (category, doc) => {
    const categoryIndex = data.findIndex(item => item.category === category);
    const documentIndex = data[categoryIndex].documents.findIndex(
      val => val.alias === doc.alias
    );
    const updatedData = [...data];
    updatedData[categoryIndex].documents[documentIndex].status = !doc.status;
    setData(updatedData);
    setChange("inline");

    const existingCategoryIndex = permStatus.message.findIndex(
      entry => entry.category === category
    );
    if (existingCategoryIndex !== -1) {
      const updatedMessages = [...permStatus.message];
      updatedMessages[existingCategoryIndex].documents.push({
        alias: doc.alias,
        status: doc.status
      });

      chngPermStatus({ message: updatedMessages });
    }
    else {
      const newMessage = {
        category: category,
        documents: [
          {
            alias: doc.alias,
            status: doc.status
          }
        ]
      };

      chngPermStatus(prevState => ({
        message: [...prevState.message, newMessage]
      }));
    }
  }

  const getRolePermissions = async () => {
    await axios.get(`/permissions/${role}`,
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ` + localStorage.getItem('access')
        }
      }).then((response) => {
        // console.log(response);
        setData(response?.data?.message);
        setChange("none");
      }).catch((error) => {
        console.log(error.response)
      })
  }

  useEffect(() => {
    getRolePermissions();
  }, [role]) // eslint-disable-line react-hooks/exhaustive-deps

  const triggerPermChange = async () => {
    await axios.put(`/permissions/${role}/update`,
      JSON.stringify(permStatus),
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          withCredentials: true,
          mode: 'no-cors',
          'Authorization': `Bearer ` + localStorage.getItem('access')
        }
      }).then((response) => {
        Notify.success("Permissions Changed Successfully!");
        setChange("none");
      }).catch((error) => {
        Notify.error("Permissions Changes Failed. Try Again!")
        getRolePermissions();
      })
  }

  return (
    <Box>
      <Timeline
        sx={{
          [`& .${timelineOppositeContentClasses.root}`]: {
            flex: 0,
          },
        }}>
        <Paper minHeight='60vh' p={2} my={2} sx={{ borderRadius: '7px', overflow: 'hidden' }}>
          <Stack sx={{ backgroundColor: '#657EBF' }} py={0.8} px={2.5} mb={3} direction='row' justifyContent='space-between' alignItems='center'>
            <Stack p="7px 10px" borderRadius='5px' direction='row' spacing={2} alignItems='center' justifyContent='center'>
              <Typography color='white' fontWeight='600' variant='subtitle1'>Select Role:</Typography>
              <FormControl sx={{ m: 1, minWidth: 180 }} size='medium'>
                <Select
                  value={role}
                  onChange={handleChange}
                  displayEmpty
                  inputProps={{ 'aria-label': 'Without label' }}
                  sx={{ height: '35px', borderColor: 'darkgray', bgcolor: 'white' }}
                >
                  {
                    allRoles.map((val) =>
                      <MenuItem value={val}>{val}</MenuItem>
                    )}
                </Select>
              </FormControl>
            </Stack>

            <Box>
              <Button sx={{ mr: '10px', border: 'solid 1px white', display: `${ChangeStatus}` }} onClick={triggerPermChange} size='small' variant='contained'>Save Changes</Button>
              <Button sx={{ mr: '10px', border: 'solid 1px white', display: `${ChangeStatus}` }} onClick={getRolePermissions} variant='contained' size='small'>Discard Changes</Button>
            </Box>
          </Stack>
          <Box height='80vh' overflow='auto'>
            <Scrollbars minimalThumbSize='120px'>
              {
                data ?
                  data.map((val) => (
                    <Box pb={5}>
                      <TimelineItem>
                        <TimelineOppositeContent color="textSecondary">
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineDot />
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent>
                          <Typography variant='h6' sx={{ fontFamily: 'Segoe UI' }}>{val.category}</Typography>
                          <Grid pt={1} container direction='row' rowSpacing={3}>
                            {
                              val.documents.map((catData) => (
                                <Grid item xs={12} sm={2} md={4}>
                                  <Grid container direction='row' alignItems='center'>
                                    <Grid item xs={6}><Typography fontSize='17px' sx={{ fontFamily: 'Segoe UI', fontWeight: '600', color: '#625a5a' }} variant='subtitle1'>{catData.alias}</Typography></Grid>
                                    <Grid item xs={6}><Switch onChange={() => permChange(val.category, catData)} checked={catData.status} /></Grid>
                                  </Grid>
                                </Grid>
                              ))
                            }
                          </Grid>
                        </TimelineContent>
                      </TimelineItem>
                    </Box>
                  )) :
                  <><Stack alignItems='center' justifyContent='center' spacing={6}>
                    <Skeleton animation="wave" variant="rectangular" width='90%' height={50} />
                    <Skeleton animation="wave" variant="rectangular" width='90%' height={50} />
                    <Skeleton animation="wave" variant="rectangular" width='90%' height={50} />
                    <Skeleton animation="wave" variant="rectangular" width='90%' height={50} />
                    <Skeleton animation="wave" variant="rectangular" width='90%' height={50} />
                    <Skeleton animation="wave" variant="rectangular" width='90%' height={50} />
                  </Stack></>
              }
            </Scrollbars>
          </Box>
        </Paper>
      </Timeline>
    </Box>
  )
}
