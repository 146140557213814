import { useEffect, useState } from "react";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {
    Autocomplete,
    Box,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Paper,
    TextField,
    Typography,
    useTheme,
} from "@mui/material";
import { css, Global } from '@emotion/react';

import { format } from 'date-fns';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import AddIcon from '@mui/icons-material/Add';
import { postData } from ".";

const all_categories = ['Blog', 'Webinar', 'Meeting']
export const globalStyles = css`
  .ql-picker-options {
    z-index: 99 !important;
  },
  .ql-toolbar {
    border-radius: 5px 5px 0px 0px;
  },
  .ql-container {
    border-radius: 0px 0px 5px 5px;
    min-height: 7rem;
   }
`;

export const EventsForm = ({ empList, finData, setFinData }) => {
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [value, setValue] = useState('');

    const handleChange = (event, value, ind, naam) => {
        value = finData.task_info[ind]['event_type'] == 'Blog' ? [value] : value;
        const updatedTaskInfo = [...finData.task_info];
        updatedTaskInfo[ind][naam] = value;
        setFinData({ ...finData, task_info: updatedTaskInfo });
    };

    const handleInChange = (e, ind, naam) => {
        const updatedTaskInfo = [...finData.task_info];
        updatedTaskInfo[ind][naam] = e.target.value;
        setFinData({ ...finData, task_info: updatedTaskInfo });
    }

    const handleDescChange = (content, ind) => {
        console.log(content);
        const updatedTaskInfo = [...finData.task_info];
        updatedTaskInfo[ind]["description"] = content;
        setFinData({ ...finData, task_info: updatedTaskInfo });
    };

    const handleEvent = (e, key, field) => {
        // const { value } = e.target;
        console.log(e.target);
        // const updatedPoc = [...data.point_of_contact];
        // updatedPoc[key][field] = value;
        // setData((prevState) => ({
        //   ...prevState,
        //   point_of_contact: updatedPoc,
        // }));
    };

    const handleDateChange = (date, index, naam) => {
        const dateObject = new Date(date.$d);
        const val = naam == "end_date" ? postData(date.$d) : format(dateObject, "h:mm a");
        const updatedTaskInfo = [...finData.task_info];
        updatedTaskInfo[index][naam] = val;
        setFinData({ ...finData, task_info: updatedTaskInfo });
    };

    const addEventCard = () => {
        const updatedPoc = [...finData.task_info];
        updatedPoc.unshift({
            "event_type": "",
            "event_title": "",
            "assigned_to": [],
            "end_date": "",
            "start_time": "",
            "end_time": "",
            "is_full_day": false,
            "description": ""
        });
        setFinData((prevState) => ({
            ...prevState,
            task_info: updatedPoc,
        }));
    };

    return (
        <>
            <Grid item xs={12}>
                <Typography fontWeight="600" mt={3} textAlign="left">
                    Create New &nbsp;&nbsp;
                    <IconButton
                        size="small"
                        sx={{
                            backgroundColor: "#C7C8CC",
                            "&:hover": {
                                backgroundColor: "#B4B4B8",
                            },
                        }}
                        onClick={addEventCard}
                    >
                        <AddIcon fontSize="7px" />
                    </IconButton>
                </Typography>
            </Grid>

            {finData.task_info.map((val, index) => (
                <Paper elevation={4} sx={{ marginTop: "1rem", padding: "1rem" }}>
                    <Grid mt={0} container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <Autocomplete
                                value={val.event_type}
                                id={`event_type_${index}`}
                                freeSolo
                                onChange={(e, val) => handleChange(e, val, index, "event_type")}
                                options={all_categories}
                                renderInput={(params) => <TextField {...params} label="Category Type" variant="outlined" />}
                            />
                            <Typography mt={0.3} fontSize={12} color='red'>* You can also type a new category</Typography>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Autocomplete
                                multiple={val.event_type == "Blog" ? false : true}
                                limitTags={2}
                                id={`assigned_to_${index}`}
                                value={val.assigned_to}
                                onChange={(e, val) => handleChange(e, val, index, "assigned_to")}
                                options={Object.keys(empList)}
                                getOptionLabel={(option) => (empList[option] ? empList[option].employee_name : '')}
                                getOptionSelected={(option, value) => empList[option].employee_name === value}
                                renderInput={(params) => <TextField {...params} label="Select Assignee" variant="outlined" />}
                                renderOption={(props, option, { selected }) => (
                                    <li {...props}>
                                        <FormControlLabel
                                            control={<Checkbox checked={selected} />}
                                            label={empList[option].employee_name}
                                        />
                                    </li>
                                )}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                value={val.event_title ?? ""}
                                id={`event_title_${index}`}
                                label="Title"
                                fullWidth
                                onChange={(e) => handleInChange(e, index, "event_title")}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <TextField
                                sx={{ marginTop: '8px' }}
                                value={finData.start_date ?? ""}
                                id={`start_date_${index}`}
                                label="Start Date"
                                fullWidth
                                disabled
                            />
                        </Grid>

                        {finData.task_info[index]['event_type'] == 'Blog' ? "" :
                            <>
                                <Grid item xs={12} sm={6}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer components={['TimePicker']}>
                                            <TimePicker onChange={(date) => handleDateChange(date, index, "start_time")} label="Start Time" />
                                        </DemoContainer>
                                    </LocalizationProvider>
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer components={['DatePicker']}>
                                            <DatePicker onChange={(date) => handleDateChange(date, index, "end_date")} label="End Date" />
                                        </DemoContainer>
                                    </LocalizationProvider>
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer components={['TimePicker']}>
                                            <TimePicker onChange={(date) => handleDateChange(date, index, "end_time")} label="End Time" />
                                        </DemoContainer>
                                    </LocalizationProvider>
                                </Grid>
                            </>
                        }


                        <Grid item xs={12}>
                            {/* <TextField
                                sx={{
                                    paddingTop: '8px',
                                    '& .MuiInputBase-input': {
                                        height: "auto !important"
                                    }
                                }}
                                value={val.description ?? ""}
                                id={`description_${index}`}
                                label="Description"
                                fullWidth
                                multiline
                                rows={3}
                                onChange={(e) => handleInChange(e, index, "description")}
                            /> */}
                            <Global styles={globalStyles} />
                            <ReactQuill style={{ marginTop: '0.6rem' }} value={val.description ?? ""} id={`description_${index}`} onChange={(content) => handleDescChange(content, index)} placeholder="Description here....." theme="snow" />
                        </Grid>
                    </Grid>
                </Paper>
            ))}
        </>
    )
}
