import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import FilledInput from '@mui/material/FilledInput';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Checkbox, FormControlLabel, Grid, MenuItem, Select } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
// {ChancesOfWinning,Channel,Customers,EC_PointOfContact,Reseller,Stage,WonLost}
export function PrjctForm({ prop=[], data, setData, type="", contentChange }) {
    console.log(data);
    const { taskNames, employees, managers } = prop;
    const status = ['Ongoing', 'Active', 'Finished'];
    const [selectedEmployees, setSelectedEmployees] = useState(data['employees']);
    const [selectedTasks, setSelectedTasks] = useState(data['taskList']);

    const handleAddInputData = (e) => {
        const val = e.target.value;
        const naam = e.target.id ? e.target.id : e.target.name;

        setData(prevState => ({
            ...prevState,
            [naam]: val
        }))

        if(type=="edit") contentChange(true);
    }

    // const handleAddData = (e, val) => {
    //     if (e && e.target) {
    //         const naam = e.target.id.split('-')[0];
    //         setData(prevState => ({
    //             ...prevState,
    //             [naam]: val
    //         }))
    //     }
    // }

    const handleEmployeeSelection = (event, value) => {
        setSelectedEmployees(value);
        if(type=="edit") contentChange(true);
    };

    const handleTaskSelection = (event, value) => {
        setSelectedTasks(value);
        if(type=="edit") contentChange(true);
    };

    useEffect(()=>{
        // console.log(selectedEmployees)
        setData(prevState => ({
            ...prevState,
            "employees": selectedEmployees
        }))
    },[selectedEmployees])

    useEffect(()=>{
        setData(prevState => ({
            ...prevState,
            "taskList": selectedTasks
        }))
    },[selectedTasks])

    return (
        <Box sx={{ display: 'flex', flexWrap: 'wrap' }} mt={1}>
            <Grid container>
                <Grid item xs={12} p={1}>
                    <TextField
                        required
                        id="name"
                        sx={{ width: "100%" }}
                        onChange={handleAddInputData}
                        value={data['name']}
                        label="Project Name"
                    />
                </Grid>

                <Grid item xs={12} p={1}>
                    <TextField
                        id="description"
                        sx={{ width: "100%" }}
                        onChange={handleAddInputData}
                        value={data['description']}
                        label="Description"
                    />
                </Grid>

                <Grid item xs={6} p={1}>
                    <FormControl fullWidth>
                        <InputLabel id="agelabel">Select Manager <span style={{ color: "red" }}>*</span></InputLabel>
                        <Select
                            required
                            labelId="agelabel"
                            name="managerID"
                            value={data['managerID']}
                            label="Select Manager"
                            onChange={handleAddInputData}
                            sx={{ height: "47px" }}
                        >
                            {managers.map((val, key) =>
                                <MenuItem key={key} className='managerID' value={val._id}>{val.name}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={6} p={1}>
                    <FormControl fullWidth>
                        <InputLabel id="statuslabel">Select Status</InputLabel>
                        <Select
                            labelId="statuslabel"
                            name="status"
                            value={data['status']}
                            label="Select Status"
                            sx={{ height: "47px" }}
                            onChange={handleAddInputData}
                        >
                            {status.map((val,key) =>
                                <MenuItem key={key} className='status' value={val}>{val}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                </Grid>

                {/* <Grid item xs={6} p={1}>
                     <FormControl sx={{ width:"100%" }}>
                <InputLabel htmlFor="budget">Amount</InputLabel>
                    <OutlinedInput id='budget' onChange={handleAddInputData} label="Budget" startAdornment={<InputAdornment position="start">$</InputAdornment>} />
                    </FormControl>
                </Grid> */}

                <Grid item xs={6} p={1}>
                    <TextField
                        id="allocatedHours"
                        sx={{ width: "100%" }}
                        value={data['allocatedHours']}
                        onChange={handleAddInputData}
                        label="Allocated Hours"
                    />
                </Grid>

                <Grid item xs={12} p={1}>
                    <Autocomplete
                        multiple
                        id="employees"
                        value={data['employees']}
                        sx={{ width: "100%" }}
                        options={employees}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => <TextField {...params} label="Select Employees" />}
                        onChange={handleEmployeeSelection}
                        renderOption={(props, option, { selected }) => (
                            <li {...props}>
                                <FormControlLabel
                                    control={<Checkbox checked={selected} />}
                                    label={option.name}
                                />
                            </li>
                        )}
                    />
                </Grid>

                {/* <Grid item xs={12} p={1}>
                    <Autocomplete
                        multiple
                        id="employees"
                        value={data['employees']}
                        sx={{ width: "100%" }}
                        options={employees}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => <TextField {...params} label="Select Employees" />}
                        onChange={handleEmployeeSelection}
                        renderOption={(props, option, { selected }) => (
                            <li {...props}>
                                <FormControlLabel
                                    control={<Checkbox checked={selected} />}
                                    label={option.name}
                                />
                            </li>
                        )}
                    />
                </Grid> */}

                <Grid item xs={12} p={1}>
                    <Autocomplete
                        multiple
                        id="taskList"
                        value={data['taskList']}
                        sx={{ width: "100%" }}
                        freeSolo
                        options={taskNames}
                        renderInput={(params) => (<TextField {...params} label="Select Tasks" />)}
                        onChange={handleTaskSelection}
                        renderOption={(props, option, { selected }) => (
                            <li {...props}>
                                <FormControlLabel
                                    control={<Checkbox checked={selected} />}
                                    label={option}
                                />
                            </li>
                        )}
                    />
                </Grid>
            </Grid>
        </Box >
    );
}