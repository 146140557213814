import React, { useEffect, useState } from 'react'
import { Box, Typography, Stack, Paper, Link, Grid, LinearProgress } from '@mui/material'
import axios from '../api/axios'
import { advancedDate } from '../pages/Events'
import UserIcon from '@mui/material/Avatar';
import { Scrollbars } from 'react-custom-scrollbars';
import { Plane } from './Icons/Plane';
import { date_diff } from '../pages/Events/TableView';

const DisplayName = (emp, date, ProfilePics) => {
    return (
        <Grid item width='100%'>
            <Paper style={{ borderRadius: '20px 5px 5px 20px', overflow:'hidden' }}>
                <Box p={0.8} bgcolor={date_diff(date)>=7?'#c63c3cf0':'#e9753a'} color='white'>
                    <Stack justifyContent='space-between' alignItems='center' direction='row'>
                        <Stack direction='row' alignItems='center' spacing={1.5}>
                            {ProfilePics[emp.employee_name]
                                ?<Box sx={{ width: '35px', height: '35px', border: 'solid 2px white', borderRadius:'50%', backgroundImage:`url(${ProfilePics[emp.employee_name]})`, backgroundSize: 'cover', backgroundPosition: 'center' }}></Box>
                                :<UserIcon sx={{ width: '35px', height: '35px', border: 'solid 2px white' }} />
                            }
                            <Typography variant='subtitle2'>{emp.employee_name}</Typography>
                        </Stack>
                        <Typography variant='subtitle2' pr={1} fontWeight='600'>{advancedDate(date)}</Typography>
                    </Stack>
                </Box>
            </Paper>
        </Grid>
    )
}

export const PendingBlogShow = () => {
    const [data, setData] = useState(null);

    useEffect(() => {
        const getBlogsInfo = async () => {
            await axios.get(`/blog-tracking/get-report-till-month`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ` + localStorage.getItem('access'),
                        'Access-Control-Allow-Origin': '*',
                        mode: 'no-cors'
                    }
                }
            ).then((response) => {
                console.log(response);
                setData(response?.data?.message);
            }).catch((error) => {
                console.log(error.response)
            });
        }
        getBlogsInfo();
    }, [])

    return (
        <Box width={'100%'} bgcolor="#F5FAFF" sx={{ border: "solid 1px #E0E0E0", display: "inline-block", borderRadius: "5px" }}>
            <Box px={2} py={1} borderBottom={'solid 1px #E0E0E0'}>
                <Typography fontWeight="600" color="#1B2C58">Pending Blogs</Typography>
            </Box>
            <Box height='32vh' overflow='auto'>
                <Scrollbars>
                    <Grid container direction='column' flexDirection='row' spacing={1} p='4px 10px 4px 5px'>
                        {
                            data
                                ?
                                Object.keys(data.not_submitted).length != 0
                                    ? Object.keys(data.not_submitted).map((val) => {
                                        return data.not_submitted[val].map((vaal, key) => {
                                            return DisplayName(vaal, val, data.profile_pictures)
                                        })
                                    })
                                    :
                                    <Stack justifyContent="center" alignItems="center" height='30vh' width='100%'>
                                        <Plane width='60' height='60' />
                                        <Box pt={2}>No Pending Blogs Found!</Box>
                                    </Stack>
                                :
                                <Stack justifyContent="center" alignItems="center" height='30vh' width='100%'>
                                    <Box pb={2}>Loading...</Box>
                                    <Stack gap={4} sx={{ width: '50%', color: 'grey.500' }} spacing={2}>
                                        <LinearProgress color="inherit" />
                                    </Stack>
                                </Stack>
                        }
                    </Grid>
                </Scrollbars>
            </Box>
        </Box>
    )
}
