import React from 'react'

export const Oreilly = ({width}) => {
    return (
        <svg width={width} height="40" viewBox="0 0 49 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_198_788)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M44.2719 9.42406C41.6614 9.42406 39.5459 7.31368 39.5459 4.71033C39.5459 2.10839 41.6614 0 44.2719 0C46.8811 0 48.9965 2.10836 48.9965 4.71036C48.9965 7.31368 46.8811 9.42406 44.2719 9.42406ZM20.0647 40C8.98414 39.9973 0.0061852 31.0456 0.00415039 20.0007C0.0061852 8.95428 8.98414 0.00206488 20.0647 0C31.1452 0.00206488 40.1219 8.95432 40.1253 20.0007C40.1219 31.0456 31.1452 39.9973 20.0647 40ZM20.0647 6.40114C16.2873 6.40321 12.8968 7.91702 10.4183 10.3813C7.94578 12.8551 6.4253 16.234 6.42327 20.0007C6.4253 23.7646 7.94578 27.1448 10.4183 29.6153C12.8968 32.0816 16.2873 33.5974 20.0647 33.5994C23.8415 33.5974 27.2305 32.0816 29.7098 29.6153C32.1843 27.1448 33.7041 23.7646 33.7041 20.0007C33.7041 16.234 32.1843 12.8551 29.7098 10.3813C27.2305 7.91702 23.8415 6.40321 20.0647 6.40114Z" fill="#D3002D" />
            </g>
            <defs>
                <clipPath id="clip0_198_788">
                    <rect width="49" height="40" fill="white" />
                </clipPath>
            </defs>
        </svg>

    )
}
