import React, { useState } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Bar, BarChart } from 'recharts';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

export const GraphInfo = ({data}) => {
  const [selectedProduct, setSelectedProduct] = useState('');

  // Filter companies and prices based on selected product
  const filteredData = selectedProduct
    ? data.filter(item => item.product_name === selectedProduct)
    : data;

  // Get unique product names for the filter
  const productNames = [...new Set(data.map(item => item.product_name))];

  return (
    <div>
    <FormControl sx={{mt: '2rem'}}>
      <InputLabel id="product-filter-label">Select Product</InputLabel>
      <Select
        labelId="product-filter-label"
        id="product-filter"
        value={selectedProduct}
        label="Select Product"
        onChange={event => setSelectedProduct(event.target.value)}
        sx={{width: '10rem'}}
      >
        <MenuItem value="All Products">All Products</MenuItem>
        {productNames.map(product => (
          <MenuItem key={product} value={product}>{product}</MenuItem>
        ))}
      </Select>
    </FormControl>
    <BarChart
      width={600}
      height={300}
      data={filteredData}
      margin={{ top: 20, right: 30, left: 20, bottom: 10 }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="company_name" />
      <YAxis />
      <Tooltip />
      <Legend />
      {filteredData.map((item, index) => (
        <Bar
          fill='#1B2C58'
          key={index}
          dataKey={`info[${index}].price`}
          barSize={20} radius={[5, 5, 0, 0]}
          name={`${item.product_name} - ${item.info[0].company_name}`}
        />
      ))}
    </BarChart>
  </div>
  );
}
