import React from 'react'
import noDataImage from '../noData.png'
import { Box, Typography } from '@mui/material'

export const NodataFound = ({msg="No Data Available here yet!", height=''}) => {
  return (
    <Box flexDirection="column" display='flex' alignItems='center' justifyContent='center' height={height==''?'60vh':height}>
        <Box width='20rem' height='12rem' sx={{backgroundImage:`url(${noDataImage})`,backgroundSize:'10rem', backgroundRepeat:'no-repeat', backgroundPosition:'center'}}></Box>
        <Typography variant='h6'>{msg}</Typography>
    </Box>
  )
}
