import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import axios from '../../api/axios';
import { Token } from '../../constants'
import { Typography, Box, Stack, Button, LinearProgress, Paper, Grid, FormControl, Select, MenuItem, InputLabel, Accordion, AccordionSummary, AccordionDetails, IconButton, Menu, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText } from '@mui/material';
import { SingleTable } from '../../features/components/Table/SingleTable';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import Popover from '@mui/material/Popover';
import { Scrollbars } from 'react-custom-scrollbars';
import Notify from '../../features/helpers/notifyConfig';

const color_cont = {
  'primary': "#65B741",
  'corporate': "#6895D2",
  'technical': "#FF8080"
}

export const ContractInfo = () => {
  const [data, setData] = useState();
  const current_product_id = useParams().product_id;
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const openAction = Boolean(anchorEl);
  const [editOpen, setEditOpen] = useState(false);
  const [deleteProd, setDelete] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const openEdit = () => {
    setEditOpen(true);
    handleClose();
  };

  const openDelete = () => {
    setDelete(true);
    handleClose();
  }

  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  const handleDeleteBox = () => {
    setDelete(false);
  };

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const [expanded, setExpanded] = React.useState('panel0');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const getCurrentContract = async () => {
    await axios.get(`/dashboard/contract/${current_product_id}`,
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ` + localStorage.getItem('access')
        }
      }
    ).then((response) => {
      console.log(response);
      setData(response?.data?.message);
      // const date = new Date(dateStr);
      // setData(prev => ({
      //   ...prev, certificates_details: prev.certificate_details
      // }));
    }).catch((error) => {
      console.log(error.response)
    });
  }

  useEffect(() => {
    getCurrentContract();
  }, [refresh])

  const confirmDelete =()=>{
    const deleteProduct = async () => {
      await axios.delete(`/dashboard/contract/deleteproduct`,
        {
          headers: {
            'Authorization': `Bearer ` + localStorage.getItem('access')
          },
          data: {
            "_id": data._id,
            "company_id": data.company_id
          }
        }).then((response) => {
          Notify.success('Product Deleted Successfully!');
          handleDeleteBox();
          window.location.href = '/contractsigning';
        }).catch((error) => {
          Notify.error('Product Deletion Failed. Try Again!');
        })
    }
    deleteProduct();
  }

  const titles = ['Certificate Name', 'Expiry Date', 'Action'];


  return (
    <Box px={3} py={2}>
      {
        data ?
          <Grid container rowSpacing={4} columnSpacing={6}>
            <Grid item md={12}>
              <Paper elevation={2} sx={{ paddingY: "0.6rem", paddingX: "2rem", borderRadius: "10px", minHeight: "360px", backgroundColor: "#F6F5F5", overflowY: 'hidden' }}>
                <Grid container>
                  <Grid item md={6}>
                    <Stack spacing={1} direction={'row'} mb={1.5} alignItems='center'>
                      <Typography fontWeight={'600'} variant='h5' color='primary.main'>Basic Information</Typography>
                      <Box>
                        <IconButton
                          id="basic-button"
                          aria-controls={openAction ? 'basic-menu' : undefined}
                          aria-haspopup="true"
                          aria-expanded={openAction ? 'true' : undefined}
                          onClick={handleClick}
                        >
                          <MoreVertIcon />
                        </IconButton>
                        <Menu
                          id="basic-menu"
                          anchorEl={anchorEl}
                          open={openAction}
                          onClose={handleClose}
                          MenuListProps={{
                            'aria-labelledby': 'basic-button',
                          }}
                        >
                          <MenuItem onClick={openEdit}>Edit</MenuItem>
                          <MenuItem onClick={openDelete}>Delete</MenuItem>
                        </Menu>
                      </Box>
                    </Stack>

                    <div style={{ marginBottom: '8px' }}>
                      <Typography fontWeight={'600'} variant='subtitle1' display="inline-block">Company Name:&nbsp;&nbsp;</Typography>
                      <Typography variant='subtitle1' color="primary.main" display="inline-block">{data.company_name}</Typography>
                    </div>

                    <div style={{ marginBottom: '8px' }}>
                      <Typography fontWeight={'600'} variant='subtitle1' display="inline-block">Product Name:&nbsp;&nbsp;</Typography>
                      <Typography variant='subtitle1' color="primary.main" display="inline-block">{data.product_name}</Typography>
                    </div>

                    <div style={{ marginBottom: '8px' }}>
                      <Typography fontWeight={'600'} variant='subtitle1' display="inline-block">Company Address:&nbsp;&nbsp;</Typography>
                      <Typography variant='subtitle1' color="primary.main" display="inline-block">{data.company_address}</Typography>
                    </div>

                    <div style={{ marginBottom: '8px' }}>
                      <Typography fontWeight={'600'} variant='subtitle1' display="inline-block">Duration:&nbsp;&nbsp;</Typography>
                      <Typography variant='subtitle1' color="primary.main" display="inline-block">{Math.floor((data.duration) / 12)} year {(data.duration) % 12} month</Typography>
                    </div>

                    <div style={{ marginBottom: '8px' }}>
                      <Typography fontWeight={'600'} variant='subtitle1' display="inline-block">Start Date:&nbsp;&nbsp;</Typography>
                      <Typography variant='subtitle1' color="primary.main" display="inline-block">{data.start_date}</Typography>
                    </div>

                    <div style={{ marginBottom: '8px' }}>
                      <Typography fontWeight={'600'} variant='subtitle1' display="inline-block">End Date:&nbsp;&nbsp;</Typography>
                      <Typography variant='subtitle1' color="primary.main" display="inline-block">{data.end_date}</Typography>
                    </div>

                    <div style={{ marginBottom: '8px' }}>
                      <Typography fontWeight={'600'} variant='subtitle1' display="inline-block">Price:&nbsp;&nbsp;</Typography>
                      <Typography variant='subtitle1' color="primary.main" display="inline-block">{data.price}</Typography>
                    </div>

                    <div style={{ marginBottom: '8px' }}>
                      <Typography fontWeight={'600'} variant='subtitle1' display="inline-block">Quantity:&nbsp;&nbsp;</Typography>
                      <Typography variant='subtitle1' color="primary.main" display="inline-block">{data.quantity}</Typography>
                    </div>

                    {/* <Button>sd</Button> */}
                  </Grid>

                  <Grid item md={6}>
                    {/* <Paper elevation={2} sx={{ paddingY: "0.6rem", paddingX: "2rem", borderRadius: "10px", backgroundColor: "#F6F5F5", height: "365px", overflowY: "hidden" }}> */}
                    {/* <Typography fontWeight={'600'} variant='h5' mb={1.5} color='primary.main'>Point Of Contacts</Typography> */}
                    <div style={{ width: "450px", marginLeft: "2rem" }}>
                      {data.point_of_contact.map((val, key) =>
                        <Accordion expanded={expanded === `panel${key}`} onChange={handleChange(`panel${key}`)}>
                          <AccordionSummary sx={{
                            '& .MuiAccordionSummary-content': {
                              margin: 0
                            },
                            '& .MuiAccordionSummary-root': {
                              minHeight: '20px'
                            }
                          }} aria-controls="panel2d-content" id="panel2d-header">
                            <Stack direction='row' alignItems='center'>
                              <FiberManualRecordIcon sx={{ color: color_cont[val.type] }} fontSize='10px' />&nbsp;
                              <Typography color='primary.main' fontWeight={'600'}>{val.type.toUpperCase()}</Typography>
                            </Stack>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Stack mb={1} direction='row'>
                              <Typography fontWeight={'600'} variant='subtitle1'>Name:&nbsp;&nbsp;</Typography>
                              <Typography variant='subtitle1' color="primary.main">{val.poc_name}</Typography>
                            </Stack>

                            <Stack mb={1} direction='row'>
                              <Typography fontWeight={'600'} variant='subtitle1'>Contact:&nbsp;&nbsp;</Typography>
                              <Typography variant='subtitle1' color="primary.main">{val.contact == "" ? "-" : val.contact}</Typography>
                            </Stack>

                            <Stack mb={1} direction='row'>
                              <Typography fontWeight={'600'} variant='subtitle1'>Mail ID:&nbsp;&nbsp;</Typography>
                              <Typography variant='subtitle1' color="primary.main">{val.mail_id}</Typography>
                            </Stack>

                          </AccordionDetails>
                        </Accordion>
                      )}
                    </div>
                    {/* </Paper> */}
                  </Grid>

                </Grid>
              </Paper>
            </Grid>

            <Grid item md={12}>
              <SingleTable titles={titles} accountInfo={data.certificates_details} editData={data} editOpen={editOpen} setEditOpen={setEditOpen} setRefresh={setRefresh} type="edit" />
            </Grid>

            <Dialog
              open={deleteProd}
              onClose={handleDeleteBox}
              aria-labelledby="edit-apartment"
              fullWidth
              maxWidth='sm'
            >
              <DialogTitle sx={{ backgroundColor: "#F6F5F5" }} id="edit-apartment">Are you sure you want to delete {data.product_name}</DialogTitle>
              <DialogContent sx={{ backgroundColor: "#F6F5F5" }}>
                <DialogContentText>Deleting this product will also remove all associated Certificates</DialogContentText>
              </DialogContent>
              <DialogActions sx={{ backgroundColor: "#F6F5F5" }}>
                <Button onClick={handleDeleteBox} color="secondary">
                  Cancel
                </Button>
                <Button onClick={confirmDelete} color="error">
                  Delete
                </Button>
              </DialogActions>
            </Dialog>

          </Grid>
          :
          <Stack justifyContent="center" alignItems="center" height="80vh">
            <Box pb={2}>Wait while we gather required information...</Box>
            <Stack gap={4} sx={{ width: '50%', color: 'grey.500' }} spacing={2}>
              <LinearProgress color="inherit" />
            </Stack>
          </Stack>
      }
    </Box >
  )
}