import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#1B2C58",
      light: "#657EBF",
      lighter: "#7F9DEC",
      dark: "##648EFB",
      white: "#FFFFFF"
    },
    secondary: {
      main: "#78A1DE",
      dark: "#375CBD",
    },
    tertiary: {
      main: "#ECF0F2",
      light: "#F6F7FB",
    },
    background: {
      default: "#F3F3F3",
      paper: "#FFFFFF",
      light_gray: "#eeeeee"
    },
    success: {
      // main: "#E2FDD5",
      main: "#198f51",
      dark: "#3f9b14",
    },
    error: {
      // main: "#FFCFCC",
      main: "#d84d4d",
      dark: "#ff6a62",
    },
    divider: "#dcdcdc",
    dark_divider: "#bab8b8",
    text: {
      placeholder: "#989898",
    },
    // mode: 'light'
  },
  typography: {
    fontFamily: 'Nunito, "Open Sans", sans-serif',
    h6: {
      fontSize: "1.25rem",
      fontWeight: 700,
    },
  },
  shape: {
    borderRadius: 10,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          padding: "0.3em 1.25em",
          width: "fit-content",
          textTransform: "none",
        },
      },
      variants: [
        {
          props: { size: "small" },
          style: {
            fontSize: "0.875rem",
          },
        },
        {
          props: { size: "medium" },
          style: {
            fontSize: "0.95rem",
          },
        },
        {
          props: { size: "large" },
          style: {
            fontSize: "1rem",
          },
        },
      ],
    },
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {color:"red"},
      },
    },
  },
  // '@global': {
  //   '*::-webkit-scrollbar': {
  //     width: '0.4em'
  //   },
  //   '*::-webkit-scrollbar-track': {
  //     '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
  //   },
  //   '*::-webkit-scrollbar-thumb': {
  //     backgroundColor: 'rgba(0,0,0,.1)',
  //     outline: '1px solid slategrey'
  //   }
  // }
});

export default theme;
