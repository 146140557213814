import { useEffect, useState } from 'react';
import { Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, Menu, MenuItem, Modal, Pagination, Skeleton, Stack, Tab, Tooltip, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';
import Rating from '@mui/material/Rating';
import StarIcon from '@mui/icons-material/Star';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Notify from '../../features/helpers/notifyConfig';
import axios from '../../api/axios';

const isEmptyArray = (val) => {
    return val == "" ? "-" : val;
}

const labels = {
    0.5: '0.5',
    1: '1',
    1.5: '1.5',
    2: '2',
    2.5: '2.5',
    3: '3',
    3.5: '3.5',
    4: '4',
    4.5: '4.5',
    5: '5',
};

function getLabelText(value) {
    return `${value} Star${value !== 1 ? 's' : ''}, ${labels[value]}`;
}

export function CertTable({ table_data, setLoading, type = "" }) {
    const [anchorElMap, setAnchorElMap] = useState({});
    const [open, setOpen] = useState(false);
    const [confirm, isConfirm] = useState("");
    const [value, setValue] = useState(1);
    const [hover, setHover] = useState(-1);
    const [page, setPage] = useState(0);

    const setCredits = (row, vaal) => {
        setValue(vaal);
        isConfirm(row);
    }

    const handleClick = (event, id) => {
        setAnchorElMap(prevMap => ({
            ...prevMap,
            [id]: event.currentTarget,
        }));
    };

    const downloadCert = (src, name) => {
        setAnchorElMap({});
        const linkSource = `${src}`;
        const downloadLink = document.createElement("a");
        downloadLink.href = linkSource;
        downloadLink.download = name;
        downloadLink.click();
    }

    const deleteCert = async (ied) => {
        setAnchorElMap({});
        await axios.delete(`/dashboard/certificates/delete`,
            {
                headers: {
                    'Authorization': `Bearer ` + localStorage.getItem('access')
                },
                data: {
                    "Certificate_Id": ied
                }
            }).then((response) => {
                Notify.success('Certificate Deleted Successfully!');
                setLoading(prev => !prev)
            }).catch((error) => {
                Notify.error("Certificate Deletion Error");
            })
    }

    const rows =
        (table_data && table_data != "") ?
            table_data.map((key, val) => ({
                id: table_data[val].Certificate_Id,
                'Course_Name': table_data[val].Course_Name,
                'Issuing_Authority': table_data[val].Issuing_Authority,
                'Issue_Date': table_data[val].Issue_Date,
                'Expiry_Date': table_data[val].Expiry_Date,
                'Rating': table_data[val].Rating,
                'Certificate_Id': table_data[val].Certificate_Id,
                'Document': table_data[val].Document
            }))
            : [{ id: 1, Course_Name: "", Issuing_Authority: "", Issue_Date: "No Certificate found", Expiry_Date: "", Rating: "", Certificate_Id: "", Document: "" }];
    ;

    const handleClose = () => {
        isConfirm("");
        setAnchorElMap({});
    };

    const submitCredit = async (id, cert_id, vaal) => {
        await axios.post
            ("/dashboard/certificates/review", JSON.stringify({
                "Certificates": [
                    {
                        "id": id,
                        "Certificate_Id": cert_id,
                        "Rating": vaal.toString()
                    }
                ]
            }),
                {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Content-Type': 'application/json',
                        withCredentials: true,
                        mode: 'no-cors',
                        'Authorization': `Bearer ` + localStorage.getItem('access')
                    }
                }).then((response) => {
                    setLoading(prev => !prev);
                    handleClose();
                    Notify.success("Credits Added Successfully!")
                }).catch((error) => {
                    console.log(error.response);
                    Notify.error("Error Occurred. Try Again!");
                })
    }

    const pageSize = 10;
    const pageCount = Math.ceil(rows.length / pageSize);
    const rowsOnPage = rows.slice(page * pageSize, (page + 1) * pageSize);

    const columns = [
        { field: 'Course_Name', headerName: 'Name', flex: 1, headerClassName: 'whiteMenuIcon' },
        { field: 'Issuing_Authority', headerName: 'Issuing Authority', flex: 1, headerClassName: 'whiteMenuIcon' },
        { field: 'Issue_Date', headerName: 'Issue Date', flex: 1, headerClassName: 'whiteMenuIcon' },
        {
            field: 'Expiry_Date', headerName: 'Expiry Date', flex: 1, headerClassName: 'whiteMenuIcon', renderCell: (params) => {
                return (
                    <Box>{params.row.Expiry_Date != "" ? params.row.Expiry_Date : "-"}</Box>
                )
            }
        },

        (table_data != "" && type == "pending")
            ? {
                field: 'Rating', headerName: 'Rating', flex: 1, headerClassName: 'whiteMenuIcon', renderCell: (params) => {
                    return (
                        <Box
                            sx={{
                                width: 200,
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <Rating
                                name="hover-feedback"
                                defaultValue={1}
                                precision={0.5}
                                getLabelText={getLabelText}
                                onChange={(event, newValue) => {
                                    setCredits(params.row, newValue);
                                }}
                                onChangeActive={(event, newHover) => {
                                    setHover(newHover);
                                }}
                                emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                            />
                            {/* {value !== null && (
                                <Box sx={{ ml: 2 }}>{labels[hover !== -1 ? hover : value]}</Box>
                            )} */}
                        </Box>
                    )
                }
            }
            : { field: 'Rating', headerName: 'Rating', flex: 1, headerClassName: 'whiteMenuIcon' }
        ,

        {
            field: 'Document', headerName: 'Action', flex: 1, headerClassName: 'whiteMenuIcon', renderCell: (params) => {
                return (
                    (table_data && table_data != "") ?
                        <Box>
                            <IconButton
                                id={`basic-button-${params.id}`}
                                aria-controls={anchorElMap[params.id] ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={anchorElMap[params.id] ? 'true' : undefined}
                                onClick={(event) => handleClick(event, params.id)}
                            >
                                <MoreVertIcon />
                            </IconButton>
                            <Menu
                                id={`basic-menu-${params.id}`}
                                anchorEl={anchorElMap[params.id]}
                                open={Boolean(anchorElMap[params.id])}
                                onClose={handleClose}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                }}
                                PaperProps={{
                                    style: {
                                        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                                    },
                                }}
                            >
                                <MenuItem onClick={() => downloadCert(params.row.Document, params.row.Course_Name)}>Download</MenuItem>
                                <MenuItem onClick={() => deleteCert(params.row.Certificate_Id)}>Delete</MenuItem>
                            </Menu>
                        </Box> : <></>)
            }
        },
    ];

    return (
        <>
            <DataGrid
                rows={rowsOnPage}
                columns={columns}
                // pageSize={pageSize}
                disableColumnSelector
                hideFooter
                // showCellVerticalBorder
                disableSelectionOnClick
                rowHeight={65}
                style={{ backgroundColor: 'white' }}
                sx={{
                    '& .MuiDataGrid-columnHeaders': {
                        backgroundColor: '#657EBF'
                    },
                    '& .MuiDataGrid-cell': {
                        fontSize: '15px',
                        paddingY: '25px'
                    },
                    '& .MuiDataGrid-columnHeaderTitle': {
                        fontWeight: 'bold',
                        fontSize: '16px'
                    },
                    '& .MuiDataGrid-columnSeparator': {
                        display: 'none'
                    },
                    '& .MuiInputBase-input': {
                        height: '20px !important'
                    },
                    '& .MuiDataGrid-cell:focus, & .MuiDataGrid-columnHeader:focus': {
                        outline: 'none',
                        boxShadow: 'none',
                        border: 'none'
                    },
                }}
            />
            {table_data ? <Stack borderRadius='10px' bgcolor='white' mt={0.2} py={1.5} justifyContent='center' direction='row' width='100%'>
                <Pagination
                    count={pageCount}
                    page={page + 1}
                    onChange={(event, value) => setPage(value - 1)} // Adjust for 0-based index
                    shape="rounded"
                />
            </Stack> : <></>}

            {confirm != "" ?
                <Dialog maxWidth='xs' onClose={handleClose} open={confirm != "" ? true : false}>
                    <DialogContent>
                        <Typography mb={1} variant='h6'>Are you sure you want to set credits for <span style={{ color: 'green' }}>{confirm.Course_Name}</span> as <span style={{ color: '#1B2C58' }}>{value}</span> ? </Typography>
                        {/* <DialogContentText mb={2}>Deleting this employee will remove all the information of employee from Database and Azure !</DialogContentText> */}
                        {console.log(confirm)}
                        <Typography></Typography>
                    </DialogContent>

                    <DialogActions>
                        <Button variant='contained' color='primary' onClick={() => submitCredit(confirm.Certificate_Id, confirm.id, value)}>Confirm</Button>
                    </DialogActions>
                </Dialog>
                : <></>
            }
        </>
    );
}