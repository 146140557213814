import { AccordionActions, AccordionDetails, Box, Button, Dialog, DialogContent, DialogContentText, DialogTitle, Grid, Skeleton, Stack, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { TimeSheetHours, getHours, getMinutes } from '../../features/components/TimeSheet/TimeSheetHours';
import { TimeSheetWeek } from '../../features/components/TimeSheet/TimeSheetWeek';
import { ButtonStyle } from './TimeSheet';
import { CreateTimesheet } from './CreateTimesheet';
import { UserContext } from './ManagerTimesheet';
import { TimesheetEntry } from '../../features/components/TimeSheet/TimesheetEntry';
import axios from '../../api/axios';
import { NodataFound } from '../NodataFound';
import Notify from '../../features/helpers/notifyConfig';
import { TimeSheetNewTable, TimeSheetTitles } from '../../features/components/TimeSheet/TimeSheetNewTable';
import { LoadingButton } from '@mui/lab';
// import { TimeSheetTable } from '../../features/components/TimeSheet/TimeSheetTable';

export const EditTimesheet = ({ activeSheet, sheetData, employeeSheetID, setRefresh }) => {
    const schedule = activeSheet.work_schedule;
    var remaining_hours = { mon: 0, tue: 0, wed: 0, thu: 0, fri: 0, sat: 0, sun: 0 };
    const defaultState = {
        "projectID": "",
        "taskID": "",
        "billable": false,
        "workDay": {
            "fri": {
                "comment": activeSheet.work_schedule.fri.comment ?? "",
                "hour": 0,
                "work": activeSheet.work_schedule.fri.work ?? false
            },
            "mon": {
                "comment": activeSheet.work_schedule.mon.comment ?? "",
                "hour": 0,
                "work": activeSheet.work_schedule.mon.work ?? false
            },
            "sat": {
                "comment": activeSheet.work_schedule.sat.comment ?? "",
                "hour": 0,
                "work": activeSheet.work_schedule.sat.work ?? false
            },
            "sun": {
                "comment": activeSheet.work_schedule.sun.comment ?? "",
                "hour": 0,
                "work": activeSheet.work_schedule.sun.work ?? false
            },
            "thu": {
                "comment": activeSheet.work_schedule.thu.comment ?? "",
                "hour": 0,
                "work": activeSheet.work_schedule.thu.work ?? false
            },
            "tue": {
                "comment": activeSheet.work_schedule.tue.comment ?? "",
                "hour": 0,
                "work": activeSheet.work_schedule.tue.work ?? false
            },
            "wed": {
                "comment": activeSheet.work_schedule.wed.comment ?? "",
                "hour": 0,
                "work": activeSheet.work_schedule.wed.work ?? false
            }
        },
        "description": ""
    }
    const [loading, setLoading] = useState();
    const [submit, triggerSubmit] = useState();

    var temp_sheet = [];
    sheetData.map((val, key) => {
        temp_sheet.push(
            {
                "projectID": val.Project.projectID,
                "taskID": val.Task.taskID,
                "billable": val.billable,
                "workDay": {
                    "fri": {
                        "comment": activeSheet.work_schedule.fri.comment ?? "",
                        "hour": activeSheet.work_schedule.fri.work == false ? 0 : val.workDay.fri.hour,
                        "work": activeSheet.work_schedule.fri.work ?? false
                    },
                    "mon": {
                        "comment": activeSheet.work_schedule.mon.comment ?? "",
                        "hour": activeSheet.work_schedule.mon.work == false ? 0 : val.workDay.mon.hour,
                        "work": activeSheet.work_schedule.mon.work ?? false
                    },
                    "sat": {
                        "comment": activeSheet.work_schedule.sat.comment ?? "",
                        "hour": activeSheet.work_schedule.sat.work == false ? 0 : val.workDay.sat.hour,
                        "work": activeSheet.work_schedule.sat.work ?? false
                    },
                    "sun": {
                        "comment": activeSheet.work_schedule.sun.comment ?? "",
                        "hour": activeSheet.work_schedule.sun.work == false ? 0 : val.workDay.sun.hour,
                        "work": activeSheet.work_schedule.sun.work ?? false
                    },
                    "thu": {
                        "comment": activeSheet.work_schedule.thu.comment ?? "",
                        "hour": activeSheet.work_schedule.thu.work == false ? 0 : val.workDay.thu.hour,
                        "work": activeSheet.work_schedule.thu.work ?? false
                    },
                    "tue": {
                        "comment": activeSheet.work_schedule.tue.comment ?? "",
                        "hour": activeSheet.work_schedule.tue.work == false ? 0 : val.workDay.tue.hour,
                        "work": activeSheet.work_schedule.tue.work ?? false
                    },
                    "wed": {
                        "comment": activeSheet.work_schedule.wed.comment ?? "",
                        "hour": activeSheet.work_schedule.wed.work == false ? 0 : val.workDay.wed.hour,
                        "work": activeSheet.work_schedule.wed.work ?? false
                    }
                },
                "description": val.description
            }
        );
        remaining_hours.mon = remaining_hours.mon + temp_sheet[key].workDay.mon.hour;
        remaining_hours.tue = remaining_hours.tue + temp_sheet[key].workDay.tue.hour;
        remaining_hours.wed = remaining_hours.wed + temp_sheet[key].workDay.wed.hour;
        remaining_hours.thu = remaining_hours.thu + temp_sheet[key].workDay.thu.hour;
        remaining_hours.fri = remaining_hours.fri + temp_sheet[key].workDay.fri.hour;
    }
    )

    remaining_hours.mon = schedule.mon.work == "false" ? 0 : (8 - remaining_hours.mon);
    remaining_hours.tue = schedule.tue.work == "false" ? 0 : (8 - remaining_hours.tue);
    remaining_hours.wed = schedule.wed.work == "false" ? 0 : (8 - remaining_hours.wed);
    remaining_hours.thu = schedule.thu.work == "false" ? 0 : (8 - remaining_hours.thu);
    remaining_hours.fri = schedule.fri.work == "false" ? 0 : (8 - remaining_hours.fri);

    const [sets, handleSets] = useState(temp_sheet);
    const [remain_hours, setHours] = useState(remaining_hours);
    const handleSubmit = (action) => {
        let hasWarning = false;
        sets.some((val, key) => {
            if (val.projectID === "") {
                Notify.warning("Project is not selected!");
                hasWarning = true;
                return true;  // Exit the loop
            } else if (val.taskID === "") {
                Notify.warning("Task is not selected!");
                hasWarning = true;
                return true;  // Exit the loop
            } else if (val.description === "") {
                Notify.warning("Description must be filled!");
                hasWarning = true;
                return true;  // Exit the loop
            }
            return false;  // Continue to the next iteration
        });

        if (hasWarning) {
            return;
        }

        setLoading(action == "Submit" ? "Submit" : "Draft");
        triggerSubmit({
            "timesheet": {
                "action": action == "Submit" ? "Submit" : "Draft",
                "employeeSheetID": employeeSheetID,
                "employeeSheetObjects": [
                    ...sets
                ]
            }
        })
    }

    const changeRowsHandler = () => {
        handleSets(prev => [...prev, defaultState])
    }

    // useEffect(()=>{
    //     if(sets){
    //     const getHour =sets.workDay;
    //     const temp =[{mon: 8-(sets.workDay.mon.hour), tue: 8-(sets.workDay.tue.hour), wed: 8-(sets.workDay.wed.hour),
    //         thu: 8-(sets.workDay.thu.hour), fri: 8-(sets.workDay.fri.hour)}]
    //     setHours(temp)
    //     }
    // },[sets])

    //1.  Employee submit/draft his/her timesheet
    const postTimeSheet = async () => {
        await axios.post("/timesheet/employee/action",
            JSON.stringify(submit),
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json',
                    withCredentials: true,
                    mode: 'no-cors',
                    'Authorization': `Bearer ` + localStorage.getItem('access')
                }
            }).then((response) => {
                console.log(response);
                // setSubmit(false);
                // setError(false);
                setRefresh(prev => !prev);
                loading == "Submit" ? Notify.success("Timesheet Submitted Successfully!") : Notify.success("Timesheet Saved as Draft!")
                setLoading();
            }).catch((error) => {
                console.log(error.response)
                // setSubmit(false);
                // setError(true);
                setLoading();
                Notify.error((error?.response?.data?.error) ?? "Error Occurred");
            })
    }
    useEffect(() => {
        if (submit) {
            postTimeSheet();
        }
    }, [submit])

    return (
        <AccordionDetails>
            <Box>
                <Box my={2} border='1px solid' borderColor="dark_divider">
                    <Grid gap={1} container columns={16.5} py={0.3} px={1} alignItems='center' sx={{
                        backgroundColor: 'primary.light',
                        color: 'primary.white'
                    }}>
                        {TimeSheetTitles.map((title, key) => {

                            return (
                                <Grid key={key} item my={1} md={title == "Project" || title == "Task" || title == "Description" ? 2 : 1}>
                                    <Typography
                                        variant='subtitle2'>
                                        {title}
                                    </Typography>
                                </Grid>
                            )
                        })}
                    </Grid>

                    {/* Main Table */}
                    <Grid gap={1} container columns={16.5} py={1} px={1} alignItems='center'>
                        {
                            sets.map((val, key) => (
                                <TimeSheetNewTable
                                    key={key}
                                    sets={sets}
                                    handleSets={handleSets}
                                    employeeData={activeSheet.Projects}
                                    val={key}
                                    setHours={setHours}
                                    remain_hours={remain_hours}
                                    type='edit'
                                    escalation={activeSheet.isEscalated}
                                />))
                        }
                    </Grid>

                    {/* Remaining hours band */}
                    <Grid gap={1} container columns={16.5} py={0.8} px={1} alignItems='center' bgcolor="#F68989">
                        <Grid item md={5.3} textAlign="center" fontWeight="600">Remaining Hours:</Grid>
                        {Object.entries(remain_hours).map(([day, hours]) => 
                            (
                                <Grid item md={1}>{getHours(hours)}h {getMinutes(hours)}m</Grid>
                            )
                        )}
                    </Grid>

                </Box>
            </Box>

            <AccordionActions>
                <Button disabled={loading ? true : false} onClick={changeRowsHandler} variant='contained' sx={{ py: 1, px: 3, borderRadius: '5px', height: '35px' }}>
                    <Typography variant='subtitle2'>Add Rows</Typography>
                </Button>
                <LoadingButton loading={loading == "Draft"} onClick={() => handleSubmit("Draft")} variant='contained'>Save</LoadingButton>
                <LoadingButton loading={loading == "Submit"} onClick={() => handleSubmit("Submit")} variant='outlined'>Submit</LoadingButton>
            </AccordionActions>
        </AccordionDetails>
    )
}