import { Autocomplete, Box, Checkbox, FormControlLabel, Grid, Paper, TextField, Typography } from '@mui/material'
import { advancedDate, postData } from './index';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { format } from 'date-fns';
import DOMPurify from 'dompurify';
import ReactQuill from 'react-quill';
import { css, Global } from '@emotion/react';
import 'react-quill/dist/quill.snow.css';
import dayjs from 'dayjs';
import { globalStyles } from './EventsForm';

export const EventsView = ({ data, editData, setEdit, empList }) => {
    console.log(editData);

    const handleChange = (event, value, naam) => {
        value = editData['event_type'] == 'Blog' ? [value] : value;
        setEdit(prevEdit => ({
            ...prevEdit,
            [naam]: value
        }));
    };

    const handleDateChange = (date, naam) => {
        const dateObject = new Date(date.$d);
        const val = naam == "start_date" ? postData(date.$d) : format(dateObject, "h:mm a");
        setEdit(prevEdit => ({
            ...prevEdit,
            [naam]: val
        }));
    };

    const handleInChange = (e, naam) => {
        setEdit(prevEdit => ({
            ...prevEdit,
            [naam]: e.target.value
        }));    }
    
    const handleDescChange = (content) => {
        setEdit(prevEdit => ({
            ...prevEdit,
            description: content
        }));
    };

    return (
        editData
            ? <Box zIndex={999999}>
                <Grid mt={0} spacing={2} container>

                    <Grid item xs={12} sm={6}>
                        <TextField
                            sx={{ marginTop: '8px' }}
                            value={editData.event_title ?? ""}
                            id={`event_title`}
                            label="Title"
                            fullWidth
                        onChange={(e) => handleInChange(e, "event_title")}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={['DatePicker']}>
                                <DatePicker
                                    // sx={{ paddingTop: '8px' }}
                                    value={editData.start_date ? dayjs(editData.start_date) : null}
                                    id={`start_date`}
                                    onChange={(date) => handleDateChange(date, "start_date")}
                                    label="Start Date" />
                            </DemoContainer>
                        </LocalizationProvider>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Autocomplete
                            sx={{ paddingTop: '8px' }}
                            multiple={editData.event_type == "Blog" ? false : true}
                            limitTags={2}
                            id={`assigned_to`}
                            value={editData.assigned_to[0]}
                            onChange={(e, val) => handleChange(e, val, "assigned_to")}
                            options={Object.keys(empList)}
                            getOptionLabel={(option) => (empList[option] ? empList[option].employee_name : '')}
                            getOptionSelected={(option, value) => empList[option].employee_name === value}
                            renderInput={(params) => <TextField {...params} label="Select Assignee" variant="outlined" />}
                            renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                    <FormControlLabel
                                        control={<Checkbox checked={selected} />}
                                        label={empList[option].employee_name}
                                    />
                                </li>
                            )}
                        />
                    </Grid>

                    <Grid item xs={12}>
                          <Global styles={globalStyles} />
                          <ReactQuill style={{marginTop:'0.6rem'}} value={editData.description ?? ""} id="description" onChange={(content) => handleDescChange(content)} placeholder="Description here....." theme="snow"/>
                    </Grid>
                </Grid>
            </Box>
            : <Box zIndex={999999}>
                <Grid container md={12} my={2}>
                    <Grid item md={3}><Typography fontWeight='600'>Activity Type:</Typography></Grid>
                    <Grid pl={1} item md={7}><Typography>{data.type == "Blog" ? data.type : data.event_type}</Typography></Grid>
                </Grid>

                <Grid container md={12} my={2}>
                    <Grid item md={3}><Typography fontWeight='600'>Title:</Typography></Grid>
                    <Grid pl={1} item md={7}><Typography>{data.event_title ? data.event_title : "-"}</Typography></Grid>
                </Grid>

                <Grid container md={12} my={2}>
                    <Grid item md={3}><Typography fontWeight='600'>Assigned To:</Typography></Grid>
                    <Grid pl={1} item md={7}>
                        {data.assigned_to.map((val) =>
                            <span style={{ marginRight: '10px', backgroundColor: '#dcdcdc', padding: '4px 7px', borderRadius: '4px' }}>{val}</span>
                        )
                        }
                    </Grid>
                </Grid>

                <Grid container md={12} my={2}>
                    <Grid item md={3}><Typography fontWeight='600'>Start Date:</Typography></Grid>
                    <Grid pl={1} item md={7}><Typography>{advancedDate(data.start_date)}</Typography></Grid>
                </Grid>

                {data.start_time ? <Grid container md={12} my={2}>
                    <Grid item md={3}><Typography fontWeight='600'>Start Time:</Typography></Grid>
                    <Grid pl={1} item md={7}><Typography>{data.start_time}</Typography></Grid>
                </Grid> : <></>}

                <Grid container md={12} my={2}>
                    <Grid item md={3}><Typography fontWeight='600'>End Date:</Typography></Grid>
                    <Grid pl={1} item md={7}><Typography>{data.end_date ? advancedDate(data.end_date) : advancedDate(data.start_date)}</Typography></Grid>
                </Grid>

                {data.end_date ? <Grid container md={12} my={2}>
                    <Grid item md={3}><Typography fontWeight='600'>End Time:</Typography></Grid>
                    <Grid pl={1} item md={7}><Typography>{data.end_time}</Typography></Grid>
                </Grid> : <></>}

                <Grid container md={12} my={2}>
                    <Grid item md={3}><Typography fontWeight='600'>Description:</Typography></Grid>
                    <Grid pl={1} item md={9}>
                        <Typography pb={1} fontFamily='Segoe UI' sx={{borderStyle:'dashed'}} component="div" border='solid 2px lightgrey' borderRadius='7px' px={2}>
                            <div style={{"& p": {marginTop: '0px'}}} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data.description) }} />
                        </Typography>
                    </Grid>
                </Grid>

            </Box>
    )
}
