import { Box, Button, FormControl, Grid, IconButton, InputLabel, MenuItem, Paper, Select, Stack, TextField, Typography } from '@mui/material'
import AddIcon from '@mui/icons-material/Add';
import Autocomplete from '@mui/material/Autocomplete';

import { useEffect, useState } from 'react';

const DeviceTypes = ['Laptop', 'Desktop', 'Others'];

export const AssetForm = ({ userData, setuserData, handleClose, setbtnStatus }) => {

    const [data, setData] = useState(userData.Assets.length > 0 ? userData.Assets : [{
        "type": "",
        "device": "",
        "mac_address": "",
        "price": ""
    }]);

    const addContactCard = () => {
        const updated = [...data]
        updated.unshift({
            "type": "",
            "device": "",
            "mac_address": "",
            "price": ""
        });
        setData(updated);
    };

    const handleAssets = (e, key, field) => {
        const { value } = e.target;
        const updated = [...data];
        updated[key][field] = value;
        setData(updated);
    };

    const handleAddData = (e, val, key, id = "") => {
        if (e && e.target) {
            const naam = e.target.id;
            const newValue = val === null ? "" : val;
            const newId = id == "" ? naam : id;
            console.log(newValue + " " + newId);

            const updated = [...data];
            updated[key][newId] = newValue;
            setData(updated);
        }
    }

    const addToState = () => {
        setuserData((prevState) => ({
            ...prevState,
            Assets: data,
        }));
        handleClose(false);
        setbtnStatus('block');
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant='h6' fontWeight="600" textAlign="center">
                    Add Asset &nbsp;&nbsp;
                    <IconButton
                        size="small"
                        sx={{
                            backgroundColor: "#C7C8CC",
                            "&:hover": {
                                backgroundColor: "#B4B4B8",
                            },
                        }}
                        onClick={addContactCard}
                    >
                        <AddIcon fontSize="7px" />
                    </IconButton>
                </Typography>
            </Grid>
            <Grid item xs={12}>
                {data.map((val, index) => (
                    <Paper elevation={3} sx={{ marginBottom: "1.5rem", padding: "1rem" }}>
                        <Grid container spacing={2}>

                            <Grid item xs={6}>
                                <FormControl fullWidth>
                                    <InputLabel id={`demo-simple-select-label_${index}`}>Asset Type</InputLabel>
                                    <Select
                                        sx={{ height: '46px' }}
                                        labelId={`demo-simple-select-label_${index}`}
                                        id={`type_${index}`}
                                        value={val.type ?? ""}
                                        label="Asset Type"
                                        onChange={(e) => handleAssets(e, index, "type")}
                                    >
                                        <MenuItem value="">Select One</MenuItem>
                                        <MenuItem value="Personal">Personal</MenuItem>
                                        <MenuItem value="Company">Company</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={6}>
                                <Autocomplete
                                    id={`device_${index}`}
                                    value={val.device ?? ""}
                                    sx={{ height: '46px' }}
                                    freeSolo
                                    options={DeviceTypes.map((option) => option)}
                                    renderInput={(params) => <TextField {...params} required label="Device Type" />}
                                    getOptionLabel={(option) => option}
                                    onInputChange={(e, val) => handleAddData(e, val, index, "device")}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <TextField
                                    value={val.mac_address ?? ""}
                                    id={`mac_address_${index}`}
                                    label="Serial No."
                                    fullWidth
                                    onChange={(e) => handleAssets(e, index, "mac_address")}
                                />
                            </Grid>

                            {val.type == "Company" ? <Grid item xs={6}>
                                <TextField
                                    value={val.price ?? ""}
                                    id={`price_${index}`}
                                    label="Asset Price (in dollars)"
                                    fullWidth
                                    onChange={(e) => handleAssets(e, index, "price")}
                                />
                            </Grid> : <></>}

                        </Grid>
                    </Paper>
                ))}
            </Grid>

            <Stack width='100%' justifyContent='flex-end' direction='row'>
                <Button onClick={addToState} variant='contained'>Add</Button>
            </Stack>
        </Grid>
    );
};

