import './App.css';
import { Router } from "./Router";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal, MsalProvider} from '@azure/msal-react';
import { loginRequest } from './components/auth/auth-config';
import { Login } from './pages/Login';
import { Box, Button } from '@mui/material';
import { ForwardIcon } from './components/Icons/ForwardIcon';
import './App.css';
import axios from './api/axios';
import AuthContext from './components/auth/AuthProvider';
import { useEffect, useState } from 'react';
import { Loader } from './components/Loader';
import { BackendAuth } from './components/auth/BackendAuth';

const LOGIN_URL = '/dashboard/employeedata'

export const WrappedView = () => {
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();
  // console.log(activeAccount);
  const handleRedirect = () => {
    instance.loginRedirect({
      ...loginRequest,
      promt: 'create'
    })
      .catch((error) => console.log(error));
  }

  return (
    <Box className='App'>
      <Button onClick={handleRedirect} variant='contained' sx={{ paddingX: '20px', paddingY: '8px', borderRadius: '5px' }}>Login<ForwardIcon width={20} /></Button>
    </Box>
  );
}

const MainContent = () => {
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();
  console.log(activeAccount);

  return (
    <div className="App">
      <AuthenticatedTemplate>
        {activeAccount ? (
          <BackendAuth oid={activeAccount.idTokenClaims.oid} />
        ) : console.log("loading")}
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Login />
      </UnauthenticatedTemplate>
    </div>
  );
}

const App = ({ instance }) => {
  // console.log(ins)
  return (
    <>
      <MsalProvider instance={instance}>
      <MainContent />
      </MsalProvider>
    </>
  );
}

export default App;
