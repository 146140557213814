import { Box, Grid, IconButton, InputAdornment, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import AddIcon from '@mui/icons-material/Add';
import { Close } from "@mui/icons-material";

export const MileStoneForm = ({ goal_id, goalie, setGoalie }) => {

    useEffect (()=> {
        setGoalie((prev)=> ({
            ...prev, "goal_id": goal_id
        }))
    }, [])

    const handleInChange = (e, ind) => {
        const updatedTaskInfo = goalie.key_milestone;
        updatedTaskInfo[ind] = e.target.value;
        setGoalie((prevState) => ({
            ...prevState,
            key_milestone: updatedTaskInfo,
        }));
    }

    const addEventCard = () => {
        const updatedPoc = [...goalie.key_milestone];
        updatedPoc.push("");
        setGoalie((prevState) => ({
            ...prevState,
            key_milestone: updatedPoc,
        }));
    };

    const handleClear = (index) => {
        const updatedPoc = [...goalie.key_milestone];
        updatedPoc.splice(index, 1);
        setGoalie((prevState) => ({
            ...prevState,
            key_milestone: updatedPoc,
        }));
    }

    return (
        <Box>
            {
                <Grid item xs={12}>
                    <Typography fontWeight="600" mt={3} textAlign="left">
                        Add New Milestone &nbsp;&nbsp;
                        <IconButton
                            size="small"
                            sx={{
                                backgroundColor: "#C7C8CC",
                                "&:hover": {
                                    backgroundColor: "#B4B4B8",
                                },
                            }}
                            onClick={addEventCard}
                        >
                            <AddIcon fontSize="7px" />
                        </IconButton>
                    </Typography>
                </Grid>
            }

            <Grid mt={0} container spacing={2}>
                {goalie.key_milestone.map((val, index) => (
                    <Grid item xs={12}>
                        <TextField
                            value={val ?? ""}
                            id={`ms_${index}`}
                            label={`Milestone ${index + 1}`}
                            fullWidth
                            onChange={(e) => handleInChange(e, index, "event_title")}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => handleClear(index)}
                                            edge="end"
                                        >
                                            <Close color="error" />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Grid>
                ))}
            </Grid>
        </Box>
    )
}
