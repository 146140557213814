import { Box, FormControl, Grid, IconButton, InputLabel, MenuItem, Paper, Select, TextField, Typography } from '@mui/material'
import AddIcon from '@mui/icons-material/Add';
// ... Other imports

export const SimpleForms = ({ data, setData, type = "" }) => {

    const handleAddInputData = (e) => {
        const { value, id } = e.target;
        setData((prevState) => ({
            ...prevState,
            [id]: value,
        }));
    };

    return (
        type == "company" ?
            <Grid container spacing={2} mt={1}>
                <Grid item sm={12}>
                    <TextField
                        required
                        value={data.company_name}
                        id="company_name"
                        label="Company Name"
                        fullWidth
                        onChange={handleAddInputData}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        value={data.contact_name}
                        id="contact_name"
                        label="Contact Name"
                        fullWidth
                        onChange={handleAddInputData}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        type='email'
                        required
                        value={data.contact_email}
                        id="contact_email"
                        label="Contact Email"
                        fullWidth
                        onChange={handleAddInputData}
                    />
                </Grid>
                <Grid item sm={12}>
                    <TextField
                        value={data.contact_address}
                        id="contact_address"
                        label="Contact Address"
                        fullWidth
                        onChange={handleAddInputData}
                    />
                </Grid>

            </Grid> :
            (
                type == "product" ?
                    <Grid container spacing={2} mt={1}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                value={data.product_name}
                                id="product_name"
                                label="Product Name"
                                fullWidth
                                onChange={handleAddInputData}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                value={data.alias}
                                id="alias"
                                label="Alias"
                                fullWidth
                                onChange={handleAddInputData}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                value={data.product_code}
                                id="product_code"
                                label="Product Code"
                                fullWidth
                                onChange={handleAddInputData}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                value={data.license_type}
                                id="license_type"
                                label="License Type"
                                fullWidth
                                onChange={handleAddInputData}
                            />
                        </Grid>

                    </Grid> :
                    <Grid container spacing={2} mt={1}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                value={data.service_name}
                                id="service_name"
                                label="Service Name"
                                fullWidth
                                onChange={handleAddInputData}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                value={data.alias}
                                id="alias"
                                label="Alias"
                                fullWidth
                                onChange={handleAddInputData}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                value={data.service_code}
                                id="service_code"
                                label="Service Code"
                                fullWidth
                                onChange={handleAddInputData}
                            />
                        </Grid>

                    </Grid>
            )
    );
};

