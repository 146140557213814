import { Box, FormControl, Grid, Button, InputLabel, MenuItem, Paper, Select, TextField, Typography, Autocomplete } from '@mui/material'
import AddIcon from '@mui/icons-material/Add';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { VisuallyHiddenInput } from '../EmployeeData/EmployeeDocument';
import { useState } from 'react';
import { CertificatesApi } from '../../api/CertificatesApi';

// const priority =['High','Low','Normal'];
export const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onload = () => {
            resolve(fileReader.result);
        };
        fileReader.onerror = (error) => {
            reject(error);
        };
    });
};

export const CertForm = ({ data, setData }) => {
    const [filename, setFilename] = useState("");

    const handleAddInputData = (e) => {
        const { value, id } = e.target;
        setData((prevState) => ({
            ...prevState,
            [id]: value,
        }));
    };

    const handleAddData = (e, val) => {
        if (e && e.target) {
            const naam = e.target.id.split('-')[0];
            var flag ="other";
            CertificatesApi.map((value)=> {
                if(val==value){
                flag = "";
            }})
            setData(prevState => ({
                ...prevState,
                [naam]: val,
                'Tag': flag
            }))
            console.log(data)
        }
    }

    const handleFileUpload = async (e) => {
        const file = e.target.files[0];
        setFilename(file.name);
        const base64 = await convertToBase64(file);
        setData(prevState => ({
            ...prevState,
            Document: base64
        }))
    };

    return (
        <Grid container columnSpacing={2} rowSpacing={4} pt={1.9}>
            <Grid item sm={12}>
                {/* <TextField
                    required
                    value={data.Certificate_Name}
                    id="Certificate_Name"
                    label="Certificate Name"
                    fullWidth
                    onChange={handleAddInputData}
                /> */}
                <Autocomplete
                        id="Course_Name"
                        value={data.Course_Name}
                        sx={{ width: "100%" }}
                        freeSolo
                        options={CertificatesApi.map((option) => option)}
                        renderInput={(params) => <TextField {...params} required label="Select Certificate Name (type if not available)" />}
                        onInputChange={handleAddData}
                    />
            </Grid>
            <Grid item sm={12} md={6}>
                <TextField
                    required
                    value={data.Issuing_Authority}
                    id="Issuing_Authority"
                    label="Issuing Authority"
                    fullWidth
                    onChange={handleAddInputData}
                />
            </Grid>
            <Grid item sm={12} md={6}>
                <TextField
                    type='date'
                    required
                    value={data.Issue_Date}
                    id="Issue_Date"
                    label="Issue Date"
                    fullWidth
                    onChange={handleAddInputData}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Grid>
            <Grid item sm={12} md={6}>
                <TextField
                    type='date'
                    value={data.Expiry_Date}
                    id="Expiry_Date"
                    label="Expiry Date"
                    fullWidth
                    onChange={handleAddInputData}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Grid>

            <Grid item sm={12} md={6}>
                <TextField
                    value={data.Certificate_ID}
                    id="Certificate_ID"
                    label="Certificate Id"
                    fullWidth
                    onChange={handleAddInputData}
                />
            </Grid>

            <Grid item sm={12} md={6}>
                <Box display="flex" alignItems="center">
                <Button
                    component="label"
                    sx={{ paddingY: '10px', borderRadius: "5px", boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.4)' }}
                    tabIndex={-1}
                    variant="contained"
                    startIcon={<CloudUploadIcon />}
                >
                    Upload Your Certificate
                    <VisuallyHiddenInput required onChange={(e) => handleFileUpload(e)} type="file" />
                </Button>
                <span style={{ color: "red", paddingLeft: "5px" }}>*</span>
                </Box>                
                <Typography mt={1} color="red">{filename}</Typography>
            </Grid>

        </Grid>
    );
};

