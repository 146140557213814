import CreateTwoToneIcon from '@mui/icons-material/CreateTwoTone';
import DeleteOutlineTwoToneIcon from '@mui/icons-material/DeleteOutlineTwoTone';
import { Box, Dialog, DialogContent, IconButton, Pagination, Stack, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { PdfViewer } from '../../../components/PdfViewer';
import { useState } from 'react';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { advancedDate, postData } from '../../Events';

export const ExpenseTable = ({ data, setData }) => {
  const [open, setOpen] = useState(null);

  const viewPdf = (url) => {
    setOpen(url);
  }

  const handleClose = () => {
    setOpen(null);
  }

  const DeleteItem =(val) => {
    const index =val.id[0];
    const temp =data.claims;
    setData(prevData => ({
      ...prevData,
      claims: temp.filter((value,key)=>key!=index)
    }));
  }

  const columns = [
    {
      field: 'date',
      headerName: 'Date',
      width: 200,
      headerClassName: 'whiteMenuIcon',
      renderCell: (params) =>
        <Typography pl={1} variant='subtitle2'>{advancedDate(params.row.date)}</Typography>
    },
    {
      field: 'reimbursement',
      headerName: 'Reb Type',
      width: 200,
      headerClassName: 'whiteMenuIcon',
    },
    {
      field: 'amount',
      headerName: 'Amount',
      width: 200,
      headerClassName: 'whiteMenuIcon',
      renderCell: (params) =>
        <Typography pl={1}>{params.row.amount == "" ? '-' : params.row.currency+" "+params.row.amount}</Typography>
    },
    {
      field: 'description',
      headerName: 'Description',
      width: 300,
      headerClassName: 'whiteMenuIcon',
      renderCell: (params) =>
        <Typography pl={1}>{params.row.description == "" ? '-' : params.row.description}</Typography>
    },
    {
      field: 'invoice',
      headerName: 'Invoice',
      width: 180,
      headerClassName: 'whiteMenuIcon',
      renderCell: (params) =>
        <IconButton onClick={() => viewPdf(params.row.invoice)}><RemoveRedEyeIcon color='primary' sx={{ fontSize: '20px' }} /></IconButton>
    },
    {
      field: 'action',
      headerName: 'Action',
      width: 150,
      headerClassName: 'whiteMenuIcon',
      renderCell: (params) => (
        <Stack direction='row' spacing={1}>
          {/* <IconButton><CreateTwoToneIcon color='primary' sx={{ fontSize: '20px' }} /></IconButton> */}
          <IconButton onClick={()=>DeleteItem(params.row)}><DeleteOutlineTwoToneIcon color='error' sx={{ fontSize: '20px' }} /></IconButton>
        </Stack>
      )
    }
  ];

  const rows =
    data.claims.map((val, key) => {
      // console.log(val.items[0].receipt);
      return {
        id: [key],
        date: val.date,
        currency: data.currency,
        reimbursement: val.items[0].category,
        amount: val.items[0].amount,
        description: val.items[0].description,
        invoice: val.items[0].receipt,
        action: 'Delete'
      }
    })

  //PAGINATION 
  const pageSize = 5;
  const [page, setPage] = useState(0);
  const pageCount = Math.ceil(rows.length / pageSize);
  const rowsOnPage = rows.slice(page * pageSize, (page + 1) * pageSize);

  return (
    <Box>
      <DataGrid
        rows={rowsOnPage}
        columns={columns}
        pageSize={pageSize}
        disableColumnSelector
        hideFooter
        disableColumnMenu
        disableColumnSorting
        style={{ backgroundColor: 'white' }}
        sx={{
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: 'primary.main'
          },
          '& .MuiDataGrid-cell': {
            fontSize: '15px',
          },
          '& .MuiDataGrid-columnHeaderTitle': {
            fontWeight: 'bold',
            fontSize: '16px'
          },
          '& .MuiDataGrid-columnSeparator': {
            display: 'none'
          },
          '& .MuiInputBase-input': {
            height: '10px !important'
          },
          '& .MuiDataGrid-cell:focus, & .MuiDataGrid-columnHeader:focus': {
            outline: 'none',
            boxShadow: 'none',
            border: 'none'
          },
        }}
      />
      {pageCount>1
      ?<Stack borderRadius='10px' bgcolor='white' mt={0.2} py={1.5} justifyContent='center' direction='row' width='100%'>
        <Pagination
          count={pageCount}
          page={page + 1}
          onChange={(event, value) => setPage(value - 1)} // Adjust for 0-based index
          shape="rounded"
        />
      </Stack>
      :<></>}

      <Dialog onClose={handleClose} open={open ? true : false} fullWidth={true} maxWidth='lg'>
        {/* <DialogContent> */}
        {open ? <PdfViewer base64String={open} /> : <></>}
        {/* </DialogContent> */}
      </Dialog>
    </Box>
  )
}
