import { Autocomplete, Box, Grid, Paper, Stack, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import UserIcon from '@mui/material/Avatar';
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';
import { DateRangePicker } from 'rsuite';
import axios from '../../api/axios';
import Notify from '../../features/helpers/notifyConfig';

const GraphDisplay = ({ pdata, type }) => {
    const subtype = type == "Task" ? "taskName" : "projectName"
    const data = [
        { id: 0, value: pdata.non_billable_hours, label: 'Non-Billable', color: type == "Task" ? '#0088FE' : '#E78895' },
        { id: 1, value: pdata.billable_hours, label: 'Billable', color: type == "Task" ? '#913175' : '#22A39F' },
    ]
    return (
        <Box sx={{ border: 'solid 1px', borderRadius: '5px', padding: '10px' }}>
            <Typography mb={3} variant='subtitle1' fontWeight='600'>{pdata[type][subtype]}</Typography>
            <PieChart
                series={[
                    {
                        arcLabel: (item) => `${item.value}h`,
                        arcLabelMinAngle: 45,
                        data,
                        cornerRadius: 5,
                        innerRadius: 30,
                    },
                ]}

                sx={{
                    [`& .${pieArcLabelClasses.root}`]: {
                        fill: 'white',
                        fontWeight: 'bold',
                    },
                }}
                // slotProps={{ legend: { hidden: true } }}
                width={400}
                height={200}
            />
        </Box>
    )
}

export const EmployeePerform = ({ table_data }) => {
    const [data, setData] = useState(table_data.individual_approved_timesheets);
    const [selEmployee, setEmployee] = useState(table_data.individual_approved_timesheets[0].employeeName ? table_data.individual_approved_timesheets[0].employeeName : "");
    const [filteredData, setFilteredData] = useState(null);
    const [dateRange, setDateRange] = useState(null);

    const allEmployees = [];
    data.map((val) => {
        allEmployees.push(val.employeeName);
    })

    const chngEmployee = (e, val) => {
        if (val == null) val = "";
        setEmployee(val);
    }

    const handleDateChange = (value) => {
        setDateRange(value);
        if (value != null) {
            dataFromDate(value[0], value[1]);
        }
        else{
            setData(table_data.individual_approved_timesheets)
        }
    }

    const formatDate = (date) => {
        if (!date) return null;

        // Get year, month, and day
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Adding 1 to month because it's zero-based
        const day = String(date.getDate()).padStart(2, '0');

        return `${year}-${month}-${day}`;
    }

    const dataFromDate = (startDate, endDate) => {
        const formattedStartDate = formatDate(startDate);
        const formattedEndDate = formatDate(endDate);
        const getDateData = async () => {
            await axios.post("/performance/reports",
                JSON.stringify({
                    "filter": {
                        "startDate": formattedStartDate,
                        "endDate": formattedEndDate
                    }
                }),
                {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Content-Type': 'application/json',
                        withCredentials: true,
                        mode: 'no-cors',
                        'Authorization': `Bearer ` + localStorage.getItem('access')
                    }
                }).then((response) => {
                    console.log(response.data.performance_report.individual_approved_timesheets);
                    // Notify.success("Hi");
                    setData(response.data.performance_report.individual_approved_timesheets);
                }).catch((error) => {
                    Notify.error(error?.response?.data?.error)
                })
        }
        getDateData();
    }

    useEffect(() => {
        const filterDataByName = (employeeName) => {
            const filtered = data.filter(
                (item) => item.employeeName === employeeName
            );
            console.log(filtered[0]);
            setFilteredData(filtered[0]);
        };
        filterDataByName(selEmployee);
    }, [selEmployee, data])

    return (
        <>
            <Stack direction={'row'}>
                <Autocomplete
                    id="employeeName"
                    value={selEmployee}
                    sx={{
                        width: "250px", display: 'inline-block', marginRight: '1rem',
                        "& .MuiInputBase-input": {
                            height: '5px !important',
                        }
                    }}
                    // freeSolo
                    options={allEmployees.map((option) => option)}
                    renderInput={(params) => <TextField {...params} required label="Select Employee" />}
                    onChange={chngEmployee}
                />

                <DateRangePicker style={{borderColor: 'yellow'}} onChange={handleDateChange} placeholder="Start Date  ~  End Date" value={dateRange} />
            </Stack>

            {filteredData ? <><Paper sx={{ mt: '2rem', p: '15px' }}>
                <Grid container alignItems='center'>
                    <Grid item md={3}>
                        <Stack direction='row' alignItems='center' spacing={2}>
                            {filteredData.pic_icon == ""
                                ? <UserIcon sx={{ width: '4rem', height: '4rem', border: '4px solid #87A922', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)', borderRadius: '50%' }} />
                                : <Box
                                    sx={{
                                        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)',
                                        width: '4rem',
                                        height: '4rem',
                                        border: '4px solid #87A922',
                                        backgroundImage: `url(${filteredData.pic_icon})`,
                                        backgroundSize: 'cover',
                                        backgroundPosition: 'center',
                                        borderRadius: '50%'
                                    }}></Box>}
                            <Typography variant='h6'>{filteredData.employeeName}</Typography>
                        </Stack>
                    </Grid>

                    <Grid textAlign='center' item md={3}>
                        <Typography>Total Billable:</Typography>
                        <Typography variant='h5'>{filteredData.total_billable_hours} hours</Typography>
                    </Grid>

                    <Grid textAlign='center' item md={3}>
                        <Typography>Total Non-Billable:</Typography>
                        <Typography variant='h5'>{filteredData.total_non_billable_hours} hours</Typography>
                    </Grid>

                    <Grid textAlign='center' item md={3}>
                        <Typography>Performance:</Typography>
                        <Typography variant='h5'>{filteredData.performance == "" ? "-" : filteredData.performance + "%"}</Typography>
                    </Grid>

                    {/* <Grid item md={4}>
                        <Typography fontWeight='600'>Total Non-Billable Hours: <span style={{ fontWeight: '400', marginLeft: '5px' }}>{filteredData.total_non_billable_hours}</span></Typography>
                    </Grid> */}
                </Grid>
            </Paper>

                <Paper sx={{ mt: '1rem', p: '15px' }}>
                    <Grid my={0.5} spacing={2} container justifyContent='center'>
                        <Grid item mb={1} md={12} textAlign='center'><Typography variant='h5' fontWeight='600' color='primary.main'>Top 3 Projects:</Typography></Grid>
                        {
                            filteredData.projects.length > 0
                                ? filteredData.projects.map((val) =>
                                    <Grid item xl={4}><GraphDisplay pdata={val} type="Project" /></Grid>
                                )
                                : <Grid item xl={4}><Typography>No Projects yet!</Typography></Grid>
                        }
                    </Grid>
                </Paper>

                <Paper sx={{ mt: '1rem', p: '15px' }}>
                    <Grid my={0.5} spacing={3} container justifyContent='center'>
                        <Grid item md={12} textAlign='center'><Typography variant='h5' fontWeight='600' color='primary.main'>Top 3 Tasks:</Typography></Grid>
                        {
                            filteredData.tasks.length > 0
                                ? filteredData.tasks.map((val) =>
                                    <Grid item xl={4}><GraphDisplay pdata={val} type="Task" /></Grid>
                                )
                                : <Grid item xl={4}><Typography>No Tasks yet!</Typography></Grid>
                        }
                    </Grid>
                </Paper></> : <></>}
        </>
    )
}
