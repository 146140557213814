import { useEffect, useState } from 'react'
import axios from '../../api/axios';
import { Box, Button, Grid, Stack, Typography, DialogActions, Dialog, DialogTitle, DialogContent, DialogContentText, Snackbar, Skeleton, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { EmployeeTable } from '../../components/ui/Table/EmployeeTable';
import { ReportTable } from './ReportTable';
import { EmployeePerform } from './EmployeePerform';

export const EscalationReport = () => {
    const [currentEmployee, setcurrentEmployee] = useState();

    //1. Get info of all escalated employees
    const getallEmployees = async () => {
        await axios.get(`/performance/reports`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ` + localStorage.getItem('access')
                }
            }
        ).then((response) => {
            console.log(response);
            setcurrentEmployee(response?.data)
        }).catch((error) => {
            console.log(error.response)
        });
    }

    useEffect(() => {
        getallEmployees();
    }, [])

    return (
        <Box mx={4} my={3}>
            {currentEmployee ?
            <>
                <Box>
                    <Typography mb={2} variant='h5' color='primary.main'><strong>Total Escalations:</strong> {currentEmployee.escalation_report.individual_pending_timesheets.length}</Typography>
                    <ReportTable table_data={currentEmployee.escalation_report.individual_pending_timesheets} />
                </Box>

                <Box mt={10}>
                    <Typography mb={3} variant='h5' color='primary.main'><strong>Employee Performance</strong></Typography>
                    <EmployeePerform table_data={currentEmployee.performance_report} />
                </Box>
            </>
                :
                <Stack gap={2} width="100%">
                    <Skeleton animation="wave" variant="rectangular" width="100%" height={80} />
                    <Skeleton animation="wave" variant="rectangular" width="100%" height={80} />
                </Stack>
            }
        </Box>
    )
}
