import React, { useEffect, useState } from 'react'
import Notify from '../../features/helpers/notifyConfig';
import { Loader } from '../Loader';
import { Router } from '../../Router';
import axios from '../../api/axios';
import { Home } from '../../home';

export const BackendAuth = ({ oid }) => {

    const [token, getToken] = useState(false);

    const getBackendToken = async () => {
        try {
            const response = await axios.post("/login",
                JSON.stringify({ id: oid }),
                {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Content-Type': 'application/json',
                        withCredentials: true,
                        mode: 'no-cors',
                    }
                }
            );
            console.log(response,"Response of API");
            console.log("Backend Token Fetched Successfully!");
            localStorage.setItem('access', response?.data?.token);
            localStorage.setItem('profileImage', response?.data?.profile_picture);
            getToken(true);
        } catch (error) {
            console.log(error,"Error of API")
            console.log("No backend Token fetched!")
        }
    };

    useEffect(() => {
        if(!token)
        getBackendToken();
    }, [token])

    return (
        token ? <Router /> : <Loader />
        // localStorage.getItem('access') ? <Router /> : <Loader />
        // <Home/>
    )
}
