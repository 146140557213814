import { Box, Grid, Stack, Typography, TextField, MenuItem, Button, InputAdornment, Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material"
import { useState } from "react"
import { ExpenseTable } from "./ExpenseTable"
import { ExpenseForm } from "./ExpenseForm"
import axios from "../../../api/axios"
import Notify from "../../../features/helpers/notifyConfig"
import { advancedDate } from "../../Events"
import CloseIcon from '@mui/icons-material/Close';
import { Cancel } from "@mui/icons-material"
import { LoadingButton } from "@mui/lab"
import { currentUser } from "../../../Router"

const ButtonStyle = {
  '&.MuiButtonBase-root': {
    height: '35px !important',
    padding: '0px 35px',
    background: '#198f51'
  }
}

export const ExpenseClaim = ({ infodata, setReload }) => {
  const LOCATION_WISE_CURRENCY =currentUser().usageLocation==="IN"?"₹":"$";
  const [data, setData] = useState({
    "projectID": "",
    "advances": 0,
    "duration": {
      "from": "", "to": ""
    },
    "purpose": "",
    "currency": LOCATION_WISE_CURRENCY,
    "claims": []
  });
  const [totalExp, viewTotalExp] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    viewTotalExp(false);
  }

  const viewTotalExpense = () => {
    if (data.projectID == "") Notify.warning("Project must be selected");
    else if (data.duration.from == "" || data.duration.to == "") Notify.warning("Expense duration must be provided");
    else if (data.purpose == "") Notify.warning("Expense Purpose must be provided");
    else viewTotalExp(true);
  }

  const submitExpense = async () => {
    setLoading(true);
    await axios.post("/expense-report/new",
      JSON.stringify(data),
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          withCredentials: true,
          mode: 'no-cors',
          'Authorization': `Bearer ` + localStorage.getItem('access')
        }
      }).then((response) => {
        setData({
          "projectID": "",
          "advances": 0,
          "duration": {
            "from": "", "to": ""
          },
          "purpose": "",
          "currency": LOCATION_WISE_CURRENCY,
          "claims": []
        })
        setLoading(false);
        viewTotalExp(false);
        Notify.success("Expense Report has been submitted");
      }).catch((error) => {
        setLoading(false);
        Notify.error("Some Error occurred. Try again!");
      })
  }

  return (
    <Box>
      <Grid container direction='column' spacing={3}>

        <Grid item>
          <ExpenseForm infodata={infodata} setData={setData} data={data} />
        </Grid>

        {
          (data.claims.length > 0)
            ? <Grid item>
              <Stack direction='row' justifyContent='space-between' alignItems='center'>
                <h3>Details of Expense</h3>
                <Button onClick={viewTotalExpense} variant='contained' sx={ButtonStyle}>Submit</Button>
              </Stack>

              <ExpenseTable data={data} setData={setData} />
            </Grid>
            : <></>
        }

      </Grid>

      <Dialog open={totalExp} onClose={handleClose} fullWidth={true} maxWidth='sm'>
        <DialogTitle><Typography fontFamily='Segoe UI' variant="h6" color='primary'>Confirm your Expense Details</Typography></DialogTitle>
        <DialogContent>
          <Stack direction='column' spacing={1}>
            <Grid container spacing={1}>
              <Grid item xs={3}>
                <Typography>Project:</Typography>
              </Grid>
              <Grid item sx={9}>
                <Typography fontWeight='600' color='secondary'>{infodata.Projects.map((vaal, key) => {
                  return vaal.projectID === data.projectID ? vaal.projectName : ''
                })}</Typography>
              </Grid>
            </Grid>

            <Grid container spacing={1}>
              <Grid item xs={3}>
                <Typography>Duration:</Typography>
              </Grid>
              <Grid item sx={9}>
                <Typography><span>{advancedDate(data.duration.from)}</span> &nbsp;-&nbsp; <span>{advancedDate(data.duration.to)}</span></Typography>
              </Grid>
            </Grid>

            <Grid container spacing={1}>
              <Grid item xs={3}>
                <Typography>Advances:</Typography>
              </Grid>
              <Grid item sx={9}>
                <Typography>{data.currency+" "+data.advances}</Typography>
              </Grid>
            </Grid>

            <Grid container spacing={1}>
              <Grid item xs={3}>
                <Typography>Purpose:</Typography>
              </Grid>
              <Grid item sx={9}>
                <Typography>{data.purpose}</Typography>
              </Grid>
            </Grid>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button sx={{ position: "absolute", right: "5px", top: "8px" }} onClick={handleClose} color="error"><Cancel /></Button>
          <LoadingButton sx={ButtonStyle} loading={loading} variant='contained' onClick={submitExpense}>Confirm</LoadingButton>
        </DialogActions>
      </Dialog>
    </Box>
  )
}
