import React, { useState } from 'react'
import {Grid, Box } from "@mui/material";
import SideBar from '../components/SideBar'
import HeadNavigation from '../components/HeadNavigation'
import theme from "../theme";
import { Outlet } from 'react-router-dom';
import { UtilsConfigurator } from '../features/helpers/notifyConfig';
import { Scrollbars } from 'react-custom-scrollbars';

export const Layout = () => {
    const [open, setOpen] = useState(true);

    return (
        <Grid container sx={{ width: "100vw", height: "100vh" }}>
            <Grid item xs="auto">
                <SideBar open={open} setOpen={setOpen} />
            </Grid>
            
            <Grid xs item sx={{ width: "100%", overflowX: "hidden", position: "relative", transition: "all 3s linear"}}>
            <Scrollbars autoHide autoHideTimeout={1000} autoHideDuration={200} autoHeightMin={0} autoHeightMax={200} thumbMinSize={30}>
                <Box>
                    <HeadNavigation open={open} setOpen={setOpen} />
                </Box>
                <Box> 
                    <Outlet />
                </Box>
                </Scrollbars>
            </Grid>

            <UtilsConfigurator/>
        </Grid>
    )
}
