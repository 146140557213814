import { useEffect, useState } from 'react';
import { Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, Menu, MenuItem, Modal, Pagination, Skeleton, Stack, Tab, Typography } from '@mui/material';
import UserIcon from '@mui/material/Avatar';
import { DataGrid } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';
import { Cancel } from '@mui/icons-material';
// import './ReportTable.css'

export const SkeletonLoad = () => {
    return (
        <Stack gap={2} width="100%">
            <Skeleton animation="wave" variant="rectangular" width="100%" height={80} />
            <Skeleton animation="wave" variant="rectangular" width="100%" height={80} />
        </Stack>
    )
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const userDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: 'numeric', month: 'short', year: 'numeric' };
    return (date.toLocaleDateString('en-US', options));
}

export function ReportTable({ table_data,type="" }) {
    const [viewData, setviewData] = useState();
    const [openViewBox, setViewOpen] = useState(false);

    const rows = table_data.map((key, val) => ({
        id: val,
        ...key
    }));

    const pageSize = 7;
    const [page, setPage] = useState(0);
    const pageCount = Math.ceil(rows.length / pageSize);
    const rowsOnPage = rows.slice(page * pageSize, (page + 1) * pageSize);

    const viewEmployee = (val) => {
        setviewData(val);
        setViewOpen(true);
    }

    const handleClose = () => {
        setViewOpen(false);
    };

    const columns = [
        {
            field: 'pic_icon', headerName: 'Profile', flex: 1, headerClassName: 'centerStyle', align: 'center', renderCell: (params) =>
                params.row.pic_icon == ""
                    ? <Box><UserIcon sx={{ width: '2.5rem', height: '2.5rem', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)', borderRadius: '50%' }} /></Box>
                    : <Box
                        sx={{
                            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)',
                            width: '2.5rem',
                            height: '2.5rem',
                            backgroundImage: `url(${params.row.pic_icon})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            borderRadius: '50%'
                        }}></Box>
        },
        { field: 'employeeName', headerName: 'Username', flex: 1, headerClassName: 'whiteMenuIcon' },
        { field: 'pending_timesheets', headerName: type=="history"?'Escalated TimeSheets':'Pending TimeSheets', flex: 1, align: 'center', headerClassName: 'centerStyle' },
        type==""?{  
            field: 'pending_weeks', headerName: 'First Pending Week', flex: 1, headerClassName: 'whiteMenuIcon', renderCell: (params) =>
                <Box>
                    {
                        <Typography>{userDate(params.row.pending_weeks[0].from)}&nbsp;&nbsp; - &nbsp;&nbsp;{userDate(params.row.pending_weeks[0].to)}</Typography>
                    }
                </Box>
        }:<></>,
        {
            field: 'sd', headerName: type=="history"?'History':'Action', align: 'center', flex: 1, headerClassName: 'centerStyle', renderCell: (params) =>
                <Link style={{ color: "#1B2C58", textDecoration: "none", fontWeight: "600" }} onClick={() => viewEmployee(params.row)}>{type==""?<i>View More</i>:"View"}</Link>
        }

    ];

    return (
        <>
            <DataGrid
                rows={rowsOnPage}
                columns={columns}
                // pageSize={pageSize}
                disableColumnSelector
                hideFooter
                // showCellVerticalBorder
                disableSelectionOnClick
                rowHeight={65}
                style={{ backgroundColor: 'white' }}
                sx={{
                    '& .MuiDataGrid-columnHeaders': {
                        backgroundColor: type==""?'#657EBF':'#5384b8e6'
                    },
                    '& .MuiDataGrid-cell': {
                        fontSize: '15px',
                    },
                    '& .MuiDataGrid-columnHeaderTitle': {
                        fontWeight: 'bold',
                        fontSize: '16px'
                    },
                    '& .MuiDataGrid-columnSeparator': {
                        display: 'none'
                    },
                    '& .MuiInputBase-input': {
                        height: '20px !important'
                    },
                    '& .MuiDataGrid-cell:focus, & .MuiDataGrid-columnHeader:focus': {
                        outline: 'none',
                        boxShadow: 'none',
                        border: 'none'
                    },
                }}
            />
            <Stack borderRadius='10px' bgcolor='white' mt={0.2} py={1.5} justifyContent='center' direction='row' width='100%'>
                <Pagination
                    count={pageCount}
                    page={page + 1}
                    onChange={(event, value) => setPage(value - 1)} // Adjust for 0-based index
                    shape="rounded"
                />
            </Stack>

            {/* Show Employee Data in a Dialog Box */}
            {
                viewData ? <Dialog
                    maxWidth="sm"
                    fullWidth={type==""?true:false}
                    open={openViewBox}
                    onClose={handleClose}
                    aria-labelledby="edit-apartment"
                >
                    <DialogTitle>{viewData.givenName}</DialogTitle>
                    <DialogContent style={{ minHeight:'60vh', minWidth:'300px' }}>
                        <Stack direction='row' alignItems='center' spacing={2}>
                        {viewData.pic_icon == ""
                            ? <Box><UserIcon sx={{ width: '4rem', height: '4rem', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)', borderRadius: '50%' }} /></Box>
                            : <Box
                                sx={{
                                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)',
                                    width: '4rem',
                                    height: '4rem',
                                    backgroundImage: `url(${viewData.pic_icon})`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    borderRadius: '50%'
                                }}></Box>
                        }
                        <Typography variant='h5' color='primary.main' fontWeight='600'>{viewData.employeeName}</Typography>
                        </Stack>

                        <Grid container md={12} mt={4}>
                            <Grid item md={type==""?8:12}><Typography fontWeight='600' variant='h6'>Escalation Week/s</Typography></Grid>
                            {type==""?<Grid item md={4}><Typography fontWeight='600' variant='h6'>Current Status</Typography></Grid>:<></>}
                        </Grid>

                        {viewData.pending_weeks.map((val) => {
                            return (
                                <>
                                <Grid container md={12} my={2}>
                                    <Grid item md={type==""?8:12}><Typography>{userDate(val.from)}&nbsp;&nbsp;-&nbsp;&nbsp;{userDate(val.to)}</Typography></Grid>
                                    {type==""?<Grid item md={4}><Chip label={val.submitted==false?'Not Submitted':'Manager Reviewing'} variant='outlined' color={val.submitted==false?"error":"success"} /></Grid>:<></>}
                                </Grid>
                                </>
                            )
                        })}

                        <Button sx={{ position: "absolute", top: 10, right: 0 }} onClick={handleClose} color="secondary">
                            <Cancel sx={{ fontSize: "1.8rem" }} />
                        </Button>
                    </DialogContent>
                </Dialog>

                    : <></>
            }
        </>
    );
}