import { useEffect, useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, Menu, MenuItem, Modal, Pagination, Skeleton, Stack, Tab, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import axios, { CryptoJSsecretKey } from '../../../api/axios';
import Notify from '../../../features/helpers/notifyConfig';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import UserIcon from '@mui/material/Avatar';
import { Cancel } from '@mui/icons-material';
import { DataGrid } from '@mui/x-data-grid';
import { EmployeeForm } from '../EmployeeForm';
import { tableStyle } from './EmployeeTable.css'
import DevicesIcon from '@mui/icons-material/Devices';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import CryptoJS from 'crypto-js';

const decryptData = (encryptedData) => {
        const bytes = CryptoJS.AES.decrypt(encryptedData, CryptoJSsecretKey);
        return bytes.toString(CryptoJS.enc.Utf8);
}

export const SkeletonLoad = () => {
    return (
        <Stack gap={2} width="100%">
            <Skeleton animation="wave" variant="rectangular" width="100%" height={80} />
            <Skeleton animation="wave" variant="rectangular" width="100%" height={80} />
        </Stack>
    )
}

const filesName = {
    "aadhar_back": "Aadhar (Back)",
    "aadhar_front": "Aadhar (Front)",
    "pan_back": "Pan (Back)",
    "pan_front": "Pan (Front)",
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export function EmployeeTable({ table_data, setotherAction }) {
    const [open, setOpen] = useState(false);
    const [anchorElMap, setAnchorElMap] = useState({});
    const [addForm, setAddForm] = useState(false);
    const [addData, setaddData] = useState();
    const [confirm, isConfirm] = useState("");
    const [submitLoad, setSubmitLoad] = useState(false);
    const [viewData, setviewData] = useState();
    const [openViewBox, setViewOpen] = useState(false);
    const [userFiles, getUserFiles] = useState(null);
    const [data, setData] = useState({
        id: "", Address: "", ContactNo: "", Country: "", Date_of_Joining: "", Designation: "", FirstName: "",
        LastName: "", givenName: "", mail: "", tempPassword: "", usageLocation: "", status: "", team: "", team_id: [], ReportingTo: "",
        role_string_id: []
    });

    const rows = Object.keys(table_data).map((key, val) => ({
        // id: val,
        ...table_data[key],
    }));

    const openEdit = ({ id, FirstName, LastName, givenName, mail, tempPassword, usageLocation, Country, status, team, team_id, ContactNo, ReportingTo,
        Designation, Address, Date_of_Joining, Role, accessSharepoint }) => {
        handleClose();
        setData(prevState => ({
            ...prevState,
            id: id, Address: Address, FirstName: FirstName, LastName: LastName, Date_of_Joining: Date_of_Joining, ContactNo: ContactNo,
            givenName: givenName, mail: mail, tempPassword: tempPassword, usageLocation: usageLocation, Country: Country, status: status, team: team, team_id: team_id, ReportingTo: ReportingTo,
            role_string_id: Role.role_string_id, accessSharepoint: accessSharepoint, Designation: Designation
        }));
        setOpen(true);
    };

    const openDelete = (delData) => {
        if (delData.status != "Non-Active") return Notify.warning("Employee status must be Non-Active before deletion!");
        handleClose();
        isConfirm(delData);
        // deleteRecord(id);
    };

    const handleClick = (event, id) => {
        setAnchorElMap(prevMap => ({
            ...prevMap,
            [id]: event.currentTarget,
        }));
    };

    const pageSize = 10;
    const [page, setPage] = useState(0);
    const pageCount = Math.ceil(rows.length / pageSize);
    const rowsOnPage = rows.slice(page * pageSize, (page + 1) * pageSize);

    const columns = [
        {
            field: 'profile_picture_id', headerName: 'Profile', flex: 1, headerClassName: 'centerStyle', align: 'center', renderCell: (params) =>
                params.row.profile_picture_id == ""
                    ? <Box><UserIcon sx={{ width: '2.5rem', height: '2.5rem', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)', borderRadius: '50%' }} /></Box>
                    : <Box
                        sx={{
                            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)',
                            width: '2.5rem',
                            height: '2.5rem',
                            backgroundImage: `url(${params.row.profile_picture_id})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            borderRadius: '50%'
                        }}></Box>
        },
        {
            field: 'FirstName', headerName: 'First Name', flex: 1, headerClassName: 'whiteMenuIcon', renderCell: (params) =>
                params.row.FirstName == "" ? "-" : <Link style={{ color: "#1B2C58", textDecoration: "none", fontWeight: "600" }} onClick={() => viewEmployee(params.row)}>{params.row.FirstName}</Link>
        },
        { field: 'LastName', headerName: 'Last Name', flex: 1, headerClassName: 'whiteMenuIcon' },
        { field: 'mail', headerName: 'Mail', flex: 1, headerClassName: 'whiteMenuIcon' },
        { field: 'ContactNo', headerName: 'Contact Number', flex: 1, headerClassName: 'whiteMenuIcon' },
        {
            field: 'Action', headerName: 'Action', flex: 1, headerClassName: 'whiteMenuIcon', renderCell: (params) => {
                return (<Box>
                    <IconButton
                        id={`basic-button-${params.id}`}
                        aria-controls={anchorElMap[params.id] ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={anchorElMap[params.id] ? 'true' : undefined}
                        onClick={(event) => handleClick(event, params.id)}
                    >
                        <MoreVertIcon />
                    </IconButton>
                    <Menu
                        id={`basic-menu-${params.id}`}
                        anchorEl={anchorElMap[params.id]}
                        open={Boolean(anchorElMap[params.id])}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                        PaperProps={{
                            style: {
                                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                            },
                        }}
                    >
                        <MenuItem onClick={() => openEdit(params.row)}>Edit</MenuItem>
                        <MenuItem disabled onClick={() => openDelete(params.row)}>Delete</MenuItem>
                    </Menu>
                </Box>)
            }
        },
    ];

    const handleClose = () => {
        setOpen(false);
        isConfirm("");
        getUserFiles(null);
        setAnchorElMap({});
        setViewOpen(false);
    };

    const deleteRecord = (id) => {
        setotherAction(true);
        isConfirm("");
        const deleteEmployee = async () => {
            await axios.delete(`/admin/users/delete`,
                {
                    headers: {
                        'Authorization': `Bearer ` + localStorage.getItem('access')
                    },
                    data: {
                        "id": id
                    }
                }).then((response) => {
                    Notify.success('Employee Record Deleted Successfully!');
                    setotherAction(false);
                    handleClose();
                }).catch((error) => {
                    const err = error.response?.data?.ERROR;
                    (err == "Active Users can't be deleted. Make the user Non-Active before deleting." || err == "Can't delete this employee. Other employees report to this member.") ? Notify.error(err) : Notify.error("Record Can't be Deleted. Try Again!");
                })
        }
        deleteEmployee();
    }

    const viewEmployee = (val) => {
        setviewData(val);
        setViewOpen(true);
        axios.get(`/admin/users/documents/${val.id}`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ` + localStorage.getItem('access')
                }
            }).then((response) => {
                getUserFiles(response?.data?.message?.file_id);
            }).catch((error) => {
                console.log(error.response)
            })
    }

    const submitAddForm = (type) => {
        let hasWarning = false;
        if (data.FirstName === "") {
            Notify.warning("First Name field cannot be empty!");
            hasWarning = true;
        }
        else if (data.LastName === "") {
            Notify.warning("Last Name field cannot be empty!");
            hasWarning = true;
        }
        else if (data.givenName === "") {
            Notify.warning("UserName field cannot be empty!");
            hasWarning = true;
        }
        else if (data.tempPassword === "") {
            Notify.warning("Please provide a temporary password for first time login of user!");
            hasWarning = true;
        }
        else if (data.Country === "") {
            Notify.warning("Country field cannot be empty!");
            hasWarning = true;
        }
        else if (data.status === "") {
            Notify.warning("Status field cannot be empty!");
            hasWarning = true;
        }
        else if (data.ReportingTo === "") {
            Notify.warning("Reporting To field cannot be empty!");
            hasWarning = true;
        }
        else if (data.role_string_id.length == 0) {
            Notify.warning("Atleat 1 role should be selected!");
            hasWarning = true;
        }
        else if (data.ContactNo != "" && data.ContactNo.length != 10) {
            Notify.warning("Contact number should be of 10 numbers only!");
            hasWarning = true;
        }
        if (hasWarning) return;

        setAddForm(true);
        setotherAction(true);
    }

    //1. Get Add Current Employee info
    const getAddEmployeeData = async () => {
        await axios.get(`/admin/users/edit`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ` + localStorage.getItem('access')
                }
            }).then((response) => {
                setaddData(response.data)
            }).catch((error) => {
                console.log(error.response)
            })
    }

    //2. Edit Employee info
    const editEmployeeDetails = async () => {
        await axios.put(`/admin/users/edit`,
            JSON.stringify(data),
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json',
                    withCredentials: true,
                    mode: 'no-cors',
                    'Authorization': `Bearer ` + localStorage.getItem('access')
                }
            }).then((response) => {
                console.log(response);
                setAddForm(false);
                setotherAction(false);
                Notify.success("Employee Data Edited Successfully!")
                setSubmitLoad(false);
                handleClose();
            }).catch((error) => {
                const err = error.response?.data?.ERROR;
                err == "No available licenses" ? Notify.error(err) : Notify.error("Record Can't Be Edited. Try Again!");
                setAddForm(false);
                setSubmitLoad(false);
            })
    }

    useEffect(() => {
        if (addForm) {
            setSubmitLoad(true);
            editEmployeeDetails()
        }
    }, [addForm])


    useEffect(() => {
        if (open) getAddEmployeeData()
    }, [open])

    return (
        <>
            <DataGrid
                rows={rowsOnPage}
                columns={columns}
                // pageSize={pageSize}
                disableColumnSelector
                hideFooter
                // showCellVerticalBorder
                disableSelectionOnClick
                rowHeight={65}
                style={{ backgroundColor: 'white' }}
                sx={{
                    '& .MuiDataGrid-columnHeaders': {
                        backgroundColor: '#657EBF'
                    },
                    '& .MuiDataGrid-cell': {
                        fontSize: '15px',
                    },
                    '& .MuiDataGrid-columnHeaderTitle': {
                        fontWeight: 'bold',
                        fontSize: '16px'
                    },
                    '& .MuiDataGrid-columnSeparator': {
                        display: 'none'
                    },
                    '& .MuiInputBase-input': {
                        height: '20px !important'
                    },
                    '& .MuiDataGrid-cell:focus, & .MuiDataGrid-columnHeader:focus': {
                        outline: 'none',
                        boxShadow: 'none',
                        border: 'none'
                    },
                }}
            />
            <Stack borderRadius='10px' bgcolor='white' mt={0.2} py={1.5} justifyContent='center' direction='row' width='100%'>
                <Pagination
                    count={pageCount}
                    page={page + 1}
                    onChange={(event, value) => setPage(value - 1)} // Adjust for 0-based index
                    shape="rounded"
                />
            </Stack>


            {/* EDIT */}
            {data ? <Dialog
                maxWidth="lg"
                fullWidth={true}
                open={open}
                onClose={handleClose}
                aria-labelledby="edit-apartment"
            >

                {addData ? <DialogTitle>{data.givenName}</DialogTitle> : ""}
                <DialogContent style={{ height: '100vh' }}>
                    {
                        addData
                            ? <EmployeeForm data={data} prop={addData} setData={setData} type='edit' />
                            : <Stack gap={2} mt={6} width="100%">
                                <Skeleton animation="wave" variant="rectangular" width="100%" height={80} />
                                <Skeleton animation="wave" variant="rectangular" width="100%" height={80} />
                            </Stack>
                    }
                </DialogContent>

                <DialogActions sx={{ backgroundColor: "#EEEDEB" }}>
                    <Button sx={{ position: "absolute", top: 10, right: 0 }} onClick={handleClose} color="secondary">
                        <Cancel sx={{ fontSize: "1.8rem" }} />
                    </Button>
                    <LoadingButton loading={submitLoad} variant='contained' onClick={() => submitAddForm("Submit")}>
                        Save
                    </LoadingButton>
                </DialogActions>
            </Dialog> : <></>}


            {/* Show Employee Data in a Dialog Box */}
            {
                viewData ? <Dialog
                    maxWidth="sm"
                    fullWidth={true}
                    open={openViewBox}
                    onClose={handleClose}
                    aria-labelledby="edit-apartment"
                >
                    <DialogTitle>{viewData.givenName}</DialogTitle>
                    <DialogContent style={{ height: '100vh' }}>

                        {viewData.profile_picture_id == ""
                            ? <Box><UserIcon sx={{ width: '6rem', height: '6rem', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)', borderRadius: '50%' }} /></Box>
                            : <Box
                                sx={{
                                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)',
                                    width: '6rem',
                                    height: '6rem',
                                    backgroundImage: `url(${viewData.profile_picture_id})`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    borderRadius: '50%'
                                }}></Box>
                        }

                        <Grid container md={12} my={2}>
                            <Grid item md={5}><Typography fontWeight='600'>First Name:</Typography></Grid>
                            <Grid pl={1} item md={7}><Typography>{viewData.FirstName ? viewData.FirstName : "-"}</Typography></Grid>
                        </Grid>

                        <Grid container md={12} my={2}>
                            <Grid item md={5}><Typography fontWeight='600'>Last Name:</Typography></Grid>
                            <Grid pl={1} item md={7}><Typography>{viewData.LastName ? viewData.LastName : "-"}</Typography></Grid>
                        </Grid>

                        <Grid container md={12} my={2}>
                            <Grid item md={5}><Typography fontWeight='600'>Address:</Typography></Grid>
                            <Grid pl={1} item md={7}><Typography>{viewData.Address ? viewData.Address : "-"}</Typography></Grid>
                        </Grid>

                        {
                            viewData.tempPassword ? <Grid container md={12} my={2}>
                                <Grid item md={5}><Typography fontWeight='600'>Temporary Password:</Typography></Grid>
                                <Grid pl={1} item md={7}><Typography>{viewData.tempPassword ? viewData.tempPassword : "-"}</Typography></Grid>
                            </Grid> : <></>
                        }

                        <Grid container md={12} my={2}>
                            <Grid item md={5}><Typography fontWeight='600'>Contact No.:</Typography></Grid>
                            <Grid pl={1} item md={7}><Typography>{viewData.ContactNo ? viewData.ContactNo : "-"}</Typography></Grid>
                        </Grid>

                        <Grid container md={12} my={2}>
                            <Grid item md={5}><Typography fontWeight='600'>Mail ID:</Typography></Grid>
                            <Grid pl={1} item md={7}><Typography sx={{wordBreak:'break-word'}}>{viewData.mail ? viewData.mail : "-"}</Typography></Grid>
                        </Grid>

                        <Grid container md={12} my={2}>
                            <Grid item md={5}><Typography fontWeight='600'>Designation:</Typography></Grid>
                            <Grid pl={1} item md={7}><Typography>{viewData.Designation ? viewData.Designation : "-"}</Typography></Grid>
                        </Grid>

                        <Grid container md={12} my={2}>
                            <Grid item md={5}><Typography fontWeight='600'>Team:</Typography></Grid>
                            <Grid pl={1} item md={7}><Typography>{viewData.team ? viewData.team : "-"}</Typography></Grid>
                        </Grid>

                        <Grid container md={12} my={2}>
                            <Grid item md={5}><Typography fontWeight='600'>Reporting To:</Typography></Grid>
                            <Grid pl={1} item md={7}><Typography>{viewData.ReportingTo ? viewData.ReportingTo : "-"}</Typography></Grid>
                        </Grid>

                        <Grid container md={12} my={2}>
                            <Grid item md={5}><Typography fontWeight='600'>Role:</Typography></Grid>
                            <Grid pl={1} item md={7}><Typography>{viewData.Role.role_name ? viewData.Role.role_name : "-"}</Typography></Grid>
                        </Grid>

                        <Grid container md={12} my={2}>
                            <Grid item md={5}><Typography fontWeight='600'>Emergency Contact:</Typography></Grid>
                            <Grid pl={1} item md={7}><Typography>{viewData.Emergency_Contact_Number ? viewData.Emergency_Contact_Number : "-"}</Typography></Grid>
                        </Grid>


                        <Grid container md={12} my={2}>
                            <Grid item md={5}><Typography fontWeight='600'>Date of Joining:</Typography></Grid>
                            <Grid pl={1} item md={7}><Typography>{viewData.Date_of_Joining ? viewData.Date_of_Joining : "-"}</Typography></Grid>
                        </Grid>

                        <Grid container md={12} my={2}>
                            <Grid item md={5}><Typography fontWeight='600'>Date of Birth:</Typography></Grid>
                            <Grid pl={1} item md={7}><Typography>{viewData.Date_of_Birth ? viewData.Date_of_Birth : "-"}</Typography></Grid>
                        </Grid>

                        <Grid container md={12} my={2}>
                            <Grid item md={5}><Typography fontWeight='600'>Country:</Typography></Grid>
                            <Grid pl={1} item md={7}><Typography>{viewData.Country ? viewData.Country : "-"}</Typography></Grid>
                        </Grid>

                        <Grid container md={12} my={2}>
                            <Grid item md={5}><Typography fontWeight='600'>Zone:</Typography></Grid>
                            <Grid pl={1} item md={7}><Typography>{viewData.Zone ? viewData.Zone : "-"}</Typography></Grid>
                        </Grid>

                        <Grid container md={12} my={2}>
                            <Grid item md={5}><Typography fontWeight='600'>Status:</Typography></Grid>
                            <Grid pl={1} item md={7}><Typography sx={viewData.status == "Active" ? { color: "green" } : { color: "red" }}>{viewData.status}</Typography></Grid>
                        </Grid>

                        <Grid container md={12} my={2}>
                            <Grid item md={5}><Typography fontWeight='600'>Usage Location:</Typography></Grid>
                            <Grid pl={1} item md={7}><Typography>{viewData.usageLocation ? viewData.usageLocation : "-"}</Typography></Grid>
                        </Grid>

                        <Grid container md={12} my={2}>
                            <Grid item md={5}><Typography fontWeight='600'>SharePoint Access:</Typography></Grid>
                            <Grid pl={1} item md={7}><Typography>{viewData.accessSharepoint ? "Yes" : "No"}</Typography></Grid>
                        </Grid>

                        {/* BANK DETAILS */}
                        <Box><Typography fontWeight='600' color="primary.main" variant='h6' mt={4} mb={1}><AccountBalanceIcon sx={{ transform: 'translateY(5px)', mr: '10px' }} />Bank Details:</Typography>
                            <Grid container md={12} mb={2}>
                                <Grid item md={5}><Typography fontWeight='600'>Bank Name:</Typography></Grid>
                                <Grid pl={1} item md={7}><Typography>{decryptData(viewData.Bank_Details.Bank_Name)}</Typography></Grid>
                            </Grid>

                            <Grid container md={12} mb={2}>
                                <Grid item md={5}><Typography fontWeight='600'>Bank Account:</Typography></Grid>
                                <Grid pl={1} item md={7}><Typography>{decryptData(viewData.Bank_Details.Bank_Ac)}</Typography></Grid>
                            </Grid>

                            <Grid container md={12} mb={2}>
                                <Grid item md={5}><Typography fontWeight='600'>IFSC Code:</Typography></Grid>
                                <Grid pl={1} item md={7}><Typography>{decryptData(viewData.Bank_Details.Ifsc_Code)}</Typography></Grid>
                            </Grid>
                        </Box>

                        {/* ASSETS */}
                        <Box><Typography fontWeight='600' color="primary.main" variant='h6' mt={4} mb={1}><DevicesIcon sx={{ transform: 'translateY(5px)', mr: '10px' }} />Assets:</Typography>
                            {viewData.Assets.length > 0
                                ? viewData.Assets.map((val) => (<Grid container md={12} mb={2}>
                                    <Grid item md={5}><Typography fontWeight='600'>{val.type + " " + val.device}:</Typography></Grid>
                                    <Grid pl={1} item md={7}><Typography>{val.mac_address + "   "}&nbsp;&nbsp;&nbsp;{val.type == "Personal" ? '' : <span style={{ color: 'green' }}>{"$" + val.price}</span>}</Typography></Grid>
                                </Grid>))
                                : <Box>No Assets Found!</Box>}
                        </Box>

                        {/* CERTIFICATES */}
                        <Box><Typography fontWeight='600' color="primary.main" variant='h6' mt={4} mb={1}><WorkspacePremiumIcon sx={{ transform: 'translateY(5px)', mr: '8px' }} />Certificates:</Typography>
                            {viewData.Certificate_Name.length > 0
                                ? viewData.Certificate_Name.map((val) => (<Grid container md={12} mb={2}>
                                    <Grid item md={12}><Typography>{val}</Typography></Grid>
                                </Grid>))
                                : <Box>No Certificate Found!</Box>}
                        </Box>

                        {/* FILES */}

                        <Box><Typography fontWeight='600' color="primary.main" variant='h6' mt={4} mb={1}><DocumentScannerIcon sx={{ transform: 'translateY(5px)', mr: '8px' }} />Files:</Typography>
                            {userFiles != null ?
                                Object.keys(userFiles).length > 0
                                    ? Object.keys(userFiles).map((key) => (<Grid container md={12} mb={2}>
                                        <Grid item md={5}><Typography fontWeight='600'>{filesName[key]}:</Typography></Grid>
                                        <Grid pl={1} item md={7}><Typography>{userFiles[key] == "" ? '-' : <a download={`${key}.png`} href={userFiles[key]}>Download</a>}</Typography></Grid>
                                    </Grid>))
                                    : <Box>No File Found!</Box>
                                :
                                <>
                                    <Skeleton sx={{ marginBottom: '10px' }} animation="wave" variant="rectangular" width="80%" height={30} />
                                    <Skeleton sx={{ marginBottom: '10px' }} animation="wave" variant="rectangular" width="80%" height={30} />
                                </>}
                        </Box>

                    </DialogContent>

                    <DialogActions>
                        <Button sx={{ position: "absolute", top: 10, right: 0 }} onClick={handleClose} color="secondary">
                            <Cancel sx={{ fontSize: "1.8rem" }} />
                        </Button>
                    </DialogActions>

                </Dialog>

                    : <></>
            }

            {confirm != "" ?
                <Dialog maxWidth='xs' onClose={handleClose} open={confirm != "" ? true : false}>
                    <DialogContent>
                        <Typography mb={1} variant='h6'>Are you sure you want to delete <span style={{ color: 'red' }}>{confirm.givenName}</span> ? </Typography>
                        <DialogContentText mb={2}>Deleting this employee will remove all the information of employee from Database and Azure !</DialogContentText>
                        <Typography></Typography>
                        <Button variant='contained' color='error' onClick={() => deleteRecord(confirm.id)}>Delete</Button>
                    </DialogContent>
                </Dialog>
                : <></>
            }
        </>
    );
}