import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { CssBaseline, ThemeProvider } from "@mui/material";
import { BrowserRouter } from "react-router-dom";
import theme from "./theme";
import App from './App';
import { AuthProvider } from './components/auth/AuthProvider';
import { Loader } from './components/Loader';

//MSAL imports
import { PublicClientApplication, EventType } from '@azure/msal-browser';
import { msalConfig } from './components/auth/auth-config';
import { SnackbarProvider } from "notistack";

//EventType used to identify various events and progress of your login process
//MSAL should be instantiated outside of component tree to prevent re-instantiating on re-renders.

const msalInstance = new PublicClientApplication(msalConfig);

if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

// Optional - This will update account state if a user signs in from another tab or window
msalInstance.enableAccountStorageEvents();

//Listen for sign-in event and set active account.
//On success login whatever account getting on payload, set it as active account.
msalInstance.addEventCallback((e) => {
  if (e.eventType === EventType.LOGIN_SUCCESS && e.payload.account) {
    const account = e.payload.account;
    msalInstance.setActiveAccount(account);
  }
})

// console.log(msalInstance);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <CssBaseline>
          <AuthProvider>
            <SnackbarProvider
              maxSnack={3}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
              <App instance={msalInstance} />
            </SnackbarProvider>
            {/* <Loader/> */}
          </AuthProvider>
        </CssBaseline>
      </ThemeProvider>
    </BrowserRouter>
  </React.StrictMode >
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
