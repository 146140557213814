import { Box, FormControl, Grid, Button, InputLabel, MenuItem, Paper, Select, TextField, Typography, Autocomplete, FormControlLabel, Checkbox } from '@mui/material'
import AddIcon from '@mui/icons-material/Add';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { VisuallyHiddenInput } from '../EmployeeData/EmployeeDocument';
import { useState } from 'react';
import { CertificatesApi } from '../../api/CertificatesApi';

// const priority =['High','Low','Normal'];

export const TrainForm = ({ data, setData, empList  }) => {
    const [filename, setFilename] = useState("");
    const empnames ={};
    Object.values(empList).map((val,key)=>{
        empnames[val.emp_uuid] = val.employee_name;
    })

    const handleAddInputData = (e) => {
        const { value, id } = e.target;
        setData((prevState) => ({
            ...prevState,
            [id]: value,
        }));
    };

    const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
                resolve(fileReader.result);
            };
            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };

    const handleAddData = (e, val) => {
        if (e && e.target) {
            const naam = e.target.id.split('-')[0];
            var flag = "other";
            CertificatesApi.map((value) => {
                if (val == value) {
                    flag = "";
                }
            })
            setData(prevState => ({
                ...prevState,
                [naam]: val,
                'Tag': flag
            }))
            console.log(data)
        }
    }

    const handleFileUpload = async (e) => {
        const file = e.target.files[0];
        setFilename(file.name);
        const base64 = await convertToBase64(file);
        setData(prevState => ({
            ...prevState,
            Document: base64
        }))
    };

    return (
        <Grid container columnSpacing={2} rowSpacing={4} pt={1.9}>
            <Grid item sm={12} md={6}>
                <TextField
                    required
                    value={data.topic}
                    id="topic"
                    label="Topic"
                    fullWidth
                    onChange={handleAddInputData}
                />
            </Grid>
            <Grid item sm={12} md={6}>
                <TextField
                    required
                    value={data.platform}
                    id="platform"
                    label="Platform Name"
                    fullWidth
                    onChange={handleAddInputData}
                />
            </Grid>
            <Grid item sm={12} md={6}>
                <TextField
                    type='date'
                    required
                    value={data.start_date}
                    id="start_date"
                    label="Start Date"
                    fullWidth
                    onChange={handleAddInputData}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Grid>
            <Grid item sm={12} md={6}>
                <TextField
                    type='date'
                    required
                    value={data.end_date}
                    id="end_date"
                    label="End Date"
                    fullWidth
                    onChange={handleAddInputData}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Grid>
            <Grid item sm={12} md={6}>
                <Autocomplete
                    multiple={true}
                    limitTags={2}
                    id={"assigned_to"}
                    value={data.assigned_to}
                    // onChange={(e, val) => handleChange(e, val, index, "assigned_to")}
                    options={Object.keys(empnames)}
                    getOptionLabel={(option) => (empnames[option])}
                    // getOptionSelected={(option, value) => empList[option].employee_name === value}
                    renderInput={(params) => <TextField {...params} label="Select Assignee" variant="outlined" />}
                    renderOption={(props, option, { selected }) => (
                        <li {...props}>
                            <FormControlLabel
                                control={<Checkbox checked={selected} />}
                                label={empnames[option]}
                            />
                        </li>
                    )}
                />
            </Grid>


        </Grid>
    );
};

